"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _LangSelect = _interopRequireDefault(require("@/components/LangSelect"));
var _SocialSignin = _interopRequireDefault(require("./components/SocialSignin"));
var _index = _interopRequireDefault(require("../admin/register_bak/create/index"));
var _config = _interopRequireDefault(require("../../../static/config"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Login',
  components: {
    LangSelect: _LangSelect.default,
    SocialSign: _SocialSignin.default,
    Register: _index.default
  },
  data: function data() {
    var _this = this;
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length == 0) {
        callback(new Error('密码不能为空'));
      } else if (value.length < 4) {
        callback(new Error('密码长度不能小于4'));
      } else {
        callback();
      }
    };
    var validatePass = function validatePass(rule, value, callback) {
      var reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,32}$/;
      if (value === '') {
        callback(new Error('请输入密码'));
      }
      // else if (!reg.test(value)) {
      //   callback(new Error('密码必须为6-32位数字、字母、符号的组合'))
      // } 
      else {
        if (_this.resetForm.confirmPassword !== '') {
          _this.$refs.resetForm.validateField('confirmPassword');
        }
        callback();
      }
    };
    var validatePass2 = function validatePass2(rule, value, callback) {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== _this.resetForm.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      usernameAlert: false,
      phoneAlert: false,
      loginForm: {
        tenant: undefined,
        username: '',
        password: '',
        client_id: _config.default.client.client_id,
        client_secret: _config.default.client.client_secret,
        grant_type: _config.default.client.grant_type,
        phoneNumber: '',
        loginType: 'userType',
        scop: ''
      },
      loginRules: {
        tenant: [
          // { required: true, message: '租户名称不能为空', trigger: 'blur' },
          // { pattern: /^(?![^A-Za-z]+$)[0-9A-Za-z_]{6,32}$/, message: '请输入正确的租户名', trigger: 'blur' }
        ],
        username: [{
          required: true,
          message: '用户名不能为空',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          trigger: 'blur',
          validator: validatePassword
        }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      pictrures: [require('@/assets/images/login-1.png'), require('@/assets/images/login-2.png'), require('@/assets/images/login-3.png'), require('@/assets/images/login-4.png')],
      backgroupImg: require('@/assets/images/background2.png'),
      logoImg: require('@/assets/images/logo1.png'),
      qrImg: "",
      qrlogoImg: require('@/assets/images/qrloginlogo.png'),
      openRegDialog: false,
      dialogResetVisible: false,
      resetForm: {
        tenantName: '',
        password: '',
        confirmPassword: '',
        phone: '',
        validCode: ''
      },
      resetRules: {
        tenantName: [{
          required: true,
          message: '租户名称不能为空',
          trigger: 'blur'
        }, {
          pattern: /^(?![^A-Za-z]+$)[0-9A-Za-z_]{6,32}$/,
          message: '请输入正确的租户名',
          trigger: 'blur'
        }],
        phone: [{
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }, {
          pattern: /^1[3456789]\d{9}$/,
          message: '请输入正确的手机号码',
          trigger: 'blur'
        }],
        validCode: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          validator: validatePass,
          trigger: 'blur'
        }],
        confirmPassword: [{
          required: true,
          validator: validatePass2,
          trigger: 'blur'
        }]
      }
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  created: function created() {
    // window.addEventListener('storage', this.afterQRScan)
  },
  destroyed: function destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    checkCapslock: function checkCapslock(e) {
      var key = e.key;
      this.capsTooltip = key && key.length === 1 && key >= 'A' && key <= 'Z';
    },
    showPwd: function showPwd() {
      var _this2 = this;
      if (this.passwordType === 'password') {
        this.passwordType = '';
      } else {
        this.passwordType = 'password';
      }
      this.$nextTick(function () {
        _this2.$refs.password.focus();
      });
    },
    handleLogin: function handleLogin() {
      var _this3 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          if (_this3.loginForm.loginType == 'phoneType') {
            if (_this3.loginForm.phoneNumber == null || _this3.loginForm.phoneNumber == '') {
              _this3.phoneAlert = true;
              _this3.usernameAlert = false;
              return false;
            } else {
              _this3.phoneAlert = false;
              _this3.usernameAlert = false;
            }
          } else if (_this3.loginForm.loginType == 'userType') {
            if (_this3.loginForm.username == null || _this3.loginForm.username == '') {
              _this3.usernameAlert = true;
              _this3.phoneAlert = false;
              return false;
            } else {
              _this3.usernameAlert = false;
              _this3.phoneAlert = false;
            }
          } else {
            _this3.usernameAlert = false;
            _this3.phoneAlert = false;
          }
          _this3.loading = true;
          _this3.$store.dispatch('user/userLogin', _this3.loginForm).then(function () {
            _this3.$router.push({
              path: _this3.redirect || '/',
              query: _this3.otherQuery
            });
            _this3.loading = false;
          }).catch(function () {
            _this3.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== 'redirect') {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    register: function register() {
      this.openRegDialog = true;
    },
    closeReg: function closeReg() {
      this.openRegDialog = false;
    },
    passwordReset: function passwordReset() {
      this.dialogResetVisible = true;
    },
    resetPassword: function resetPassword() {
      var _this4 = this;
      this.$refs.resetForm.validate(function (valid) {
        if (valid) {
          _this4.formLoading = true;
          _this4.$axios.posts('/api/saas/register/resetPassword', _this4.resetForm).then(function (response) {
            _this4.formLoading = false;
            _this4.$notify({
              title: '成功',
              message: '注册成功',
              type: 'success',
              duration: 2000
            });
            _this4.dialogResetVisible = false;
          }).catch(function () {
            _this4.formLoading = false;
          });
        }
      });
    },
    cancel: function cancel() {
      this.resetForm = {
        password: '',
        confirmPassword: '',
        phone: '',
        validCode: ''
      }, this.dialogResetVisible = false;
    },
    handleClick: function handleClick(tab, event) {
      if (this.loginForm.loginType == 'userType') {
        this.loginForm.password = '1q2w3E*';
        this.loginForm.client_id = _config.default.client.client_id;
        this.loginForm.client_secret = _config.default.client.client_secret;
        this.loginForm.grant_type = _config.default.client.grant_type;
        this.loginForm.scop = '';
        this.$refs.loginForm.validateField("password");
      } else if (this.loginForm.loginType == 'phoneType') {
        this.loginForm.password = '';
        this.loginForm.client_id = _config.default.clientPhone.client_id;
        this.loginForm.client_secret = _config.default.clientPhone.client_secret;
        this.loginForm.grant_type = _config.default.clientPhone.grant_type;
        this.loginForm.scop = 'phone';
      } else {
        this.loginForm.password = '';
        this.loginForm.client_id = '';
        this.loginForm.client_secret = '';
        this.loginForm.grant_type = '';
        this.loginForm.scop = '';
      }
    }
  }
};
exports.default = _default;