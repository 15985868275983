"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var _api = _interopRequireDefault(require("../api"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//表单默认值
var defaultForm = {
  id: null,
  approve: 1,
  approveIdea: ''
};
var _default = {
  name: 'merchantDelayCreate',
  directives: {
    permission: _index.default
  },
  props: {
    //是否显示窗口
    isShow: {
      type: Boolean
    },
    //窗口关闭事件回调
    closeCreate: {
      type: Function
    },
    //保存成功后回调
    callback: {
      type: Function
    },
    //是否是修改
    isEdit: {
      type: Boolean
    },
    //修改时传入的id
    id: {
      type: String
    },
    formTitle: {
      type: String
    }
  },
  watch: {
    isShow: function isShow(newVal) {
      this.dialogFormVisible = newVal; //对父组件传过来的值进行监听，如果改变也对子组件内部的值进行改变
      this.initData();
    },
    dialogFormVisible: function dialogFormVisible(newVal) {
      if (!newVal) {
        this.closeCreate(); //内部不关闭自己页面，会造成循环，让外部父组件来关闭
      }
    }
  },
  data: function data() {
    return {
      isAgent: false,
      //控制弹窗显示
      dialogFormVisible: false,
      //弹窗加载框
      formLoading: false,
      isTop: true,
      //表单定义 并赋默认值
      form: Object.assign({}, defaultForm),
      approveFlagMap: _enum.default.ApproveFlagMap,
      isApprove: false,
      //表单提交校验规则
      rules: {
        approve: [{
          required: true,
          message: '请选择审核状态',
          trigger: 'change'
        }, {
          pattern: /^\+?[1-9]\d*$/,
          message: '请选择审核状态',
          trigger: 'change'
        }],
        approveIdea: [{
          required: true,
          message: '请输入审核意见',
          trigger: 'blur'
        }]
      }
    };
  },
  created: function created() {
    this.isAgent = this.$store.state.user.userEx.isAgent;
  },
  methods: {
    /** 显示时初始化数据 */initData: function initData() {
      this.form = Object.assign({}, defaultForm);
      //如果是编辑状态，远程加载信息
      if (this.isEdit) {
        if (this.id) {
          this.fetchData(this.id);
        }
      }
    },
    /** 加载数据 */fetchData: function fetchData(id) {
      var _this = this;
      _api.default.get(id).then(function (response) {
        _this.form = response;
        if (response.approve == 0) {
          _this.isApprove = true;
        } else {
          _this.isApprove = false;
        }
      });
    },
    /**取消按钮 */cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
      this.form = Object.assign({}, defaultForm);
    },
    /** 审核 */approve: function approve() {
      var _this2 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this2.form.id) {
            _this2.$confirm('是否审核' + _this2.form.merchantName + '?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(function () {
              _api.default.approveById(_this2.form).then(function (response) {
                _this2.$notify({
                  title: '成功',
                  message: '审核成功',
                  type: 'success',
                  duration: 2000
                });
                _this2.dialogFormVisible = false;
                _this2.callback();
              });
            }).catch(function () {
              _this2.$message({
                type: 'info',
                message: '已取消'
              });
            });
          }
        }
      });
    }
  }
};
exports.default = _default;