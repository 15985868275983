"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _api = _interopRequireDefault(require("./api"));
var _index2 = _interopRequireDefault(require("@/components/MyElColumn/index.vue"));
var _SaasButtonGroup = _interopRequireDefault(require("@/saas/components/SaasButtonGroup.vue"));
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
//导入本功能的api接口

//导入创建窗口

var defaultForm = {
  remark: ""
};
var _default = {
  name: "orders",
  components: {
    Pagination: _Pagination.default,
    SaasButtonGroup: _SaasButtonGroup.default,
    MyElColumn: _index2.default
  },
  directives: {
    permission: _index.default
  },
  data: function data() {
    return {
      list: [],
      totalCount: 0,
      listLoading: true,
      listQuery: Object.assign({}, _api.default.listQuery),
      page: 1,
      multipleSelection: [],
      //显示创建窗口
      dialogFormVisible: false,
      formTitle: "",
      isEdit: false,
      editId: '',
      isTop: true,
      tableDef: _api.default.tableDef,
      merchantOrderTypeMap: _enum.default.merchantOrderType,
      merchantOrderStatusMap: _enum.default.merchantOrderSta,
      defaultProps: {
        children: 'children',
        label: 'text'
      },
      form: Object.assign({}, defaultForm),
      pickerOptions: _api.default.pickerOptions,
      dialogVisible: false,
      defaultKey: []
    };
  },
  created: function created() {
    this.$nextTick(function () {
      this.$refs.tree.setCurrentKey(0);
    });
    this.getList();
  },
  methods: {
    /** 更新 */update: function update(row) {
      console.info('详情');
      this.$router.push({
        path: '/merchantOrderCreate/index',
        query: {
          otherPageOpen: true,
          isEdit: true,
          id: row.id
        }
      });
    },
    ok: function ok(value) {
      var _this = this;
      _api.default.update(this.form).then(function (response) {
        _this.dialogVisible = false;
        _this.getList();
        _this.$notify({
          title: "成功",
          message: "保存成功",
          type: "success",
          duration: 2000
        });
      });
    },
    remark: function remark(row) {
      this.form = row;
      console.info("订单详情", this.form);
      this.dialogVisible = true;
    },
    copyOrderNo: function copyOrderNo(no) {
      var oInput = document.createElement("input");
      oInput.value = no;
      document.body.appendChild(oInput);
      oInput.select();
      console.log(oInput.value);
      document.execCommand("Copy");
      oInput.remove();
      this.$message({
        message: "复制成功",
        type: "success"
      });
    },
    cellStyle: function cellStyle(row) {
      return "border-right: 1px solid #EBEEF5;";
    },
    handleNodeClick: function handleNodeClick(data) {
      this.listQuery.orderType = data.value;
      this.getList();
      console.info('点击树形', data);
    },
    changeStatus: function changeStatus(val) {
      this.getList();
    },
    /** 获取分页列表 */getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      if (this.listQuery.DateRegion != null) {
        this.listQuery.start = new Date(this.listQuery.DateRegion[0]);
        this.listQuery.end = new Date(this.listQuery.DateRegion[1]);
      } else {
        this.listQuery.start = null;
        this.listQuery.end = null;
      }
      this.listQuery.SkipCount = (this.page - 1) * this.listQuery.MaxResultCount;
      this.list = [];
      _api.default.getAll(this.listQuery).then(function (response) {
        _this2.list = response.items;
        _this2.totalCount = response.totalCount;
        _this2.listLoading = false;
      });
    },
    /**  排序 */sortChange: function sortChange(data) {
      var prop = data.prop,
        order = data.order;
      if (!prop || !order) {
        this.Filter();
        return;
      }
      this.listQuery.Sorting = prop + ' ' + order;
      this.Filter();
    },
    handleCreateCallback: function handleCreateCallback(val) {
      this.getList();
    },
    /** 搜索事件 */Search: function Search() {
      this.page = 1;
      this.getList();
    },
    /** 新增按钮点击事件 */Create: function Create() {
      this.formTitle = "产品";
      console.info(this.listQuery);
      this.isEdit = false;
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    /** 新增窗口关闭事件 */handleCloseCreate: function handleCloseCreate() {
      this.dialogFormVisible = false;
    },
    Delete: function Delete() {
      var _this3 = this;
      if (this.multipleSelection.length == 0) {
        this.$message({
          message: "删除必须选择单行",
          type: "warning"
        });
        return;
      }
      if (this.multipleSelection.length > 1) {
        this.$alert("暂时不支持用户批量删除", "提示", {
          confirmButtonText: "确定",
          callback: function callback(action) {
            _this3.getList();
          }
        });
        return;
      }
      this.$confirm("是否删除" + this.multipleSelection[0].title + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _api.default.deleteById(_this3.multipleSelection[0].id).then(function (response) {
          _this3.$notify({
            title: "成功",
            message: "删除成功",
            type: "success",
            duration: 2000
          });
          if (_this3.totalCount - 1 <= _this3.listQuery.SkipCount) {
            _this3.Search();
          } else {
            _this3.getList();
          }
        });
      }).catch(function () {
        _this3.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    /** 更新 */Update: function Update(row) {
      this.formTitle = "产品";
      this.isEdit = true;
      if (row) {
        this.editId = row.id;
        this.dialogFormVisible = true;
      } else {
        if (this.multipleSelection.length != 1) {
          this.$message({
            message: "编辑必须选择单行",
            type: "warning"
          });
          return;
        } else {
          this.editId = this.multipleSelection[0].id;
          this.dialogFormVisible = true;
        }
      }
    },
    /** 多选事件 */handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      console.info(this.multipleSelection);
    },
    /** 行点击事件 */handleRowClick: function handleRowClick(row, column, event) {
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row);
    }
  }
};
exports.default = _default;