var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.settings, function (item) {
          return _c(
            "el-tab-pane",
            {
              key: item.groupName,
              attrs: {
                label: _vm.$t(item.groupDisplayName),
                name: item.groupName,
              },
            },
            [
              _vm.formLoading
                ? _c("el-form", {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.formLoading,
                        expression: "formLoading",
                      },
                    ],
                    staticStyle: { height: "720px" },
                    attrs: {
                      "element-loading-text": "拼命加载中",
                      "element-loading-spinner": "el-icon-loading",
                    },
                  })
                : _vm._e(),
              _c(
                "el-form",
                _vm._l(item.settingGroups, function (group) {
                  return _c("div", { key: group.groupName }, [
                    _c(
                      "div",
                      {
                        staticStyle: { color: "red" },
                        attrs: { id: group.groupDisplayName },
                      },
                      [
                        _c(
                          "el-card",
                          [
                            _c(
                              "div",
                              {
                                staticClass: "clearfix",
                                attrs: { slot: "header" },
                                slot: "header",
                              },
                              [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t(group.groupDisplayName))
                                  ),
                                ]),
                              ]
                            ),
                            _vm._l(group.settingInfos, function (detail) {
                              return _c(
                                "el-form-item",
                                {
                                  key: detail.name,
                                  staticStyle: { width: "450px" },
                                  attrs: {
                                    label: _vm.$t(detail.displayName),
                                    "label-width": "200px",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      directives: [
                                        {
                                          name: "popover",
                                          rawName: "v-popover",
                                          value: detail.name,
                                          expression: "detail.name",
                                        },
                                      ],
                                      attrs: { slot: "label" },
                                      slot: "label",
                                    },
                                    [_vm._v(_vm._s(_vm.$t(detail.displayName)))]
                                  ),
                                  detail.properties.Type === "text" &&
                                  detail.properties.select !== true
                                    ? _c("el-input", {
                                        staticStyle: { width: "450px" },
                                        attrs: {
                                          size: "mini",
                                          placeholder: _vm.$t(
                                            detail.description
                                          ),
                                          type: detail.isEncrypted
                                            ? "password"
                                            : "text",
                                          "show-password": detail.isEncrypted,
                                        },
                                        on: {
                                          input: function (value) {
                                            return _vm.handleSettingValueChanged(
                                              detail.name,
                                              value
                                            )
                                          },
                                        },
                                        model: {
                                          value: detail.value,
                                          callback: function ($$v) {
                                            _vm.$set(detail, "value", $$v)
                                          },
                                          expression: "detail.value",
                                        },
                                      })
                                    : _vm._e(),
                                  detail.properties.Type === "number"
                                    ? _c("el-input-number", {
                                        attrs: {
                                          size: "mini",
                                          min: 1,
                                          max: 9999,
                                          label: _vm.$t(detail.description),
                                        },
                                        on: {
                                          change: function (value) {
                                            _vm.handleSettingValueChanged(
                                              detail.name,
                                              value
                                            )
                                          },
                                        },
                                        model: {
                                          value: detail.value,
                                          callback: function ($$v) {
                                            _vm.$set(detail, "value", $$v)
                                          },
                                          expression: "detail.value",
                                        },
                                      })
                                    : _vm._e(),
                                  detail.properties.Type === "checkbox"
                                    ? _c("el-checkbox", {
                                        attrs: { size: "mini" },
                                        on: {
                                          change: function (value) {
                                            _vm.handleSettingValueChanged(
                                              detail.name,
                                              value
                                            )
                                          },
                                        },
                                        model: {
                                          value: detail.value,
                                          callback: function ($$v) {
                                            _vm.$set(detail, "value", $$v)
                                          },
                                          expression: "detail.value",
                                        },
                                      })
                                    : _vm._e(),
                                  detail.properties.Type === "switch"
                                    ? _c("el-switch", {
                                        attrs: { size: "mini" },
                                        on: {
                                          change: function (value) {
                                            _vm.handleSettingValueChanged(
                                              detail.name,
                                              value
                                            )
                                          },
                                        },
                                        model: {
                                          value: detail.value,
                                          callback: function ($$v) {
                                            _vm.$set(detail, "value", $$v)
                                          },
                                          expression: "detail.value",
                                        },
                                      })
                                    : _vm._e(),
                                  detail.properties.Type === "select"
                                    ? _c(
                                        "el-select",
                                        {
                                          staticStyle: { width: "450px" },
                                          attrs: { size: "mini" },
                                          on: {
                                            change: function (value) {
                                              _vm.handleSettingValueChanged(
                                                detail.name,
                                                value
                                              )
                                            },
                                          },
                                          model: {
                                            value: detail.value,
                                            callback: function ($$v) {
                                              _vm.$set(detail, "value", $$v)
                                            },
                                            expression: "detail.value",
                                          },
                                        },
                                        _vm._l(
                                          detail.properties.Options,
                                          function (option) {
                                            return _c("el-option", {
                                              key: option.name,
                                              attrs: {
                                                label: option.name,
                                                value: option.value,
                                                disabled:
                                                  option.value === detail.value,
                                              },
                                            })
                                          }
                                        ),
                                        1
                                      )
                                    : _vm._e(),
                                  detail.properties.Type === "image"
                                    ? _c("SaasUplaod", {
                                        attrs: {
                                          callback: _vm.uploadIcon,
                                          imgUrl: detail.value,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ])
                }),
                0
              ),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "el-button",
        {
          staticClass: "save-button",
          attrs: { type: "primary", loading: _vm.formLoading },
          on: { click: _vm.onSavesetting },
        },
        [_vm._v(" 保存 ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }