var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.dialogFormVisible,
        title: _vm.formTitle,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: function ($event) {
          return _vm.cancel()
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            inline: true,
            model: _vm.form,
            rules: _vm.rules,
            size: "small",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司名称：", prop: "companyName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "170px" },
                    attrs: {
                      "auto-complete": "off",
                      placeholder: "输入公司名称",
                      "prefix-icon": "el-icon-user",
                    },
                    model: {
                      value: _vm.form.companyName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "companyName", $$v)
                      },
                      expression: "form.companyName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "联系人", prop: "contacts" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "170px" },
                    attrs: {
                      "auto-complete": "off",
                      placeholder: "输入联系人名称",
                      "prefix-icon": "el-icon-user",
                    },
                    model: {
                      value: _vm.form.contacts,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "contacts", $$v)
                      },
                      expression: "form.contacts",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "类型", prop: "agentType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "170px" },
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.agentType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "agentType", $$v)
                        },
                        expression: "form.agentType",
                      },
                    },
                    _vm._l(_vm.agentType, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.text, value: item.value },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "手机号", prop: "tel" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "170px" },
                    attrs: {
                      "auto-complete": "off",
                      placeholder: "输入手机号",
                      "prefix-icon": "el-icon-user",
                      disabled: _vm.form.approve == 1,
                    },
                    model: {
                      value: _vm.form.tel,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "tel", $$v)
                      },
                      expression: "form.tel",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { prop: "areaData", label: "代理商区域" } },
                [
                  _c("Area", {
                    attrs: { width: "170px" },
                    model: {
                      value: _vm.form.areaData,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "areaData", $$v)
                      },
                      expression: "form.areaData",
                    },
                  }),
                ],
                1
              ),
              _c("PageSelectFormItem", {
                attrs: {
                  width: "170px",
                  allowcreate: true,
                  selectType: "salesMan",
                  prop: "salesManName",
                  selectDataChange: _vm.selectbranch,
                },
                model: {
                  value: _vm.form.salesManName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "salesManName", $$v)
                  },
                  expression: "form.salesManName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { staticStyle: { width: "270px" }, attrs: { label: "OEM" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                    },
                    model: {
                      value: _vm.form.isOEM,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "isOEM", $$v)
                      },
                      expression: "form.isOEM",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "domainName", label: "域名" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "170px" },
                    attrs: {
                      oninput: "if(value.length>200)value=value.slice(0,200)",
                      "auto-complete": "off",
                    },
                    model: {
                      value: _vm.form.domainName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "domainName", $$v)
                      },
                      expression: "form.domainName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "热线电话", prop: "serviceHotline" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "170px" },
                    attrs: { maxlength: "20", placeholder: "请输入热线电话" },
                    model: {
                      value: _vm.form.serviceHotline,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "serviceHotline", $$v)
                      },
                      expression: "form.serviceHotline",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "短信签名", prop: "signName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "170px" },
                    attrs: { maxlength: "20", placeholder: "请输入短信签名" },
                    model: {
                      value: _vm.form.signName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "signName", $$v)
                      },
                      expression: "form.signName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "公司地址", prop: "companyAddress" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "450px" },
                    attrs: {
                      "auto-complete": "off",
                      placeholder: "请填写公司地址",
                    },
                    model: {
                      value: _vm.form.companyAddress,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "companyAddress", $$v)
                      },
                      expression: "form.companyAddress",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "memo" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "450px" },
                    attrs: {
                      "auto-complete": "off",
                      placeholder: "请填写备注",
                    },
                    model: {
                      value: _vm.form.memo,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "memo", $$v)
                      },
                      expression: "form.memo",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "创建日期", prop: "creationTime" } },
                [
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm._f("formatDateTime")(_vm.form.creationTime))
                      ),
                    ]),
                  ],
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "到期时间", prop: "stopDate" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "200px" },
                    attrs: {
                      type: "datetime",
                      "default-time": "23:59:59",
                      clearable: false,
                      editable: false,
                      placeholder: "选择到期时间",
                    },
                    model: {
                      value: _vm.form.stopDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "stopDate", $$v)
                      },
                      expression: "form.stopDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { staticStyle: { width: "450px" }, attrs: { label: "启用" } },
                [
                  _c("el-switch", {
                    attrs: {
                      "active-color": "#13ce66",
                      "inactive-color": "#ff4949",
                    },
                    model: {
                      value: _vm.form.status,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "status", $$v)
                      },
                      expression: "form.status",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("el-row"),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "text" },
              on: { click: _vm.cancel },
            },
            [_vm._v("取消")]
          ),
          _vm.form.approve == 0 && _vm.isEdit
            ? _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    loading: _vm.formLoading,
                    type: "danger",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.approve(_vm.form.id)
                    },
                  },
                },
                [_vm._v("审核")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: {
                size: "small",
                loading: _vm.formLoading,
                type: "primary",
              },
              on: { click: _vm.submitData },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }