var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    { staticClass: "dashboard-editor-container", attrs: { gutter: 10 } },
    [
      _c("el-col", { style: { width: _vm.contentWidth } }),
      _c(
        "div",
        {
          staticStyle: {
            width: "256px",
            background: "#fff",
            overflow: "hidden",
            position: "absolute",
            right: "0",
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showHelp,
                  expression: "showHelp",
                },
              ],
              staticClass: "help",
            },
            [
              _c("HelpAndService", {
                on: { changeShowHelp: _vm.changeShowHelp },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }