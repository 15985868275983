"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _api = _interopRequireDefault(require("@/views/saasService/agent/api.js"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _api2 = _interopRequireDefault(require("../api"));
var _SassUpload = _interopRequireDefault(require("@/saas/components/SassUpload.vue"));
var _config = _interopRequireDefault(require("../../../../../static/config"));
var _axios = _interopRequireDefault(require("@/axios"));
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var _SaasInputDialogs = _interopRequireDefault(require("@/saas/components/SaasInputDialogs.vue"));
//表单默认值
var defaultForm = {
  id: null,
  title: '',
  versions: '',
  environment: '',
  url: '',
  status: true,
  warnLevel: 20
};
var _default = {
  name: "item_brandCreate",
  components: {
    Pagination: _Pagination.default,
    SaasUplaod: _SassUpload.default,
    config: _config.default,
    SaasInputDialog: _SaasInputDialogs.default
  },
  directives: {
    permission: _index.default
  },
  props: [
  //是否显示窗口
  'isShow',
  //窗口关闭事件回调
  'closeCreate',
  //保存成功后回调
  'callback',
  //是否是修改
  'isEdit',
  //修改时传入的id
  'id',
  //标题
  'formTitle'],
  watch: {
    isShow: function isShow(newVal) {
      this.dialogFormVisible = newVal; //对父组件传过来的值进行监听，如果改变也对子组件内部的值进行改变
      this.initData(); //在显示的时候处理数据才能生效,因为显示的值在每次打开时肯定是变动的，
    },
    dialogFormVisible: function dialogFormVisible(newVal) {
      if (!newVal) {
        this.closeCreate(); //内部不关闭自己页面，会造成循环，让外部父组件来关闭
      }
    }
  },
  data: function data() {
    return {
      //控制弹窗显示
      dialogFormVisible: false,
      //弹窗加载框
      formLoading: false,
      //表单提交校验规则
      rules: {
        title: [{
          required: true,
          message: "请填写标题",
          trigger: "blur"
        }],
        versions: [{
          required: true,
          message: "请填写版本",
          trigger: "blur"
        }],
        environment: [{
          required: true,
          message: "请填写运行环境",
          trigger: "blur"
        }],
        url: [{
          required: true,
          message: "请填写下载地址",
          trigger: "blur"
        }],
        packageType: [{
          required: true,
          message: "请填写安装包类型",
          trigger: "blur"
        }],
        posType: [{
          required: true,
          message: "请填写包类型",
          trigger: "blur"
        }]
      },
      //表单定义 并赋默认值
      form: Object.assign({}, defaultForm),
      disabled: true,
      checked: true,
      storageApi: _config.default.storage.ip,
      options: _enum.default.packageType,
      optionsPosType: _enum.default.posTypeMap,
      warnLevel: _enum.default.warnLevelMap,
      agentApi: _api.default,
      agentListQuery: {
        filter: '',
        skipCount: 0,
        maxResultCount: 10,
        approve: 1
      }
    };
  },
  created: function created() {},
  methods: {
    /** 显示时初始化数据 */initData: function initData() {
      this.checked = true;
      this.disabled = false;
      this.orgs = [];
      this.form = Object.assign({}, defaultForm);
      //如果是编辑状态，远程加载信息
      if (this.isEdit) {
        if (this.id != 0 && this.id != undefined) {
          this.checked = false;
          this.disabled = true;
          this.fetchData(this.id);
        }
      }
    },
    /** 加载数据 */fetchData: function fetchData(id) {
      var _this = this;
      this.formLoading = true;
      _api2.default.get(id).then(function (response) {
        _this.form = response;
        _this.formLoading = false;
      });
    },
    /**保存 */save: function save() {
      var _this2 = this;
      this.$refs.upload.submit();
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this2.isEdit) {
            _this2.formLoading = true;
            _api2.default.update(_this2.form).then(function (response) {
              console.info(response);
              _this2.formLoading = false;
              _this2.$notify({
                title: "成功",
                message: "更新成功",
                type: "success",
                duration: 2000
              });
              _this2.dialogFormVisible = false;
              _this2.callback();
            }).catch(function () {
              _this2.formLoading = false;
            });
          } else {
            _this2.formLoading = true;
            _api2.default.create(_this2.form).then(function (response) {
              _this2.formLoading = false;
              _this2.$notify({
                title: "成功",
                message: "新增成功",
                type: "success",
                duration: 2000
              });
              if (_this2.checked == true) {
                _this2.$refs.form.clearValidate();
                _this2.form = Object.assign({}, defaultForm);
                _this2.orgs = [];
                _this2.callback();
              } else {
                _this2.dialogFormVisible = false;
                _this2.callback();
              }
            }).catch(function () {
              _this2.formLoading = false;
            });
          }
        }
      });
    },
    /**取消按钮 */cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
      this.form = Object.assign({}, defaultForm);
    },
    uploadIcon: function uploadIcon(val) {
      this.form.img = val;
    },
    beforeUpload: function beforeUpload(file) {
      var isLt2M = true;
      isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.loading = false;
        this.$message.error("上传文件大小不能超过 100MB!");
      }
      return isLt2M;
    },
    handleSuccess: function handleSuccess(response, file, fileList) {
      console.log("fff" + file.size / 1024 / 1024 + "ddd" + JSON.stringify(file.url));
      this.$notify({
        title: "成功",
        message: "上传成功",
        type: "success",
        duration: 2000
      });
      this.dialogFormVisible = false;
      this.getList();
    },
    // 监听上传失败
    handleError: function handleError(e, file, fileList) {
      this.$notify({
        title: e,
        type: "error",
        duration: 4000
      });
      this.loading = false;
    },
    onUploadChange: function onUploadChange(file) {},
    // 上传文件之前的钩子, 参数为上传的文件,若返回 false，则停止上传
    beforeUploadFile: function beforeUploadFile(file) {
      var extension = file.name.substring(file.name.lastIndexOf(".") + 1);
      if (extension !== "exe") {
        alert('文件格式不正确');
        return false;
      }
      this.fileTemp = file;
      console.log(file, "file------");
      if (file) {
        if (file.raw.type == "application/octet-stream") {
          this.customUpload(this.fileTemp);
        } else {
          this.$message({
            type: "warning",
            message: "附件格式错误，请删除后重新上传"
          });
        }
      } else {
        this.$message({
          type: "warning",
          message: "请上传附件"
        });
      }
    },
    //上传文件
    customUpload: function customUpload(file) {
      var _this3 = this;
      var FormDatas = new FormData();
      FormDatas.append('file', file.file);
      var url = this.storageApi + '/api/FileService/file/upload?business=agent&reName=false&name=' + file.name;
      _axios.default.posts(url, FormDatas).then(function (res) {
        var urlUp = _this3.storageApi + '/api/FileService/file?blobName=' + res.fileIData.blobName;
        _axios.default.gets(urlUp).then(function (response) {
          _this3.form.url = response.fileIData.url;
          _this3.form.fileLength = '10';
        });

        // var url =this.storageApi +"/" +  res.fileInfo.url;
        // this.form.url = url;
        // this.form.fileLength = res.fileInfo.size;

        // this.form.fileLength = (file.size/1024/1024).toFixed(2);
      });
    },
    selectAgentCallback: function selectAgentCallback() {}
  }
};
exports.default = _default;