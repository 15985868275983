var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.dialogFormVisible,
        title: _vm.formTitle,
        width: "650px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: function ($event) {
          return _vm.cancel()
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            inline: true,
            model: _vm.form,
            rules: _vm.rules,
            size: "small",
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "shopName", label: "商户名" } },
            [
              _c("el-input", {
                staticStyle: { width: "210px" },
                attrs: {
                  oninput: "if(value.length>20)value=value.slice(0,20)",
                  "auto-complete": "off",
                },
                model: {
                  value: _vm.form.shopName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "shopName", $$v)
                  },
                  expression: "form.shopName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "contacts", label: "联系人" } },
            [
              _c("el-input", {
                staticStyle: { width: "210px" },
                attrs: {
                  oninput: "if(value.length>10)value=value.slice(0,10)",
                  "auto-complete": "off",
                  "prefix-icon": "el-icon-user",
                },
                model: {
                  value: _vm.form.contacts,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "contacts", $$v)
                  },
                  expression: "form.contacts",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "shopType", label: "店铺类型" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "210px" },
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.form.shopType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "shopType", $$v)
                    },
                    expression: "form.shopType",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.text, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "phone", label: "手机号码" } },
            [
              _c("el-input", {
                staticStyle: { width: "210px" },
                attrs: {
                  "auto-complete": "off",
                  "prefix-icon": "el-icon-mobile-phone",
                  disabled: !_vm.showPhone,
                },
                model: {
                  value: _vm.form.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "areas", label: "地区" } },
            [
              _c("Area", {
                attrs: { width: "210px" },
                model: {
                  value: _vm.form.areas,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "areas", $$v)
                  },
                  expression: "form.areas",
                },
              }),
            ],
            1
          ),
          !_vm.isEdit
            ? _c(
                "el-form-item",
                { attrs: { prop: "VerificationCode", label: "验证码" } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "210px" },
                      attrs: {
                        "auto-complete": "off",
                        placeholder: "输入验证码",
                        "prefix-icon": "el-icon-s-promotion",
                      },
                      on: { change: _vm.confirmPhone },
                      model: {
                        value: _vm.form.VerificationCode,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "VerificationCode", $$v)
                        },
                        expression: "form.VerificationCode",
                      },
                    },
                    [
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showTime,
                              expression: "showTime",
                            },
                          ],
                          attrs: { slot: "append" },
                          on: { click: _vm.sendMsg },
                          slot: "append",
                        },
                        [_vm._v("获取验证码")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.showTime,
                              expression: "!showTime",
                            },
                          ],
                          attrs: { slot: "append" },
                          slot: "append",
                        },
                        [_vm._v(_vm._s(_vm.sendTime) + "秒")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "saasProductId", label: "软件版本" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "210px" },
                  attrs: {
                    clearable: "",
                    placeholder: "请选择",
                    disabled: _vm.isEdit,
                  },
                  model: {
                    value: _vm.form.saasProductId,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "saasProductId", $$v)
                    },
                    expression: "form.saasProductId",
                  },
                },
                _vm._l(_vm.products, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: {
                      label: item.title,
                      disabled:
                        item.unit != 0 ||
                        item.id == "3a115243-79a5-efd8-14b6-d57000389942",
                      value: item.id,
                    },
                  })
                }),
                1
              ),
            ],
            1
          ),
          !_vm.isAgent
            ? _c(
                "el-form-item",
                { attrs: { label: "代理商" } },
                [
                  _c("SaasInputDialog", {
                    staticStyle: { width: "210px" },
                    attrs: {
                      api: _vm.agentApi,
                      title: "选择代理商",
                      hasTree: false,
                      displayText: "companyName",
                      displayId: "agentCode",
                      callback: _vm.selectAgentCallback,
                      listQuery: _vm.agentListQuery,
                      hasRows: false,
                    },
                    model: {
                      value: _vm.form.agent,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "agent", $$v)
                      },
                      expression: "form.agent",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "address", label: "地址" } },
            [
              _c("el-input", {
                staticStyle: { width: "510px" },
                attrs: {
                  oninput: "if(value.length>25)value=value.slice(0,25)",
                  "auto-complete": "off",
                },
                model: {
                  value: _vm.form.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "memo", label: "备注" } },
            [
              _c("el-input", {
                staticStyle: { width: "510px" },
                attrs: {
                  oninput: "if(value.length>20)value=value.slice(0,20)",
                  "auto-complete": "off",
                },
                model: {
                  value: _vm.form.memo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "memo", $$v)
                  },
                  expression: "form.memo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "companyCode", label: "企业编号" } },
            [
              _c("el-input", {
                staticStyle: { width: "210px" },
                attrs: {
                  oninput: "if(value.length>20)value=value.slice(0,20)",
                  "auto-complete": "off",
                },
                model: {
                  value: _vm.form.companyCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "companyCode", $$v)
                  },
                  expression: "form.companyCode",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { width: "290px" }, attrs: { label: "已续费" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  disabled: _vm.isAgent,
                },
                model: {
                  value: _vm.form.isOfficial,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "isOfficial", $$v)
                  },
                  expression: "form.isOfficial",
                },
              }),
            ],
            1
          ),
          !_vm.form.isOfficial && _vm.isEdit
            ? _c("el-form-item", { attrs: { label: "试用天数" } }, [
                _c("div", { staticStyle: { width: "210px" } }, [
                  _vm._v(_vm._s(_vm.form.testDay) + "天"),
                ]),
              ])
            : _vm._e(),
          _vm.isEdit
            ? _c(
                "div",
                [
                  _c(
                    "el-form-item",
                    { attrs: { prop: "branchNum", label: "门店数" } },
                    [
                      _c("div", { staticStyle: { width: "210px" } }, [
                        _vm._v(_vm._s(_vm.form.branchNum)),
                      ]),
                    ]
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "posNum", label: "POS点数" } },
                    [
                      _c("el-input-number", {
                        staticStyle: { width: "210px" },
                        attrs: {
                          min: 0,
                          precision: 0,
                          controls: false,
                          disabled: _vm.isAgent,
                        },
                        model: {
                          value: _vm.form.posNum,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "posNum", $$v)
                          },
                          expression: "form.posNum",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("el-form-item", { attrs: { label: "创建日期" } }, [
                    _c("div", { staticStyle: { width: "210px" } }, [
                      _vm._v(
                        _vm._s(_vm._f("formatDateTime")(_vm.form.creationTime))
                      ),
                    ]),
                  ]),
                  _c("el-form-item", { attrs: { label: "到期时间" } }, [
                    _c(
                      "div",
                      { staticStyle: { width: "210px" } },
                      [
                        _vm._v(
                          _vm._s(_vm._f("formatDateTime")(_vm.form.stopDate)) +
                            " "
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-left": "20px" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                _vm.delayVisible = true
                              },
                            },
                          },
                          [_vm._v("申请延期")]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      staticStyle: { width: "290px" },
                      attrs: { label: "提前到期" },
                    },
                    [
                      _c("el-switch", {
                        attrs: {
                          "active-color": "#13ce66",
                          "inactive-color": "#ff4949",
                        },
                        model: {
                          value: _vm.form.isBeforeStop,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isBeforeStop", $$v)
                          },
                          expression: "form.isBeforeStop",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.isBeforeStop
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { prop: "beforeStopDate", label: "提前时间" },
                        },
                        [
                          _c("el-date-picker", {
                            staticStyle: { width: "210px" },
                            attrs: {
                              type: "datetime",
                              "default-time": "23:59:59",
                              clearable: false,
                              editable: false,
                              placeholder: "选择提前时间",
                            },
                            model: {
                              value: _vm.form.beforeStopDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "beforeStopDate", $$v)
                              },
                              expression: "form.beforeStopDate",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isEdit
                    ? _c(
                        "el-form-item",
                        {
                          staticStyle: { "padding-left": "200px" },
                          attrs: { prop: "MerchantRenewal" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.Renewal(
                                    _vm.form.id,
                                    _vm.form.agent
                                  )
                                },
                              },
                            },
                            [_vm._v("商户续费")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { staticStyle: { width: "500px" }, attrs: { label: "启用" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                },
                model: {
                  value: _vm.form.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "status", $$v)
                  },
                  expression: "form.status",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                float: "left",
                "font-size": "12px",
                color: "#555",
                "font-style": "italic",
                "line-height": "30px",
              },
            },
            [
              _vm._v(
                " 提示：软件登录账号密码会通过短信发送到注册的手机号，请注意查收！"
              ),
            ]
          ),
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancel } },
            [_vm._v("取消")]
          ),
          _vm.form.approve == 0 && _vm.isEdit
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["Saas.Business.Approve"],
                      expression: "['Saas.Business.Approve']",
                    },
                  ],
                  attrs: {
                    size: "small",
                    loading: _vm.formLoading,
                    type: "danger",
                  },
                  on: { click: _vm.approve },
                },
                [_vm._v("审核")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: {
                size: "small",
                loading: _vm.formLoading,
                type: "primary",
              },
              on: { click: _vm.handleSave },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "450px",
            title: "申请延期",
            visible: _vm.delayVisible,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.delayVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "delayForm",
              attrs: { model: _vm.delayForm, "label-width": "80px" },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "延期天数" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, precision: 0, controls: false },
                    model: {
                      value: _vm.delayForm.delayDay,
                      callback: function ($$v) {
                        _vm.$set(_vm.delayForm, "delayDay", $$v)
                      },
                      expression: "delayForm.delayDay",
                    },
                  }),
                  _vm._v(" 天 "),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "申请原因" } },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 3,
                      maxlength: 200,
                      "show-word-limit": true,
                    },
                    model: {
                      value: _vm.delayForm.delayReason,
                      callback: function ($$v) {
                        _vm.$set(_vm.delayForm, "delayReason", $$v)
                      },
                      expression: "delayForm.delayReason",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.delayVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.approveDelay } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.appdialogVisible,
            width: "690px",
            title: "商户续费",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.appdialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            _vm._l(_vm.platformApp, function (e, i) {
              return _c("el-col", { key: i, attrs: { span: 6 } }, [
                _c(
                  "div",
                  { staticClass: "application" },
                  [
                    e.buyStatus == 1
                      ? _c(
                          "el-tag",
                          {
                            staticClass: "app-status",
                            staticStyle: { color: "#1890ff" },
                            attrs: { effect: "plain", type: "text" },
                          },
                          [_vm._v("使用中")]
                        )
                      : _vm._e(),
                    e.buyStatus == 2
                      ? _c(
                          "el-tag",
                          {
                            staticClass: "app-status",
                            staticStyle: { color: "red" },
                            attrs: { effect: "plain", type: "text" },
                          },
                          [_vm._v("已到期")]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "app-ico" }, [
                      _c("img", {
                        staticClass: "app-logo",
                        attrs: { src: require("@/assets/images/logo.png") },
                      }),
                    ]),
                    _c("div", { staticClass: "app-desc" }, [
                      _c("div", { staticClass: "app-name" }, [
                        _vm._v(_vm._s(e.appName)),
                      ]),
                      _c(
                        "div",
                        { staticClass: "app-price" },
                        [
                          _vm._v(" ￥ "),
                          _c("span", [_vm._v(_vm._s(e.price))]),
                          _vm._v(
                            " /" +
                              _vm._s(
                                _vm._f("findEnumKey")(
                                  e.unit,
                                  _vm.productUnitType
                                )
                              ) +
                              " "
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "app-buy",
                              attrs: {
                                type: "primary",
                                size: "mini",
                                round: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.buy(e)
                                },
                              },
                            },
                            [_vm._v("购买")]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                ),
              ])
            }),
            1
          ),
          _c("el-divider"),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _vm._l(_vm.products, function (item) {
                return _c("el-col", { key: item.id, attrs: { span: 6 } }, [
                  _c("div", { staticClass: "pro" }, [
                    _c("div", { staticClass: "app-ico" }, [
                      _c("img", {
                        staticClass: "app-logo",
                        attrs: { src: require("@/assets/images/logo.png") },
                      }),
                    ]),
                    _c("div", { staticClass: "title" }, [
                      _vm._v(_vm._s(item.title)),
                    ]),
                    _c("div", { staticClass: "config" }),
                    _c(
                      "div",
                      { staticClass: "price" },
                      [
                        _vm._v(
                          "￥" +
                            _vm._s(item.price) +
                            " /" +
                            _vm._s(
                              _vm._f("findEnumKey")(
                                item.unit,
                                _vm.productUnitType
                              )
                            ) +
                            " "
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "app-buy",
                            attrs: { type: "primary", size: "mini", round: "" },
                            on: {
                              click: function ($event) {
                                return _vm.buyapp(item.id)
                              },
                            },
                          },
                          [_vm._v("购买")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ])
              }),
              _c(
                "el-dialog",
                {
                  staticClass: "payCode",
                  attrs: {
                    visible: _vm.isShowCode,
                    "append-to-body": true,
                    title: "扫码支付",
                    width: "350px",
                    center: "",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.isShowCode = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c("vue-qr", {
                        attrs: {
                          logoSrc: _vm.imageUrl,
                          text: _vm.codeurl,
                          size: 180,
                        },
                      }),
                      _c("div", { staticClass: "tips" }, [
                        _vm.seconds > 60
                          ? _c(
                              "label",
                              [
                                _vm._v(
                                  " 界面等待超时，请手动查询结果或刷新页面。 "
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "primary",
                                      round: "",
                                      size: "mini",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.getOrder("orderManual")
                                      },
                                    },
                                  },
                                  [_vm._v("手动查询支付结果")]
                                ),
                              ],
                              1
                            )
                          : _c("label", [
                              _vm._v(
                                "等待支付中（" + _vm._s(_vm.seconds) + "秒）"
                              ),
                            ]),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            2
          ),
          _c("MyCar", {
            attrs: {
              data: _vm.app,
              isShow: _vm.dialogFormVisiblenew,
              closeCreate: _vm.handleCloseCreate,
              callback: _vm.handleCreateCallback,
              formTitle: "扫码支付",
              TenantId: this.id,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }