"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("@/axios"));
var _pos_down_def = _interopRequireDefault(require("@/saas/table-def/pos_down_def.js"));
var tableDef = _pos_down_def.default.table;
function get(id) {
  return _axios.default.gets("/api/BDService/bdPosDown/" + id);
}
function getByIds(ids) {
  return _axios.default.gets("/api/BDService/bdPosDown/byIds?ids=" + ids);
}
function getpname(no) {
  return _axios.default.gets("/api/BDService/bdPosDown/pname?pno=" + no);
}
function getAll(data) {
  return _axios.default.gets("/api/BDService/bdPosDown", data);
}
function deleteById(id) {
  return _axios.default.deletes("/api/BDService/bdPosDown/" + id);
}
function update(data) {
  return _axios.default.puts("/api/BDService/bdPosDown", data);
}
function create(data) {
  return _axios.default.posts("/api/BDService/bdPosDown", data);
}
function uploadFile(name) {
  return _axios.default.gets("/api/FileService/file?blobName=" + name);
}
var _default = {
  get: get,
  getpname: getpname,
  getAll: getAll,
  deleteById: deleteById,
  update: update,
  create: create,
  getByIds: getByIds,
  tableDef: tableDef,
  uploadFile: uploadFile
};
exports.default = _default;