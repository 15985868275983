"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("@/axios"));
var _agent_def = _interopRequireDefault(require("@/saas/table-def/agent_def.js"));
var tableDef = _agent_def.default.table;
function get(id) {
  return _axios.default.gets("/api/SettingService/agent/" + id);
}
function getAll(data) {
  return _axios.default.gets("/api/SettingService/agent", data);
}
function getByIds(ids) {
  return _axios.default.gets("/api/SettingService/agent/byIds?ids=" + ids);
}
function deleteById(id) {
  return _axios.default.deletes("/api/SettingService/agent/" + id);
}
function create(data) {
  return _axios.default.posts("/api/SettingService/agent", data);
}
function approveById(data) {
  return _axios.default.gets("/api/SettingService/agent/approve", data);
}
function give(data) {
  return _axios.default.posts("/api/SettingService/agent/give", data);
}
function status(data) {
  return _axios.default.posts("/api/SettingService/agent/status", data);
}
var _default = {
  get: get,
  getAll: getAll,
  getByIds: getByIds,
  deleteById: deleteById,
  create: create,
  approveById: approveById,
  give: give,
  status: status,
  tableDef: tableDef
};
exports.default = _default;