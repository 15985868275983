var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "reg-form",
      attrs: {
        "append-to-body": "",
        "close-on-click-modal": false,
        visible: _vm.dialogFormVisible,
        title: "协议详情",
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: function ($event) {
          return _vm.cancel()
        },
      },
    },
    [_vm._v(" 用户协议 ")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }