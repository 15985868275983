"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find");
var _SaasSelectDialogs = _interopRequireDefault(require("@/saas/components/SaasSelectDialogs.vue"));
var _jszip = require("jszip");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    SaasSelectDialog: _SaasSelectDialogs.default
  },
  props: {
    callback: Function,
    api: Object,
    title: String,
    displayText: String,
    displayId: String,
    hasTree: Boolean,
    hasRows: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    size: String,
    value: String,
    type: String,
    showTag: Boolean,
    tagDisplayText: String,
    tagEnum: Array,
    disabled: Boolean,
    isfilter: "",
    listQuery: Object,
    //是否显示右侧图标
    isIco: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    }
  },
  watch: {
    isfilter: function isfilter(val) {
      //console.info("***********************************", val);
    },
    value: function value(val) {
      // console.info("监听到值变化~~~~~~~~~~~~~~~~~~~~~~",val)
      this.getData(this.value);
    },
    // 选择后要更改这个值
    selectId: function selectId(val) {
      //console.info("watch val", val);
      //清空按钮显示/隐藏
      if (val != null) {
        this.circleclose = false;
      } else {
        this.circleclose = false;
        this.selectName = "";
        this.selectId = null;
        this.showTag = false;
        this.callback(null);
      }
      this.$emit("input", val); //v-model实现的是v-bind:value 和v-on:input，这句更新父级组件的value
    },
    selectName: function selectName(val) {
      if (val == null || val === "" || val === undefined) {
        this.selectName = "";
        this.selectId = null;
        this.showTag = false;
        this.callback(null);
      }
    }
  },
  data: function data() {
    return {
      circleclose: false,
      list: [],
      dialogVisible: false,
      selectName: "",
      selectId: this.value,
      placeholder: "请选择",
      titleName: "选择",
      tagName: "",
      tagType: "",
      isInit: false
    };
  },
  mounted: function mounted() {
    //console.log("mounted");
    if (this.title == null) {
      this.title = "";
    }
    this.placeholder += this.title;
    this.titleName += this.title;
  },
  created: function created() {
    //console.log("created");
    if (!this.isInit) {
      this.getData(this.value);
    }
  },
  methods: {
    enterSearch: function enterSearch() {
      this.dialogVisible = true;
      this.$refs.SaasSelectDialog.SearchByParent(this.selectName);
    },
    clear: function clear() {
      this.selectName = "";
      this.selectId = null;
      this.showTag = false;
      this.callback(null);
    },
    icoClick: function icoClick() {
      if (this.disabled) {} else {
        this.dialogVisible = true;
      }
    },
    SelectRow: function SelectRow(rows) {
      var _this = this;
      this.selectName = "";
      this.dialogVisible = false;
      var ids = "";
      if (rows.length == 1) {
        if (rows[0].length > 1) {
          for (var index = 0; index <= rows.length; index++) {
            ids = ids + rows[0][index].id + ",";
            var value = "[" + rows[0][index][this.displayId] + "] " + rows[0][index][this.displayText];
            this.selectName = this.selectName + value + ",";
          }
          this.selectName = this.selectName.substring(0, this.selectName.lastIndexOf(","));
          this.selectId = ids;
        } else {
          var firstName = rows[0][this.displayId];
          var secondName = rows[0][this.displayText];
          this.selectName = "[" + firstName + "] " + secondName;
          this.selectId = rows[0].id;
          if (this.showTag) {
            var tagData = this.tagEnum.find(function (x) {
              return x.value == rows[0][_this.tagDisplayText];
            });
            // var tagData = this.tagEnum[];
            this.tagName = tagData.text;
            if (tagData.type != null && tagData.type != "") {
              this.tagType = tagData.type;
            }
          }
        }
        // this.callback(rows);
      } else if (rows.length > 1) {
        for (var _index = 0; _index < rows.length; _index++) {
          ids = ids + rows[_index].id + ",";
          var value = "[" + rows[_index][this.displayId] + "] " + rows[_index][this.displayText];
          this.selectName = this.selectName + value + ",";
        }
        this.selectName = this.selectName.substring(0, this.selectName.lastIndexOf(","));
        this.selectId = ids.lastIndexOf(",");
        this.selectId = ids;
        // this.callback(rows);
      } else {}
      this.callback(rows);
    },
    closeEven: function closeEven(val) {
      this.dialogVisible = false;
    },
    getData: function getData(val) {
      var _this2 = this;
      console.log(val, 88888888);
      if (val) {
        //外部绑定的值如果第一次是有值的，从这里传入
        this.selectId = val;
        if (val.indexOf(",") != -1) {
          this.api.getByIds(val).then(function (result) {
            _this2.list = [];
            _this2.list = result;
            // console.log("找到这条id的结果", result);
            //给input赋值
            // this.list.push(result);
            _this2.SelectRow(result);
          }).catch(function (err) {});
        } else {
          this.api.get(val).then(function (result) {
            _this2.list = [];
            // console.log("找到这条id的结果", result);
            //给input赋值
            _this2.list.push(result);
            _this2.SelectRow(_this2.list);
          }).catch(function (err) {});
        }
        //找到这个值的名称
      }

      if (val == null) {
        this.selectName = "";
        this.selectId = null;
      }
    }
  }
};
exports.default = _default2;