"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.replace");
// 开发环境
var _default = {
  base: {
    ip: process.env.VUE_APP_BASE_API.replace("www.", "allingateway.").replace(".xxxxxxxxx.xxx", window.location.hostname.substring(window.location.hostname.indexOf('.')))
  },
  basic: {},
  storage: {
    ip: process.env.VUE_APP_STORAGE_API.replace("www.", "allingateway.").replace(".xxxxxxxxx.xxx", window.location.hostname.substring(window.location.hostname.indexOf('.')))
  },
  auth: {
    ip: process.env.VUE_APP_AUTHSERVER_API.replace("www.", "allinauth.").replace(".xxxxxxxxx.xxx", window.location.hostname.substring(window.location.hostname.indexOf('.')))
  },
  crm: {
    ip: 'http://localhost'
  },
  oms: {
    ip: 'http://localhost'
  },
  wx: {
    ip: 'http://localhost',
    basicPort: ''
  },
  client: {
    client_id: 'basic-web',
    client_secret: '1q2w3e*',
    grant_type: 'password'
  },
  clientPhone: {
    client_id: 'phonenumber_app',
    client_secret: '1q2w3e*',
    grant_type: 'phonenumber_credentials',
    scop: 'phone'
  }
}; // 测试环境
// export default {
// }
// 发布环境
// export default {
// }
exports.default = _default;