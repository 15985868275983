var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-input",
        {
          attrs: {
            size: _vm.size,
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            clearable: "",
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.enterSearch($event)
            },
          },
          model: {
            value: _vm.selectName,
            callback: function ($$v) {
              _vm.selectName = $$v
            },
            expression: "selectName",
          },
        },
        [
          _vm.isIco
            ? _c(
                "template",
                { slot: "suffix" },
                [
                  _vm.showTag && _vm.tagName != ""
                    ? _c(
                        "el-tag",
                        { attrs: { type: _vm.tagType, size: "mini" } },
                        [_vm._v(_vm._s(_vm.tagName))]
                      )
                    : _vm._e(),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.circleclose,
                        expression: "!circleclose",
                      },
                    ],
                    staticClass: "el-input__icon el-icon-more",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.icoClick($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _c(
                "template",
                { slot: "suffix" },
                [
                  _vm.showTag && _vm.tagName != ""
                    ? _c(
                        "el-tag",
                        { attrs: { type: _vm.tagType, size: "mini" } },
                        [_vm._v(_vm._s(_vm.tagName))]
                      )
                    : _vm._e(),
                ],
                1
              ),
        ],
        2
      ),
      _c("SaasSelectDialog", {
        ref: "SaasSelectDialog",
        attrs: {
          isShow: _vm.dialogVisible,
          closeEven: _vm.closeEven,
          api: _vm.api,
          SelectRow: _vm.SelectRow,
          title: _vm.titleName,
          hasTree: _vm.hasTree,
          hasRows: _vm.hasRows,
          isfilter: _vm.isfilter,
          listQuery: _vm.listQuery,
          type: _vm.type,
        },
        model: {
          value: _vm.selectId,
          callback: function ($$v) {
            _vm.selectId = $$v
          },
          expression: "selectId",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }