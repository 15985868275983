var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            [
              _c(
                "div",
                { staticClass: "head-container" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: {
                        model: _vm.listQuery,
                        "label-width": "80px",
                        size: "mini",
                      },
                    },
                    [
                      _c(
                        "el-row",
                        { attrs: { size: "mini" } },
                        [
                          _c(
                            "el-col",
                            { attrs: { size: "mini" } },
                            [
                              _c("SaasButtonGroup", {
                                attrs: {
                                  BtnType: "report",
                                  Query: _vm.Search,
                                  Print: _vm.Print,
                                  Export: _vm.Export,
                                  PrintPermission: [
                                    "Saas.BusinessReport.Print",
                                  ],
                                  ExportPermission: [
                                    "Saas.BusinessReport.Export",
                                  ],
                                },
                              }),
                              _c("el-date-picker", {
                                staticStyle: {
                                  "margin-left": "10px",
                                  width: "250px",
                                },
                                attrs: {
                                  size: "mini",
                                  type: "daterange",
                                  align: "right",
                                  "unlink-panels": "",
                                  "range-separator": "至",
                                  "start-placeholder": "开始日期",
                                  "end-placeholder": "结束日期",
                                  format: "yyyy-MM-dd",
                                  "picker-options": _vm.pickerOptions,
                                  "default-time": ["00:00:00", "23:59:59"],
                                  clearable: false,
                                },
                                on: { change: _vm.changeTime },
                                model: {
                                  value: _vm.DateRegion,
                                  callback: function ($$v) {
                                    _vm.DateRegion = $$v
                                  },
                                  expression: "DateRegion",
                                },
                              }),
                              _c(
                                "el-radio-group",
                                {
                                  staticClass: "select-time",
                                  staticStyle: { "padding-left": "20px" },
                                  on: { change: _vm.selectTime },
                                  model: {
                                    value: _vm.timePicker,
                                    callback: function ($$v) {
                                      _vm.timePicker = $$v
                                    },
                                    expression: "timePicker",
                                  },
                                },
                                [
                                  _c("el-radio", { attrs: { label: 1 } }, [
                                    _vm._v("今天"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 2 } }, [
                                    _vm._v("昨天"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 3 } }, [
                                    _vm._v("本周"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 4 } }, [
                                    _vm._v("上周"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 5 } }, [
                                    _vm._v("本月"),
                                  ]),
                                  _c("el-radio", { attrs: { label: 6 } }, [
                                    _vm._v("上月"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "search-group" },
                        [
                          _c(
                            "el-row",
                            { staticStyle: { margin: "10px 0" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "商户ID" } },
                                [
                                  _c("el-input", {
                                    staticClass: "filter-item",
                                    staticStyle: { width: "185px" },
                                    attrs: {
                                      placeholder: "商户ID",
                                      "prefix-icon": "el-icon-search",
                                    },
                                    model: {
                                      value: _vm.listQuery.merchantId,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.listQuery,
                                          "merchantId",
                                          $$v
                                        )
                                      },
                                      expression: "listQuery.merchantId",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { label: "代理商" } },
                                [
                                  _c("SaasInputDialog", {
                                    staticStyle: { width: "185px" },
                                    attrs: {
                                      api: _vm.agentApi,
                                      title: "选择代理商",
                                      hasTree: false,
                                      displayText: "companyName",
                                      displayId: "agentCode",
                                      callback: _vm.selectAgentCallback,
                                      listQuery: _vm.agentListQuery,
                                      type: "id",
                                      hasRows: false,
                                    },
                                    model: {
                                      value: _vm.listQuery.agentId,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "agentId", $$v)
                                      },
                                      expression: "listQuery.agentId",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-divider", { staticClass: "el-divider-thin" }),
              _c(
                "div",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        id: "saleTableId",
                        stripe: "",
                        "highlight-current-row": "",
                        "row-key": "id",
                        data: _vm.list,
                        size: "mini",
                      },
                      on: {
                        "sort-change": _vm.sortChange,
                        "selection-change": _vm.handleSelectionChange,
                        "row-click": _vm.handleRowClick,
                      },
                    },
                    [
                      _c("MyElColumn", {
                        attrs: { tableDef: _vm.tableDef, Update: _vm.Update },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.totalCount > 0,
                    expression: "totalCount > 0",
                  },
                ],
                attrs: {
                  total: _vm.totalCount,
                  page: _vm.page,
                  limit: _vm.listQuery.MaxResultCount,
                },
                on: {
                  "update:page": function ($event) {
                    _vm.page = $event
                  },
                  "update:limit": function ($event) {
                    return _vm.$set(_vm.listQuery, "MaxResultCount", $event)
                  },
                  pagination: _vm.getList,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("SmallTicket", {
        attrs: {
          isEdit: _vm.isEdit,
          id: _vm.editId,
          isShow: _vm.dialogFormVisible,
          closeCreate: _vm.handleCloseCreate,
          callback: _vm.handleCreateCallback,
        },
      }),
      _c("SaasTablePrint", {
        ref: "tableContent",
        attrs: {
          tableHeader: _vm.tableDef,
          tableData: _vm.multipleSelection,
          title: "商户报表",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }