"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var table = [{
  Display: true,
  DisplayType: "text",
  Label: "应用名称",
  Name: "appName",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "应用Id",
  Name: "clientId",
  Sort: false,
  Align: "center",
  Width: "100",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: false,
  DisplayType: "text",
  Label: "应用秘钥",
  Name: "clientSecret",
  Sort: false,
  Align: "",
  Width: "270",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "tagenum",
  DisplayTypeEnum: _enum.default.appType,
  Label: "应用类型",
  Name: "appType",
  Sort: false,
  Align: "center",
  Width: "120",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "零售价",
  Name: "price",
  Sort: false,
  Align: "center",
  Width: "100",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "代理商价",
  Name: "agentPrice",
  Sort: false,
  Align: "center",
  Width: "100",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "tagenum",
  DisplayTypeEnum: _enum.default.productUnitType,
  Label: "单位",
  Name: "unit",
  Sort: false,
  Align: "center",
  Width: "100",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "tagenum",
  DisplayTypeEnum: _enum.default.accessType,
  Label: "开放范围",
  Name: "accessType",
  Sort: false,
  Align: "center",
  Width: "120",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "tagenum",
  DisplayTypeEnum: _enum.default.ApproveFlagMap,
  Label: "审核状态",
  Name: "approve",
  Sort: false,
  Align: "center",
  Width: "100",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "bool",
  Label: "状态",
  Name: "status",
  Sort: false,
  Align: "center",
  Width: "80",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "datetime",
  Label: "创建时间",
  Name: "creationTime",
  Sort: false,
  Align: "center",
  Width: "135",
  IsLink: false,
  NavDisplayProp: ""
}];
var _default = {
  table: table
};
exports.default = _default;