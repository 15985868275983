"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("@/axios"));
function get(id, service, type) {
  if (type == 'published') {
    return _axios.default.gets("/api/BDService/published/" + id);
  }
  if (type == 'received') {
    return _axios.default.gets("/api/BDService/received/" + id);
  }
}
function getAll(data, service, type) {
  return _axios.default.gets("/api/" + service + '/' + type, data);
}

// 供下拉组件传参
var listQuery = {
  filter: "",
  statusName: "",
  SkipCount: 0,
  MaxResultCount: 10
};
var _default = {
  get: get,
  getAll: getAll,
  listQuery: listQuery
};
exports.default = _default;