"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var table = [{
  Display: true,
  DisplayType: "text",
  Label: "编号",
  Name: "agentCode",
  Sort: false,
  Align: "",
  Width: "70",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "代理商公司名称",
  Name: "companyName",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "tagenum",
  DisplayTypeEnum: _enum.default.agentType,
  Label: "代理商等级",
  Name: "agentType",
  Sort: false,
  Align: "center",
  Width: "90",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "商户数量",
  Name: "businessQty",
  Sort: false,
  Align: "center",
  Width: "70",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "日均笔数",
  Name: "daysSaleQty",
  Sort: false,
  Align: "center",
  Width: "70",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "日均金额",
  Name: "daysSaleAmt",
  Sort: false,
  Align: "center",
  Width: "70",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "总站点数",
  Name: "pointQty",
  Sort: false,
  Align: "center",
  Width: "70",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "已用点数",
  Name: "pointUseQty",
  Sort: false,
  Align: "center",
  Width: "70",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "剩余点数",
  Name: "pointRemainQty",
  Sort: false,
  Align: "center",
  Width: "70",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "业务员",
  Name: "salesManName",
  Sort: false,
  Align: "center",
  Width: "100",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "联系人",
  Name: "contacts",
  Sort: false,
  Align: "center",
  Width: "100",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "联系电话",
  Name: "tel",
  Sort: false,
  Align: "center",
  Width: "100",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: false,
  DisplayType: "tagenum",
  DisplayTypeEnum: _enum.default.ApproveFlagMap,
  Label: "审核状态",
  Name: "approve",
  Sort: false,
  Align: "center",
  Width: "100",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "bool",
  Label: "状态",
  Name: "status",
  Sort: false,
  Align: "center",
  Width: "50",
  IsLink: false,
  NavDisplayProp: ""
}];
var _default = {
  table: table
};
exports.default = _default;