"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _api = _interopRequireDefault(require("./api"));
var _index2 = _interopRequireDefault(require("@/components/MyElColumn/index.vue"));
var _index3 = _interopRequireDefault(require("./create/index.vue"));
var _index4 = _interopRequireDefault(require("./give/index.vue"));
var _index5 = _interopRequireDefault(require("../merchant/create/index.vue"));
var _SaasButtonGroup = _interopRequireDefault(require("@/saas/components/SaasButtonGroup.vue"));
var _Area = _interopRequireDefault(require("@/components/Area"));
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
var _methods;
var _default = {
  name: "agent",
  components: {
    Pagination: _Pagination.default,
    MyCreate: _index3.default,
    SaasButtonGroup: _SaasButtonGroup.default,
    MyElColumn: _index2.default,
    Area: _Area.default,
    MyMerchantCreate: _index5.default,
    MyGive: _index4.default,
    QRCode: _qrcodejs.default
  },
  directives: {
    permission: _index.default
  },
  data: function data() {
    return {
      //是否是门店登录
      isBranch: false,
      list: [],
      totalCount: 0,
      listLoading: true,
      listQuery: {
        filter: "",
        SkipCount: 0,
        MaxResultCount: 10,
        approve: 0,
        agentType: null,
        province: "",
        city: "",
        county: ""
      },
      num: null,
      page: 1,
      multipleSelection: [],
      //显示创建窗口
      dialogFormVisible: false,
      formTitle: "",
      isEdit: false,
      editId: "",
      isTop: true,
      tableDef: _api.default.tableDef,
      agentType: _enum.default.agentType,
      areaData: [],
      tabPosition: "first",
      businessDialogVisible: false,
      giveDialogVisible: false,
      agentInfo: null,
      imgsrc: require("@/assets/images/agent.png"),
      dialogVisible: false,
      qrcode: null,
      qr: null
    };
  },
  created: function created() {
    this.isBranch = this.$store.state.user.userEx.isBranch;
    this.getList();
  },
  watch: {
    areaData: function areaData(val) {
      if (val && Array.isArray(val)) {
        if (val[0] != undefined) {
          this.listQuery.province = val[0];
        } else {
          this.listQuery.province = null;
        }
        if (val[1] != undefined) {
          this.listQuery.city = val[1];
        } else {
          this.listQuery.city = null;
        }
        if (val[2] != undefined) {
          this.listQuery.county = val[2];
        } else {
          this.listQuery.county = null;
        }
      }
    }
  },
  methods: (_methods = {
    /** 获取分页列表 */getList: function getList() {
      var _this = this;
      this.listLoading = true;
      this.listQuery.SkipCount = (this.page - 1) * this.listQuery.MaxResultCount;
      this.list = [];
      _api.default.getAll(this.listQuery).then(function (response) {
        _this.list = response.items;
        _this.totalCount = response.totalCount;
        _this.listLoading = false;
      });
    },
    /**  排序 */sortChange: function sortChange(data) {
      var prop = data.prop,
        order = data.order;
      if (!prop || !order) {
        this.Filter();
        return;
      }
      this.listQuery.Sorting = prop + " " + order;
      this.Filter();
    },
    /** 搜索事件 */Search: function Search() {
      this.page = 1;
      this.getList();
    },
    /** 多选事件 */handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
      console.info(this.multipleSelection);
    },
    /** 行点击事件 */handleRowClick: function handleRowClick(row, column, event) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    Create: function Create() {
      this.formTitle = "创建代理商";
      this.isEdit = false;
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    /** 新增窗口关闭事件 */handleCloseCreate: function handleCloseCreate() {
      this.dialogFormVisible = false;
      //   this.qr = null;
      //   this.qrcode = null;
      //   document.getElementById("qrcode").innerHTML = "";
    },
    /** 新增成功刷新列表*/handleCreateCallback: function handleCreateCallback(val) {
      this.getList();
    },
    /** 删除 */Delete: function Delete(row) {
      var _this2 = this;
      if (row && row.id) {
        this.$confirm("是否删除" + row.companyName + "?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _api.default.deleteById(row.id).then(function (response) {
            _this2.$notify({
              title: "成功",
              message: "删除成功",
              type: "success",
              duration: 2000
            });
            _this2.getList();
          });
        }).catch(function () {
          _this2.$message({
            type: "info",
            message: "已取消删除"
          });
        });
      }
    },
    Update: function Update(row) {
      console.log("修改代理商");
      this.formTitle = "修改代理商";
      this.isEdit = true;
      if (row) {
        this.editId = row.id;
        this.dialogFormVisible = true;
        // if (this.qr == null) {
        //   this.qrcode = this.editId;
        //   this.qr = new QRCode("qrcode", {
        //     width: 150,
        //     height: 150, // 高度
        //     text: this.qrcode, // 二维码内容
        //   });
        // }
      } else {
        if (this.multipleSelection.length != 1) {
          this.$message({
            message: "编辑必须选择单行",
            type: "warning"
          });
          return;
        } else {
          this.editId = this.multipleSelection[0].id;
          this.dialogFormVisible = true;
        }
      }
    }
  }, (0, _defineProperty2.default)(_methods, "handleSelectionChange", function handleSelectionChange(val) {
    this.multipleSelection = val;
  }), (0, _defineProperty2.default)(_methods, "handleRowClick", function handleRowClick(row, column, event) {
    this.$refs.multipleTable.clearSelection();
    this.$refs.multipleTable.toggleRowSelection(row);
  }), (0, _defineProperty2.default)(_methods, "approve", function approve(row) {
    var _this3 = this;
    if (row && row.id) {
      if (row.approve) {
        this.$message({
          message: "当前数据状态已审核，不可重复审核！",
          type: "warning"
        });
        return;
      } else {
        this.$confirm("是否审核" + row.companyName + "?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _api.default.approveById({
            id: row.id
          }).then(function (response) {
            _this3.$notify({
              title: "成功",
              message: "审核成功",
              type: "success",
              duration: 2000
            });
            _this3.getList();
          });
        }).catch(function () {
          _this3.$message({
            type: "info",
            message: "已取消"
          });
        });
      }
    }
  }), (0, _defineProperty2.default)(_methods, "showBusiness", function showBusiness(row) {
    this.$router.push({
      name: "merchant",
      params: {
        agent: row.id
      }
    });
  }), (0, _defineProperty2.default)(_methods, "addBusiness", function addBusiness(row) {
    this.businessDialogVisible = true;
  }), (0, _defineProperty2.default)(_methods, "businessDialogClose", function businessDialogClose() {
    this.businessDialogVisible = false;
  }), (0, _defineProperty2.default)(_methods, "businessDialogCreateCallback", function businessDialogCreateCallback() {
    this.businessDialogVisible = false;
  }), (0, _defineProperty2.default)(_methods, "give", function give(row) {
    this.giveDialogVisible = true;
    this.agentInfo = row;
  }), (0, _defineProperty2.default)(_methods, "giveDialogClose", function giveDialogClose() {
    this.giveDialogVisible = false;
  }), (0, _defineProperty2.default)(_methods, "giveDialogCreateCallback", function giveDialogCreateCallback() {
    this.getList();
  }), (0, _defineProperty2.default)(_methods, "changeStatus", function changeStatus(row) {
    var _this4 = this;
    this.formLoading = true;
    _api.default.status(row).then(function (response) {
      _this4.formLoading = false;
      _this4.$notify({
        title: "成功",
        type: "success",
        duration: 2000
      });
      _this4.dialogFormVisible = false;
    }).catch(function () {
      _this4.formLoading = false;
    });
  }), _methods)
};
exports.default = _default;