"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _config = _interopRequireDefault(require("../../../static/config"));
var _elementUi = require("element-ui");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "loginPlatform",
  components: {},
  data: function data() {
    var _this = this;
    var validatePassword = function validatePassword(rule, value, callback) {
      if (value.length < 6) {
        callback(new Error("请输入密码,长度必须有6位"));
      } else {
        callback();
      }
    };
    var validatePass = function validatePass(rule, value, callback) {
      var reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*,.#?&])[a-zA-Z\d@$!%,.*#?&]{6,}$/;
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (!reg.test(value)) {
        callback(new Error("必须包含大小写字母、数字、特殊字符(至少6位)"));
      } else {
        if (_this.resetForm.confirmPassword !== "") {
          _this.$refs.resetForm.validateField("confirmPassword");
        }
        callback();
      }
    };
    var validatePass2 = function validatePass2(rule, value, callback) {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== _this.resetForm.Password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loginMode: "agent",
      showTime: true,
      sendTime: "",
      timer: null,
      dialogResetVisible: false,
      openRegDialog: false,
      loginForm: {
        tenant: "",
        Username: "",
        PhoneNumber: "",
        Password: "",
        client_id: _config.default.client.client_id,
        client_secret: _config.default.client.client_secret,
        grant_type: _config.default.client.grant_type,
        scop: "",
        loginType: "userType"
      },
      resetForm: {
        Password: "",
        confirmPassword: "",
        PhoneNumber: "",
        VerificationCode: "",
        grant_type: _config.default.clientPhone.grant_type,
        client_id: _config.default.clientPhone.client_id,
        client_secret: _config.default.clientPhone.client_secret,
        scop: _config.default.clientPhone.scop,
        VerificationCodeType: "",
        TemplateCodeName: "哇哇来客"
      },
      loginRules: {
        Username: [{
          required: true,
          message: "请输入用户名",
          trigger: "blur"
        }],
        Password: [{
          required: true,
          trigger: "blur",
          validator: validatePassword
        }]
      },
      resetRules: {
        PhoneNumber: [{
          required: true,
          message: "请输入手机号码",
          trigger: "blur"
        }, {
          pattern: /^1[3456789]\d{9}$/,
          message: "请输入正确的手机号码",
          trigger: "blur"
        }],
        VerificationCode: [{
          required: true,
          message: "请输入验证码",
          trigger: "blur"
        }],
        Password: [{
          required: true,
          validator: validatePass,
          trigger: "blur"
        }],
        confirmPassword: [{
          required: true,
          validator: validatePass2,
          trigger: "blur"
        }]
      },
      loading: false,
      redirect: undefined,
      otherQuery: {},
      pictrures: [require("@/assets/images/login-1.png"), require("@/assets/images/login-2.png"), require("@/assets/images/login-3.png"), require("@/assets/images/login-4.png")],
      token: ""
    };
  },
  watch: {
    $route: {
      handler: function handler(route) {
        var query = route.query;
        if (query) {
          this.redirect = query.redirect;
          this.otherQuery = this.getOtherQuery(query);
        }
      },
      immediate: true
    }
  },
  methods: {
    sendMsg: function sendMsg() {
      var _this2 = this;
      // 发送验证码
      this.resetForm.VerificationCodeType = 4;
      this.$axios.postAuth("/api/auth/phone/send-verification-code", this.resetForm).then(function () {
        var TIME_COUNT = 60;
        if (!_this2.timer) {
          _this2.sendTime = TIME_COUNT;
          _this2.showTime = false;
          _this2.timer = setInterval(function () {
            if (_this2.sendTime > 1 && _this2.sendTime <= TIME_COUNT) {
              _this2.sendTime--;
            } else {
              _this2.showTime = true;
              clearInterval(_this2.timer);
              _this2.timer = null;
            }
          }, 1000);
        }
      }).catch(function (err) {
        (0, _elementUi.Message)({
          message: "获取验证码失败，请联系管理员！",
          type: "error",
          duration: 5 * 1000
        });
      });
    },
    confirmPhone: function confirmPhone() {
      var _this3 = this;
      // 输入验证码后确认手机号
      this.$axios.postAuth("/api/auth/phone/confirm-phone-number", this.resetForm).then(function () {
        // 确认成功后获取token
        _this3.resetForm.VerificationCodeType = 3;
        _this3.$axios.postAuth("/api/auth/phone/send-verification-code", _this3.resetForm).then(function (res) {
          _this3.token = res.data.resetPwdToken;
        }).catch(function (err) {
          (0, _elementUi.Message)({
            message: "获取token失败，请联系管理员！",
            type: "error",
            duration: 5 * 1000
          });
        });
      }).catch(function (err) {
        (0, _elementUi.Message)({
          message: "确认手机号失败，请输入正确的验证码！",
          type: "error",
          duration: 5 * 1000
        });
      });
    },
    handleLogin: function handleLogin() {
      var _this4 = this;
      this.$refs.loginForm.validate(function (valid) {
        if (valid) {
          _this4.loading = true;
          //this.loginForm.loginMode = this.loginMode;
          _this4.$store.dispatch("user/userLogin", _this4.loginForm).then(function () {
            _this4.$router.push({
              path: _this4.redirect || "/",
              query: _this4.otherQuery
            });
            _this4.loading = false;
          }).catch(function () {
            _this4.loading = false;
          });
        } else {
          return false;
        }
      });
    },
    getOtherQuery: function getOtherQuery(query) {
      return Object.keys(query).reduce(function (acc, cur) {
        if (cur !== "redirect") {
          acc[cur] = query[cur];
        }
        return acc;
      }, {});
    },
    passwordReset: function passwordReset() {
      this.dialogResetVisible = true;
    },
    cancel: function cancel() {
      this.resetForm = {
        password: "",
        confirmPassword: "",
        phone: "",
        validCode: ""
      }, this.dialogResetVisible = false;
    },
    resetPassword: function resetPassword() {
      var _this5 = this;
      this.$refs.resetForm.validate(function (valid) {
        if (valid) {
          _this5.formLoading = true;
          _this5.$axios.postAuth("/api/auth/phone/reset-password", {
            grant_type: _config.default.clientPhone.grant_type,
            client_id: _config.default.clientPhone.client_id,
            client_secret: _config.default.clientPhone.client_secret,
            scop: _config.default.clientPhone.scop,
            VerificationCode: _this5.token,
            PhoneNumber: _this5.resetForm.PhoneNumber,
            Password: _this5.resetForm.Password
          }).then(function () {
            _this5.formLoading = false;
            _this5.$notify({
              title: "成功",
              message: "修改密码成功",
              type: "success",
              duration: 2000
            });
            _this5.dialogResetVisible = false;
          }).catch(function () {
            _this5.formLoading = false;
            (0, _elementUi.Message)({
              message: "修改密码失败，请联系管理员！",
              type: "error",
              duration: 5 * 1000
            });
          });
        }
      });
    },
    register: function register() {
      this.openRegDialog = true;
    },
    closeReg: function closeReg() {
      this.openRegDialog = false;
    }
  }
};
exports.default = _default;