"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _slicedToArray2 = _interopRequireDefault(require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime-corejs2/helpers/slicedToArray.js"));
require("core-js/modules/web.dom.iterable");
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
require("core-js/modules/es6.function.name");
var state = {
  visitedViews: [],
  cachedViews: []
};
var mutations = {
  ADD_VISITED_VIEW: function ADD_VISITED_VIEW(state, view) {
    if (view.name === 'page-details') {
      view.meta.title = view.query.title;
      if (state.visitedViews.some(function (v) {
        return v.fullPath === view.fullPath;
      })) return;else state.visitedViews.push(Object.assign({}, view, {
        title: view.meta.title || 'no-name'
      }));
    } else {
      if (state.visitedViews.some(function (v) {
        return v.path === view.path;
      })) return;else state.visitedViews.push(Object.assign({}, view, {
        title: view.meta.title || 'no-name'
      }));
    }
  },
  ADD_CACHED_VIEW: function ADD_CACHED_VIEW(state, view) {
    if (state.cachedViews.includes(view.name)) return;
    // if (!view.meta.noCache) {
    state.cachedViews.push(view.name);
    // }
  },

  DEL_VISITED_VIEW: function DEL_VISITED_VIEW(state, view) {
    var _iterator = (0, _createForOfIteratorHelper2.default)(state.visitedViews.entries()),
      _step;
    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _step$value = (0, _slicedToArray2.default)(_step.value, 2),
          i = _step$value[0],
          v = _step$value[1];
        if (v.fullPath === view.fullPath) {
          state.visitedViews.splice(i, 1);
          break;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  },
  DEL_CACHED_VIEW: function DEL_CACHED_VIEW(state, view) {
    var index = state.cachedViews.indexOf(view.name);
    console.log("DEL_CACHED_VIEW", index + view.name);
    index > -1 && state.cachedViews.splice(index, 1);
  },
  DEL_OTHERS_VISITED_VIEWS: function DEL_OTHERS_VISITED_VIEWS(state, view) {
    state.visitedViews = state.visitedViews.filter(function (v) {
      return v.meta.affix || v.path === view.path;
    });
  },
  DEL_OTHERS_CACHED_VIEWS: function DEL_OTHERS_CACHED_VIEWS(state, view) {
    var index = state.cachedViews.indexOf(view.name);
    if (index > -1) {
      state.cachedViews = state.cachedViews.slice(index, index + 1);
    } else {
      // if index = -1, there is no cached tags
      state.cachedViews = [];
    }
  },
  DEL_ALL_VISITED_VIEWS: function DEL_ALL_VISITED_VIEWS(state) {
    // keep affix tags
    var affixTags = state.visitedViews.filter(function (tag) {
      return tag.meta.affix;
    });
    state.visitedViews = affixTags;
  },
  DEL_ALL_CACHED_VIEWS: function DEL_ALL_CACHED_VIEWS(state) {
    state.cachedViews = [];
  },
  UPDATE_VISITED_VIEW: function UPDATE_VISITED_VIEW(state, view) {
    var _iterator2 = (0, _createForOfIteratorHelper2.default)(state.visitedViews),
      _step2;
    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var v = _step2.value;
        if (v.fullPath === view.fullPath) {
          v = Object.assign(v, view);
          break;
        }
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  }
};
var actions = {
  addView: function addView(_ref, view) {
    var dispatch = _ref.dispatch;
    dispatch('addVisitedView', view);
    dispatch('addCachedView', view);
  },
  addVisitedView: function addVisitedView(_ref2, view) {
    var commit = _ref2.commit;
    commit('ADD_VISITED_VIEW', view);
  },
  addCachedView: function addCachedView(_ref3, view) {
    var commit = _ref3.commit;
    // commit('ADD_CACHED_VIEW', view)

    console.log();
    //若为三级及其以上路由点击打开标签页时，将三级路由或以上的根目录路由塞入缓存路由name list中
    if (view.matched && view.matched.length >= 3) {
      // commit('ADD_CACHED_VIEW', view.matched[0])
      commit('ADD_CACHED_VIEW', view.matched[1]);
      // commit('ADD_CACHED_VIEW', view.matched[2])
    }
    // 反之，正常的进行标签页的添加进缓存路由name list中
    commit('ADD_CACHED_VIEW', view);
  },
  delView: function delView(_ref4, view) {
    var dispatch = _ref4.dispatch,
      state = _ref4.state;
    return new Promise(function (resolve) {
      dispatch('delVisitedView', view);
      dispatch('delCachedView', view);
      resolve({
        visitedViews: (0, _toConsumableArray2.default)(state.visitedViews),
        cachedViews: (0, _toConsumableArray2.default)(state.cachedViews)
      });
    });
  },
  delVisitedView: function delVisitedView(_ref5, view) {
    var commit = _ref5.commit,
      state = _ref5.state;
    return new Promise(function (resolve) {
      commit('DEL_VISITED_VIEW', view);
      resolve((0, _toConsumableArray2.default)(state.visitedViews));
    });
  },
  delCachedView: function delCachedView(_ref6, view) {
    var commit = _ref6.commit,
      state = _ref6.state;
    console.log("delCachedView", view);
    // return new Promise(resolve => {
    //   commit('DEL_CACHED_VIEW', view)
    //   resolve([...state.cachedViews])
    // })

    return new Promise(function (resolve) {
      // 若为三级及其以上路由关闭当前标签页时，将3级路由以上的根目录name 从list中删除
      if (view.matched && view.matched.length >= 3) {
        // commit('DEL_CACHED_VIEW', view.matched[0])
        commit('DEL_CACHED_VIEW', view.matched[1]);
        // commit('DEL_CACHED_VIEW', view.matched[2])
      }
      // 反之，正常的进行标签页的name 从list中删除
      commit('DEL_CACHED_VIEW', view);
      resolve((0, _toConsumableArray2.default)(state.cachedViews));
    });
  },
  delOthersViews: function delOthersViews(_ref7, view) {
    var dispatch = _ref7.dispatch,
      state = _ref7.state;
    return new Promise(function (resolve) {
      dispatch('delOthersVisitedViews', view);
      dispatch('delOthersCachedViews', view);
      resolve({
        visitedViews: (0, _toConsumableArray2.default)(state.visitedViews),
        cachedViews: (0, _toConsumableArray2.default)(state.cachedViews)
      });
    });
  },
  delOthersVisitedViews: function delOthersVisitedViews(_ref8, view) {
    var commit = _ref8.commit,
      state = _ref8.state;
    return new Promise(function (resolve) {
      commit('DEL_OTHERS_VISITED_VIEWS', view);
      resolve((0, _toConsumableArray2.default)(state.visitedViews));
    });
  },
  delOthersCachedViews: function delOthersCachedViews(_ref9, view) {
    var commit = _ref9.commit,
      state = _ref9.state;
    return new Promise(function (resolve) {
      commit('DEL_OTHERS_CACHED_VIEWS', view);
      resolve((0, _toConsumableArray2.default)(state.cachedViews));
    });
  },
  delAllViews: function delAllViews(_ref10, view) {
    var dispatch = _ref10.dispatch,
      state = _ref10.state;
    return new Promise(function (resolve) {
      dispatch('delAllVisitedViews', view);
      dispatch('delAllCachedViews', view);
      resolve({
        visitedViews: (0, _toConsumableArray2.default)(state.visitedViews),
        cachedViews: (0, _toConsumableArray2.default)(state.cachedViews)
      });
    });
  },
  delAllVisitedViews: function delAllVisitedViews(_ref11) {
    var commit = _ref11.commit,
      state = _ref11.state;
    return new Promise(function (resolve) {
      commit('DEL_ALL_VISITED_VIEWS');
      resolve((0, _toConsumableArray2.default)(state.visitedViews));
    });
  },
  delAllCachedViews: function delAllCachedViews(_ref12) {
    var commit = _ref12.commit,
      state = _ref12.state;
    return new Promise(function (resolve) {
      commit('DEL_ALL_CACHED_VIEWS');
      resolve((0, _toConsumableArray2.default)(state.cachedViews));
    });
  },
  updateVisitedView: function updateVisitedView(_ref13, view) {
    var commit = _ref13.commit;
    commit('UPDATE_VISITED_VIEW', view);
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;