"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.array.find");
require("core-js/modules/web.dom.iterable");
/**
 * 获取表格底部合计
 * @param {*} param 
 * @param {*} tableDef 
 * @returns 
 */
function getSummaries(param, tableDef) {
  var columns = param.columns,
    data = param.data;
  var sums = [];
  columns.forEach(function (column, index) {
    if (index === 2) {
      sums[index] = '合计';
      return;
    }
    if (column.property) {
      var tableCol = tableDef.find(function (i) {
        return i.Name == column.property;
      });
      if (tableCol.IsSum) {
        var values = data.map(function (item) {
          return Number(item[column.property]);
        });
        if (!values.every(function (value) {
          return isNaN(value);
        })) {
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = sums[index].toFixed(2);
        } else {
          sums[index] = '';
        }
      }
    }
  });
  return sums;
}
;
var _default = {
  getSummaries: getSummaries
};
exports.default = _default;