"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _ReportRouterlayout = _interopRequireDefault(require("@/layout/ReportRouterlayout.vue"));
var DataService = {
  path: '/merchantService',
  component: _layout.default,
  redirect: 'noRedirect',
  alwaysShow: true,
  name: 'merchantService',
  type: 'home',
  meta: {
    title: 'merchant',
    icon: 'saas-supcust',
    haveDetails: false
  },
  children: [{
    path: 'merchant',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/saasService/merchant/index.vue'));
      });
    },
    name: 'merchant',
    meta: {
      title: 'merchant',
      roles: ['Saas.Business.Qlist'],
      icon: 'saas-supcust',
      keepAlive: true
    }
  }, {
    path: 'merchantDelay',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/saasService/merchantDelay/index.vue'));
      });
    },
    name: 'merchantDelay',
    meta: {
      title: 'merchantDelay',
      roles: ['Saas.MerchantDelay.Qlist'],
      icon: 'saas-supcust',
      keepAlive: true
    }
  }, {
    path: 'merchantReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/saasService/merchantReport/index.vue'));
      });
    },
    name: 'merchantReport',
    meta: {
      title: 'merchantReport',
      roles: ['Saas.BusinessReport.Qlist'],
      icon: 'saas-supcust',
      keepAlive: true
    }
  }, {
    path: 'merchantDel',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/saasService/merchantDel/index.vue'));
      });
    },
    name: 'merchantDel',
    meta: {
      title: 'merchantDel',
      //roles: ['Saas.BusinessDel.Qlist'],
      roles: ['Saas.BusinessDel.Qlist1'],
      icon: 'saas-supcust',
      keepAlive: true
    }
  }]
};
var _default = DataService;
exports.default = _default;