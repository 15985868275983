"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var table = [{
  Display: true,
  DisplayType: "text",
  Label: "标题",
  Name: "title",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "副标题",
  Name: "subTitle",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "零售价",
  Name: "price",
  Sort: false,
  Align: "center",
  Width: "120",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "tagenum",
  DisplayTypeEnum: _enum.default.productUnitType,
  Label: "单位",
  Name: "unit",
  Sort: false,
  Align: "center",
  Width: "100",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "tagenum",
  DisplayTypeEnum: _enum.default.productType,
  Label: "类型",
  Name: "productType",
  Sort: false,
  Align: "center",
  Width: "120",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "备注",
  Name: "remark",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "bool",
  Label: "是否启用",
  Name: "states",
  Sort: false,
  Align: "center",
  Width: "120",
  IsLink: false,
  NavDisplayProp: ""
}];
var _default = {
  table: table
};
exports.default = _default;