"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _toConsumableArray2 = _interopRequireDefault(require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray.js"));
var _allKeepAlive = require("all-keep-alive");
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'AppMain',
  components: {
    allKeepAlive: _allKeepAlive.allKeepAlive
  },
  computed: {
    cachedViews: function cachedViews() {
      // var cachedViews = this.$store.state.tagsView.cachedViews;
      // console.log("AppMain cachedViews", cachedViews);
      // return cachedViews;
      return ['ParentView'].concat((0, _toConsumableArray2.default)(this.$store.state.tagsView.cachedViews));
    },
    key: function key() {
      // console.log("this.$route.path",this.$route.path);
      return this.$route.path;
    }
  }
};
exports.default = _default;