"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "HelpAndService",
  props: {
    phoneNumer: {
      type: String,
      default: "400-816-9808"
    }
  },
  data: function data() {
    return {
      qrcode: require("@/assets/images/logo3.jpg")
    };
  },
  methods: {
    changeShowHelp: function changeShowHelp() {
      this.$emit('changeShowHelp', false);
    }
  }
};
exports.default = _default;