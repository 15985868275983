var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "div",
            { staticClass: "head-container" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.listQuery,
                    "label-width": "100px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { size: "mini" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { size: "mini" } },
                        [
                          _c("SaasButtonGroup", {
                            attrs: {
                              BtnType: "list",
                              Query: _vm.Search,
                              Create: _vm.Create,
                              Update: _vm.Update,
                              Delete: _vm.Delete,
                              CreatePermission: ["Saas.Business.Create"],
                              UpdatePermission: [""],
                              DeletePermission: [""],
                              ApprovePermission: [""],
                              PrintPermission: [""],
                              ExportPermission: [""],
                              SettingPermission: [""],
                              canApprove: !_vm.isAgent,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "search-group" },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "mini" },
                                  on: { change: _vm.getList },
                                  model: {
                                    value: _vm.listQuery.approve,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "approve", $$v)
                                    },
                                    expression: "listQuery.approve",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { value: "", label: "-1" } },
                                    [_vm._v("全部")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { value: "", label: "1" } },
                                    [_vm._v("已审核")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { value: "", label: "0" } },
                                    [_vm._v("未审核")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  staticStyle: { "margin-left": "40px" },
                                  attrs: { size: "mini" },
                                  on: { change: _vm.getList },
                                  model: {
                                    value: _vm.listQuery.disable,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "disable", $$v)
                                    },
                                    expression: "listQuery.disable",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { value: "", label: "-1" } },
                                    [_vm._v("全部")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { value: "", label: "1" } },
                                    [_vm._v("启用")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { value: "", label: "0" } },
                                    [_vm._v("禁用")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "到期时间" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "mini" },
                                  on: { change: _vm.getList },
                                  model: {
                                    value: _vm.listQuery.stopDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "stopDate", $$v)
                                    },
                                    expression: "listQuery.stopDate",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { value: "", label: "-1" } },
                                    [_vm._v("无")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { value: "", label: "30" } },
                                    [_vm._v("30天内")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { value: "", label: "60" } },
                                    [_vm._v("60天内")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { value: "", label: "120" } },
                                    [_vm._v("100天内")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-row",
                        { staticStyle: { margin: "10px 0" } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "所在区域" } },
                            [
                              _c("Area", {
                                attrs: { cateProps: { checkStrictly: true } },
                                model: {
                                  value: _vm.areas,
                                  callback: function ($$v) {
                                    _vm.areas = $$v
                                  },
                                  expression: "areas",
                                },
                              }),
                            ],
                            1
                          ),
                          !_vm.isAgent
                            ? _c(
                                "el-form-item",
                                { attrs: { label: "代理商" } },
                                [
                                  _c("SaasInputDialog", {
                                    staticStyle: { width: "185px" },
                                    attrs: {
                                      api: _vm.agentApi,
                                      title: "选择代理商",
                                      hasTree: false,
                                      displayText: "companyName",
                                      displayId: "agentCode",
                                      callback: _vm.selectAgentCallback,
                                      listQuery: _vm.agentListQuery,
                                      type: "id",
                                      hasRows: false,
                                    },
                                    model: {
                                      value: _vm.listQuery.agent,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.listQuery, "agent", $$v)
                                      },
                                      expression: "listQuery.agent",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "el-form-item",
                            { attrs: { label: "店铺类型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100px" },
                                  attrs: {
                                    placeholder: "请选择",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.listQuery.shopType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "shopType", $$v)
                                    },
                                    expression: "listQuery.shopType",
                                  },
                                },
                                _vm._l(_vm.shopType, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "商户类型" } },
                            [
                              _c("el-cascader", {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  props: {
                                    checkStrictly: true,
                                    emitPath: false,
                                  },
                                  options: _vm.merchantTypes,
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.listQuery.merchantType,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "merchantType", $$v)
                                  },
                                  expression: "listQuery.merchantType",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "5px" } },
                            [
                              _c("el-input", {
                                staticClass: "filter-item",
                                staticStyle: { width: "180px" },
                                attrs: {
                                  size: "mini",
                                  clearable: "",
                                  placeholder: "请输入名称/手机号",
                                },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.Search($event)
                                  },
                                },
                                model: {
                                  value: _vm.listQuery.filter,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.listQuery, "filter", $$v)
                                  },
                                  expression: "listQuery.filter",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.listLoading,
                  expression: "listLoading",
                },
              ],
              ref: "multipleTable",
              attrs: {
                stripe: "",
                "highlight-current-row": "",
                "row-key": "id",
                data: _vm.list,
                size: "small",
                "show-summary": "",
                "summary-method": _vm.getSummaries,
              },
              on: {
                "sort-change": _vm.sortChange,
                "selection-change": _vm.handleSelectionChange,
                "row-click": _vm.handleRowClick,
              },
            },
            [
              _c("el-table-column", { attrs: { type: "index", width: "50" } }),
              _c("el-table-column", {
                attrs: { prop: "agentName", label: "所属代理商" },
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "商户ID" },
              }),
              _c("el-table-column", {
                attrs: { prop: "shopName", label: "商户名称" },
              }),
              _c("el-table-column", {
                attrs: { prop: "shopVersion", label: "商户版本" },
              }),
              _c("el-table-column", {
                attrs: { prop: "approve", label: "审核状态", width: "80" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: _vm._f("findEnumType")(
                                    scope.row.approve,
                                    _vm.ApproveFlagMap
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("findEnumKey")(
                                        scope.row.approve,
                                        _vm.ApproveFlagMap
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "isOfficial", label: "续费状态", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: _vm._f("findEnumType")(
                                    scope.row.isOfficial,
                                    _vm.RenewFlagMap
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("findEnumKey")(
                                        scope.row.isOfficial,
                                        _vm.RenewFlagMap
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "shopType", label: "店铺类型", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: _vm._f("findEnumType")(
                                    scope.row.shopType,
                                    _vm.shopType
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("findEnumKey")(
                                        scope.row.shopType,
                                        _vm.shopType
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "posNum", label: "站点数", width: "70" },
              }),
              _c("el-table-column", {
                attrs: { prop: "branchNum", label: "门店数", width: "70" },
              }),
              _c("el-table-column", {
                attrs: { prop: "contacts", label: "联系人", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "phone", label: "手机号", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "areasStr", label: "所在区域", width: "100" },
              }),
              _c("el-table-column", {
                attrs: { prop: "status", label: "状态", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "span",
                          [
                            _c(
                              "el-tag",
                              {
                                attrs: {
                                  type: _vm._f("findEnumType")(
                                    scope.row.status,
                                    _vm.StatusFlagMap
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("findEnumKey")(
                                        scope.row.status,
                                        _vm.StatusFlagMap
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "creationTime",
                  label: "创建时间",
                  width: "135",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatDateTime")(scope.row.creationTime)
                            )
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "stopDate", label: "到期时间", width: "135" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm._f("formatDateTime")(scope.row.stopDate))
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "usedBranchNum",
                  label: "已使用门店数",
                  width: "100",
                },
              }),
              _c("el-table-column", {
                attrs: { label: "CS微会员", width: "100", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            size: "mini",
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeCs(scope.row, "vip")
                            },
                          },
                          model: {
                            value: scope.row.csVip,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "csVip", $$v)
                            },
                            expression: "scope.row.csVip",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "CS老板助手", width: "100", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          attrs: {
                            size: "mini",
                            "active-color": "#13ce66",
                            "inactive-color": "#ff4949",
                          },
                          on: {
                            change: function ($event) {
                              return _vm.changeCs(scope.row, "Boos")
                            },
                          },
                          model: {
                            value: scope.row.csBoos,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "csBoos", $$v)
                            },
                            expression: "scope.row.csBoos",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  fixed: "right",
                  label: "操作",
                  width: "150",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        scope.row.approve == 0
                          ? _c(
                              "el-button",
                              {
                                directives: [
                                  {
                                    name: "permission",
                                    rawName: "v-permission",
                                    value: ["Saas.Business.Approve"],
                                    expression: "['Saas.Business.Approve']",
                                  },
                                ],
                                attrs: { type: "primary", size: "mini" },
                                on: {
                                  click: function ($event) {
                                    return _vm.Approve(scope.row)
                                  },
                                },
                              },
                              [_vm._v("审核")]
                            )
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", size: "mini" },
                            on: {
                              click: function ($event) {
                                return _vm.Update(scope.row)
                              },
                            },
                          },
                          [_vm._v("详情")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.totalCount > 0,
                expression: "totalCount > 0",
              },
            ],
            attrs: {
              total: _vm.totalCount,
              page: _vm.page,
              limit: _vm.listQuery.MaxResultCount,
            },
            on: {
              "update:page": function ($event) {
                _vm.page = $event
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "MaxResultCount", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c("MyCreate", {
        attrs: {
          isEdit: _vm.isEdit,
          id: _vm.editId,
          isShow: _vm.dialogFormVisible,
          closeCreate: _vm.handleCloseCreate,
          callback: _vm.handleCreateCallback,
          formTitle: _vm.formTitle,
          operType: _vm.listQuery.operType,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }