"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = _interopRequireDefault(require("@/layout"));
var _ReportRouterlayout = _interopRequireDefault(require("@/layout/ReportRouterlayout.vue"));
var SassService = {
  path: '/saas',
  component: _layout.default,
  redirect: 'noRedirect',
  // alwaysShow: true,
  name: 'SAAS',
  type: 'home',
  meta: {
    title: 'Saas',
    icon: 'cloud',
    haveDetails: false
  },
  children: [{
    path: 'baseInfo',
    component: _ReportRouterlayout.default,
    name: 'baseInfo',
    meta: {
      title: 'baseInfo',
      icon: 'saas-supcust'
    },
    children: []
  }]
};
var _default = SassService;
exports.default = _default;