"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _api = _interopRequireDefault(require("../api"));
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var _SaasInputDialogs = _interopRequireDefault(require("@/saas/components/SaasInputDialogs.vue"));
var _api2 = _interopRequireDefault(require("@/views/saasService/agent/api.js"));
var _api3 = _interopRequireDefault(require("@/views/saasService/merchant/api.js"));
var _api4 = _interopRequireDefault(require("@/views/saasService/developer/api.js"));
//表单默认值
var defaultForm = {
  id: null,
  appName: "",
  appIco: "",
  appInfo: "",
  price: "",
  agentPrice: "",
  appType: 0,
  appletId: null,
  pageLink: "",
  accessType: 0,
  accessSub: null,
  accessSubMerchant: null,
  developerId: null,
  clientId: "",
  clientSecret: "",
  unit: "",
  specs: "",
  status: true
};
var _default = {
  name: "applicationCreate",
  components: {
    Pagination: _Pagination.default,
    SaasInputDialog: _SaasInputDialogs.default
  },
  props: [
  //是否显示窗口
  'isShow',
  //窗口关闭事件回调
  'closeCreate',
  //保存成功后回调
  'callback',
  //是否是修改
  'isEdit',
  //修改时传入的id
  'id',
  //标题
  'formTitle'],
  watch: {
    isShow: function isShow(newVal) {
      this.dialogFormVisible = newVal; //对父组件传过来的值进行监听，如果改变也对子组件内部的值进行改变
      this.initData(); //在显示的时候处理数据才能生效,因为显示的值在每次打开时肯定是变动的，
      if (!this.isEdit) {
        this.getSecret();
        this.getAppid();
      }
    },
    dialogFormVisible: function dialogFormVisible(newVal) {
      if (!newVal) {
        this.closeCreate(); //内部不关闭自己页面，会造成循环，让外部父组件来关闭
      }
    }
  },
  data: function data() {
    var _this = this;
    // 验证应用类型
    var checkapplet = function checkapplet(rule, value, callback) {
      console.log(value, _this.form.appletId, 2222);
      if (value == 0) {
        if (_this.form.appletId) {
          callback();
        } else {
          return callback(new Error('请选择平台应用'));
        }
      } else {
        callback();
      }
    };
    // 验证开放范围
    var checkaccess = function checkaccess(rule, value, callback) {
      if (value == 1 && !_this.form.accessSub) {
        return callback(new Error('请选择代理商'));
      } else if (value == 2 && !_this.form.accessSubMerchant) {
        return callback(new Error('请选择商户'));
      } else {
        callback();
      }
    };
    return {
      //控制弹窗显示
      dialogFormVisible: false,
      //弹窗加载框
      formLoading: false,
      isTop: true,
      //表单提交校验规则
      rules: {
        appName: [{
          required: true,
          message: "请输入应用名称",
          trigger: "blur"
        }],
        price: [{
          required: true,
          message: "请输入零售价",
          trigger: "blur"
        }],
        agentPrice: [{
          required: true,
          message: "请输入代理商价",
          trigger: "blur"
        }],
        unit: [{
          required: true,
          message: "请选择单位",
          trigger: "change"
        }],
        specs: [{
          required: true,
          message: "请输入规格（默认填1，多个规格用“|”隔开）",
          trigger: "blur"
        }],
        appType: [{
          required: true,
          message: "请选择应用类型",
          trigger: "change"
        }, {
          validator: checkapplet,
          required: true,
          trigger: 'blur'
        }],
        accessType: [{
          required: true,
          message: "请选择开放范围",
          trigger: "change"
        }, {
          validator: checkaccess,
          required: true,
          trigger: 'blur'
        }],
        clientId: [{
          required: true,
          message: "请输入应用Id",
          trigger: "blur"
        }],
        clientSecret: [{
          required: true,
          message: "请输入应用秘钥",
          trigger: "blur"
        }]
      },
      //表单定义 并赋默认值
      form: Object.assign({}, defaultForm),
      disabled: true,
      checked: true,
      applet: [],
      appTypes: _enum.default.appType,
      productUnitType: _enum.default.productUnitType,
      accessTypes: _enum.default.accessType,
      agentApi: _api2.default,
      agentListQuery: _api.default.agentListQuery,
      merchantApi: _api3.default,
      merchantListQuery: _api.default.merchantListQuery,
      developerApi: _api4.default,
      devaloperListQuery: _api.default.devaloperListQuery
    };
  },
  created: function created() {
    var _this2 = this;
    _api.default.getApplet({
      maxResultCount: 20,
      status: true
    }).then(function (response) {
      _this2.applet = response.items;
    });
  },
  methods: {
    /** 显示时初始化数据 */initData: function initData() {
      this.checked = true;
      this.disabled = false;
      this.form = Object.assign({}, defaultForm);
      //如果是编辑状态，远程加载信息
      if (this.isEdit) {
        if (this.id != 0 && this.id != undefined) {
          this.checked = false;
          this.disabled = true;
          this.fetchData(this.id);
        }
      }
    },
    /** 加载数据 */fetchData: function fetchData(id) {
      var _this3 = this;
      // this.getOrgNodes(id);
      this.formLoading = true;
      _api.default.get(id).then(function (response) {
        _this3.form = response;
        if (_this3.form.accessType == 2) {
          _this3.form.accessSubMerchant = _this3.form.accessSub;
          _this3.form.accessSub = null;
        }
        _this3.formLoading = false;
      });
    },
    /**保存 */save: function save() {
      var _this4 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this4.form.accessType == 2) {
            _this4.form.accessSub = _this4.form.accessSubMerchant;
          }
          if (_this4.isEdit) {
            _this4.formLoading = true;
            _api.default.update(_this4.form).then(function (response) {
              console.info(response);
              _this4.formLoading = false;
              _this4.$notify({
                title: "成功",
                message: "更新成功",
                type: "success",
                duration: 2000
              });
              _this4.dialogFormVisible = false;
              _this4.callback();
            }).catch(function () {
              _this4.formLoading = false;
            });
          } else {
            _this4.formLoading = true;
            _api.default.create(_this4.form).then(function (response) {
              _this4.formLoading = false;
              _this4.$notify({
                title: "成功",
                message: "新增成功",
                type: "success",
                duration: 2000
              });
              _this4.dialogFormVisible = false;
              _this4.callback();
            }).catch(function () {
              _this4.formLoading = false;
            });
          }
        }
      });
    },
    /**取消按钮 */cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
      this.form = Object.assign({}, defaultForm);
    },
    changeAccess: function changeAccess(e) {
      this.form.accessSub = null;
      if (e == 1) {} else if (e == 2) {} else {}
    },
    accessSubCallback: function accessSubCallback(data) {},
    developerCallback: function developerCallback(data) {},
    randomString: function randomString(len) {
      var isNum = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      len = len || 32;
      if (isNum) {
        var chars = '0123456789';
      } else {
        var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      }
      var maxPos = chars.length;
      var rand = '';
      for (var i = 0; i < len; i++) {
        rand += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return rand;
    },
    getSecret: function getSecret() {
      var secret = this.randomString();
      this.form.clientSecret = secret;
    },
    getAppid: function getAppid() {
      var id = this.randomString(10, true);
      this.form.clientId = id;
    }
  }
};
exports.default = _default;