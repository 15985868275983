"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
// 商品
var table = [{
  Display: true,
  DisplayType: "text",
  Label: "所属代理商",
  Name: "agentName",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "商户名称",
  Name: "shopName",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "商户版本",
  Name: "shopVersion",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "tagenum",
  DisplayTypeEnum: _enum.default.ApproveFlagMap,
  Label: "审核状态",
  Name: "approve",
  Sort: false,
  Align: "center",
  Width: "80",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "tagenum",
  DisplayTypeEnum: _enum.default.RenewFlagMap,
  Label: "续费状态",
  Name: "isOfficial",
  Sort: false,
  Align: "center",
  Width: "80",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "tagenum",
  DisplayTypeEnum: _enum.default.shopType,
  Label: "店铺类型",
  Name: "shopType",
  Sort: false,
  Align: "center",
  Width: "100",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "站点数",
  Name: "posNum",
  Sort: false,
  Align: "center",
  Width: "65",
  IsLink: false,
  IsSum: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "门店数",
  Name: "branchNum",
  Sort: false,
  Align: "center",
  Width: "65",
  IsLink: false,
  IsSum: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "联系人",
  Name: "contacts",
  Sort: false,
  Align: "center",
  Width: "80",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "手机号",
  Name: "phone",
  Sort: false,
  Align: "center",
  Width: "100",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "所在区域",
  Name: "areasStr",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "bool",
  Label: "状态",
  Name: "status",
  Sort: false,
  Align: "center",
  Width: "50",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "datetime",
  Label: "创建时间",
  Name: "creationTime",
  Sort: false,
  Align: "center",
  Width: "135",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "datetime",
  Label: "到期时间",
  Name: "stopDate",
  Sort: false,
  Align: "center",
  Width: "135",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: false,
  DisplayType: "text",
  Label: "已使用门店数",
  Name: "usedBranchNum",
  Sort: false,
  Align: "center",
  Width: "100",
  IsLink: false,
  IsSum: true,
  NavDisplayProp: ""
}
// {
//   Display: true,
//   DisplayType: "bool",
//   Label: "CS微会员",
//   Name: "csVip",
//   Sort: false,
//   Align: "center",
//   Width: "80",
//   IsLink: false,
//   NavDisplayProp: "",
// },
// {
//   Display: true,
//   DisplayType: "bool",
//   Label: "CS老板助手",
//   Name: "csBoos",
//   Sort: false,
//   Align: "center",
//   Width: "90",
//   IsLink: false,
//   NavDisplayProp: "",
// },
];
var _default = {
  table: table
};
exports.default = _default;