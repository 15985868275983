var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-tabs",
    {
      staticStyle: { "padding-left": "8px" },
      on: { "tab-click": _vm.tabClick },
      model: {
        value: _vm.activeName,
        callback: function ($$v) {
          _vm.activeName = $$v
        },
        expression: "activeName",
      },
    },
    [
      _c(
        "el-tab-pane",
        { attrs: { label: "本地存储", name: "local" } },
        [_c("Local", { ref: "local" })],
        1
      ),
      _c(
        "el-tab-pane",
        { attrs: { label: "数据库存储", name: "blob" } },
        [_c("Blob", { ref: "blob" })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }