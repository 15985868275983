"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _SassUpload = _interopRequireDefault(require("@/saas/components/SassUpload.vue"));
var _methods;
var _default = {
  name: "Settings",
  components: {
    SaasUplaod: _SassUpload.default
  },
  directives: {
    permission: _index.default
  },
  data: function data() {
    return {
      formLoading: false,
      searchId: "",
      settings: [],
      activeName: "",
      selectTabIndex: 0,
      changeSetting: {
        settings: []
      },
      selectSetting: null,
      listQuery: {
        platform: "agent"
      }
    };
  },
  created: function created() {
    this.initData();
  },
  methods: (_methods = {
    /**
    * tab切换事件
    * @param {*} tab
    * @param {*} event
    */
    handleClick: function handleClick(tab, event) {
      //切换时清空改变值数组
      this.changeSetting.settings = [];
      this.selectTabIndex = tab.index;
    },
    setSetting: function setSetting(data) {
      return this.$axios.puts('/api/SettingService/setting?platform=agent', data);
    },
    reset: function reset(data) {
      return this.$axios.posts('/api/SettingService/setting/reset', data);
    },
    /**
     *  初始化所有数据
     */
    initData: function initData() {
      var _this = this;
      this.formLoading = true;
      //获取当前tab页中的设置
      this.$axios.gets('/api/SettingService/setting', this.listQuery).then(function (result) {
        _this.settings = result;
        console.log(_this.settings);
        _this.formLoading = false;
        //当前选择的service
        if (_this.settings && _this.settings.length > 0) {
          _this.selectSetting = _this.settings[_this.selectTabIndex];
          //当前激活页
          _this.activeName = _this.selectSetting.groupName;
        }
      });
    }
  }, (0, _defineProperty2.default)(_methods, "handleClick", function handleClick(tab, e) {
    this.selectTabIndex = tab.index;
    // this.initData();
  }), (0, _defineProperty2.default)(_methods, "handleSettingValueChanged", function handleSettingValueChanged(key, value) {
    console.log(key, value);
    var setting = this.changeSetting.settings.filter(function (x) {
      return x.key == key;
    });
    if (setting.length == 0) {
      var setting = {
        key: key,
        value: value
      };
      this.changeSetting.settings.push(setting);
    } else {
      setting[0].value = value; // 配置项必须为文本类型
    }
  }), (0, _defineProperty2.default)(_methods, "onSavesetting", function onSavesetting() {
    var _this2 = this;
    if (this.changeSetting.settings.length > 0) {
      this.formLoading = true;
      this.setSetting(this.changeSetting).then(function (result) {
        _this2.formLoading = false;
        if (result) {
          //保存成功时清空改变值数组
          _this2.changeSetting.settings = [];
          _this2.$notify({
            title: "成功",
            message: "保存成功",
            type: "success",
            duration: 2000
          });
        } else {
          _this2.$notify({
            title: "失败",
            message: "保存失败",
            type: "fail",
            duration: 2000
          });
        }
      });
    }
  }), (0, _defineProperty2.default)(_methods, "changenavigationBar", function changenavigationBar(key) {
    // console.log(key);
    // debugger
    // 获取点击的按钮对应页面的id
    console.log(this.searchId);
    var PageId = document.querySelector("#" + this.searchId);
    // 打印出对应页面与窗口的距离
    // console.log(PageId.offsetTop)

    // 使用平滑属性，滑动到上方获取的距离
    // 下方我只设置了top，当然 你也可以加上 left 让他横向滑动
    // widow 根据浏览器滚动条，如果你是要在某个盒子里面产生滑动，记得修改
    window.scrollTo({
      top: PageId.offsetTop,
      behavior: "smooth"
    });
  }), (0, _defineProperty2.default)(_methods, "uploadIcon", function uploadIcon(val) {
    this.handleSettingValueChanged('BD.Company_Logo', val);
  }), _methods)
};
exports.default = _default;