var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
        on: { toggleClick: _vm.toggleSideBar },
      }),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c("search", {
                  staticClass: "right-menu-item show",
                  attrs: { id: "header-search" },
                }),
              ]
            : _vm._e(),
          _c(
            "div",
            { staticClass: "avatar-container right-menu-item hover-effect" },
            [
              _c(
                "div",
                {
                  staticClass: "topbar-title",
                  staticStyle: { height: "100%" },
                },
                [
                  _c("el-avatar", {
                    staticStyle: {
                      cursor: "pointer",
                      "font-size": "18px",
                      "vertical-align": "middle",
                    },
                    attrs: { src: _vm.logo },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "topbar-title",
                  staticStyle: { height: "100%" },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "100%" } },
                    [
                      _c(
                        "el-link",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "bold",
                          },
                          attrs: { underline: false },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.profile($event)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              this.$store.state.user.userEx.branchName == null
                                ? "代理商"
                                : this.$store.state.user.userEx.branchName
                            ) +
                              ":" +
                              _vm._s(_vm.user.name)
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "topbar-title",
                  staticStyle: { height: "100%" },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { height: "100%" } },
                    [
                      _c(
                        "el-link",
                        {
                          staticStyle: {
                            "font-size": "16px",
                            "font-weight": "bold",
                          },
                          attrs: { underline: false },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.logout($event)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "el-icon-switch-button" }),
                          _vm._v("退出"),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        2
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "个人中心",
            visible: _vm.dialogFormVisible,
            width: "500px",
            "modal-append-to-body": false,
          },
          on: {
            close: function ($event) {
              return _vm.cancel()
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-width": "80px",
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "el-descriptions",
                    { attrs: { column: 2, colon: false } },
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: "手机号",
                            labelStyle: "width:70px;text-align:right;",
                          },
                        },
                        [
                          _c(
                            "el-tag",
                            { attrs: { size: "small", type: "info" } },
                            [_vm._v(_vm._s(_vm.user.phoneNumber))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: "门店",
                            labelStyle: "width:70px;text-align:right;",
                          },
                        },
                        [
                          _c(
                            "el-tag",
                            { attrs: { size: "small", type: "tag" } },
                            [_vm._v(_vm._s(_vm.user.branch))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "用户名" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", disabled: "" },
                    model: {
                      value: _vm.user.name,
                      callback: function ($$v) {
                        _vm.$set(_vm.user, "name", $$v)
                      },
                      expression: "user.name",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "原密码", prop: "pass" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", "show-password": "" },
                    model: {
                      value: _vm.form.pass,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "pass", $$v)
                      },
                      expression: "form.pass",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "新密码", prop: "currentpass" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", "show-password": "" },
                    model: {
                      value: _vm.form.currentpass,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "currentpass", $$v)
                      },
                      expression: "form.currentpass",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "确认密码", prop: "confirmpass" } },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", "show-password": "" },
                    model: {
                      value: _vm.form.confirmpass,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "confirmpass", $$v)
                      },
                      expression: "form.confirmpass",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }