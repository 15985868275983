"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("@/axios"));
var _register_def = _interopRequireDefault(require("@/saas/table-def/register_def.js"));
var tableDef = _register_def.default.table;
function get(id) {
  return _axios.default.gets("/api/SettingService/merchant/" + id);
}
function getAll(data) {
  return _axios.default.gets("/api/SettingService/merchant", data);
}
function getByIds(ids) {
  return _axios.default.gets("/api/SettingService/merchant/byIds?ids=" + ids);
}
function deleteById(id) {
  return _axios.default.deletes("/api/SettingService/merchant/" + id);
}
function update(data) {
  return _axios.default.posts("/api/SettingService/merchant", data);
}
function create(data) {
  return _axios.default.posts("/api/SettingService/merchant", data);
}
function approveById(data) {
  return _axios.default.gets("/api/SettingService/merchant/approve", data);
}
function getProduct(data) {
  return _axios.default.gets("/api/SettingService/product", data);
}
function merchantDelay(data) {
  return _axios.default.posts("/api/SettingService/merchantDelay", data);
}
function merchantDel(id) {
  return _axios.default.deletes("/api/SettingService/merchant/MerchantDelete?id=" + id);
}
var productListQuery = {
  SkipCount: 0,
  MaxResultCount: 20,
  states: true
};
var _default = {
  get: get,
  getAll: getAll,
  getByIds: getByIds,
  deleteById: deleteById,
  update: update,
  create: create,
  approveById: approveById,
  tableDef: tableDef,
  getProduct: getProduct,
  productListQuery: productListQuery,
  merchantDelay: merchantDelay,
  merchantDel: merchantDel
};
exports.default = _default;