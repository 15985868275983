"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = exports.asyncRoutes = void 0;
exports.resetRouter = resetRouter;
var _interopRequireWildcard2 = _interopRequireDefault(require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _saasService = _interopRequireDefault(require("./modules/saasService"));
var _downPosService = _interopRequireDefault(require("./modules/downPosService"));
var _sysService = _interopRequireDefault(require("./modules/sysService"));
var _merchantService = _interopRequireDefault(require("./modules/merchantService"));
var _productsService = _interopRequireDefault(require("./modules/productsService"));
var _userService = _interopRequireDefault(require("./modules/userService"));
var _agentService = _interopRequireDefault(require("./modules/agentService"));
/* Layout */

/* Router Modules */

_vue.default.use(_vueRouter.default);

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if set true, the page will no be cached(default is false)
    affix: true                  if set true, the tag will affix in the tags-view
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
var constantRoutes = [{
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path*',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/redirect/index'));
      });
    }
  }]
}, {
  path: '/login',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/index'));
    });
  },
  hidden: true
}, {
  path: '/loginPlatform',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/loginPlatform/index'));
    });
  },
  hidden: true
}, {
  path: '/auth-redirect',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/login/auth-redirect'));
    });
  },
  hidden: true
}, {
  path: '/404',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/error-page/404'));
    });
  },
  hidden: true
}, {
  path: '/401',
  component: function component() {
    return Promise.resolve().then(function () {
      return (0, _interopRequireWildcard2.default)(require('@/views/error-page/401'));
    });
  },
  hidden: true
}, {
  path: '/',
  component: _layout.default,
  redirect: '/dashboard',
  children: [{
    path: 'dashboard',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/dashboard/admin/index.vue'));
      });
    },
    name: 'Dashboard',
    meta: {
      title: 'dashboard',
      icon: 'dashboard',
      affix: true,
      keepAlive: true
    }
  }]
}, {
  path: '/page',
  component: _layout.default,
  //redirect: '/page-details/index',
  hidden: true,
  children: [{
    path: 'details',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/page-details/index.vue'));
      });
    },
    name: 'page-details',
    meta: {
      title: 'pageDetails',
      icon: 'dashboard',
      common: true
    }
  }]
}, {
  path: '/profile',
  component: _layout.default,
  redirect: '/profile/index',
  hidden: true,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/profile/index'));
      });
    },
    name: 'Profile',
    meta: {
      title: 'profile',
      icon: 'user',
      noCache: true
    }
  }]
}];

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
exports.constantRoutes = constantRoutes;
var asyncRoutes = [
// {
//   path: '/agent',
//   component: Layout,
//   children: [
//     {
//       path: 'index',
//       component: () => import('@/views/saasService/agent/index.vue'),
//       name: 'agent',
//       meta: {
//         title: 'agent',
//         roles: ['Saas.Agent.Qlist'],
//         icon: 'cloud',
//         keepAlive: true
//       },
//     }
//   ]
// },
// {
//   path: '/merchant',
//   component: Layout,
//   children: [
//     {
//       path: 'index',
//       component: () => import('@/views/saasService/merchant/index.vue'),
//       name: 'merchant',
//       meta: {
//         title: 'merchant',
//         roles: ['Saas.Business.Qlist'],
//         icon: 'saas-supcust', 
//         keepAlive: true 
//       },
//     }
//   ]
// },
_agentService.default, _merchantService.default,
// {
//   path: '/merchantDelay',
//   component: Layout,
//   children: [
//     {
//       path: 'index',
//       component: () => import('@/views/saasService/merchantDelay/index.vue'),
//       name: 'merchantDelay',
//       meta: {
//         title: 'merchantDelay',
//         roles: ['Saas.MerchantDelay.Qlist'],
//         icon: 'saas-supcust', 
//         keepAlive: true 
//       },
//     }
//   ]
// },
//    {
//   path: '/products',
//   component: Layout,
//   children: [
//     {
//       path: 'index',
//       component: () => import('@/views/saasService/products/index.vue'),
//       name: 'orders',
//       meta: {
//         title: 'products',
//         roles: ['Saas.Products.Qlist'],
//         icon: 'saas-supcust', 
//         keepAlive: true 
//       },
//     }
//   ]
// },
_productsService.default,
// {
//   path: '/applet',
//   component: Layout,
//   children: [
//     {
//       path: 'index',
//       component: () => import('@/views/saasService/applet/index.vue'),
//       name: 'orders',
//       meta: {
//         title: 'applet',
//         roles: ['Saas.Applet.Qlist'],
//         icon: 'saas-supcust', 
//         keepAlive: true 
//       },
//     }
//   ]
// },
// {
//   path: '/application',
//   component: Layout,
//   children: [
//     {
//       path: 'index',
//       component: () => import('@/views/saasService/application/index.vue'),
//       name: 'orders',
//       meta: {
//         title: 'application',
//         roles: ['Saas.Application.Qlist'],
//         icon: 'saas-supcust', 
//         keepAlive: true 
//       },
//     }
//   ]
// },
// {
//   path: '/developer',
//   component: Layout,
//   children: [
//     {
//       path: 'index',
//       component: () => import('@/views/saasService/developer/index.vue'),
//       name: 'orders',
//       meta: {
//         title: 'developer',
//         roles: ['Saas.Developer.Qlist'],
//         icon: 'saas-supcust',
//         keepAlive: true
//       },
//     }
//   ]
// },
{
  path: '/orders',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/saasService/orders/index.vue'));
      });
    },
    name: 'orders',
    meta: {
      title: 'orders',
      roles: ['Saas.Orders.Qlist'],
      icon: 'saas-supcust',
      keepAlive: true
    }
  }]
}, {
  path: '/merchantOrderCreate',
  component: _layout.default,
  children: [{
    path: 'index',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/saasService/orders/create/index.vue'));
      });
    },
    name: 'merchantOrderCreate',
    meta: {
      title: 'merchantOrderCreate',
      roles: ['Saas.Orders.Qlist'],
      icon: 'saas-supcust',
      keepAlive: true
    },
    hidden: true
  }]
}, _userService.default,
// {
//   path: '/user',
//   component: Layout,
//   children: [
//     {
//       path: 'index',
//       component: () => import('@/views/admin/user/index.vue'),
//       name: 'User',
//       meta: {
//         title: 'user',
//         roles: ['Saas.User.Qlist'],
//         icon: 'user',
//         keepAlive: true
//       }
//     },
//   ]
// },
// {
//   path: '/tenant',
//   component: Layout,
//   children: [
//     {
//       path: 'index',
//       component: () => import('@/views/admin/tenant/index.vue'),
//       name: 'tenant',
//       meta: {
//         title: 'tenant',
//         roles: ['Saas.User.Qlist'],
//         icon: 'tenant',
//         keepAlive: true
//       }
//     },
//   ]
// },

/** when your routing map is too long, you can split it into small modules **/

_downPosService.default, _sysService.default,
// 404 page must be placed at the end !!!
{
  path: '*',
  redirect: '/404',
  hidden: true
}];
exports.asyncRoutes = asyncRoutes;
var createRouter = function createRouter() {
  return new _vueRouter.default({
    // mode: 'history', // require service support
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};
var router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
var _default = router;
exports.default = _default;