var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "step" },
      _vm._l(_vm.items, function (item, index) {
        return _c("Steps", { key: index, attrs: { items: item.data } })
      }),
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showHelp,
            expression: "showHelp",
          },
        ],
        staticClass: "help",
      },
      [_c("HelpAndService", { on: { changeShowHelp: _vm.changeShowHelp } })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }