"use strict";

var _interopRequireWildcard = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
require("core-js/modules/es6.regexp.replace");
var _auth = require("@/utils/auth");
var _axios = _interopRequireDefault(require("axios"));
var _elementUi = _interopRequireWildcard(require("element-ui"));
var _qs = _interopRequireDefault(require("qs"));
var _config = _interopRequireDefault(require("../../static/config"));
var _router = _interopRequireDefault(require("../router"));
//请求超时时间
_axios.default.defaults.timeout = 1000000;
_axios.default.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8;';
//TODO:配置读取
_axios.default.defaults.headers['Accept-Language'] = 'zh-Hans';
// axios.defaults.baseURL = '';
_axios.default.defaults.baseURL = _config.default.base.ip;
// POST传参序列化
_axios.default.interceptors.request.use(function (config) {
  // eslint-disable-next-line eqeqeq
  //console.log('axios.index.js', config)
  if ((0, _auth.getToken)() != '') {
    config.headers.Authorization = 'Bearer ' + (0, _auth.getToken)();
  } else {
    // const loginMode = store.getters.loginMode
    // if(loginMode.length>0){
    //   router.replace({
    //     path: '/loginPlatform'
    //   })
    // }else{
    //   router.replace({
    //     path: '/login'
    //   })
    // }
    _router.default.replace({
      path: '/login'
    });
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

// 返回状态判断
_axios.default.interceptors.response.use(function (res) {
  console.log(res);
  // return Promise.reject(res);
  return res;
}, function (err) {
  // 404等问题可以在这里处理
  if (err.response) {
    var error = err.error = {};
    switch (err.response.status) {
      // 判断后台返回的token值是否失效
      case 401:
        (0, _elementUi.Message)({
          message: '登录过期，请重新登录！',
          type: 'error',
          duration: 5 * 1000
        });
        // const loginMode = store.getters.loginMode
        // if (loginMode && loginMode.length > 0) {
        //   router.replace({
        //     path: '/loginPlatform'
        //   })
        // } else {
        //   router.replace({
        //     path: '/login'
        //   })
        // }
        _router.default.replace({
          path: '/login'
        });
        break;
      case 400:
        error.message = err.response.data.error.message;
        error.details = err.response.data.error.details;
        break;
      case 403:
        error.message = err.response.data.error.code ? err.response.data.error.code : err.response.data.error.message;
        error.details = Object.keys(err.response.data.error.data).length == 0 ? err.response.data.error.code : err.response.data.error.message;
        break;
      case 404:
        error.message = '未找到服务';
        error.details = '未找到服务';
        break;
      case 408:
        error.message = err.response.data.error.message;
        error.details = err.response.data.error.details;
        break;
      case 500:
        error.message = err.response.data.error.message;
        error.details = err.response.data.error.message;
        break;
      case 501:
        error.message = err.response.data.error.message;
        error.details = err.response.data.error.details;
        break;
      case 502:
        error.message = '502 Bad Gateway';
        error.details = '网络错误';
        break;
      case 503:
        error.message = err.response.data.error.message;
        error.details = err.response.data.error.details;
        break;
      case 504:
        error.message = err.response.data.error.message;
        error.details = err.response.data.error.details;
        break;
      case 505:
        error.message = err.response.data.error.message;
        error.details = err.response.data.error.details;
        break;
      default:
    }
    return Promise.reject(err);
  } else if (err.request) {
    return Promise.reject(err.request);
  } else {
    // Something happened in setting up the request that triggered an Error
    return Promise.reject('Error', err.message);
  }
});
var _default = {
  uploadFileRequest: function uploadFileRequest(url, params, title) {
    var errAlert = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
    console.info('导出名字', title);
    return new Promise(function (resolve, reject) {
      _elementUi.default.Notification({
        title: title + '消息',
        message: '准备导出，请稍等。',
        duration: 0
      });
      (0, _axios.default)({
        method: 'post',
        url: url,
        data: params,
        responseType: 'blob',
        onDownloadProgress: function onDownloadProgress(progressEvent) {
          var progressBar = Math.round(progressEvent.loaded / progressEvent.total * 100);
          if (progressBar >= 99) {
            var obj = document.getElementById("progressBar");
            if (obj) obj.innerHTML += ',' + progressBar + '%';
            // ElementUI.Notification({
            //   title: '成功',
            //   message: '文件正在编译',
            //   type: 'success',
            //   duration:0
            // });
          } else {
            if (document.getElementsByClassName('el-notification').length === 1) {
              _elementUi.default.Notification({
                title: '下载进度',
                dangerouslyUseHTMLString: true,
                message: '<p style=\'width: 100%;word-break: break-all;white-space: pre-wrap;\' id=\'progressBar\'>' + progressBar + '%' + '</p>',
                type: 'warning',
                duration: 0
              });
            } else {
              var obj = document.getElementById("progressBar");
              if (obj) obj.innerHTML += ',' + progressBar + '%';
            }
          }
        }
      }).then(function (response) {
        _elementUi.default.Notification({
          title: '成功',
          message: '下载完成',
          type: 'success',
          duration: 0
        });
        setTimeout(function () {
          _elementUi.default.Notification.closeAll();
        }, 500);
        resolve(response.data);
      }, function (err) {
        if (errAlert) {
          (0, _elementUi.Message)({
            message: err.error.details,
            type: 'error',
            duration: 5 * 1000
          });
        }
        setTimeout(function () {
          _elementUi.default.Notification.closeAll();
        }, 500);
        reject(err);
      }).catch(function (error) {
        setTimeout(function () {
          _elementUi.default.Notification.closeAll();
        }, 500);
        reject(error);
      });
    });
  },
  posts: function posts(url, params) {
    var errAlert = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    return new Promise(function (resolve, reject) {
      _axios.default.post(url, params).then(function (response) {
        resolve(response.data);
      }, function (err) {
        if (errAlert) {
          (0, _elementUi.Message)({
            message: err.error.details,
            type: 'error',
            duration: 5 * 1000
          });
        }
        reject(err);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  postAuth: function postAuth(url, params) {
    var instance = _axios.default.create({
      baseURL: _config.default.auth.ip
    });
    return new Promise(function (resolve, reject) {
      instance.post(url, params).then(function (response) {
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  saves: function saves(url, params) {
    // 保存方法
    return new Promise(function (resolve, reject) {
      var loading = _elementUi.Loading.service({
        lock: true,
        target: document.querySelector('.contentWrapper')
      });
      _axios.default.post(url, params).then(function (response) {
        loading.close();
        resolve(response.data);
      }, function (err) {
        (0, _elementUi.Message)({
          message: err.error.message,
          type: 'error',
          duration: 5 * 1000
        });
        reject(err);
        loading.close();
      }).catch(function (error) {
        loading.close();
        reject(error);
      });
    });
  },
  update: function update(url, params) {
    // 修改方法
    return new Promise(function (resolve, reject) {
      var loading = _elementUi.Loading.service({
        lock: true,
        target: document.querySelector('.contentWrapper')
      });
      _axios.default.put(url, params).then(function (response) {
        loading.close();
        resolve(response.data);
      }, function (err) {
        (0, _elementUi.Message)({
          message: err.error.message,
          type: 'error',
          duration: 5 * 1000
        });
        reject(err);
        loading.close();
      }).catch(function (error) {
        loading.close();
        reject(error);
      });
    });
  },
  view: function view(url, params) {
    return new Promise(function (resolve, reject) {
      var loading = _elementUi.Loading.service({
        lock: true,
        target: document.querySelector('.contentWrapper')
      });
      _axios.default.get(url, {
        'params': params
      }).then(function (response) {
        resolve(response.data);
        loading.close();
      }, function (err) {
        (0, _elementUi.Message)({
          message: err.error.message,
          type: 'error',
          duration: 5 * 1000
        });
        reject(err);
        loading.close();
      }).catch(function (error) {
        reject(error);
        loading.close();
      });
    });
  },
  gets: function gets(url, params) {
    return new Promise(function (resolve, reject) {
      _axios.default.get(url, {
        'params': params
      }).then(function (response) {
        resolve(response.data);
      }, function (err) {
        (0, _elementUi.Message)({
          message: err.error.message,
          type: 'error',
          duration: 5 * 1000
        });
        reject(err);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  deletes: function deletes(url, params) {
    return new Promise(function (resolve, reject) {
      _axios.default.delete(url, {
        'params': params
      }).then(function (response) {
        resolve(response.data);
      }, function (err) {
        (0, _elementUi.Message)({
          message: err.error.details,
          type: 'error',
          duration: 5 * 1000
        });
        reject(err);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  puts: function puts(url, params) {
    return new Promise(function (resolve, reject) {
      _axios.default.put(url, params).then(function (response) {
        resolve(response.data);
      }, function (err) {
        (0, _elementUi.Message)({
          message: err.error.details || err.error.message,
          type: 'error',
          duration: 5 * 1000
        });
        reject(err);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  instancePosts: function instancePosts(url, params) {
    // 登录
    console.log('登录', url);
    console.log('登录', _config.default.auth.ip);
    var instance = _axios.default.create({
      baseURL: _config.default.auth.ip
    });
    var data = _qs.default.stringify(params);
    if (params.loginType == 'phoneType') {
      instance = _axios.default.create({
        baseURL: _config.default.auth.ip,
        headers: {
          '__tenant': params.phoneNumber
        }
      });
    } else {
      if (params.tenant && params.tenant.trim() != '') {
        url = url + '?__tenant=' + params.tenant;
      }
    }
    return new Promise(function (resolve, reject) {
      instance.post(url, data).then(function (response) {
        resolve(response.data);
      }, function (err) {
        console.log(err);
        if (err.response.status === 400) {
          (0, _elementUi.Message)({
            message: '用户名或密码错误',
            type: 'error',
            duration: 5 * 1000
          });
        } else {
          if (err.response.data && err.response.data.indexOf("There is no tenant with the tenant id or name") != -1) {
            (0, _elementUi.Message)({
              message: "租户不存在",
              type: 'error',
              duration: 5 * 1000
            });
          } else {
            (0, _elementUi.Message)({
              message: err.message,
              type: 'error',
              duration: 5 * 1000
            });
          }
        }
        reject(err);
      }).catch(function (error) {
        (0, _elementUi.Message)({
          message: '登录异常',
          type: 'error',
          duration: 5 * 1000
        });
        reject(error);
      });
    });
  },
  getUserInfo: function getUserInfo(url) {
    // 获取用户信息
    var instance = _axios.default.create({
      baseURL: _config.default.auth.ip
    });
    instance.defaults.headers.Authorization = 'Bearer ' + (0, _auth.getToken)();
    return new Promise(function (resolve, reject) {
      instance.get(url).then(function (response) {
        resolve(response.data);
      }, function (err) {
        (0, _elementUi.Message)({
          message: err.message,
          type: 'error',
          duration: 5 * 1000
        });
        reject(err);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getPermissions: function getPermissions(url, params) {
    var instance = _axios.default.create({
      baseURL: _config.default.base.ip
    });
    instance.defaults.headers.Authorization = 'Bearer ' + (0, _auth.getToken)();
    return new Promise(function (resolve, reject) {
      instance.get(url, {
        'params': params
      }).then(function (response) {
        resolve(response.data);
      }, function (err) {
        //此处403错误会提示2次
        // Message({
        //   message: err.message,
        //   type: 'error',
        //   duration: 5 * 1000
        // })
        reject(err);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
exports.default = _default;