var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    [
      _c(
        "el-main",
        [
          _c("el-image", {
            staticStyle: {
              "background-size": "cover",
              position: "absolute",
              "z-index": "-1",
              "background-repeat": "no-repeat",
              width: "50%",
              height: "100%",
            },
            attrs: { src: _vm.backgroupImg },
          }),
        ],
        1
      ),
      _c(
        "el-main",
        [
          _c(
            "el-row",
            {
              staticStyle: { height: "100%" },
              attrs: { type: "flex", justify: "center", align: "middle" },
            },
            [
              _c(
                "el-form",
                {
                  ref: "loginForm",
                  staticClass: "login-form",
                  attrs: {
                    model: _vm.loginForm,
                    rules: _vm.loginRules,
                    "label-position": "left",
                    "label-width": "0px",
                  },
                },
                [
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [_c("el-image", { attrs: { src: _vm.logoImg } })],
                        1
                      ),
                      _c("el-col", { attrs: { span: 10 } }, [
                        _c("span", { staticClass: "title_text_1" }, [
                          _vm._v("哇哇来客"),
                        ]),
                      ]),
                    ],
                    1
                  ),
                  _c("el-row", { staticStyle: { "margin-top": "20px" } }, [
                    _c("span", { staticClass: "title_text_2" }, [
                      _vm._v("欢迎登录"),
                    ]),
                  ]),
                  _c("el-row", { staticStyle: { "margin-top": "20px" } }, [
                    _c("span", { staticClass: "title_text_3" }, [
                      _vm._v("哇哇来客 智慧收银"),
                    ]),
                  ]),
                  _c(
                    "el-row",
                    { staticClass: "row_1" },
                    [
                      _c(
                        "el-tabs",
                        {
                          attrs: { stretch: "stretch" },
                          on: { "tab-click": _vm.handleClick },
                          model: {
                            value: _vm.loginForm.loginType,
                            callback: function ($$v) {
                              _vm.$set(_vm.loginForm, "loginType", $$v)
                            },
                            expression: "loginForm.loginType",
                          },
                        },
                        [
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "账号登录", name: "userType" } },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "tenant" } },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        type: "text",
                                        "auto-complete": "off",
                                        placeholder: "租户",
                                      },
                                      model: {
                                        value: _vm.loginForm.tenant,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.loginForm, "tenant", $$v)
                                        },
                                        expression: "loginForm.tenant",
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass:
                                          "el-input__icon input-icon",
                                        attrs: {
                                          slot: "prefix",
                                          "icon-class": "cloud",
                                        },
                                        slot: "prefix",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { prop: "username" } },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        type: "text",
                                        "auto-complete": "off",
                                        placeholder: "账号",
                                      },
                                      model: {
                                        value: _vm.loginForm.username,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.loginForm,
                                            "username",
                                            $$v
                                          )
                                        },
                                        expression: "loginForm.username",
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass:
                                          "el-input__icon input-icon",
                                        attrs: {
                                          slot: "prefix",
                                          "icon-class": "user",
                                        },
                                        slot: "prefix",
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.usernameAlert
                                    ? _c("el-alert", {
                                        staticStyle: {
                                          padding: "0px",
                                          "margin-top": "5px",
                                          "margin-bottom": "-10px",
                                        },
                                        attrs: {
                                          title: "账号不能为空",
                                          type: "error",
                                          closable: false,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { prop: "password" } },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        type: "password",
                                        "auto-complete": "off",
                                        placeholder: "密码",
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.handleLogin($event)
                                        },
                                      },
                                      model: {
                                        value: _vm.loginForm.password,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.loginForm,
                                            "password",
                                            $$v
                                          )
                                        },
                                        expression: "loginForm.password",
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass:
                                          "el-input__icon input-icon",
                                        attrs: {
                                          slot: "prefix",
                                          "icon-class": "password",
                                        },
                                        slot: "prefix",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { staticStyle: { "margin-top": "-5px" } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          staticClass: "buttom_title",
                                          staticStyle: { color: "#1453E6" },
                                          attrs: { type: "primary" },
                                          on: { click: _vm.register },
                                        },
                                        [_vm._v("立即注册22 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { "text-align": "right" },
                                      attrs: { span: 12 },
                                    },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          staticClass: "buttom_title",
                                          staticStyle: { color: "#1453E6" },
                                          attrs: { type: "primary" },
                                          on: { click: _vm.passwordReset },
                                        },
                                        [_vm._v("忘记密码 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "margin-top": "10px",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        background: "#F04F0B",
                                        opacity: "1",
                                        "border-radius": "2px",
                                        "font-size": "13px",
                                        "font-family": "Microsoft YaHei",
                                        "font-weight": "400",
                                        "line-height": "14px",
                                        color: "#FFFFFF",
                                      },
                                      attrs: {
                                        loading: _vm.loading,
                                        size: "medium",
                                        type: "primary",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleLogin($event)
                                        },
                                      },
                                    },
                                    [
                                      !_vm.loading
                                        ? _c("span", [_vm._v("登 录")])
                                        : _c("span", [_vm._v("登 录 中...")]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-tab-pane",
                            {
                              attrs: { label: "手机号登录", name: "phoneType" },
                            },
                            [
                              _c(
                                "el-form-item",
                                { attrs: { prop: "username" } },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        type: "text",
                                        "auto-complete": "off",
                                        placeholder: "手机号",
                                      },
                                      model: {
                                        value: _vm.loginForm.phoneNumber,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.loginForm,
                                            "phoneNumber",
                                            $$v
                                          )
                                        },
                                        expression: "loginForm.phoneNumber",
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass:
                                          "el-input__icon input-icon",
                                        attrs: {
                                          slot: "prefix",
                                          "icon-class": "user",
                                        },
                                        slot: "prefix",
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm.phoneAlert
                                    ? _c("el-alert", {
                                        staticStyle: {
                                          padding: "0px",
                                          "margin-top": "5px",
                                          "margin-bottom": "-10px",
                                        },
                                        attrs: {
                                          title: "手机号不能为空",
                                          type: "error",
                                          closable: false,
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                { attrs: { prop: "password" } },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      attrs: {
                                        type: "password",
                                        "auto-complete": "off",
                                        placeholder: "密码",
                                      },
                                      nativeOn: {
                                        keyup: function ($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.handleLogin($event)
                                        },
                                      },
                                      model: {
                                        value: _vm.loginForm.password,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.loginForm,
                                            "password",
                                            $$v
                                          )
                                        },
                                        expression: "loginForm.password",
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        staticClass:
                                          "el-input__icon input-icon",
                                        attrs: {
                                          slot: "prefix",
                                          "icon-class": "password",
                                        },
                                        slot: "prefix",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-row",
                                { staticStyle: { "margin-top": "-5px" } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 12 } },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          staticClass: "buttom_title",
                                          staticStyle: { color: "#1453E6" },
                                          attrs: { type: "primary" },
                                          on: { click: _vm.register },
                                        },
                                        [_vm._v("立即注22册 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-col",
                                    {
                                      staticStyle: { "text-align": "right" },
                                      attrs: { span: 12 },
                                    },
                                    [
                                      _c(
                                        "el-link",
                                        {
                                          staticClass: "buttom_title",
                                          staticStyle: { color: "#1453E6" },
                                          attrs: { type: "primary" },
                                          on: { click: _vm.passwordReset },
                                        },
                                        [_vm._v("忘记密码 ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "el-form-item",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "margin-top": "10px",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: {
                                        width: "100%",
                                        opacity: "1",
                                        "border-radius": "2px",
                                        "font-size": "13px",
                                        "font-family": "Microsoft YaHei",
                                        "font-weight": "400",
                                        "line-height": "14px",
                                        color: "#FFFFFF",
                                      },
                                      attrs: {
                                        loading: _vm.loading,
                                        size: "medium",
                                        type: "primary",
                                      },
                                      nativeOn: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.handleLogin($event)
                                        },
                                      },
                                    },
                                    [
                                      !_vm.loading
                                        ? _c("span", [_vm._v("登 录")])
                                        : _c("span", [_vm._v("登 录 中...")]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "扫码登录", name: "qrType" } },
                            [
                              _c("el-image", {
                                staticStyle: { width: "100%", height: "200px" },
                                attrs: { src: _vm.qrImg },
                              }),
                              _c(
                                "el-row",
                                {
                                  staticStyle: { "margin-top": "20px" },
                                  attrs: {
                                    type: "flex",
                                    justify: "center",
                                    align: "middle",
                                  },
                                },
                                [
                                  _c("el-image", {
                                    staticStyle: { height: "16px" },
                                    attrs: { src: _vm.qrlogoImg },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        height: "16px",
                                        "font-size": "13px",
                                        "font-family": "Microsoft YaHei",
                                        "font-weight": "400",
                                        "line-height": "16px",
                                        color: "#999999",
                                        opacity: "1",
                                        "margin-left": "10px",
                                      },
                                    },
                                    [_vm._v("打开微信“扫一扫”即可登录")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("Register", {
                attrs: {
                  isShow: _vm.openRegDialog,
                  closeCreate: _vm.closeReg,
                  formTitle: "注册",
                  isLoginShow: false,
                },
              }),
              _c(
                "el-dialog",
                {
                  staticClass: "reg-form",
                  attrs: {
                    "close-on-click-modal": false,
                    visible: _vm.dialogResetVisible,
                    title: "找回密码",
                    width: "450px",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogResetVisible = $event
                    },
                    close: function ($event) {
                      return _vm.cancel()
                    },
                  },
                },
                [
                  _c(
                    "el-form",
                    {
                      ref: "resetForm",
                      attrs: {
                        inline: true,
                        model: _vm.resetForm,
                        rules: _vm.resetRules,
                        size: "small",
                        "label-width": "100px",
                      },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { prop: "tenantName", label: "租户名称：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "255px" },
                            attrs: {
                              "auto-complete": "off",
                              placeholder: "租户名称",
                              "prefix-icon": "el-icon-mobile-phone",
                            },
                            model: {
                              value: _vm.resetForm.tenantName,
                              callback: function ($$v) {
                                _vm.$set(_vm.resetForm, "tenantName", $$v)
                              },
                              expression: "resetForm.tenantName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "phone", label: "手机号码：" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "255px" },
                            attrs: {
                              "auto-complete": "off",
                              placeholder: "账号绑定的手机号码",
                              "prefix-icon": "el-icon-mobile-phone",
                            },
                            model: {
                              value: _vm.resetForm.phone,
                              callback: function ($$v) {
                                _vm.$set(_vm.resetForm, "phone", $$v)
                              },
                              expression: "resetForm.phone",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "validCode", label: "验证码：" } },
                        [
                          _c(
                            "el-input",
                            {
                              attrs: {
                                "auto-complete": "off",
                                placeholder: "输入验证码",
                                "prefix-icon": "el-icon-s-promotion",
                              },
                              model: {
                                value: _vm.resetForm.validCode,
                                callback: function ($$v) {
                                  _vm.$set(_vm.resetForm, "validCode", $$v)
                                },
                                expression: "resetForm.validCode",
                              },
                            },
                            [
                              _c(
                                "el-button",
                                { attrs: { slot: "append" }, slot: "append" },
                                [_vm._v("发送")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { prop: "password", label: "新密码" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "255px" },
                            attrs: {
                              type: "password",
                              "auto-complete": "off",
                              placeholder: "6-32位数字、字母、符号的组合",
                              "prefix-icon": "el-icon-lock",
                              "show-password": "",
                            },
                            model: {
                              value: _vm.resetForm.password,
                              callback: function ($$v) {
                                _vm.$set(_vm.resetForm, "password", $$v)
                              },
                              expression: "resetForm.password",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            prop: "confirmPassword",
                            label: "确认密码：",
                          },
                        },
                        [
                          _c("el-input", {
                            staticStyle: { width: "255px" },
                            attrs: {
                              type: "password",
                              "auto-complete": "off",
                              placeholder: "重复输入登录密码",
                              "prefix-icon": "el-icon-lock",
                              "show-password": "",
                            },
                            model: {
                              value: _vm.resetForm.confirmPassword,
                              callback: function ($$v) {
                                _vm.$set(_vm.resetForm, "confirmPassword", $$v)
                              },
                              expression: "resetForm.confirmPassword",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c("el-button", { on: { click: _vm.cancel } }, [
                        _vm._v("取 消"),
                      ]),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.resetPassword },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }