"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _objectSpread2 = _interopRequireDefault(require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime-corejs2/helpers/objectSpread2.js"));
var _vuex = require("vuex");
var _UserCard = _interopRequireDefault(require("./components/UserCard"));
var _Activity = _interopRequireDefault(require("./components/Activity"));
var _Timeline = _interopRequireDefault(require("./components/Timeline"));
var _Account = _interopRequireDefault(require("./components/Account"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'profile',
  components: {
    UserCard: _UserCard.default,
    Activity: _Activity.default,
    Timeline: _Timeline.default,
    Account: _Account.default
  },
  data: function data() {
    return {
      user: {},
      activeTab: 'activity'
    };
  },
  computed: (0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['name', 'avatar', 'roles'])),
  created: function created() {
    this.getUser();
  },
  methods: {
    getUser: function getUser() {
      this.user = {
        name: this.name,
        role: this.roles.join(' | '),
        email: 'admin@test.com',
        avatar: this.avatar
      };
    }
  }
};
exports.default = _default;