"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _api = _interopRequireDefault(require("../api"));
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
//表单默认值
var defaultForm = {
  id: null,
  tempNo: "",
  smsTemplateType: "",
  title: "",
  content: ""
};
var _default = {
  name: "smsTemplateCreate",
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    permission: _index.default
  },
  props: [
  //是否显示窗口
  'isShow',
  //窗口关闭事件回调
  'closeCreate',
  //保存成功后回调
  'callback',
  //是否是修改
  'isEdit',
  //修改时传入的id
  'id',
  //标题
  'formTitle'],
  watch: {
    isShow: function isShow(newVal) {
      this.dialogFormVisible = newVal; //对父组件传过来的值进行监听，如果改变也对子组件内部的值进行改变
      this.initData(); //在显示的时候处理数据才能生效,因为显示的值在每次打开时肯定是变动的，
    },
    dialogFormVisible: function dialogFormVisible(newVal) {
      if (!newVal) {
        this.closeCreate(); //内部不关闭自己页面，会造成循环，让外部父组件来关闭
      }
    }
  },
  data: function data() {
    return {
      //控制弹窗显示
      dialogFormVisible: false,
      //弹窗加载框
      formLoading: false,
      isTop: true,
      //表单提交校验规则
      rules: {
        tempNo: [{
          required: true,
          message: "请输入短信模板ID",
          trigger: "blur"
        }],
        smsTemplateType: [{
          required: true,
          message: "请选择短信类型",
          trigger: "change"
        }],
        title: [{
          required: true,
          message: "请输入短信主题",
          trigger: "blur"
        }],
        content: [{
          required: true,
          message: "请输入短信内容",
          trigger: "blur"
        }]
      },
      //表单定义 并赋默认值
      form: Object.assign({}, defaultForm),
      smsTemplateType: _enum.default.smsTemplateType,
      cursorIndex: 0
    };
  },
  methods: {
    /** 显示时初始化数据 */initData: function initData() {
      this.form = Object.assign({}, defaultForm);
      //如果是编辑状态，远程加载信息
      if (this.isEdit) {
        if (this.id) {
          this.fetchData(this.id);
        }
      }
    },
    /** 加载数据 */fetchData: function fetchData(id) {
      var _this = this;
      this.formLoading = true;
      _api.default.get(id).then(function (response) {
        _this.form = response;
        _this.formLoading = false;
      });
    },
    /**保存 */save: function save() {
      var _this2 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this2.isEdit) {
            _this2.formLoading = true;
            _api.default.update(_this2.form).then(function (response) {
              console.info(response);
              _this2.formLoading = false;
              _this2.$notify({
                title: "成功",
                message: "更新成功",
                type: "success",
                duration: 2000
              });
              _this2.dialogFormVisible = false;
              _this2.callback();
            }).catch(function () {
              _this2.formLoading = false;
            });
          } else {
            _this2.formLoading = true;
            _api.default.create(_this2.form).then(function (response) {
              _this2.formLoading = false;
              _this2.$notify({
                title: "成功",
                message: "新增成功",
                type: "success",
                duration: 2000
              });
              if (_this2.checked == true) {
                _this2.$refs.form.clearValidate();
                _this2.form = Object.assign({}, defaultForm);
                _this2.callback();
              } else {
                _this2.dialogFormVisible = false;
                _this2.callback();
              }
            }).catch(function () {
              _this2.formLoading = false;
            });
          }
        }
      });
    },
    /**取消按钮 */cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
      this.form = Object.assign({}, defaultForm);
    },
    handleInputBlur: function handleInputBlur(e) {
      this.cursorIndex = e.srcElement.selectionStart;
    },
    addLabel: function addLabel(e) {
      var str = '{' + e + '}';
      var s1 = '';
      var s2 = '';
      if (this.form.content.length < this.cursorIndex) {
        this.form.content = this.form.content + str;
      } else {
        s1 = this.form.content.substring(0, this.cursorIndex);
        s2 = this.form.content.substring(this.cursorIndex, this.form.content.length);
        this.form.content = s1 + str + s2;
      }
      this.$refs.content.focus();
    }
  }
};
exports.default = _default;