"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getPageTitle;
var _settings = _interopRequireDefault(require("@/settings"));
var _lang = _interopRequireDefault(require("@/lang"));
var title = _settings.default.title || '哇哇来客';
function getPageTitle(key) {
  var hasKey = _lang.default.te("route.".concat(key));
  if (hasKey) {
    var pageName = _lang.default.t("route.".concat(key));
    return "".concat(pageName, " - ").concat(title);
  }
  return "".concat(title);
}