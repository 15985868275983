"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.array.find-index");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _api = _interopRequireDefault(require("./api"));
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var _index2 = _interopRequireDefault(require("./create/index.vue"));
var _SaasFormItemSelect = _interopRequireDefault(require("@/saas/components/SaasFormItemSelect.vue"));
var _SaasButtonGroup = _interopRequireDefault(require("@/saas/components/SaasButtonGroup.vue"));
var _index3 = _interopRequireDefault(require("@/components/MyElColumn/index.vue"));
//导入本功能的api接口
//导入创建窗口
var _default = {
  name: "posAuth",
  components: {
    Pagination: _Pagination.default,
    MyCreate: _index2.default,
    PageSelectFormItem: _SaasFormItemSelect.default,
    SaasButtonGroup: _SaasButtonGroup.default,
    MyElColumn: _index3.default
  },
  directives: {
    permission: _index.default
  },
  data: function data() {
    return {
      defaultProps: {
        children: "children",
        label: "label"
      },
      list: [],
      totalCount: 0,
      listLoading: true,
      listQuery: {
        Filter: "",
        SkipCount: 0,
        MaxResultCount: 10
      },
      page: 1,
      multipleSelection: [],
      PosAuthType: _enum.default.PosAuthType,
      //显示创建窗口
      dialogFormVisible: false,
      formTitle: "",
      isEdit: false,
      editId: '',
      isTop: true,
      tableDef: _api.default.tableDef
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    /**  排序 */sortChange: function sortChange(data) {
      var prop = data.prop,
        order = data.order;
      if (!prop || !order) {
        this.Filter();
        return;
      }
      this.listQuery.Sorting = prop + ' ' + order;
      this.Filter();
    },
    /** 获取分页列表 */getList: function getList() {
      var _this = this;
      this.listLoading = true;
      this.listQuery.SkipCount = (this.page - 1) * this.listQuery.MaxResultCount;
      this.list = [];
      _api.default.getAll(this.listQuery).then(function (response) {
        _this.list = response.items;
        _this.totalCount = response.totalCount;
        _this.listLoading = false;
      });
    },
    /** 搜索事件 */Search: function Search() {
      this.page = 1;
      this.getList();
    },
    /** 新增按钮点击事件 */Create: function Create() {
      this.formTitle = "新增机构";
      this.isEdit = false;
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    /** 新增窗口关闭事件 */handleCloseCreate: function handleCloseCreate() {
      this.dialogFormVisible = false;
    },
    /** 新增成功刷新列表*/handleCreateCallback: function handleCreateCallback(val) {
      this.getList();
    },
    /** 删除 */Delete: function Delete(row) {
      var _this2 = this;
      var params = [];
      var alert = "";
      if (this.multipleSelection.findIndex(function (item) {
        return item.sysDef === true;
      }) > -1) {
        this.$message({
          message: "选择项中含有系统默认菜单， 不允许删除",
          type: "warning"
        });
        return;
      }
      if (row) {
        params.push(row.id);
        alert = row.blanceName;
      } else {
        if (this.multipleSelection.length === 0) {
          this.$message({
            message: "未选择",
            type: "warning"
          });
          return;
        }
        this.multipleSelection.forEach(function (element) {
          var id = element.id;
          params.push(id);
        });
        alert = "选中项";
      }
      this.$confirm("是否删除" + alert + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _api.default.deleteById(params).then(function (response) {
          _this2.$notify({
            title: "成功",
            message: "删除成功",
            type: "success",
            duration: 2000
          });
          _this2.getList();
        });
      }).catch(function () {
        _this2.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    /** 更新 */Update: function Update(row) {
      this.formTitle = "修改机构";
      this.isEdit = true;
      if (row) {
        this.editId = row.id;
        this.dialogFormVisible = true;
      } else {
        if (this.multipleSelection.length != 1) {
          this.$message({
            message: "编辑必须选择单行",
            type: "warning"
          });
          return;
        } else {
          this.editId = this.multipleSelection[0].id;
          this.dialogFormVisible = true;
        }
      }
    },
    /** 多选事件 */handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /** 行点击事件 */handleRowClick: function handleRowClick(row, column, event) {
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    /** 列表状态按钮点击事件 */changeEnabled: function changeEnabled(data, val) {
      var _this3 = this;
      data.active = val ? "启用" : "停用";
      this.$confirm("是否" + data.active + data.name + "？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _api.default.update(data).then(function (response) {
          _this3.$notify({
            title: "成功",
            message: "更新成功",
            type: "success",
            duration: 2000
          });
        }).catch(function () {
          data.enabled = !data.enabled;
        });
      }).catch(function () {
        data.enabled = !data.enabled;
      });
    }
  }
};
exports.default = _default;