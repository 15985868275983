"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var table = [{
  Display: true,
  DisplayType: "text",
  Label: "商户ID",
  Name: "merchantId",
  Sort: false,
  Align: "",
  Width: "100",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "商户名称",
  Name: "merchantName",
  Sort: false,
  Align: "",
  Width: "120",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "商户版本",
  Name: "merchantVersion",
  Sort: false,
  Align: "",
  Width: "100",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "移动支付交易笔数",
  Name: "businessNum",
  Sort: false,
  Align: "",
  Width: "180",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "移动支付交易金额",
  Name: "businessAmount",
  Sort: false,
  Align: "",
  Width: "180",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "datetime",
  Label: "移动支付最后一笔交易时间",
  Name: "businessDate",
  Sort: false,
  Align: "",
  Width: "200",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "所属代理商",
  Name: "agentName",
  Sort: false,
  Align: "",
  Width: "120",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "商户号",
  Name: "merchant",
  Sort: false,
  Align: "",
  Width: "150",
  IsLink: true,
  NavDisplayProp: ""
}];
var _default = {
  table: table
};
exports.default = _default;