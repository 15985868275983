"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//POS类型
var warnLevelMap = [{
  value: 20,
  text: "正常升级",
  type: "info"
}, {
  value: 30,
  text: "强制升级",
  type: "warning"
}, {
  value: 0,
  text: "不规则",
  type: "primary"
}, {
  value: 10,
  text: "不升级",
  type: "success"
}];
var categoryMap = [{
  value: 1,
  text: "公司"
}, {
  value: 2,
  text: "组织"
}, {
  value: 3,
  text: "部门"
}, {
  value: 4,
  text: "供应商"
}];

/// 商户订单类型
var merchantOrderType = [{
  value: 0,
  text: "销售",
  type: "success"
}, {
  value: 1,
  text: "退款",
  type: "info"
}];
//商户订单状态
var merchantOrderSta = [{
  value: 0,
  text: "待付款",
  type: "info"
}, {
  value: 1,
  text: "已付款",
  type: "success"
}];
var SettleStatus = [{
  value: -1,
  text: "全部"
}, {
  value: 0,
  text: "未结算",
  type: "primary"
}, {
  value: 1,
  text: "已结算",
  type: "success"
}, {
  value: 2,
  text: "无需结算",
  type: "info"
}];
//POS类型
var posTypeMap = [{
  value: 0,
  text: "windows",
  type: "primary"
}, {
  value: 1,
  text: "安卓pos",
  type: "success"
}, {
  value: 2,
  text: "Linux",
  type: "info"
}, {
  value: 3,
  text: "Trans",
  type: "warning"
}, {
  value: 4,
  text: "WinAutoUp",
  type: "warning"
}, {
  value: 5,
  text: "WindowsOEM",
  type: "warning"
}];
//秤类型
var blanceTypeMap = [{
  value: "0",
  text: "NONE"
}, {
  value: "1",
  text: "顶尖LS2X系列"
}, {
  value: "2",
  text: "寺岗SM-80/SM-81"
}, {
  value: "3",
  text: "石田BC-3000"
}, {
  value: "4",
  text: "托利多RL100"
}, {
  value: "5",
  text: "寺岗-2"
}, {
  value: "6",
  text: "寺岗-execl文件接口"
}, {
  value: "7",
  text: "太航"
}, {
  value: "8",
  text: "寺岗SM-80/SM-80XP系列(TOP2000)"
}, {
  value: "9",
  text: "大华TM系列"
}, {
  value: "10",
  text: "友声TM系列"
}, {
  value: "A",
  text: "石油 Astra XT"
}, {
  value: "B",
  text: "石油DB-8000L1"
}, {
  value: "F",
  text: "大华TM15A"
}, {
  value: "G",
  text: "耐克斯MDS/ADS系列条码标签称"
}, {
  value: "K",
  text: "迪宝清风系列"
}, {
  value: "H",
  text: "托利多bPlus"
}, {
  value: "I",
  text: "托利多新(RL100 & BCOM)"
}, {
  value: "J",
  text: "精函TM-XA系列"
}, {
  value: "N",
  text: "碧彩BIZERBR BC(新)"
}, {
  value: "V",
  text: "顶尖PX2(兼容M3)"
}, {
  value: "X",
  text: "易衡ELS-30"
}, {
  value: "Y",
  text: "容大RLS1XXX系列"
}, {
  value: "Z",
  text: "顶尖LS6X系列"
}];
/*经营方式 */
var payAwayTypeMap = [{
  value: -1,
  text: "全部"
}, {
  value: 0,
  text: "购销"
}, {
  value: 1,
  text: "联营",
  type: "success"
}, {
  value: 2,
  text: "扣率代销",
  type: "warning"
}, {
  value: 3,
  text: "租赁(商户)",
  type: "danger"
}];
/*结算方式 */
var settlementAwayTypeMap = [{
  value: 0,
  text: "临时指定"
}, {
  value: 1,
  text: "货到付款"
}, {
  value: 2,
  text: "指定账期"
}, {
  value: 3,
  text: "指定日期"
}];
//优惠方式
var saleWayMap = [{
  value: "0",
  text: "零售价折扣"
}, {
  value: "1",
  text: "会员价"
}];
//额度类型
var quotaTypeMap = [{
  value: "LJ",
  text: "累计积分"
}, {
  value: "SY",
  text: "剩余积分"
}];
//机构类型
var tradeTypeMap = [{
  value: 0,
  text: "总部",
  type: "info",
  disabled: true
}, {
  value: 1,
  text: "自营店",
  type: "success"
}, {
  value: 2,
  text: "加盟店(强加盟)",
  type: "warning"
}, {
  value: 3,
  text: "配送中心",
  type: "danger"
}, {
  value: 4,
  text: "加盟总部/加盟商(弱加盟)",
  type: "success"
}, {
  value: 5,
  text: "仓库",
  type: "",
  disabled: true
}];
//配送价格
var priceOptionMap = [{
  value: 0,
  text: "进货价"
}, {
  value: 1,
  text: "成本价"
}, {
  value: 2,
  text: "配送价"
}, {
  value: 3,
  text: "零售价"
}, {
  value: 4,
  text: "批发价"
}, {
  value: 5,
  text: "进货价(1+加价率)"
}, {
  value: 6,
  text: "零售价(1+加价率)"
}, {
  value: 7,
  text: "成本价(1+加价率)"
}, {
  value: 8,
  text: "配送价(1+加价率)"
}, {
  value: 9,
  text: "零售价*加价率"
}];
//商品类型
var combineStaMap = [{
  value: 0,
  text: "普通商品"
}, {
  value: 1,
  text: "捆绑商品",
  type: "info"
},
// { value: 2, text: "制单拆分",type:"success" },
// { value: 3, text: "制单组合",type:"danger" },
{
  value: 4,
  text: "自动转货",
  type: "warning"
},
// { value: 5, text: "混售商品",type:"warning" },
{
  value: 6,
  text: "配送商品",
  type: "success"
}];
//计价方式
var measureFlagMap = [{
  value: 0,
  text: "普通"
}, {
  value: 1,
  text: "计重"
}, {
  value: 2,
  text: "计数"
}];
//单位
var unitnoMap = [{
  value: 0,
  text: "包"
}, {
  value: 1,
  text: "件"
}, {
  value: 2,
  text: "Kg"
}, {
  value: 3,
  text: "公斤"
}, {
  value: 4,
  text: "桶"
}, {
  value: 5,
  text: "袋"
}, {
  value: 6,
  text: "碗"
}, {
  value: 7,
  text: "瓶"
}, {
  value: 8,
  text: "支"
}, {
  value: 9,
  text: "个"
}];
//销售状态
var ItemStatusMap = [{
  value: 0,
  text: "正常"
}, {
  value: 1,
  text: "停售"
}, {
  value: 2,
  text: "淘汰"
}, {
  value: 3,
  text: "停购"
}];
//单据状态
var SheetStatusMap = [{
  value: 0,
  text: "待配送",
  type: "info"
}, {
  value: 1,
  text: "配送中",
  type: "warning"
}, {
  value: 2,
  text: "未采购",
  type: "danger"
}, {
  value: 3,
  text: "已采购",
  type: "primary"
}, {
  value: 4,
  text: "已完成",
  type: "success"
}];
//审核状态
var ApproveFlagMap = [
//{ value: '', text: "全部" },
{
  value: 0,
  text: "待审核",
  type: "warning"
}, {
  value: 1,
  text: "已审核",
  type: "success"
}, {
  value: 2,
  text: "已驳回",
  type: "danger"
}];

//续费状态
var RenewFlagMap = [{
  value: 0,
  text: "未续费",
  type: "warning"
}, {
  value: 1,
  text: "已续费",
  type: "success"
}];
//商户状态
var StatusFlagMap = [{
  value: 0,
  text: "未启用",
  type: "warning"
}, {
  value: 1,
  text: "已启用",
  type: "success"
}];
var BranchRegStatusType = [{
  value: 0,
  text: "启用"
}, {
  value: 1,
  text: "停用"
}, {
  value: 2,
  text: "到期"
}];
var rentalTypeMap = [{
  value: 0,
  text: "空闲",
  type: ""
}, {
  value: 1,
  text: "已出租",
  type: "info"
}];
var shopTypeMap = [{
  value: 1,
  text: "装修中",
  type: "info"
}, {
  value: 2,
  text: "营业中",
  type: "success"
}, {
  value: 3,
  text: "闭店中",
  type: "danger"
}];
//合同审核状态
var approveStateMap = [{
  value: 0,
  text: "已审核",
  type: ""
}, {
  value: 1,
  text: "未审核",
  type: "danger"
}];
//合同交费周期
var paymentCycleMap = [{
  value: 0,
  text: "年",
  type: ""
}, {
  value: 1,
  text: "月",
  type: "danger"
}];
//合同付款状态
var payStateMap = [{
  value: 0,
  text: "已付",
  type: ""
}, {
  value: 1,
  text: "未付",
  type: "danger"
}, {
  value: 2,
  text: "作废",
  type: "info"
}];
//销售方式
var SellWay = [{
  value: "A",
  text: "销售",
  type: ""
}, {
  value: "C",
  text: "赠送",
  type: "info"
}, {
  value: "B",
  text: "退货",
  type: "danger"
}, {
  value: "D",
  text: "找零",
  type: "success"
}, {
  value: "E",
  text: "充值",
  type: "danger"
}];
//巨龙支付付款状态
var JLpayStatusMap = [{
  value: 1,
  text: "支付成功",
  type: "success"
}, {
  value: 2,
  text: "支付失败",
  type: "danger"
}, {
  value: 3,
  text: "支付中",
  type: "info"
}, {
  value: 5,
  text: "退款成功",
  type: "success"
}, {
  value: 6,
  text: "退款失败",
  type: "danger"
}, {
  value: 8,
  text: "退款中",
  type: "info"
}];
var PayRequestType = [{
  value: 1,
  text: "普通请求"
}, {
  value: 2,
  text: "小程序请求"
}, {
  value: 3,
  text: "App请求"
}];
var TagMarketingType = [{
  value: 0,
  text: "指定日期"
}, {
  value: 1,
  text: "指定天数"
}];
//小程序菜单分组
var MiniMenuGroup = [{
  value: "-1",
  text: "所有"
}, {
  value: "0",
  text: "系统",
  type: "success"
}, {
  value: "1",
  text: "供应商",
  type: "danger"
}, {
  value: "2",
  text: "店员",
  type: "info"
}];
//员工身份类型
var OperType = [{
  value: "1",
  text: "收银员"
}, {
  value: "2",
  text: "店员"
}, {
  value: "3",
  text: "营业员"
}, {
  value: "4",
  text: "采购员"
}, {
  value: "5",
  text: "分拣员"
}, {
  value: "6",
  text: "司机"
}, {
  value: "7",
  text: "供应商"
}];
//终止状态
var StopMap = [{
  value: 0,
  text: "未终止",
  type: "success"
}, {
  value: 1,
  text: "已终止",
  type: "danger"
}];
//系统服务列表
var Services = [{
  value: 1,
  text: "档案"
}, {
  value: 2,
  text: "单据"
}, {
  value: 3,
  text: "会员"
}, {
  value: 4,
  text: "销售流水"
}, {
  value: 5,
  text: "库存"
}, {
  value: 6,
  text: "支付记录"
}, {
  value: 7,
  text: "审计日志"
}];

//采购任务状态
var PcStatusMap = [{
  value: "0",
  text: "未完成",
  type: "danger"
}, {
  value: "1",
  text: "已完成",
  type: "success"
}, {
  value: "2",
  text: "部分完成",
  type: "info"
}, {
  value: "3",
  text: "超量完成",
  type: "info"
}];
var ReceiveStatusMap = [{
  value: "0",
  text: "未领取",
  type: "danger"
}, {
  value: "1",
  text: "已领取",
  type: "success"
}];
var shopType = [{
  value: 1,
  text: "生鲜超市"
}, {
  value: 2,
  text: "便利店"
}, {
  value: 3,
  text: "综合超市"
}, {
  value: 4,
  text: "专卖店"
}, {
  value: 5,
  text: "服装店"
}, {
  value: 6,
  text: "母婴店"
}, {
  value: 7,
  text: "菜市场"
}, {
  value: 8,
  text: "批发市场"
}, {
  value: 9,
  text: "大型商场"
}];
var AssignmentStatusMap = [{
  value: "0",
  text: "未分配",
  type: "danger"
}, {
  value: "1",
  text: "已分配",
  type: "success"
}];
//配送任务状态
var DistributionStatusMap = [{
  value: "-1",
  text: "全部"
}, {
  value: "0",
  text: "未完成"
}, {
  value: "1",
  text: "已完成"
}];

//收货状态
var ReceiptApproveFlagMap = [{
  value: 0,
  text: "待收货",
  type: "warning"
}, {
  value: 1,
  text: "已收货",
  type: "success"
}, {
  value: 2,
  text: "已拒收",
  type: "danger"
}];
//退款收货状态
var ReceivStatusMap = [{
  value: 1,
  text: "已收货",
  type: "success"
}, {
  value: 2,
  text: "未收货",
  type: "danger"
}];
//退货原因
var ReturnReasonMap = [{
  value: 1,
  text: "质量问题"
}, {
  value: 2,
  text: "配送问题"
}, {
  value: 3,
  text: "其他问题"
}];
var ProgressStatusMap = [{
  value: null,
  text: "全部"
}, {
  value: 0,
  text: "待分配",
  type: "danger"
}, {
  value: 1,
  text: "到司机",
  type: "warning"
}, {
  value: 2,
  text: "到分拣",
  type: "info"
}, {
  value: 3,
  text: "到供应商",
  type: "danger"
}, {
  value: 5,
  text: "已完成",
  type: "success"
}];
//收支类型
var payType = [{
  value: 0,
  text: "全部"
}, {
  value: 1,
  text: "收入",
  type: "success"
}, {
  value: -1,
  text: "支出",
  type: "warning"
}];
//收支项目
var payItem = [{
  value: 0,
  text: "全部"
}, {
  value: 1,
  text: "销售",
  type: "warning"
}, {
  value: 2,
  text: "结算",
  type: "info"
}, {
  value: 3,
  text: "盘点",
  type: "danger"
}, {
  value: 4,
  text: "门店费用",
  type: "success"
}];
//积分订单状态
var accOrderStatus = [{
  value: 0,
  text: "已完成"
}, {
  value: 1,
  text: "待核销"
}, {
  value: 2,
  text: "待收货"
}];
//购物券类型
var couponType = [{
  value: 1,
  text: "现金券"
}, {
  value: 2,
  text: "折扣券"
}, {
  value: 3,
  text: "兑物券"
}];
//购物券使用状态
var couponUseStatus = [{
  value: 0,
  text: "未领取"
}, {
  value: 1,
  text: "未使用"
}, {
  value: 2,
  text: "已使用"
}, {
  value: 3,
  text: "已过期"
}];
//微门禁在线状态
var wmjOnline = [{
  value: 0,
  text: "离线"
}, {
  value: 1,
  text: "在线"
}];
var oplockAction = [{
  value: 0,
  text: "后台操作",
  type: "warning"
}, {
  value: 1,
  text: "扫码进门",
  type: "success"
}, {
  value: 2,
  text: "扫码出门"
}];
var PaymentType = [{
  value: 1,
  text: "POS",
  type: "warning"
}, {
  value: 2,
  text: "AndroidPos",
  type: "success"
}, {
  value: 3,
  text: "扫码支付"
}, {
  value: 4,
  text: "AndroidApp",
  type: "info"
}, {
  value: 5,
  text: "订单支付",
  type: "danger"
}, {
  value: 6,
  text: "后台",
  type: ""
}, {
  value: 7,
  text: "小程序",
  type: ""
}];
var orderTypeMap = [{
  value: 1,
  text: "商城订单",
  type: "success"
}, {
  value: 2,
  text: "积分订单",
  type: "danger"
}];
var deliveryMethodMap = [{
  value: 1,
  text: "自配送",
  type: "success"
}, {
  value: 2,
  text: "达达",
  type: "danger"
}];
var orderStatusMap = [
// { value: 0, text: "全部", type: "warning" },
{
  value: -1,
  text: "已取消",
  type: "danger"
}, {
  value: 1,
  text: "待付款",
  type: "extra"
}, {
  value: 2,
  text: "待发货",
  type: "warning"
}, {
  value: 3,
  text: "待收货",
  type: "warning"
}, {
  value: 4,
  text: "待评价",
  type: "warning"
}, {
  value: 5,
  text: "售后"
}, {
  value: 6,
  text: "已退款",
  type: "danger"
}, {
  value: 7,
  text: "交易成功",
  type: "success"
}];
var agentType = [{
  value: 4,
  text: "普通代理"
}, {
  value: 3,
  text: "区代"
}, {
  value: 2,
  text: "市代"
}, {
  value: 1,
  text: "省代"
}];
var TypeSale = [{
  value: 0,
  text: "POS",
  type: "warning"
}, {
  value: 1,
  text: "二维码Pos",
  type: "success"
}, {
  value: 2,
  text: "二维码店铺"
}, {
  value: 3,
  text: "订单支付",
  type: "info"
}];
// 验证码枚举
var verificationCodeType = [{
  value: 4,
  text: "Confirm"
}, {
  value: 3,
  text: "ResetPassword"
}, {
  value: 2,
  text: "Register"
}, {
  value: 1,
  text: "Login"
}];
var packageType = [{
  value: 0,
  text: "安装包",
  type: "success"
}, {
  value: 1,
  text: "升级包",
  type: "danger"
}];
var productType = [{
  value: 0,
  text: "软件",
  type: "success"
}, {
  value: 1,
  text: "点数",
  type: "warning"
}
//   { value: 2, text: "微会员", type: "danger" },
//   { value: 3, text: "微小店", type: "info" },
//   { value: 4, text: "手机管家", type: "primary" },
//   { value: 5, text: "供应商助手", type: "success" },
];

var appType = [{
  value: 0,
  text: "小程序",
  type: "success"
}, {
  value: 1,
  text: "平台",
  type: "primary"
}, {
  value: 2,
  text: "三方",
  type: "danger"
}];
var productUnitType = [{
  value: 0,
  text: "年",
  type: "success"
}, {
  value: 1,
  text: "点",
  type: "primary"
}, {
  value: 2,
  text: "条",
  type: "danger"
}, {
  value: 3,
  text: "终身",
  type: "warning"
}];
var accessType = [{
  value: 0,
  text: "所有",
  type: "success"
}, {
  value: 1,
  text: "指定代理商",
  type: "danger"
}, {
  value: 2,
  text: "指定商户",
  type: "danger"
}];
var smsTemplateType = [{
  value: 1,
  text: "会员消费提醒",
  type: "success"
}, {
  value: 2,
  text: "会员充值提醒",
  type: "primary"
}, {
  value: 3,
  text: "会员注册提醒",
  type: "danger"
}];
var _default = {
  warnLevelMap: warnLevelMap,
  packageType: packageType,
  merchantOrderType: merchantOrderType,
  merchantOrderSta: merchantOrderSta,
  categoryMap: categoryMap,
  SettleStatus: SettleStatus,
  posTypeMap: posTypeMap,
  blanceTypeMap: blanceTypeMap,
  payAwayTypeMap: payAwayTypeMap,
  settlementAwayTypeMap: settlementAwayTypeMap,
  saleWayMap: saleWayMap,
  quotaTypeMap: quotaTypeMap,
  tradeTypeMap: tradeTypeMap,
  priceOptionMap: priceOptionMap,
  combineStaMap: combineStaMap,
  measureFlagMap: measureFlagMap,
  unitnoMap: unitnoMap,
  ItemStatusMap: ItemStatusMap,
  rentalTypeMap: rentalTypeMap,
  shopTypeMap: shopTypeMap,
  approveStateMap: approveStateMap,
  paymentCycleMap: paymentCycleMap,
  payStateMap: payStateMap,
  SellWay: SellWay,
  ApproveFlagMap: ApproveFlagMap,
  RenewFlagMap: RenewFlagMap,
  StatusFlagMap: StatusFlagMap,
  BranchRegStatusType: BranchRegStatusType,
  JLpayStatusMap: JLpayStatusMap,
  PayRequestType: PayRequestType,
  TagMarketingType: TagMarketingType,
  MiniMenuGroup: MiniMenuGroup,
  OperType: OperType,
  StopMap: StopMap,
  Services: Services,
  PcStatusMap: PcStatusMap,
  ReceiveStatusMap: ReceiveStatusMap,
  shopType: shopType,
  AssignmentStatusMap: AssignmentStatusMap,
  DistributionStatusMap: DistributionStatusMap,
  ReceiptApproveFlagMap: ReceiptApproveFlagMap,
  ProgressStatusMap: ProgressStatusMap,
  payType: payType,
  payItem: payItem,
  accOrderStatus: accOrderStatus,
  couponType: couponType,
  couponUseStatus: couponUseStatus,
  wmjOnline: wmjOnline,
  oplockAction: oplockAction,
  PaymentType: PaymentType,
  TypeSale: TypeSale,
  agentType: agentType,
  orderTypeMap: orderTypeMap,
  orderStatusMap: orderStatusMap,
  deliveryMethodMap: deliveryMethodMap,
  verificationCodeType: verificationCodeType,
  ReceivStatusMap: ReceivStatusMap,
  ReturnReasonMap: ReturnReasonMap,
  productType: productType,
  appType: appType,
  accessType: accessType,
  productUnitType: productUnitType,
  smsTemplateType: smsTemplateType
};
exports.default = _default;