"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var _api = _interopRequireDefault(require("../api"));
//导入本功能的api接口

//表单默认值
var defaultForm = {
  discount: 0
};
var _default = {
  name: "merchantOrderCreate",
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    permission: _index.default
  },
  watch: {
    isShow: function isShow(newVal) {
      this.dialogFormVisible = newVal; //对父组件传过来的值进行监听，如果改变也对子组件内部的值进行改变
      this.initData(); //在显示的时候处理数据才能生效,因为显示的值在每次打开时肯定是变动的，
    },
    dialogFormVisible: function dialogFormVisible(newVal) {
      if (!newVal) {
        this.closeCreate(); //内部不关闭自己页面，会造成循环，让外部父组件来关闭
      }
    }
  },
  data: function data() {
    return {
      //控制弹窗显示
      dialogFormVisible: false,
      //弹窗加载框
      formLoading: false,
      //表单提交校验规则
      rules: {},
      //表单定义 并赋默认值
      form: Object.assign({}, defaultForm),
      isEdit: false,
      //修改时传入的id
      id: "",
      dialogVisibleHj: false,
      payAmount: 0,
      merchantOrderTypeMap: _enum.default.merchantOrderType,
      merchantOrderStatusMap: _enum.default.merchantOrderSta
    };
  },
  created: function created() {
    this.otherPageOpen = this.$route.query.otherPageOpen;
    this.isEdit = this.$route.query.isEdit;
    this.id = this.$route.query.id;
    this.initData();
  },
  methods: {
    saveMoney: function saveMoney() {
      if (this.payAmount == 0) {
        this.$notify({
          message: "金额不能为0",
          type: "info",
          duration: 2000
        });
        return;
      }
      this.form.payDiscount = this.payAmount;
      this.dialogVisibleHj = false;
    },
    gj: function gj() {
      this.payAmount = this.form.payDiscount;
      this.dialogVisibleHj = true;
    },
    initData: function initData() {
      this.form = Object.assign({}, defaultForm);
      //如果是编辑状态，远程加载信息
      if (this.isEdit) {
        if (this.id != 0 && this.id != undefined) {
          this.fetchData(this.id);
        }
      }
    },
    /** 加载数据 */fetchData: function fetchData(id) {
      var _this = this;
      _api.default.get(id).then(function (response) {
        _this.form = response;
        var details = [];
        details.push(_this.form.details);
        _this.form.details = details;
        console.info('详情信息', response);
      });
    },
    goReturn: function goReturn(item) {
      if (item.orderLogType == 6) {
        this.$router.push({
          path: '/shoppingMallService/shoppingMallBusiness/OrderReturn',
          query: {
            otherPageOpen: true,
            isEdit: true,
            filter: this.form.orderNo,
            startDate: this.orderReturnTime,
            endDate: this.orderReturnTime
          }
        });
      }
    },
    /**保存 */save: function save() {
      var _this2 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this2.isEdit) {
            if (_this2.form.payDiscount - _this2.form.discount <= 0) {
              _this2.$notify({
                message: "优惠金额超出总金额",
                type: "info",
                duration: 2000
              });
              return;
            }
            _api.default.update(_this2.form).then(function (response) {
              _this2.$notify({
                title: "成功",
                message: "更新成功",
                type: "success",
                duration: 2000
              });
            }).catch(function () {});
          } else {}
        }
      });
    },
    /**取消按钮 */cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
      this.form = Object.assign({}, defaultForm);
    }
  }
};
exports.default = _default;