var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.dialogFormVisible,
        title: _vm.formTitle,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: function ($event) {
          return _vm.cancel()
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            size: "small",
            inline: true,
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "短信模板ID", prop: "tempNo" } },
            [
              _c("el-input", {
                attrs: {
                  maxlength: "4",
                  oninput: "value=value.replace(/[^\\d]/g,'')",
                  placeholder: "短信模板ID",
                  disabled: _vm.form.id,
                },
                model: {
                  value: _vm.form.tempNo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "tempNo", $$v)
                  },
                  expression: "form.tempNo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "短信类型", prop: "smsTemplateType" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "150px" },
                  attrs: { placeholder: "请选择短信类型" },
                  model: {
                    value: _vm.form.smsTemplateType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "smsTemplateType", $$v)
                    },
                    expression: "form.smsTemplateType",
                  },
                },
                _vm._l(_vm.smsTemplateType, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.text, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "短信主题", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "445px" },
                attrs: { maxlength: "50", placeholder: "请输入短信主题" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "短信内容", prop: "content" } },
            [
              _c(
                "el-row",
                [
                  _c("div", { staticClass: "variable" }, [
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addLabel("姓名")
                          },
                        },
                      },
                      [_vm._v("姓名")]
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addLabel("消费金额")
                          },
                        },
                      },
                      [_vm._v("消费金额")]
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addLabel("账户余额")
                          },
                        },
                      },
                      [_vm._v("账户余额")]
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addLabel("充值金额")
                          },
                        },
                      },
                      [_vm._v("充值金额")]
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addLabel("赠送金额")
                          },
                        },
                      },
                      [_vm._v("赠送金额")]
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addLabel("会员卡号")
                          },
                        },
                      },
                      [_vm._v("会员卡号")]
                    ),
                    _c(
                      "span",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.addLabel("操作时间")
                          },
                        },
                      },
                      [_vm._v("操作时间")]
                    ),
                  ]),
                  _c("el-input", {
                    ref: "content",
                    staticStyle: { width: "445px" },
                    attrs: {
                      type: "textarea",
                      autosize: { minRows: 4, maxRows: 8 },
                      maxlength: "200",
                      "show-word-limit": "",
                      placeholder: "请输入短信内容",
                    },
                    on: { blur: _vm.handleInputBlur },
                    model: {
                      value: _vm.form.content,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "content", $$v)
                      },
                      expression: "form.content",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-button",
                { attrs: { size: "small" }, on: { click: _vm.cancel } },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    loading: _vm.formLoading,
                    type: "primary",
                  },
                  on: { click: _vm.save },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }