"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("@/axios"));
var _smsTemplate_def = _interopRequireDefault(require("@/saas/table-def/smsTemplate_def"));
var tableDef = _smsTemplate_def.default.table;
function get(id) {
  return _axios.default.gets("/api/SettingService/smsTemplate/" + id);
}
function getAll(data) {
  return _axios.default.gets("/api/SettingService/smsTemplate", data);
}
function deleteById(id) {
  return _axios.default.deletes("/api/SettingService/smsTemplate/" + id);
}
function update(data) {
  return _axios.default.posts("/api/SettingService/smsTemplate", data);
}
function create(data) {
  return _axios.default.posts("/api/SettingService/smsTemplate", data);
}

// 供下拉组件传参
var listQuery = {
  Filter: "",
  SkipCount: 0,
  MaxResultCount: 10
};
var _default = {
  get: get,
  getAll: getAll,
  deleteById: deleteById,
  update: update,
  create: create,
  tableDef: tableDef,
  listQuery: listQuery
};
exports.default = _default;