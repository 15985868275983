"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("@/axios"));
var _register_def = _interopRequireDefault(require("@/saas/table-def/register_def.js"));
var tableDef = _register_def.default.table;
function get(id) {
  return _axios.default.gets("/api/saas/register/" + id);
}
function getAll(data) {
  return _axios.default.gets("/api/saas/register", data);
}
function deleteById(id) {
  return _axios.default.deletes("/api/saas/register/" + id);
}
function update(data) {
  return _axios.default.posts("/api/saas/register", data);
}
function create(data) {
  return _axios.default.posts("/api/saas/register", data);
}
function approveById(data) {
  return _axios.default.posts("/api/saas/register/Approve", data);
}
function select() {
  return _axios.default.gets("/api/saas/agent/select");
}
var _default = {
  get: get,
  getAll: getAll,
  deleteById: deleteById,
  update: update,
  create: create,
  approveById: approveById,
  tableDef: tableDef,
  select: select
};
exports.default = _default;