"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _api = _interopRequireDefault(require("./api"));
var _index2 = _interopRequireDefault(require("@/components/MyElColumn/index.vue"));
var _index3 = _interopRequireDefault(require("./create/index.vue"));
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var _api2 = _interopRequireDefault(require("@/views/saasService/agent/api.js"));
var _SaasInputDialogs = _interopRequireDefault(require("@/saas/components/SaasInputDialogs.vue"));
//导入本功能的api接口
//导入创建窗口
var _default = {
  name: 'merchant',
  components: {
    Pagination: _Pagination.default,
    MyCreate: _index3.default,
    MyElColumn: _index2.default,
    SaasInputDialog: _SaasInputDialogs.default
  },
  directives: {
    permission: _index.default
  },
  data: function data() {
    return {
      //是否代理商登录
      isAgent: false,
      list: [],
      totalCount: 0,
      listLoading: true,
      listQuery: _api.default.listQuery,
      num: null,
      page: 1,
      multipleSelection: [],
      formTitle: '',
      isEdit: false,
      editId: '',
      tableDef: _api.default.tableDef,
      dialogFormVisible: false,
      agentApi: _api2.default,
      agentListQuery: {
        filter: "",
        SkipCount: 0,
        MaxResultCount: 10,
        approve: 1
      }
    };
  },
  created: function created() {
    this.isAgent = this.$store.state.user.userEx.isAgent;
    this.getList();
  },
  methods: {
    /** 获取分页列表 */getList: function getList() {
      var _this = this;
      this.listLoading = true;
      this.listQuery.SkipCount = (this.page - 1) * this.listQuery.MaxResultCount;
      this.list = [];
      _api.default.getAll(this.listQuery).then(function (response) {
        _this.list = response.items;
        _this.totalCount = response.totalCount;
        _this.listLoading = false;
      });
    },
    /**  排序 */sortChange: function sortChange(data) {
      var prop = data.prop,
        order = data.order;
      if (!prop || !order) {
        this.Filter();
        return;
      }
      this.listQuery.Sorting = prop + ' ' + order;
      this.Filter();
    },
    /** 搜索事件 */Search: function Search() {
      this.page = 1;
      this.getList();
    },
    Create: function Create() {
      this.formTitle = '';
      this.isEdit = false;
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    /** 新增窗口关闭事件 */handleCloseCreate: function handleCloseCreate() {
      this.dialogFormVisible = false;
    },
    /** 新增成功刷新列表*/handleCreateCallback: function handleCreateCallback(val) {
      this.getList();
    },
    /** 删除 */Delete: function Delete(row) {
      var _this2 = this;
      if (row) {
        if (row.approve == 1) {
          this.$message({
            message: '已审核状态不允许删除',
            type: 'warning'
          });
          return;
        }
        this.$confirm('是否删除' + row.companyName + '?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _api.default.deleteById(row.id).then(function (response) {
            _this2.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            });
            _this2.getList();
          });
        }).catch(function () {
          _this2.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    Update: function Update(row) {
      this.formTitle = '查看';
      if (row.approve == 0) {
        this.formTitle = '审核';
      }
      this.isEdit = true;
      if (row) {
        this.editId = row.id;
        this.dialogFormVisible = true;
      } else {
        if (this.multipleSelection.length != 1) {
          this.$message({
            message: '编辑必须选择单行',
            type: 'warning'
          });
          return;
        } else {
          this.editId = this.multipleSelection[0].id;
          this.dialogFormVisible = true;
        }
      }
    },
    /** 多选事件 */handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /** 行点击事件 */handleRowClick: function handleRowClick(row, column, event) {
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    /** 审核 */Approve: function Approve(row) {
      var _this3 = this;
      if (row && row.id) {
        if (row.approve == 1) {
          this.$message({
            message: '当前数据状态已审核，不可重复审核！',
            type: 'warning'
          });
          return;
        }
        this.$confirm('是否审核' + row.merchantName + '?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _api.default.approveById({
            id: row.id
          }).then(function (response) {
            _this3.$notify({
              title: '成功',
              message: '审核成功',
              type: 'success',
              duration: 2000
            });
            _this3.getList();
          });
        }).catch(function () {
          _this3.$message({
            type: 'info',
            message: '已取消'
          });
        });
      }
    },
    selectAgentCallback: function selectAgentCallback() {}
  }
};
exports.default = _default;