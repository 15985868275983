var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "reg-form",
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.dialogFormVisible,
        title: _vm.formTitle,
        width: "450px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: function ($event) {
          return _vm.cancel()
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            inline: true,
            model: _vm.form,
            rules: _vm.rules,
            size: "small",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { prop: "agent", label: "代理商：" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "255px" },
                  attrs: { clearable: "", placeholder: "请选择" },
                  model: {
                    value: _vm.form.agent,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "agent", $$v)
                    },
                    expression: "form.agent",
                  },
                },
                _vm._l(_vm.agent, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.companyName, value: item.id },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "shopName", label: "商户名称：" } },
            [
              _c("el-input", {
                staticStyle: { width: "255px" },
                attrs: { "auto-complete": "off" },
                model: {
                  value: _vm.form.shopName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "shopName", $$v)
                  },
                  expression: "form.shopName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "shopType", label: "店铺类型：" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "255px" },
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.form.shopType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "shopType", $$v)
                    },
                    expression: "form.shopType",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.text, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "contacts", label: "联系人：" } },
            [
              _c("el-input", {
                staticStyle: { width: "255px" },
                attrs: {
                  "auto-complete": "off",
                  "prefix-icon": "el-icon-user",
                },
                model: {
                  value: _vm.form.contacts,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "contacts", $$v)
                  },
                  expression: "form.contacts",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "phone", label: "手机号码：" } },
            [
              _c("el-input", {
                staticStyle: { width: "255px" },
                attrs: {
                  "auto-complete": "off",
                  "prefix-icon": "el-icon-mobile-phone",
                },
                model: {
                  value: _vm.form.phone,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "phone", $$v)
                  },
                  expression: "form.phone",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "email", label: "邮箱：" } },
            [
              _c("el-input", {
                staticStyle: { width: "255px" },
                attrs: { "auto-complete": "off" },
                model: {
                  value: _vm.form.email,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "email", $$v)
                  },
                  expression: "form.email",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "areas", label: "所在区域：" } },
            [
              _c("Area", {
                attrs: { width: "255px" },
                model: {
                  value: _vm.form.areas,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "areas", $$v)
                  },
                  expression: "form.areas",
                },
              }),
            ],
            1
          ),
          _vm.isLoginShow
            ? _c(
                "el-form-item",
                { attrs: { prop: "loginNum", label: "点数：" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "255px" },
                    attrs: { "auto-complete": "off" },
                    model: {
                      value: _vm.form.loginNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "loginNum", $$v)
                      },
                      expression: "form.loginNum",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isLoginShow
            ? _c(
                "el-form-item",
                { attrs: { prop: "branchNum", label: "门店数：" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "255px" },
                    attrs: { "auto-complete": "off" },
                    model: {
                      value: _vm.form.branchNum,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "branchNum", $$v)
                      },
                      expression: "form.branchNum",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isLoginShow
            ? _c(
                "el-form-item",
                { attrs: { prop: "stopDate", label: "到期时间：" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "255px" },
                    attrs: { type: "datetime", placeholder: "选择日期时间" },
                    model: {
                      value: _vm.form.stopDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "stopDate", $$v)
                      },
                      expression: "form.stopDate",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { prop: "address", label: "地址：" } },
            [
              _c("el-input", {
                staticStyle: { width: "255px" },
                attrs: { "auto-complete": "off" },
                model: {
                  value: _vm.form.address,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "address", $$v)
                  },
                  expression: "form.address",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "memo", label: "备注：" } },
            [
              _c("el-input", {
                staticStyle: { width: "255px" },
                attrs: { "auto-complete": "off" },
                model: {
                  value: _vm.form.memo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "memo", $$v)
                  },
                  expression: "form.memo",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "text" },
              on: { click: _vm.cancel },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "small",
                loading: _vm.formLoading,
                type: "primary",
              },
              on: { click: _vm.handleRegister },
            },
            [_vm._v("立即注册 ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }