"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  props: {
    tableDef: {
      type: Array,
      required: true,
      default: function _default() {
        return [];
      }
    },
    Update: Function,
    GetIcon: Function,
    Jump: Function,
    JumpTc: Function,
    HandleChangeType: Function,
    JumpOther: Function,
    CancelChange: Function,
    ShowCheck: {
      type: Boolean,
      default: true
    },
    ShowIndex: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      tableDef2: []
    };
  },
  watch: {
    tableDef: function tableDef(val) {
      this.tableDef2 = val;
      // console.log("tableDef watch",val);
    }
  },
  mounted: function mounted() {
    // console.log("进入MyElColumn");
    //   console.log("tableDef",this.tableDef);
    this.tableDef2 = this.tableDef;
  }
};
exports.default = _default2;