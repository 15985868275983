"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _Sticky = _interopRequireDefault(require("@/components/Sticky"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  directives: {
    permission: _index.default
  },
  components: {
    Sticky: _Sticky.default
  },
  props: {
    loading: Boolean,
    BtnType: String,
    Query: Function,
    Create: Function,
    Update: Function,
    Delete: Function,
    Print: Function,
    Export: Function,
    Setting: Function,
    Save: Function,
    Approve: Function,
    SelectItem: Function,
    Access: Function,
    CreatePermission: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    UpdatePermission: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    DeletePermission: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    PrintPermission: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    ExportPermission: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    SettingPermission: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    SavePermission: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    ApprovePermission: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    },
    canApprove: Boolean,
    hiddenCreate: Boolean,
    hiddenApprove: {
      type: [Boolean],
      required: false,
      default: function _default() {
        return false;
      }
    },
    hiddenSave: {
      type: [Boolean],
      required: false,
      default: function _default() {
        return false;
      }
    },
    canDelete: {
      type: [Boolean],
      required: false,
      default: function _default() {
        return true;
      }
    },
    canSelect: {
      type: [Boolean],
      required: false,
      default: function _default() {
        return true;
      }
    },
    canSave: {
      type: [Boolean],
      required: false,
      default: function _default() {
        return true;
      }
    },
    hasRet: {
      type: [Boolean, String],
      required: false,
      default: function _default() {
        return false;
      }
    },
    AccessPermission: {
      type: Array,
      required: false,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      isQuery: false,
      isCreate: false,
      isEidt: false,
      isDelete: false,
      isSave: false,
      isApprove: false,
      isSelect: false,
      isPrint: false,
      isExport: false,
      isSetting: false,
      isAccess: false
    };
  },
  watch: {},
  mounted: function mounted() {
    console.log(this.BtnType);
    switch (this.BtnType) {
      case "list":
        this.isQuery = true;
        this.isCreate = true;
        this.isEidt = true;
        this.isDelete = true;
        this.isSave = false;
        this.isApprove = false;
        this.isSelect = false;
        this.isPrint = true;
        this.isExport = true;
        this.isSetting = true;
        this.isAccess = false;
        break;
      case "supcust_contract":
        this.isQuery = true;
        this.isCreate = true;
        this.isEidt = true;
        this.isDelete = true;
        this.isSave = false;
        this.isApprove = true;
        this.isSelect = false;
        this.isPrint = true;
        this.isExport = true;
        this.isSetting = true;
        this.isAccess = false;
        break;
      case "create":
        this.isQuery = false;
        this.isCreate = false;
        this.isEidt = false;
        this.isDelete = false;
        this.isSave = true;
        this.isApprove = false;
        this.isSelect = false;
        this.isPrint = false;
        this.isExport = false;
        this.isSetting = false;
        this.isAccess = false;
        break;
      case "report":
        this.isQuery = true;
        this.isCreate = false;
        this.isEidt = false;
        this.isDelete = false;
        this.isSave = false;
        this.isApprove = false;
        this.isSelect = false;
        this.isPrint = true;
        this.isExport = true;
        this.isSetting = false;
        this.isAccess = false;
        break;
      case "sheet":
        this.isQuery = true;
        this.isCreate = true;
        this.isEidt = true;
        this.isDelete = true;
        this.isSave = false;
        this.isApprove = false;
        this.isSelect = false;
        this.isPrint = true;
        this.isExport = true;
        this.isSetting = true;
        this.isAccess = false;
        break;
      case "sheetCreate":
        this.isQuery = false;
        this.isCreate = false;
        this.isEidt = false;
        this.isDelete = false;
        this.isSave = true;
        this.isApprove = true;
        this.isSelect = true;
        this.isPrint = true;
        this.isExport = true;
        this.isSetting = true;
        this.isAccess = false;
        break;
      case "sheetSelect":
        this.isQuery = false;
        this.isCreate = false;
        this.isEidt = false;
        this.isDelete = false;
        this.isSave = true;
        this.isApprove = true;
        this.isSelect = false;
        this.isPrint = true;
        this.isExport = true;
        this.isSetting = true;
        this.isAccess = false;
        break;
      case "listApprove":
        this.isQuery = true;
        this.isCreate = true;
        this.isEidt = true;
        this.isDelete = true;
        this.isSave = false;
        this.isApprove = true;
        this.isSelect = false;
        this.isPrint = true;
        this.isExport = true;
        this.isSetting = true;
        this.isAccess = false;
        break;
      case "purchasingSummary":
        this.isQuery = true;
        this.isCreate = false;
        this.isEidt = false;
        this.isDelete = false;
        this.isSave = false;
        this.isApprove = false;
        this.isSelect = false;
        this.isPrint = true;
        this.isExport = true;
        this.isSetting = true;
        this.isAccess = false;
        break;
      case "purchasing":
        this.isQuery = true;
        this.isCreate = true;
        this.isEidt = false;
        this.isDelete = false;
        this.isSave = false;
        this.isApprove = false;
        this.isSelect = false;
        this.isPrint = true;
        this.isExport = true;
        this.isSetting = true;
        this.isAccess = false;
        break;
      case "purchasingCreate":
        this.isQuery = false;
        this.isCreate = false;
        this.isEidt = false;
        this.isDelete = false;
        this.isSave = true;
        this.isApprove = true;
        this.isSelect = false;
        this.isPrint = true;
        this.isExport = true;
        this.isSetting = true;
        this.isAccess = false;
        break;
      case "return":
        this.isQuery = true;
        this.isCreate = false;
        this.isEidt = false;
        this.isDelete = false;
        this.isSave = false;
        this.isApprove = false;
        this.isSelect = false;
        this.isPrint = true;
        this.isExport = true;
        this.isSetting = false;
        this.isAccess = false;
        break;
      case "access":
        this.isQuery = false;
        this.isCreate = false;
        this.isEidt = false;
        this.isDelete = false;
        this.isSave = false;
        this.isApprove = false;
        this.isSelect = false;
        this.isPrint = false;
        this.isExport = false;
        this.isSetting = false;
        this.isAccess = true;
        break;
      default:
        this.isQuery = true;
        this.isCreate = false;
        this.isEidt = false;
        this.isDelete = false;
        this.isSave = false;
        this.isApprove = false;
        this.isSelect = false;
        this.isPrint = false;
        this.isExport = false;
        this.isSetting = false;
        this.isAccess = false;
        break;
    }
  },
  methods: {
    /**
     * 返回上级页面
     */
    retLastPage: function retLastPage() {
      // 调用全局挂载的方法,关闭当前标签页
      this.$store.dispatch("tagsView/delView", this.$route);
      // 返回上一步路由，返回上一个标签页
      this.$router.go(-1);
    }
  }
};
exports.default = _default2;