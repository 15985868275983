"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: [
  //是否显示窗口
  "isShow",
  //窗口关闭事件回调
  "closeCreate",
  //保存成功后回调
  "callback"],
  watch: {
    isShow: function isShow(newVal) {
      this.dialogFormVisible = newVal; //对父组件传过来的值进行监听，如果改变也对子组件内部的值进行改变
    },
    dialogFormVisible: function dialogFormVisible(newVal) {
      if (!newVal) {
        this.closeCreate(); //内部不关闭自己页面，会造成循环，让外部父组件来关闭
      }
    }
  },
  data: function data() {
    return {
      //控制弹窗显示
      dialogFormVisible: false
    };
  },
  methods: {
    /**取消按钮 */cancel: function cancel() {
      this.dialogFormVisible = false;
    }
  }
};
exports.default = _default;