var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.dialogFormVisible,
        title: _vm.formTitle,
        width: "580px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: function ($event) {
          return _vm.cancel()
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "form",
          attrs: {
            inline: true,
            model: _vm.form,
            rules: _vm.rules,
            size: "small",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "应用名称", prop: "appName" } },
            [
              _c("el-input", {
                staticStyle: { width: "190px" },
                attrs: { maxlength: "20", placeholder: "请输入应用名称" },
                model: {
                  value: _vm.form.appName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "appName", $$v)
                  },
                  expression: "form.appName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "图标" } },
            [
              _c("el-input", {
                staticStyle: { width: "100px" },
                attrs: { maxlength: "20", placeholder: "请输入图标" },
                model: {
                  value: _vm.form.appIco,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "appIco", $$v)
                  },
                  expression: "form.appIco",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "应用简介" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  type: "textarea",
                  rows: 3,
                  maxlength: 200,
                  "show-word-limit": true,
                },
                model: {
                  value: _vm.form.appInfo,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "appInfo", $$v)
                  },
                  expression: "form.appInfo",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "零售价", prop: "price" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "145px" },
                attrs: { precision: 2, step: 0.01, min: 0.01, controls: false },
                model: {
                  value: _vm.form.price,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "price", $$v)
                  },
                  expression: "form.price",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "代理商价", prop: "agentPrice" } },
            [
              _c("el-input-number", {
                staticStyle: { width: "145px" },
                attrs: { precision: 2, step: 0.01, min: 0.01, controls: false },
                model: {
                  value: _vm.form.agentPrice,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "agentPrice", $$v)
                  },
                  expression: "form.agentPrice",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "单位", prop: "unit" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择单位" },
                  model: {
                    value: _vm.form.unit,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "unit", $$v)
                    },
                    expression: "form.unit",
                  },
                },
                _vm._l(_vm.productUnitType, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.text, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "规格", prop: "specs" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  maxlength: "100",
                  placeholder: "请输入规格（默认填1，多个规格用“|”隔开）",
                },
                model: {
                  value: _vm.form.specs,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "specs", $$v)
                  },
                  expression: "form.specs",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { prop: "appType", label: "应用类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择应用类型" },
                      model: {
                        value: _vm.form.appType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "appType", $$v)
                        },
                        expression: "form.appType",
                      },
                    },
                    _vm._l(_vm.appTypes, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.text, value: item.value },
                      })
                    }),
                    1
                  ),
                  _vm.form.appType == 0
                    ? _c(
                        "el-select",
                        {
                          staticStyle: { "margin-left": "2px" },
                          attrs: { placeholder: "请选择平台应用" },
                          model: {
                            value: _vm.form.appletId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "appletId", $$v)
                            },
                            expression: "form.appletId",
                          },
                        },
                        _vm._l(_vm.applet, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "页面路由" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: { maxlength: "100", placeholder: "请输入页面路由" },
                model: {
                  value: _vm.form.pageLink,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "pageLink", $$v)
                  },
                  expression: "form.pageLink",
                },
              }),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { prop: "accessType", label: "开放范围" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      on: { change: _vm.changeAccess },
                      model: {
                        value: _vm.form.accessType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "accessType", $$v)
                        },
                        expression: "form.accessType",
                      },
                    },
                    _vm._l(_vm.accessTypes, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: { label: item.text, value: item.value },
                      })
                    }),
                    1
                  ),
                  _c("SaasInputDialog", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.accessType == 1,
                        expression: "form.accessType == 1",
                      },
                    ],
                    staticStyle: {
                      "margin-left": "2px",
                      display: "inline-block",
                    },
                    attrs: {
                      api: _vm.agentApi,
                      title: "代理商",
                      hasTree: false,
                      displayText: "companyName",
                      displayId: "agentCode",
                      callback: _vm.accessSubCallback,
                      listQuery: _vm.agentListQuery,
                      selectId: _vm.form.accessSub,
                    },
                    model: {
                      value: _vm.form.accessSub,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "accessSub", $$v)
                      },
                      expression: "form.accessSub",
                    },
                  }),
                  _c("SaasInputDialog", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.form.accessType == 2,
                        expression: "form.accessType == 2",
                      },
                    ],
                    staticStyle: {
                      "margin-left": "2px",
                      display: "inline-block",
                    },
                    attrs: {
                      api: _vm.merchantApi,
                      title: "商户",
                      hasTree: false,
                      displayText: "shopName",
                      displayId: "phone",
                      callback: _vm.accessSubCallback,
                      listQuery: _vm.merchantListQuery,
                      selectId: _vm.form.accessSub,
                    },
                    model: {
                      value: _vm.form.accessSubMerchant,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "accessSubMerchant", $$v)
                      },
                      expression: "form.accessSubMerchant",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "开发者" } },
            [
              _c("SaasInputDialog", {
                staticStyle: { width: "400px" },
                attrs: {
                  api: _vm.developerApi,
                  title: "开发者",
                  hasTree: false,
                  displayText: "companyName",
                  displayId: "name",
                  callback: _vm.developerCallback,
                  listQuery: _vm.devaloperListQuery,
                  hasRows: false,
                },
                model: {
                  value: _vm.form.developerId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "developerId", $$v)
                  },
                  expression: "form.developerId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "应用id", prop: "clientId" } },
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                attrs: {
                  maxlength: "20",
                  placeholder: "请输入应用id",
                  disabled: true,
                },
                model: {
                  value: _vm.form.clientId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "clientId", $$v)
                  },
                  expression: "form.clientId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "应用秘钥", prop: "clientSecret" } },
            [
              _c(
                "el-input",
                {
                  staticStyle: { width: "400px" },
                  attrs: {
                    maxlength: "20",
                    placeholder: "请输入应用秘钥",
                    disabled: true,
                  },
                  model: {
                    value: _vm.form.clientSecret,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "clientSecret", $$v)
                    },
                    expression: "form.clientSecret",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { slot: "append" },
                      on: { click: _vm.getSecret },
                      slot: "append",
                    },
                    [_vm._v("生成")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否启用" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                },
                model: {
                  value: _vm.form.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "status", $$v)
                  },
                  expression: "form.status",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.cancel },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    loading: _vm.formLoading,
                    type: "primary",
                  },
                  on: { click: _vm.save },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }