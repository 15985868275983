"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var table = [{
  Display: true,
  DisplayType: "text",
  Label: "标题",
  Name: "title",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "版本",
  Name: "versions",
  Sort: false,
  Align: "center",
  Width: "",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "环境",
  Name: "environment",
  Sort: false,
  Align: "center",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "tagenum",
  DisplayTypeEnum: _enum.default.packageType,
  Label: "安装包类型",
  Name: "packageType",
  Sort: false,
  Align: "center",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "tagenum",
  DisplayTypeEnum: _enum.default.posTypeMap,
  Label: "包类型",
  Name: "posType",
  Sort: false,
  Align: "center",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "datetime",
  Label: "创建时间",
  Name: "createTime",
  Sort: false,
  Align: "center",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "datetime",
  Label: "生效时间",
  Name: "updateDate",
  Sort: false,
  Align: "center",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "bool",
  Label: "是否启用",
  Name: "status",
  Sort: false,
  Align: "center",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}];
var _default = {
  table: table
};
exports.default = _default;