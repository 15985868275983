"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("@/axios"));
var _merchant_report_def = _interopRequireDefault(require("@/saas/table-def/merchant_report_def"));
var _dateRegion = _interopRequireDefault(require("@/saas/date-region.js"));
var tableDef = _merchant_report_def.default.table;
//日期范围快捷选项
var pickerOptions = _dateRegion.default.pickerOptionsPm;
var listQuery = {
  orderType: 0,
  orderStatus: null,
  Filter: "",
  SkipCount: 0,
  MaxResultCount: 10,
  DateRegion: [_dateRegion.default.defaultDate.yesterday,
  //前一天,
  _dateRegion.default.defaultDate.todayEnd],
  endTime: new Date(),
  beginTime: new Date(),
  export: 'merchantReport'
};
function get(id) {
  return _axios.default.gets("/api/SettingService/merchantOrders/" + id);
}
function getAll(data) {
  return _axios.default.gets("/api/SettingService/merchantReport/getAll", data);
}
function deleteById(id) {
  return _axios.default.deletes("/api/SettingService/merchantOrders/" + id);
}
function update(data) {
  return _axios.default.posts("/api/SettingService/merchantOrders", data);
}
function create(data) {
  return _axios.default.posts("/api/SettingService/merchantOrders", data);
}
var _default = {
  get: get,
  getAll: getAll,
  deleteById: deleteById,
  update: update,
  create: create,
  tableDef: tableDef,
  pickerOptions: pickerOptions,
  listQuery: listQuery
};
exports.default = _default;