"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("@/axios"));
var _application_def = _interopRequireDefault(require("@/saas/table-def/application_def"));
var tableDef = _application_def.default.table;
function get(id) {
  return _axios.default.gets("/api/SettingService/saasApp/" + id);
}
function getAll(data) {
  return _axios.default.gets("/api/SettingService/saasApp", data);
}
function getByIds(ids) {
  return _axios.default.gets("/api/SettingService/saasApp/byIds?ids=" + ids);
}
function deleteById(id) {
  return _axios.default.deletes("/api/SettingService/saasApp/" + id);
}
function approve(data) {
  return _axios.default.gets("/api/SettingService/saasApp/approve", data);
}
function update(data) {
  return _axios.default.posts("/api/SettingService/saasApp", data);
}
function create(data) {
  return _axios.default.posts("/api/SettingService/saasApp", data);
}
function getApplet(data) {
  return _axios.default.gets("/api/BDService/appLet", data);
}

// 供下拉组件传参
var listQuery = {
  Filter: "",
  //编码、名称
  SkipCount: 0,
  MaxResultCount: 10
};
var agentListQuery = {
  Filter: "",
  SkipCount: 0,
  MaxResultCount: 10,
  approve: 1
};
var merchantListQuery = {
  Filter: "",
  SkipCount: 0,
  MaxResultCount: 10,
  approve: 1,
  disable: -1,
  stopDate: -1
};
var devaloperListQuery = {
  Filter: "",
  skipCount: 0,
  maxResultCount: 10,
  status: true
};
var _default = {
  get: get,
  getAll: getAll,
  getByIds: getByIds,
  deleteById: deleteById,
  update: update,
  create: create,
  getApplet: getApplet,
  tableDef: tableDef,
  listQuery: listQuery,
  agentListQuery: agentListQuery,
  merchantListQuery: merchantListQuery,
  devaloperListQuery: devaloperListQuery,
  approve: approve
};
exports.default = _default;