"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var _ReportRouterlayout = _interopRequireDefault(require("@/layout/ReportRouterlayout.vue"));
var DataService = {
  path: '/agentService',
  component: _layout.default,
  redirect: 'noRedirect',
  alwaysShow: true,
  name: 'agentService',
  type: 'home',
  meta: {
    title: 'agent',
    icon: 'saas-supcust',
    haveDetails: false
  },
  children: [{
    path: 'agent',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/saasService/agent/index.vue'));
      });
    },
    name: 'agent',
    meta: {
      title: 'agent',
      roles: ['Saas.Agent.Qlist'],
      icon: 'saas-supcust',
      keepAlive: true
    }
  }, {
    path: 'agentReport',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/saasService/agentReport/index.vue'));
      });
    },
    name: 'agentReport',
    meta: {
      title: 'agentReport',
      roles: ['Saas.BusinessAgentReport.Qlist'],
      icon: 'saas-supcust',
      keepAlive: true
    }
  }]
};
var _default = DataService;
exports.default = _default;