var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.dialogFormVisible,
        title: _vm.formTitle,
        width: "520px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: function ($event) {
          return _vm.cancel()
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "form",
          attrs: {
            model: _vm.form,
            rules: _vm.rules,
            size: "small",
            "label-width": "90px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "名称", prop: "name" } },
            [
              _c("el-input", {
                attrs: { maxlength: "20", placeholder: "请输入名称" },
                model: {
                  value: _vm.form.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "name", $$v)
                  },
                  expression: "form.name",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "AppId", prop: "appId" } },
            [
              _c("el-input", {
                attrs: { maxlength: "20", placeholder: "请输入AppId" },
                model: {
                  value: _vm.form.appId,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "appId", $$v)
                  },
                  expression: "form.appId",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "AppSecret", prop: "appSecret" } },
            [
              _c("el-input", {
                attrs: { maxlength: "500", placeholder: "请输入AppSecret" },
                model: {
                  value: _vm.form.appSecret,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "appSecret", $$v)
                  },
                  expression: "form.appSecret",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "二维码", prop: "qrcodeUrl" } },
            [
              _c("el-input", {
                attrs: { maxlength: "100", placeholder: "请输入二维码地址" },
                model: {
                  value: _vm.form.qrcodeUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "qrcodeUrl", $$v)
                  },
                  expression: "form.qrcodeUrl",
                },
              }),
            ],
            1
          ),
          !_vm.isAgent
            ? _c(
                "el-form-item",
                { attrs: { label: "代理商" } },
                [
                  _c("SaasInputDialog", {
                    staticStyle: { width: "210px" },
                    attrs: {
                      api: _vm.agentApi,
                      title: "选择代理商",
                      hasTree: false,
                      displayText: "companyName",
                      displayId: "agentCode",
                      callback: _vm.selectAgentCallback,
                      listQuery: _vm.agentListQuery,
                      hasRows: false,
                    },
                    model: {
                      value: _vm.form.agent,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "agent", $$v)
                      },
                      expression: "form.agent",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c(
            "el-form-item",
            { attrs: { label: "是否默认" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                },
                model: {
                  value: _vm.form.isDefault,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "isDefault", $$v)
                  },
                  expression: "form.isDefault",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                },
                model: {
                  value: _vm.form.status,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "status", $$v)
                  },
                  expression: "form.status",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { disabled: _vm.disabled },
                      model: {
                        value: _vm.checked,
                        callback: function ($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked",
                      },
                    },
                    [_vm._v("保存后自动新增")]
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.cancel },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    loading: _vm.formLoading,
                    type: "primary",
                  },
                  on: { click: _vm.save },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }