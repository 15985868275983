"use strict";

var _interopRequireWildcard = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireWildcard.js");
var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/core-js/modules/es6.array.iterator.js");
require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/core-js/modules/es6.promise.js");
require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/core-js/modules/es6.object.assign.js");
require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _jsCookie = _interopRequireDefault(require("js-cookie"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./styles/element-variables.scss");
require("element-ui/lib/theme-chalk/index.css");
require("@/styles/index.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _lang = _interopRequireDefault(require("./lang"));
require("./icons");
require("./permission");
require("./utils/error-log");
var _axios = _interopRequireDefault(require("./axios"));
var _moment = _interopRequireDefault(require("moment"));
var filters = _interopRequireWildcard(require("./filters"));
var _tableHelper = _interopRequireDefault(require("./utils/table-helper"));
require("@/assets/js/jQuery.print");
// a modern alternative to CSS resets

// global css

// internationalization
// icon
// permission control
// error log

// global filters

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
//  if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _lang.default.t(key, value);
  }
});

// register global utility filters
Object.keys(filters).forEach(function (key) {
  _vue.default.filter(key, filters[key]);
});
_vue.default.filter('formatDate', function (value) {
  if (value) {
    return (0, _moment.default)(String(value)).format('YYYY-MM-DD');
  }
});
_vue.default.filter('formatDateTime', function (value) {
  if (value) {
    return (0, _moment.default)(String(value)).format('YYYY-MM-DD HH:mm:ss');
  }
});
Date.prototype.toISOString = function () {
  return (0, _moment.default)(this).format('YYYY-MM-DDTHH:mm:ss');
};
_vue.default.filter('numFiter', function (value) {
  value = Number(value);
  return value.toFixed(2);
});
_vue.default.filter('formatFloat', function (value) {
  if ((value + '').indexOf('.') > -1 && !isNaN(value)) {
    return Number(value).toFixed(2);
  } else {
    return value;
  }
});
_vue.default.prototype.$axios = _axios.default;
_vue.default.config.productionTip = true;
_vue.default.prototype.$tables = _tableHelper.default;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  i18n: _lang.default,
  render: function render(h) {
    return h(_App.default);
  }
});