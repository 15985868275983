"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _permission = _interopRequireDefault(require("./permission"));
var install = function install(Vue) {
  Vue.directive('permission', _permission.default);
};
if (window.Vue) {
  window['permission'] = _permission.default;
  Vue.use(install); // eslint-disable-line
}

_permission.default.install = install;
var _default = _permission.default;
exports.default = _default;