"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("@/axios"));
var _wxSmsTemplate_def = _interopRequireDefault(require("@/saas/table-def/wxSmsTemplate_def"));
var tableDef = _wxSmsTemplate_def.default.table;
function getAll() {
  return _axios.default.gets("api/SettingService/wxSmsTemplate");
}
function create(data) {
  return _axios.default.posts("/api/SettingService/wxSmsTemplate/Create", data);
}
var _default = {
  getAll: getAll,
  create: create,
  tableDef: tableDef
};
exports.default = _default;