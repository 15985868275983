"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _createForOfIteratorHelper2 = _interopRequireDefault(require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime-corejs2/helpers/createForOfIteratorHelper.js"));
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _validate = require("@/utils/validate");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Role",
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    permission: _index.default
  },
  filters: {
    displayStatus: function displayStatus(status) {
      var statusMap = {
        true: "是",
        false: "否"
      };
      return statusMap[status];
    }
  },
  data: function data() {
    return {
      rules: {
        name: [{
          required: true,
          message: "请输入角色名",
          trigger: "blur"
        }]
      },
      form: {},
      list: null,
      totalCount: 0,
      listLoading: true,
      formLoading: false,
      treeLoading: false,
      savePerLoading: false,
      listQuery: {
        Filter: "",
        Sorting: "",
        SkipCount: 0,
        MaxResultCount: 10
      },
      page: 1,
      dialogFormVisible: false,
      multipleSelection: [],
      formTitle: "",
      isEdit: false,
      permissionsData: [],
      checkedPermission: [],
      params: {
        providerName: "R",
        providerKey: ""
      },
      defaultProps: {
        children: "children",
        label: "name"
      }
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      this.listQuery.SkipCount = (this.page - 1) * this.listQuery.MaxResultCount;
      this.$axios.gets("/api/SettingService/role", this.listQuery).then(function (response) {
        _this.list = response.items;
        _this.totalCount = response.totalCount;
        _this.listLoading = false;
      });
    },
    fetchData: function fetchData(id) {
      var _this2 = this;
      this.$axios.gets("/api/SettingService/role/" + id).then(function (response) {
        _this2.form = response;
      });
    },
    handleFilter: function handleFilter() {
      this.page = 1;
      this.getList();
    },
    save: function save() {
      var _this3 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this3.formLoading = true;
          if (_this3.isEdit) {
            _this3.$axios.puts("/api/SettingService/role/" + _this3.form.id, _this3.form).then(function (response) {
              _this3.formLoading = false;
              _this3.$notify({
                title: "成功",
                message: "更新成功",
                type: "success",
                duration: 2000
              });
              _this3.dialogFormVisible = false;
              _this3.getList();
            }).catch(function () {
              _this3.formLoading = false;
            });
          } else {
            _this3.$axios.posts("/api/SettingService/role", _this3.form).then(function (response) {
              _this3.formLoading = false;
              _this3.$notify({
                title: "成功",
                message: "新增成功",
                type: "success",
                duration: 2000
              });
              _this3.dialogFormVisible = false;
              _this3.getList();
            }).catch(function () {
              _this3.formLoading = false;
            });
          }
        }
      });
    },
    savePer: function savePer() {
      var _this4 = this;
      this.savePerLoading = true;
      var params = {};
      var checkedKeys = this.$refs.tree.getCheckedKeys();
      params.permissions = [];
      this.permissionsData.forEach(function (element) {
        element.children.forEach(function (item) {
          var perm = {};
          perm.name = item.id;
          perm.isGranted = false;
          if (checkedKeys.indexOf(item.id) > -1) {
            perm.isGranted = true;
          }
          params.permissions.push(perm);
          item.children.forEach(function (i) {
            var perm = {};
            perm.name = i.id;
            perm.isGranted = false;
            if (checkedKeys.indexOf(i.id) > -1) {
              perm.isGranted = true;
            }
            params.permissions.push(perm);
          });
        });
      });
      this.$axios.puts("/api/SettingService/permission?providerName=R&providerKey=" + this.multipleSelection[0].name, params).then(function (response) {
        _this4.$notify({
          title: "成功",
          message: "更新成功",
          type: "success",
          duration: 2000
        });
        _this4.savePerLoading = false;
      });
    },
    handleCreate: function handleCreate() {
      this.formTitle = "新增角色";
      this.isEdit = false;
      this.form = {};
      this.dialogFormVisible = true;
    },
    handleDelete: function handleDelete(row) {
      var _this5 = this;
      if (row) {
        this.$confirm("是否删除" + row.name + "?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this5.$axios.deletes("/api/SettingService/role/" + row.id).then(function (response) {
            var index = _this5.list.indexOf(row);
            _this5.list.splice(index, 1);
            _this5.$notify({
              title: "成功",
              message: "删除成功",
              type: "success",
              duration: 2000
            });
          });
        }).catch(function () {
          _this5.$message({
            type: "info",
            message: "已取消删除"
          });
        });
      } else {
        this.$alert("暂时不支持角色批量删除", "提示", {
          confirmButtonText: "确定",
          callback: function callback(action) {
            //
          }
        });
      }
    },
    handleUpdate: function handleUpdate(row) {
      this.formTitle = "修改角色";
      this.isEdit = true;
      if (row) {
        this.fetchData(row.id);
        this.dialogFormVisible = true;
      } else {
        if (this.multipleSelection.length != 1) {
          this.$message({
            message: "编辑必须选择单行",
            type: "warning"
          });
          return;
        } else {
          this.fetchData(this.multipleSelection[0].id);
          this.dialogFormVisible = true;
        }
      }
    },
    sortChange: function sortChange(data) {
      var prop = data.prop,
        order = data.order;
      if (!prop || !order) {
        this.handleFilter();
        return;
      }
      this.listQuery.Sorting = prop + " " + order;
      this.handleFilter();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleRowClick: function handleRowClick(row, column, event) {
      var _this6 = this;
      if (this.multipleSelection.length == 1 && this.multipleSelection[0].id == row.id) {
        return;
      }
      this.treeLoading = true;
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row);
      this.params.providerKey = row.name;
      this.$axios.getPermissions("/api/SettingService/permission", this.params).then(function (response) {
        _this6.permissionsData = [];
        _this6.checkedPermission = [];
        var show_arr = ['SettingService', 'SettingManagement', 'AbpIdentity', 'AbpTenantManagement', 'Saas'];
        response.groups = response.groups.filter(function (e) {
          return show_arr.indexOf(e.name) > -1;
        });
        _this6.setTree(response.groups);
        _this6.treeLoading = false;
      });
    },
    setTree: function setTree(data) {
      var _this7 = this;
      data.forEach(function (element) {
        var root = {};
        root.id = element.name;
        root.name = element.displayName;
        //   root.disabled = true;
        root.children = [];
        element.permissions.forEach(function (item) {
          if (!item.parentName) {
            var children = {};
            children.id = item.name;
            children.name = item.displayName;
            children.parentId = item.parentName;
            children.children = [];
            root.children.push(children);
          } else {
            var child = {};
            child.id = item.name;
            child.name = item.displayName;
            child.parentId = item.parentName;
            var _iterator = (0, _createForOfIteratorHelper2.default)(root.children),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var v = _step.value;
                if (v.id === item.parentName) {
                  v.children.push(child);
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
          }
          if (item.isGranted) {
            _this7.checkedPermission.push(item.name);
          }
        });
        _this7.permissionsData.push(root);
      });
    },
    loadTree: function loadTree(data) {
      var _this8 = this;
      data.forEach(function (element) {
        var root = {};
        root.id = element.name;
        root.name = element.displayName;
        //  root.disabled = true;
        root.children = [];
        element.permissions.forEach(function (item) {
          if (!item.parentName) {
            var children = {};
            children.id = item.name;
            children.name = item.displayName;
            children.parentId = item.parentName;
            children.children = [];
            root.children.push(children);
          } else {
            var child = {};
            child.id = item.name;
            child.name = item.displayName;
            child.parentId = item.parentName;
            var _iterator2 = (0, _createForOfIteratorHelper2.default)(root.children),
              _step2;
            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var v = _step2.value;
                if (v.id === item.parentName) {
                  v.children.push(child);
                }
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }
        });
        _this8.permissionsData.push(root);
      });
    },
    checkNode: function checkNode(data, state) {
      // if (!data.parentId) {
      //   if (state.checkedKeys.indexOf(data.id) === -1) {
      //     data.children.forEach(element => {
      //       this.$refs.tree.setChecked(element.id, false);
      //     });
      //   }
      // } else {
      //   if (state.checkedKeys.indexOf(data.id) > -1) {
      //     this.$refs.tree.setChecked(data.parentId, true);
      //   }
      // }
    },
    loadPermissions: function loadPermissions() {
      var _this9 = this;
      this.$axios.getPermissions("/api/SettingService/permission", this.params).then(function (response) {
        _this9.permissionsData = [];
        _this9.checkedPermission = [];
        _this9.loadTree(response.groups);
      });
    },
    cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
    }
  }
};
exports.default = _default;