"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var _api = _interopRequireDefault(require("../api"));
var _Area = _interopRequireDefault(require("@/components/Area"));
var _SaasFormItemSelect = _interopRequireDefault(require("@/saas/components/SaasFormItemSelect.vue"));
var _qrcodejs = _interopRequireDefault(require("qrcodejs2"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//表单默认值
var defaultForm = {
  companyName: '',
  contacts: '',
  tel: '',
  salesManId: '',
  salesManName: '',
  agentType: 4,
  areaData: [],
  memo: '',
  stopDate: new Date(Date.now()),
  status: true,
  creationTime: new Date(Date.now()),
  approve: 0
};
var _default = {
  name: 'AgentCreate',
  components: {
    Area: _Area.default,
    PageSelectFormItem: _SaasFormItemSelect.default,
    QRCode: _qrcodejs.default
  },
  directives: {
    permission: _index.default
  },
  props: [
  //是否显示窗口
  'isShow',
  //窗口关闭事件回调
  'closeCreate',
  //保存成功后回调
  'callback',
  //是否是修改
  'isEdit',
  //修改时传入的id
  'id', 'formTitle'],
  watch: {
    isShow: function isShow(newVal) {
      this.dialogFormVisible = newVal; //对父组件传过来的值进行监听，如果改变也对子组件内部的值进行改变
      this.initData();
    },
    dialogFormVisible: function dialogFormVisible(newVal) {
      if (!newVal) {
        this.closeCreate(); //内部不关闭自己页面，会造成循环，让外部父组件来关闭
      }
    }
  },
  data: function data() {
    return {
      //控制弹窗显示
      dialogFormVisible: false,
      //弹窗加载框
      formLoading: false,
      isTop: true,
      //表单提交校验规则
      rules: {
        agentCode: [{
          required: true,
          message: '请输入公司编号',
          trigger: 'blur'
        }],
        companyName: [{
          required: true,
          message: '请输入公司名称',
          trigger: 'blur'
        }],
        contacts: [{
          required: true,
          message: '请输入联系人名称',
          trigger: 'blur'
        }],
        tel: [{
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }, {
          pattern: /^1[3456789]\d{9}$/,
          message: '请输入正确的手机号码',
          trigger: 'blur'
        }],
        agentType: [{
          required: true,
          message: '请选择代理类型',
          trigger: 'blur'
        }],
        areaData: [{
          required: true,
          message: '请选择代理商区域',
          trigger: 'blur'
        }],
        companyAddress: [{
          required: true,
          message: '请输入公司地址',
          trigger: 'blur'
        }],
        stopDate: [{
          required: true,
          message: '请选择到期时间',
          trigger: 'blur'
        }],
        salesManName: [{
          required: true,
          message: '请选择业务员',
          trigger: 'blur'
        }]
      },
      //表单定义 并赋默认值
      form: Object.assign({}, defaultForm),
      agentType: _enum.default.agentType,
      openAgreeMentDialog: false,
      approveState: _enum.default.ApproveFlagMap,
      qr: null,
      qrcode: null
    };
  },
  created: function created() {},
  methods: {
    approve: function approve(id) {
      var _this = this;
      _api.default.approveById({
        id: id
      }).then(function (response) {
        _this.$notify({
          title: '成功',
          message: '审核成功',
          type: 'success',
          duration: 2000
        });
        _this.dialogFormVisible = false;
        _this.callback();
      });
    },
    /** 显示时初始化数据 */initData: function initData() {
      this.form = Object.assign({}, defaultForm);
      //如果是编辑状态，远程加载信息
      if (this.isEdit) {
        if (this.id) {
          this.fetchData(this.id);
        }
      }
    },
    /** 加载数据 */fetchData: function fetchData(id) {
      var _this2 = this;
      _api.default.get(id).then(function (response) {
        _this2.form = response;
        _this2.form.areaData = [];
        if (response.province !== null) _this2.form.areaData.push(response.province);
        if (response.city !== null) _this2.form.areaData.push(response.city);
        if (response.county !== null) _this2.form.areaData.push(response.county);
        _this2.qr = null;
        _this2.qrcode = null;
        document.getElementById("qrcode").innerHTML = "";
        if (_this2.qr == null) {
          _this2.qrcode = 'http://app.pospd.com/pages/signup/shanghu/shanghu?registered=true&agent=' + id;
          _this2.qr = new _qrcodejs.default('qrcode', {
            width: 150,
            height: 150,
            // 高度
            text: _this2.qrcode // 二维码内容
          });
        }
      });
    },
    /**取消按钮 */cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
      this.form = Object.assign({}, defaultForm);
      this.qr = null;
      this.qrcode = null;
      document.getElementById('qrcode').innerHTML = '';
    },
    submitData: function submitData() {
      var _this3 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this3.formLoading = true;
          var areaData = _this3.form.areaData;
          _this3.form.province = areaData[0];
          _this3.form.city = areaData[1];
          _this3.form.county = areaData[2];
          _api.default.create(_this3.form).then(function (response) {
            _this3.formLoading = false;
            _this3.$notify({
              title: '成功',
              type: 'success',
              duration: 2000
            });
            if (response.approve == 0 && _this3.isEdit == false) {
              _this3.$confirm('是否立即审核?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(function (_) {
                _this3.approve(response.id);
                _this3.form = Object.assign({}, defaultForm);
              }).catch(function () {
                _this3.$message({
                  type: 'info',
                  message: '取消审核'
                });
                _this3.callback();
              });
            }
            _this3.dialogFormVisible = false;
            _this3.callback();
          }).catch(function () {
            _this3.formLoading = false;
          });
        }
      });
    },
    selectbranch: function selectbranch(e) {
      if (e) {
        this.form.salesManId = e.id;
        this.form.salesManName = e.name;
        this.form.salesManPhoneNum = e.phoneNumber;
      }
    }
  }
};
exports.default = _default;