"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Steps",
  props: {
    items: {
      type: Array,
      default: []
    }
  },
  methods: {
    to: function to(_to) {
      this.$router.push({
        path: _to.path,
        query: _to.query
      });
    }
  }
};
exports.default = _default;