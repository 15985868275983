"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _api = _interopRequireDefault(require("./api"));
var _index2 = _interopRequireDefault(require("@/components/MyElColumn/index.vue"));
var _SaasButtonGroup = _interopRequireDefault(require("@/saas/components/SaasButtonGroup.vue"));
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var _axios = _interopRequireDefault(require("@/axios"));
var _Area = _interopRequireDefault(require("@/components/Area"));
var _api2 = _interopRequireDefault(require("@/views/saasService/agent/api.js"));
var _SaasInputDialogs = _interopRequireDefault(require("@/saas/components/SaasInputDialogs.vue"));
//导入本功能的api接口
var _default = {
  name: 'merchant',
  components: {
    Pagination: _Pagination.default,
    SaasButtonGroup: _SaasButtonGroup.default,
    MyElColumn: _index2.default,
    Area: _Area.default,
    SaasInputDialog: _SaasInputDialogs.default
  },
  directives: {
    permission: _index.default
  },
  data: function data() {
    var _ref;
    return _ref = {
      //是否代理商登录
      isAgent: false,
      list: [],
      branchInfoList: [],
      totalCount: 0,
      listLoading: true,
      listQuery: {
        filter: '',
        SkipCount: 0,
        MaxResultCount: 10,
        approve: -1,
        disable: -1,
        stopDate: -1,
        province: null,
        city: null,
        county: null,
        agent: null,
        shopType: null,
        csService: null
      },
      branchInfoData: {
        id: '',
        branchNo: '',
        branchName: '',
        stopDate: '',
        status: '',
        tenantId: '',
        area: 'null',
        tradeType: 0,
        enable: 0
      },
      branchLoading: true,
      branchUpdateLoading: true,
      branchTitle: '',
      branchRegStatusType: _enum.default.BranchRegStatusType,
      ApproveFlagMap: _enum.default.ApproveFlagMap,
      RenewFlagMap: _enum.default.RenewFlagMap,
      shopType: _enum.default.shopType,
      num: null,
      page: 1,
      multipleSelection: [],
      areas: [],
      //显示创建窗口
      dialogFormVisible: false,
      branchInfoDialogTableVisible: false,
      branchInfoUpdateDialogTableVisible: false,
      formTitle: '',
      isEdit: false,
      editId: '',
      isTop: true,
      tableDef: _api.default.tableDef,
      agent: []
    }, (0, _defineProperty2.default)(_ref, "shopType", _enum.default.shopType), (0, _defineProperty2.default)(_ref, "agentApi", _api2.default), (0, _defineProperty2.default)(_ref, "agentListQuery", {
      filter: "",
      SkipCount: 0,
      MaxResultCount: 10,
      approve: 1
    }), (0, _defineProperty2.default)(_ref, "merchantTypes", [{
      value: 1,
      label: '标准版'
    }, {
      value: 2,
      label: '增值服务',
      children: [{
        value: 3,
        label: 'CS微会员'
      }, {
        value: 4,
        label: 'CS老板助手'
      }]
    }]), (0, _defineProperty2.default)(_ref, "imgsrc", require("@/assets/images/agent.png")), (0, _defineProperty2.default)(_ref, "dialogVisible", false), _ref;
  },
  created: function created() {
    this.isAgent = this.$store.state.user.userEx.isAgent;
    this.getList();
  },
  activated: function activated() {
    if (this.$route.params.agent) {
      this.listQuery.agent = this.$route.params.agent;
      this.getList();
    }
  },
  watch: {
    areas: function areas(val) {
      if (val && Array.isArray(val)) {
        if (val[0] != undefined) {
          this.listQuery.province = val[0];
        } else {
          this.listQuery.province = null;
        }
        if (val[1] != undefined) {
          this.listQuery.city = val[1];
        } else {
          this.listQuery.city = null;
        }
        if (val[2] != undefined) {
          this.listQuery.county = val[2];
        } else {
          this.listQuery.county = null;
        }
      }
    }
  },
  methods: {
    changeCs: function changeCs(e, text) {
      var _this = this;
      var lable = "CS微会员";
      var lableBool = "开启";
      if (text == 'vip') {
        if (e.csVip) lableBool = "开启";else lableBool = "关闭";
      } else {
        lable = "CS老板助手";
        if (e.csBoos) lableBool = "开启";else lableBool = "关闭";
      }
      var h = this.$createElement;
      this.$msgbox({
        title: '警告',
        message: h('p', null, [h('span', {
          style: 'color: red;font-size: 16px;'
        }, '该功能为CS软件专用功能，开通后SAAS软件部分功能将不可用，关闭该功能即可恢复原有SAAS功能。请问是否确认开通！')]),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      }).then(function () {
        if (e.csVip || e.csBoos) e.csService = true;else e.csService = false;
        _this.$axios.posts('/api/SettingService/merchant/CreateAndApprove', e).then(function (response) {
          _this.$notify({
            title: '成功',
            message: '操作成功',
            type: 'success',
            duration: 2000
          });
          _this.Search();
        });
      }).catch(function () {
        _this.$message({
          type: 'info',
          message: '已取消'
        });
        _this.Search();
      });
    },
    /** 获取分页列表 */getList: function getList() {
      var _this2 = this;
      this.listLoading = true;
      this.listQuery.SkipCount = (this.page - 1) * this.listQuery.MaxResultCount;
      this.list = [];
      _api.default.getAll(this.listQuery).then(function (response) {
        _this2.list = response.items;
        _this2.totalCount = response.totalCount;
        _this2.listLoading = false;
      });
    },
    /**  排序 */sortChange: function sortChange(data) {
      var prop = data.prop,
        order = data.order;
      if (!prop || !order) {
        this.Filter();
        return;
      }
      this.listQuery.Sorting = prop + ' ' + order;
      this.Filter();
    },
    /** 搜索事件 */Search: function Search() {
      this.page = 1;
      this.getList();
    },
    Create: function Create() {
      this.formTitle = '新增商户';
      this.isEdit = false;
      this.dialogFormVisible = !this.dialogFormVisible;
    },
    /** 新增窗口关闭事件 */handleCloseCreate: function handleCloseCreate() {
      this.dialogFormVisible = false;
    },
    /** 新增成功刷新列表*/handleCreateCallback: function handleCreateCallback(val) {
      this.getList();
    },
    branchInfoShow: function branchInfoShow(row) {
      this.branchInfoDialogTableVisible = true;
      this.branchLoading = true;
      this.branchTitle = "门店信息（" + row.shopName + "）";
      this.initBranchInfo(row.tenantIdBind);
    },
    /** 删除 */Delete: function Delete(row) {
      var _this3 = this;
      if (row) {
        this.$confirm('将删除该商户' + row.shopName + '信息，删除后无法恢复，请确认是否删除！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          console.log("row", row);
          _api.default.merchantDel(row.id).then(function (response) {
            _this3.$notify({
              title: '成功',
              message: '删除成功',
              type: 'success',
              duration: 2000
            });
            _this3.getList();
          });
        }).catch(function () {
          _this3.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      }
    },
    Update: function Update(row) {
      this.formTitle = '修改';
      this.isEdit = true;
      if (row) {
        this.editId = row.id;
        this.dialogFormVisible = true;
      } else {
        if (this.multipleSelection.length != 1) {
          this.$message({
            message: '编辑必须选择单行',
            type: 'warning'
          });
          return;
        } else {
          this.editId = this.multipleSelection[0].id;
          this.dialogFormVisible = true;
        }
      }
    },
    /** 多选事件 */handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /** 行点击事件 */handleRowClick: function handleRowClick(row, column, event) {
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    /** 审核 */Approve: function Approve(row) {
      var _this4 = this;
      if (row && row.id) {
        if (row.approve == 1) {
          this.$message({
            message: '当前数据状态已审核，不可重复审核！',
            type: 'warning'
          });
          return;
        }
        this.$confirm('是否审核' + row.shopName + '?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _api.default.approveById({
            id: row.id
          }).then(function (response) {
            _this4.$notify({
              title: '成功',
              message: '审核成功',
              type: 'success',
              duration: 2000
            });
            _this4.getList();
          });
        }).catch(function () {
          _this4.$message({
            type: 'info',
            message: '已取消'
          });
        });
      }
    },
    getSummaries: function getSummaries(param) {
      return this.$tables.getSummaries(param, this.tableDef);
    },
    selectAgentCallback: function selectAgentCallback() {}
  }
};
exports.default = _default;