var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.dialogFormVisible,
        title: _vm.formTitle,
        width: "500px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: function ($event) {
          return _vm.cancel()
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            inline: true,
            model: _vm.form,
            rules: _vm.rules,
            size: "small",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "权限名称", prop: "authName" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { disabled: _vm.form.sysDef && _vm.isEdit },
                model: {
                  value: _vm.form.authName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "authName", $$v)
                  },
                  expression: "form.authName",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "排序" } },
            [
              _c("el-input", {
                staticStyle: { width: "300px" },
                attrs: { type: "number" },
                model: {
                  value: _vm.form.weight,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "weight", $$v)
                  },
                  expression: "form.weight",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "系统默认" } },
            [
              _c("el-switch", {
                attrs: {
                  "active-text": "是",
                  "active-color": "#13ce66",
                  "inactive-color": "#ff4949",
                  disabled: _vm.form.sysDef && _vm.isEdit,
                },
                model: {
                  value: _vm.form.sysDef,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "sysDef", $$v)
                  },
                  expression: "form.sysDef",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "text" },
              on: { click: _vm.cancel },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "small",
                loading: _vm.formLoading,
                type: "primary",
              },
              on: { click: _vm.save },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }