"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var table = [{
  Display: true,
  DisplayType: "text",
  Label: "代理商编号",
  Name: "agentCode",
  Sort: false,
  Align: "",
  Width: "150",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "代理商名称",
  Name: "agentName",
  Sort: false,
  Align: "",
  Width: "150",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "商户总数",
  Name: "merchantSum",
  Sort: false,
  Align: "",
  Width: "150",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "新增商户",
  Name: "addMerchantSum",
  Sort: false,
  Align: "",
  Width: "150",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "新增续费商户",
  Name: "addOffMerchantSum",
  Sort: false,
  Align: "",
  Width: "150",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "有交易商户",
  Name: "businessMerchantSum",
  Sort: false,
  Align: "",
  Width: "150",
  IsLink: true,
  NavDisplayProp: ""
}];
var _default = {
  table: table
};
exports.default = _default;