"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _PageSelect = _interopRequireDefault(require("@/components/PageSelect"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = {
  components: {
    PageSelect: _PageSelect.default
  },
  props: {
    value: null,
    width: {
      type: String,
      required: false,
      default: function _default() {
        return "100%";
      }
    },
    selectType: String,
    selectChange: Function,
    disabled: Boolean,
    size: String,
    label: String,
    prop: String,
    multiple: Boolean,
    selectDataChange: Function,
    labelWidth: {
      type: String,
      required: false,
      default: function _default() {
        return "";
      }
    },
    supSupType: {
      type: Number,
      required: false,
      default: function _default() {
        return -1;
      }
    },
    isFilterBranch: {
      type: Boolean,
      required: false,
      default: function _default() {
        return false;
      }
    },
    isFilterCompany: {
      type: Boolean,
      required: false,
      default: function _default() {
        return false;
      }
    },
    branchId: {
      type: String,
      required: false,
      default: function _default() {
        return "";
      }
    }
  },
  watch: {
    value: function value(val) {
      console.info("监听到值变化");
      //外部绑定的值如果第一次是有值的，从这里传入
      this.selectId = val;
    },
    branchId: function branchId(val) {
      this.initData();
    }
  },
  data: function data() {
    return {
      selectId: this.value,
      displayLable: "",
      SlectCols: [],
      url: "",
      placeholder: "请选择"
    };
  },
  mounted: function mounted() {
    this.initData();
  },
  methods: {
    initData: function initData() {
      switch (this.selectType) {
        case "oper":
          this.displayLable = "操作员";
          this.SlectCols = [{
            name: "name"
          }, {
            name: "userName"
          }];
          this.url = "/api/SettingService/user";
          this.placeholder = "请选择" + "操作员";
          break;
        case "sup":
          this.displayLable = "供应商";
          this.SlectCols = [{
            name: "name"
          }, {
            name: "supName"
          }];
          this.url = "/api/BDService/bdSupcustInfo/all?payWay=" + this.supSupType;
          this.placeholder = "请选择" + "供应商";
          break;
        case "branchAll":
          this.displayLable = "门店";
          this.SlectCols = [{
            name: "name"
          }, {
            name: "branchName"
          }];
          this.url = "/api/BDService/bdBranchInfo";
          this.placeholder = "请选择" + "门店";
          break;
        case "branch":
          this.displayLable = "门店";
          this.SlectCols = [{
            name: "name"
          }, {
            name: "branchName"
          }];
          this.url = "/api/BDService/bdBranchInfo/store?isFilterBranch=" + this.isFilterBranch + "&isFilterCompany=" + this.isFilterCompany;
          this.placeholder = "请选择" + "门店";
          break;
        case "store":
          this.displayLable = "仓库";
          this.SlectCols = [{
            name: "name"
          }, {
            name: "branchName"
          }];
          this.url = "/api/BDService/bdBranchInfo/storehouse?isFilterBranch=" + this.isFilterBranch;
          this.placeholder = "请选择" + "仓库";
          break;
        case "branchAll2":
          this.displayLable = "门店";
          this.SlectCols = [{
            name: "name"
          }, {
            name: "branchName"
          }];
          this.url = "/api/BDService/bdBranchInfo/store?isAll=true&&IsFilterCompany=true";
          this.placeholder = "请选择" + "仓库";
          break;
        case "storeSelf":
          this.displayLable = "仓库";
          this.SlectCols = [{
            name: "name"
          }, {
            name: "branchName"
          }];
          this.url = "/api/BDService/bdBranchInfo/storehouse?branchId=" + this.branchId;
          this.placeholder = "请选择" + "仓库";
          break;
        //未传id
        case "supstore":
          this.displayLable = "铺位";
          this.SlectCols = [{
            name: "name"
          }, {
            name: "shopNo"
          }];
          this.url = "/api/BDService/bdStoreInfo/storeinfo";
          this.placeholder = "请选择" + "铺位";
          break;
        case "supShop":
          this.displayLable = "店铺";
          this.SlectCols = [{
            name: ""
          }, {
            name: "name"
          }];
          this.url = "/api/BDService/bdSupShopMaster";
          this.placeholder = "请选择" + "店铺";
          break;
        case "stockReason":
          this.displayLable = "原因";
          this.SlectCols = [{
            name: "codeId"
          }, {
            name: "codeName"
          }];
          this.url = "/api/BDService/basecode/all?CodeType=1";
          this.placeholder = "请选择" + "原因";
          break;
        case "brand":
          this.displayLable = "品牌";
          this.SlectCols = [{
            name: "name"
          }, {
            name: "itemBrandname"
          }];
          this.url = "/api/BDService/bdItemBrand";
          this.placeholder = "请选择" + "品牌";
          break;
        case "pdSheet":
          this.displayLable = "盘点批号";
          this.SlectCols = [{
            name: "name"
          }, {
            name: "sheetNo"
          }];
          this.url = "/api/sheetService/TImCheckInit";
          this.placeholder = "请选择" + "盘点批号";
          break;
        case "cls":
          this.displayLable = "类别";
          this.SlectCols = [{
            name: "name"
          }, {
            name: "itemClsname"
          }];
          this.url = "/api/BDService/bdItemCls";
          this.placeholder = "请选择" + "类别";
          break;
        case "grade":
          this.displayLable = "会员等级";
          this.SlectCols = [{
            name: "name"
          }, {
            name: "typeName"
          }];
          this.url = "/api/BDService/rmviptype";
          this.placeholder = "请选择" + "类别";
          break;
        case "good":
          this.displayLable = "商品";
          this.SlectCols = [{
            name: "name"
          }, {
            name: "itemName"
          }];
          this.url = "/api/BDService/bdIteminfo";
          this.placeholder = "请选择" + "商品";
          break;
        case "vip":
          this.displayLable = "会员";
          this.SlectCols = [{
            name: "name"
          }, {
            name: "itemName"
          }];
          this.url = "/api/BDService/rmVipInfo";
          this.placeholder = "请选择" + "会员";
          break;
        case "tag":
          this.displayLable = "标签";
          this.SlectCols = [{
            name: "name"
          }, {
            name: "tagName"
          }];
          this.url = "/api/vipService/TRmVipTag";
          this.placeholder = "请选择" + "会员";
          break;
        case "buyer":
          this.displayLable = "采购员";
          this.SlectCols = [{
            name: "operNo"
          }, {
            name: "name"
          }];
          this.url = "/api/BDService/bdOper?operType=4";
          this.placeholder = "请选择" + "操作员";
          break;
        case "sorter":
          this.displayLable = "分拣员";
          this.SlectCols = [{
            name: "operNo"
          }, {
            name: "name"
          }];
          this.url = "/api/BDService/bdOper?operType=5";
          this.placeholder = "请选择" + "分拣员";
          break;
        case "batch":
          this.displayLable = "批次号";
          this.SlectCols = [{
            name: "batchNumber"
          }, {
            name: "name"
          }];
          this.url = "/api/SheetService/procurementBatchNumber?ApproveFlag=0";
          this.placeholder = "请选择" + "批次号";
          break;
        case "batch1":
          this.displayLable = "批次号";
          this.SlectCols = [{
            name: "batchNumber"
          }, {
            name: "name"
          }];
          this.url = "/api/SheetService/procurementBatchNumber?ApproveFlag=1";
          this.placeholder = "请选择" + "批次号";
          break;
        case "driver":
          this.displayLable = "司机";
          this.SlectCols = [{
            name: "operNo"
          }, {
            name: "name"
          }];
          this.url = "/api/BDService/bdOper?operType=6";
          this.placeholder = "请选择" + "司机";
          break;
        case "box":
          this.displayLable = "押金筐";
          this.SlectCols = [{
            name: "name"
          }, {
            name: "boxName"
          }];
          this.url = "/api/BDService/bdDistributionBox";
          this.placeholder = "请选择" + "押金筐";
          break;
        case "salesMan":
          this.displayLable = "业务员";
          this.SlectCols = [{
            id: "id"
          }, {
            name: "name"
          }, {
            phoneNumber: "phoneNumber"
          }];
          this.url = "/api/SettingService/user?roleName=运营";
          this.placeholder = "请选择" + "业务员";
          break;
        case "payment":
          this.displayLable = "付款方式";
          this.SlectCols = [{
            name: "payWay"
          }, {
            name: "payName"
          }];
          this.url = "/api/BdService/paymentinfo/all?payFlag=" + this.supSupType;
          this.placeholder = "请选择" + "付款方式";
          break;
        default:
          break;
      }
      if (this.selectId != "" && this.selectId != undefined && this.selectId != null) {
        this.placeholder = "";
      }
      if (this.label != null && this.label != undefined) {
        this.displayLable = this.label;
      }
    } // selectDataChange(data){
    //   this.$emit("selectDataChange",data);
    // }
  }
};
exports.default = _default2;