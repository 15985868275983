var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.dialogFormVisible,
        title: _vm.formTitle,
        width: "400px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: function ($event) {
          return _vm.cancel()
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            inline: true,
            model: _vm.form,
            rules: _vm.rules,
            size: "small",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c("el-form-item", { attrs: { label: "编号：" } }, [
                _c("span", [_vm._v(_vm._s(_vm.agent.agentCode))]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-form-item", { attrs: { label: "名称：" } }, [
                _c("span", [_vm._v(_vm._s(_vm.agent.companyName))]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-form-item", { attrs: { label: "余额：" } }, [
                _c("span", [_vm._v(_vm._s(_vm.agent.balance))]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c("el-form-item", { attrs: { label: "点数：" } }, [
                _c("span", [_vm._v(_vm._s(_vm.agent.pointQty))]),
              ]),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "赠送类型：" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.form.giveType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "giveType", $$v)
                        },
                        expression: "form.giveType",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: "0" } }, [
                        _vm._v("点数"),
                      ]),
                      _c("el-radio-button", { attrs: { label: "1" } }, [
                        _vm._v("余额"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "赠送数值：", prop: "giveAmount" } },
                [
                  _vm.form.giveType == "0"
                    ? _c("el-input-number", {
                        attrs: { precision: 0, controls: false, min: 0 },
                        model: {
                          value: _vm.form.giveAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "giveAmount", $$v)
                          },
                          expression: "form.giveAmount",
                        },
                      })
                    : _vm._e(),
                  _vm.form.giveType == "1"
                    ? _c("el-input-number", {
                        attrs: { precision: 2, controls: false, min: 0 },
                        model: {
                          value: _vm.form.giveAmount,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "giveAmount", $$v)
                          },
                          expression: "form.giveAmount",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "text" },
              on: { click: _vm.cancel },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                size: "small",
                loading: _vm.formLoading,
                type: "primary",
              },
              on: { click: _vm.save },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }