"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _api = _interopRequireDefault(require("./api"));
var _index = _interopRequireDefault(require("@/components/MyElColumn/index.vue"));
var _SaasButtonGroup = _interopRequireDefault(require("@/saas/components/SaasButtonGroup.vue"));
var _default = {
  name: "wxSmsTemplate",
  components: {
    Pagination: _Pagination.default,
    SaasButtonGroup: _SaasButtonGroup.default,
    MyElColumn: _index.default
  },
  data: function data() {
    return {
      list: [],
      listLoading: true,
      tableDef: _api.default.tableDef,
      appId: "wx19305b3b4d54b5cd",
      appSecret: "d41d3f715a469d05fde80fc964692c94"
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    /** 获取分页列表 */getList: function getList() {
      var _this = this;
      this.listLoading = true;
      _api.default.getAll().then(function (response) {
        _this.list = response;
        _this.listLoading = false;
      });
      this.listLoading = false;
    },
    /** 搜索事件 */Access: function Access() {
      var _this2 = this;
      this.listLoading = true;
      _api.default.create().then(function (response) {
        _this2.list = response;
        _this2.listLoading = false;
      });
      this.listLoading = false;
    },
    /** 更新 */Update: function Update(row) {},
    /** 行点击事件 */handleRowClick: function handleRowClick(row, column, event) {}
  }
};
exports.default = _default;