"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.find-index");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _index2 = _interopRequireDefault(require("@/components/MyElColumn/index.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//导入本功能的api接口
var defautListQuery = {
  SkipCount: 0,
  MaxResultCount: 10
};
var _default2 = {
  components: {
    Pagination: _Pagination.default,
    MyElColumn: _index2.default,
    permission: _index.default
  },
  props: {
    SelectRow: Function,
    api: Object,
    isShow: Boolean,
    closeEven: Function,
    title: String,
    type: {
      type: String,
      required: false,
      default: function _default() {
        return "num";
      }
    },
    hasTree: Boolean,
    hasRows: {
      type: Boolean,
      default: function _default() {
        return true;
      }
    },
    listQuery: {
      type: Object,
      required: false,
      default: function _default() {
        return defautListQuery;
      }
    },
    isfilter: "",
    //未用（是否特殊过滤）
    retView: {
      type: Boolean,
      required: false,
      default: function _default() {
        return false;
      }
    }
  },
  data: function data() {
    return {
      list: [],
      listLoading: true,
      listQuery2: Object.assign({}, this.listQuery),
      tableDef: this.api.tableDef,
      totalCount: 0,
      page: 1,
      // 树形列表
      treeNodeName: "",
      treeDatas: [],
      defaultProps: this.api.defaultProps,
      //对话框
      dialogVisible: false,
      treeLoading: true,
      num: null,
      text: "",
      //tree名称
      treeExpandedKeys: ["00000000-0000-0000-0000-000000000000"]
    };
  },
  watch: {
    isfilter: function isfilter(val) {
      console.info("isfilter~~~~~~~~~", val);
    },
    treeNodeName: function treeNodeName(val) {
      this.$refs.tree.filter(val);
    },
    isShow: function isShow(val) {
      console.info("isshow~~~~~~~~~", val);
      console.log("listQuery2", this.listQuery2);
      this.dialogVisible = val;
      if (val) {
        this.initData();
      }
    },
    dialogVisible: function dialogVisible(newVal) {
      if (!newVal) {
        this.closeEven(); //内部不关闭自己页面，会造成循环，让外部父组件来关闭
      }
    },
    retView: function retView(val) {
      console.log("watch-retView", val);
    }
  },
  mounted: function mounted() {},
  created: function created() {},
  methods: {
    filterNode: function filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    initData: function initData() {
      // if(this.isfilter!=''){
      //      this.listQuery = Object.assign({}, this.api.listQuery);
      // }
      console.info("this.listQuery", this.listQuery);
      //if (this.listQuery2.Filter == "")
      this.listQuery2 = Object.assign({}, this.listQuery);
      this.getList();
      if (this.hasTree) {
        this.getLeftTree();
      }
    },
    /**  排序 */sortChange: function sortChange(data) {
      var prop = data.prop,
        order = data.order;
      if (!prop || !order) {
        this.Filter();
        return;
      }
      this.listQuery2.Sorting = prop + " " + order;
      this.Filter();
    },
    /** 多选事件 */handleSelectionChange: function handleSelectionChange(val) {
      if (this.hasRows == true) {
        this.multipleSelection = val;
      } else {
        if (val.length > 1) {
          this.$refs.multipleTable.toggleRowSelection(val[0], false);
          val = val.splice(0, 1);
        } else {
          this.multipleSelection = val;
        }
      }

      // console.info("this.hasRows",this.hasRows)
      // if (!this.hasRows) {
      //   if (val.length > 1) {
      //     this.$refs.multipleTable.toggleRowSelection(val[0], false);
      //     this.multipleSelection = val.splice(0, 1);
      //   } else {
      //     this.multipleSelection = val;
      //   }
      // }
    },
    /** 行点击事件 */handleRowClick: function handleRowClick(row, column, event) {
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    /** 行双击击事件 */handleDbRowClick: function handleDbRowClick(row, column, event) {
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row);
      this.SelectRow(this.multipleSelection);
    },
    /** 点击超链接文字  */Update: function Update(row) {
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row);
      console.info("点击超链接文字", this.multipleSelection);
      this.SelectRow(this.multipleSelection);
    },
    /**
     * 获取分页列表
     */
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      this.listQuery2.SkipCount = (this.page - 1) * this.listQuery2.MaxResultCount;
      this.list = [];
      this.api.getAll(this.listQuery2).then(function (response) {
        _this.list = response.items;
        _this.totalCount = response.totalCount;
        _this.listLoading = false;
        console.info("加载了", _this.totalCount);
        console.info("retView", _this.retView);
        //如果是需要直接返回时，结果为1条时直接返回
        if (_this.retView) {
          if (_this.totalCount == 1) {
            console.info("直接返回");
            _this.SelectRow(_this.list);
          }
        }
      });
    },
    /** 搜索事件 */Search: function Search() {
      this.page = 1;
      this.getList();
    },
    SearchByParent: function SearchByParent(selectName) {
      this.page = 1;
      this.listQuery2.Filter = selectName;
      this.getList();
    },
    /** 搜索框回车事件 */enterSearch: function enterSearch() {
      this.Search();
    },
    getLeftTree: function getLeftTree() {
      var _this2 = this;
      this.treeLoading = true;
      // if (this.treeDatas.length > 0) {
      //   this.treeLoading = false;
      // } else {
      this.api.getTree(this.listQuery2).then(function (response) {
        console.info("response!!!!", response);
        _this2.treeDatas = response;
        _this2.treeLoading = false;
      });
      // }
      // } else {
      //   console.info("后台筛选")
      //   if (this.treeNodeName != "") {
      //     this.api
      //       .getTreeByName({ itemClsname: this.treeNodeName })
      //       .then((response) => {
      //         this.treeDatas = response;
      //         this.treeLoading = false;
      //       });
      //   } else {
      //     this.api.getTree().then((response) => {
      //       this.treeDatas = response;
      //       this.treeLoading = false;
      //     });
      //   }
      // }
    },
    /** 树节点点击事件 */handleNodeClick: function handleNodeClick(data) {
      console.log("树节点点击事件", data);
      var index = this.treeExpandedKeys.findIndex(function (item) {
        return item === data.id;
      });
      console.info(index);
      if (index == "-1") {
        this.treeExpandedKeys.push(data.id);
      } else {}
      if (this.type == "id") {
        this.listQuery2.num = data.id;
      } else {
        this.listQuery2.num = data.num;
      }
      this.text = data.title;
      this.listQuery2.regionNo = data.id;
      this.clsname = data.label;
      this.parentNo = data.id;
      this.getList();
    },
    handleClose: function handleClose(done) {
      done();
    },
    Ok: function Ok() {
      this.dialogVisible = false;
      this.SelectRow(this.multipleSelection);
    }
  }
};
exports.default = _default2;