"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _AgreeMent = _interopRequireDefault(require("./AgreeMent.vue"));
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var _api = _interopRequireDefault(require("../api"));
var _Area = _interopRequireDefault(require("@/components/Area"));
var _moment = _interopRequireDefault(require("moment"));
var _api2 = _interopRequireDefault(require("@/views/saasService/agent/api.js"));
var _SaasInputDialogs = _interopRequireDefault(require("@/saas/components/SaasInputDialogs.vue"));
var _index2 = _interopRequireDefault(require("../buy/index.vue"));
var _vueQr = _interopRequireDefault(require("vue-qr"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//表单默认值
var defaultForm = {
  shopName: '',
  contacts: '',
  phone: '',
  agent: null,
  shopType: '',
  province: '',
  city: '',
  county: '',
  address: '',
  posNum: 0,
  branchNum: 0,
  areas: [],
  memo: '',
  companyCode: '',
  status: true,
  approve: 0,
  isOfficial: false,
  creationTime: new Date(Date.now()),
  stopDate: null,
  isBeforeStop: false,
  beforeStopDate: null,
  csService: false,
  csVip: false,
  csBoos: false,
  VerificationCode: ''
};
var _default = {
  name: 'merchantCreate',
  components: {
    AgreeMent: _AgreeMent.default,
    Area: _Area.default,
    SaasInputDialog: _SaasInputDialogs.default,
    MyCar: _index2.default,
    vueQr: _vueQr.default
  },
  directives: {
    permission: _index.default
  },
  props: {
    //是否显示窗口
    isShow: {
      type: Boolean
    },
    //窗口关闭事件回调
    closeCreate: {
      type: Function
    },
    //保存成功后回调
    callback: {
      type: Function
    },
    //是否是修改
    isEdit: {
      type: Boolean
    },
    //修改时传入的id
    id: {
      type: String
    },
    formTitle: {
      type: String
    }
  },
  watch: {
    isShow: function isShow(newVal) {
      this.dialogFormVisible = newVal; //对父组件传过来的值进行监听，如果改变也对子组件内部的值进行改变
      this.initData();
      //     this.form.agent = this.agent.id;
    },
    dialogFormVisible: function dialogFormVisible(newVal) {
      if (!newVal) {
        this.closeCreate(); //内部不关闭自己页面，会造成循环，让外部父组件来关闭
      }
    },

    'form.areas': function formAreas(val) {
      if (val && Array.isArray(val)) {
        if (val[0] != undefined) {
          this.form.province = val[0];
        } else {
          this.form.province = null;
        }
        if (val[1] != undefined) {
          this.form.city = val[1];
        } else {
          this.form.city = null;
        }
        if (val[2] != undefined) {
          this.form.county = val[2];
        } else {
          this.form.county = null;
        }
      }
    }
  },
  data: function data() {
    return {
      vcode: false,
      TenantId: null,
      app: null,
      appdialogVisible: false,
      dialogFormVisiblenew: false,
      platformApp: [],
      productsapp: [],
      imageUrl: '',
      codeurl: '',
      isShowCode: false,
      timer: '',
      //接收定时器
      seconds: 0,
      isAgent: false,
      productUnitType: _enum.default.productUnitType,
      //控制弹窗显示
      dialogFormVisible: false,
      //弹窗加载框
      formLoading: false,
      isTop: true,
      //表单提交校验规则
      rules: {
        agent: [{
          required: true,
          message: '请选择代理商',
          trigger: 'change'
        }],
        shopName: [{
          required: true,
          message: '请输入商户名称',
          trigger: 'blur'
        }],
        shopType: [{
          required: true,
          message: '请选择店铺类型',
          trigger: 'change'
        }],
        saasProductId: [{
          required: true,
          message: '请选择软件版本',
          trigger: 'change'
        }],
        contacts: [{
          required: true,
          message: '请输入联系人',
          trigger: 'blur'
        }],
        areas: [{
          type: 'array',
          required: true,
          message: '请选择所在区域',
          trigger: 'change'
        }],
        phone: [{
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }, {
          pattern: /^1\d{10}$/,
          message: '请输入正确的手机号码',
          trigger: 'blur'
        }],
        // email: [
        //   { required: true, message: '请输入邮箱', trigger: 'blur' },
        //   {
        //     pattern: /^[0-9a-zA-Z]*@{1}[0-9a-zA-Z]+\.(com|net|cn|com\.cn)$/,
        //     message: '请输入正确的邮箱',
        //     trigger: 'blur'
        //   }
        // ],
        beforeStopDate: [{
          required: true,
          message: '请输入提前到期时间',
          trigger: 'blur'
        }],
        VerificationCode: [{
          required: true,
          message: '请输入验证码',
          trigger: 'blur'
        }]
      },
      //表单定义 并赋默认值
      form: Object.assign({}, defaultForm),
      options: _enum.default.shopType,
      agent: null,
      agentApi: _api2.default,
      agentListQuery: {
        filter: '',
        SkipCount: 0,
        MaxResultCount: 10,
        approve: 1
      },
      productListQuery: _api.default.productListQuery,
      products: [],
      delayVisible: false,
      delayForm: {
        delayDay: 0,
        delayReason: ''
      },
      resetForm: {
        PhoneNumber: '',
        VerificationCode: '',
        grant_type: 'phonenumber_credentials',
        client_id: 'phonenumber_app',
        client_secret: '1q2w3e*',
        scop: 'phone',
        VerificationCodeType: '2',
        TemplateCodeName: '哇哇来客'
      },
      showTime: true,
      sendTime: '',
      phoneConfirm: "",
      showPhone: true
    };
  },
  created: function created() {
    var _this = this;
    console.log('进入');
    this.isAgent = this.$store.state.user.userEx.isAgent;
    _api.default.getProduct(this.productListQuery).then(function (response) {
      _this.products = response.items;
      //console.log('数量:' + products.length)
    });
  },

  methods: {
    /** 显示时初始化数据 */initData: function initData() {
      this.showTime = true;
      this.showPhone = true;
      this.sendTime = '';
      this.form = Object.assign({}, defaultForm);
      //如果是编辑状态，远程加载信息
      if (this.isEdit) {
        if (this.id) {
          this.fetchData(this.id);
        }
      }
    },
    /** 加载数据 */fetchData: function fetchData(id) {
      var _this2 = this;
      _api.default.get(id).then(function (response) {
        response.testDay = Math.round((new Date(response.stopDate).getTime() - new Date(response.creationTime).getTime()) / (24 * 3600 * 1000));
        _this2.form = response;
      });
    },
    /**取消按钮 */cancel: function cancel() {
      ;
      this.resetForm = {
        PhoneNumber: '',
        VerificationCode: ''
      }, this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
      this.form = Object.assign({}, defaultForm);
    },
    /**保存 */handleSave: function handleSave() {
      var _this3 = this;
      if (!this.vcode && !this.isEdit) {
        this.$message({
          message: '验证码错误,请重新输入!',
          type: 'error',
          duration: 5 * 1000
        });
      } else {
        console.log('保存并审核' + this.vcode);
        this.$refs.form.validate(function (valid) {
          if (valid) {
            _this3.formLoading = true;
            _this3.$axios.posts('/api/SettingService/merchant/CreateAndApprove', _this3.form).then(function (response) {
              _this3.formLoading = false;
              _this3.$notify({
                title: '成功',
                message: '保存成功',
                type: 'success',
                duration: 2000
              });
              _this3.dialogFormVisible = false;
              _this3.callback();
            }).catch(function () {
              _this3.formLoading = false;
            });
          }
        });
      }
    },
    /** 审核 */approve: function approve() {
      var _this4 = this;
      if (this.form.id) {
        this.$confirm('是否审核' + this.form.shopName + '?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(function () {
          _api.default.approveById({
            id: _this4.form.id
          }).then(function (response) {
            _this4.$notify({
              title: '成功',
              message: '审核成功',
              type: 'success',
              duration: 2000
            });
            _this4.dialogFormVisible = false;
            _this4.callback();
          });
        }).catch(function () {
          _this4.$message({
            type: 'info',
            message: '已取消'
          });
        });
      }
    },
    selectAgentCallback: function selectAgentCallback() {},
    selectProduct: function selectProduct(e) {
      console.log(e, 222);
    },
    approveDelay: function approveDelay() {
      var _this5 = this;
      var params = {
        merchantId: this.form.id,
        stopTime: this.form.stopDate,
        delayDay: this.delayForm.delayDay,
        delayReason: this.delayForm.delayReason
      };
      this.$refs.delayForm.validate(function (valid) {
        if (valid) {
          _this5.formLoading = true;
          _api.default.merchantDelay(params).then(function (response) {
            _this5.formLoading = false;
            _this5.$notify({
              title: '成功',
              message: '申请成功',
              type: 'success',
              duration: 2000
            });
            _this5.delayVisible = false;
          }).catch(function () {
            _this5.formLoading = false;
          });
        }
      });
    },
    changeCsService: function changeCsService(val) {
      if (!val) {
        this.form.csBoos = false;
        this.form.csVip = false;
      }
    },
    /** 发送验证码 */sendMsg: function sendMsg() {
      var _this6 = this;
      console.log('发送验证码');
      if (/^1\d{10}$/.test(this.form.phone)) {
        this.resetForm.grant_type = 'phonenumber_credentials';
        this.resetForm.client_id = 'phonenumber_app';
        this.resetForm.client_secret = '1q2w3e*';
        this.resetForm.scop = 'phone';
        this.resetForm.TemplateCodeName = '零售云';
        this.resetForm.VerificationCodeType = 2;
        this.resetForm.PhoneNumber = this.form.phone;
        this.resetForm.VerificationCode = this.form.VerificationCode;
        this.$axios.posts('/api/SettingService/SmsTemplateMerchant/sendVerificationCode', this.resetForm).then(function () {
          var TIME_COUNT = 60;
          if (!_this6.timer) {
            _this6.sendTime = TIME_COUNT;
            _this6.showTime = false;
            _this6.showPhone = false;
            _this6.timer = setInterval(function () {
              if (_this6.sendTime > 1 && _this6.sendTime <= TIME_COUNT) {
                _this6.sendTime--;
              } else {
                _this6.showTime = true;
                clearInterval(_this6.timer);
                _this6.timer = null;
              }
            }, 1000);
          }
        }).catch(function (err) {
          Message({
            message: '获取验证码失败，请联系管理员！',
            type: 'error',
            duration: 5 * 1000
          });
        });
      }
    },
    /** 验证验证码 */confirmPhone: function confirmPhone() {
      var _this7 = this;
      this.resetForm.grant_type = 'phonenumber_credentials';
      this.resetForm.client_id = 'phonenumber_app';
      this.resetForm.client_secret = '1q2w3e*';
      this.resetForm.scop = 'phone';
      this.resetForm.TemplateCodeName = '零售云';
      this.resetForm.VerificationCodeType = 2;
      this.resetForm.PhoneNumber = this.form.phone;
      this.resetForm.VerificationCode = this.form.VerificationCode;
      this.$axios.posts('/api/SettingService/SmsTemplateMerchant/confirmPhoneCode', this.resetForm).then(function (res) {
        if (res != 1) {
          _this7.$message({
            message: '验证码错误,请重新输入!',
            type: 'error',
            duration: 5 * 1000
          });
          _this7.vcode = false;
          console.log('false');
        } else if (res == 1) {
          _this7.phoneConfirm = _this7.form.phone;
          _this7.vcode = true;
          console.log('true');
        }
      }).catch(function (err) {
        _this7.$message({
          message: '验证失败，请联系管理员！',
          type: 'error',
          duration: 5 * 1000
        });
      });
    },
    /** 商户续费 */Renewal: function Renewal(id, agentId) {
      var _this8 = this;
      this.appdialogVisible = true;
      this.$axios.gets('/api/SettingService/saasApp/buyApps', {
        Approve: 1,
        Status: true,
        merchantId: id,
        agent: agentId
      }).then(function (response) {
        // this.platformApp = response.filter((x) => x.appType == 0)
        _this8.platformApp = response;
      });
    },
    //获取版本信息
    getProducts: function getProducts() {
      var _this9 = this;
      if (this.$store.state.user.saasMerchant) {
        $axios.gets('/api/SettingService/product', {
          states: true
        }).then(function (response) {
          _this9.productsapp = response.items;
        });
      }
    },
    /** 购买按钮点击事件 */buy: function buy(e) {
      console.log('購買');
      this.app = e;
      this.dialogFormVisiblenew = !this.dialogFormVisiblenew;
    },
    //续费
    buyapp: function buyapp(id) {
      var _this10 = this;
      console.log('续费');
      this.isShowCode = true;
      clearInterval(this.timer);
      this.seconds = 0;
      this.$axios.posts('/api/SettingService/merchantOrders', {
        productId: id,
        TenantId: this.id
      }).then(function (response) {
        if (response) {
          _this10.codeurl = response.url;
          _this10.isShowCode = true;
          _this10.orderId = response.id;
          _this10.timer = setInterval(function () {
            _this10.seconds++;
            if (_this10.seconds % 3 == 0 && _this10.seconds <= 60) {
              _this10.getOrder("ordertime");
            }
            if (_this10.seconds >= 300) {
              clearInterval(_this10.timer);
            }
          }, 1000);
        }
      });
    },
    //获取订单状态
    getOrder1: function getOrder1(type) {
      console.info('关闭续费');
      this.isShowCode = false;
      this.appdialogVisible = false;
      this.dialogFormVisible = false;
      console.info('isShowCode', this.isShowCode);
      console.info('dialogFormVisiblenew', this.dialogFormVisiblenew);
    },
    getOrder: function getOrder(type) {
      var _this11 = this;
      this.$axios.gets('/api/SettingService/merchantOrders/' + this.orderId).then(function (response) {
        if (response.orderStatus == 1) {
          _this11.$notify({
            title: '成功',
            message: '支付成功！',
            type: 'success',
            duration: 2000
          });
          _this11.isShowCode = false;
          _this11.appdialogVisible = false;
          _this11.dialogFormVisiblenew = false;
          //this.form.isOfficial=true;
          //this.form.StopDate > DateTime.Now ? this.form.StopDate.Value.AddYears(1) : DateTime.Now.Date.AddYears(1).AddDays(1).AddSeconds//(-1);
          //this.handleSave();
          clearInterval(_this11.timer);
          _this11.dialogFormVisible = false;
          _this11.callback();
          //this.$refs.popover.doClose()
          // store.dispatch('user/getPermissions', {
          //   loginMode: store.getters.loginMode,
          // })
        } else if (response.orderStatus == 0 && type == "orderManual") {
          _this11.isShowCode = true;
          _this11.$message({
            message: '订单暂未支付!',
            type: 'info',
            duration: 5 * 1000
          });
        }
      });
    },
    /** 新增窗口关闭事件 */handleCloseCreate: function handleCloseCreate() {
      console.log('关闭');
      this.dialogFormVisible = false;
      this.dialogFormVisiblenew = false;
      this.showTime = true;
      this.showPhone = true;
    },
    /** 新增成功刷新列表*/handleCreateCallback: function handleCreateCallback(val) {
      //this.getList();
    }
  }
};
exports.default = _default;