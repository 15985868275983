// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/background.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "/* reset element-ui css */\n.login {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  height: 100%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.logo {\n  position: absolute;\n  left: 50px;\n  top: 20px;\n  margin: 10px;\n}\n.title {\n  text-align: left;\n  padding: 10px;\n  margin-bottom: 10px;\n}\n.main-title {\n  color: #F3D422;\n  font-weight: bold;\n  font-size: xx-large;\n  margin-bottom: 10px;\n}\n.subtitle {\n  color: #8A8989;\n  font-size: medium;\n}\n.swipper {\n  width: 50%;\n  z-index: 2;\n}\n.login-form {\n  border-radius: 6px;\n  background: #ffffff;\n  width: 20%;\n  min-width: 350px;\n  padding: 25px 25px 5px 25px;\n}\n.login-form .el-input {\n    height: 38px;\n}\n.login-form .el-input input {\n      height: 38px;\n}\n.login-form .input-icon {\n    height: 39px;\n    width: 14px;\n    margin-left: 2px;\n}\n.login-tip {\n  font-size: 13px;\n  text-align: center;\n  color: #bfbfbf;\n}\n.login-code {\n  width: 33%;\n  display: inline-block;\n  height: 38px;\n  float: right;\n}\n.login-code img {\n    cursor: pointer;\n    vertical-align: middle;\n}\n.tabs {\n  width: 100%;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n}\n.login-tab {\n  height: 54px;\n  line-height: 54px;\n  font-size: 18px;\n  text-align: center;\n  background: #fff;\n  width: 50%;\n}\n.login-tab a:hover,\n.login-tab.checked {\n  color: #F3D422;\n  font-weight: 700;\n  border-bottom: 2px solid #F3D422;\n}\n", ""]);
// Exports
exports.locals = {
	"menuText": "#ffffffb3",
	"menuActiveText": "#FFFFFF",
	"subMenuActiveText": "#FFFFFF",
	"menuBg": "#000",
	"menuHover": "#F25B13",
	"subMenuBg": "#ffffff",
	"subMenuHover": "#b1afaf",
	"sideBarWidth": "210px",
	"subMenuActive": "#8A8989",
	"textColror": "#FFF",
	"success": "#46B34F",
	"successHover": "#61e06c",
	"successPlain": "#E3F4E5",
	"warning": "#D8A54F",
	"warningHover": "#f8bf5d",
	"warningPlain": "#FAF2E5",
	"danger": "#E76969",
	"dangerHover": "#fd7575",
	"dangerPlain": "#FBE9E9",
	"primary": "#F04F0B",
	"primaryHover": "#fde245",
	"primaryPlain": "#FDF9DE",
	"info": "#8A8989",
	"infoHover": "#b9b8b8",
	"infoPlain": "#EEEDED",
	"blue": "#709AC6",
	"lightBlue": "#3A71A8",
	"red": "#C03639",
	"pink": "#E65D6E",
	"green": "#2BBBBD",
	"tiffany": "#4AB7BD",
	"yellow": "#F3D422",
	"panGreen": "#30B08F",
	"orange": "#FF8400",
	"purple": "#B19DD8",
	"lightOrange": "#FFB980",
	"lightRed": "#D87A80",
	"black": "#4A4949",
	"lightBlack": "#878787",
	"groupColor": "#B19DD8, #FF8400, #F3D422, #2BBBBD, #FFB980, #D87A80, #4A4949"
};
module.exports = exports;
