"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var table = [{
  Display: true,
  DisplayType: "text",
  Label: "模板ID",
  Name: "tempNo",
  Sort: false,
  Align: "center",
  Width: "60",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "短信主题",
  Name: "title",
  Sort: false,
  Align: "center",
  Width: "120",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "tagenum",
  DisplayTypeEnum: _enum.default.smsTemplateType,
  Label: "模板类型",
  Name: "smsTemplateType",
  Sort: false,
  Align: "center",
  Width: "120",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "datetime",
  Label: "创建时间",
  Name: "creationTime",
  Sort: false,
  Align: "center",
  Width: "135",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "创建人",
  Name: "operName",
  Sort: false,
  Align: "center",
  Width: "80",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "短信内容",
  Name: "content",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}];
var _default = {
  table: table
};
exports.default = _default;