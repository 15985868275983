"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var table = [{
  Display: true,
  DisplayType: "text",
  Label: "订单号",
  Name: "orderNo",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "商户",
  Name: "merchantName",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "代理商",
  Name: "agentName",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "tagenum",
  DisplayTypeEnum: _enum.default.merchantOrderType,
  Label: "订单类型",
  Name: "orderType",
  Sort: false,
  Align: "",
  Width: "120",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "tagenum",
  DisplayTypeEnum: _enum.default.merchantOrderSta,
  Label: "订单状态",
  Name: "orderStatus",
  Sort: false,
  Align: "",
  Width: "120",
  IsLink: false,
  NavDisplayProp: ""
},
// {
//     Display: true,
//     DisplayType: "tagenum", 
//     DisplayTypeEnum:  enums.merchantOrderStatusMap,
//     Label: "订单状态",
//     Name: "orderStatus",
//     Sort: false,
//     Align: "",
//     Width: "120",
//     IsLink: false,
//     NavDisplayProp: ""
// },
{
  Display: true,
  DisplayType: "text",
  Label: "总金额",
  Name: "amount",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "优惠金额",
  Name: "discount",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "付款金额",
  Name: "payAmount",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "datetime",
  Label: "创建时间",
  Name: "creationTime",
  Sort: true,
  Align: "center",
  Width: "150",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "datetime",
  Label: "付款时间",
  Name: "payTime",
  Sort: true,
  Align: "center",
  Width: "150",
  IsLink: false,
  NavDisplayProp: ""
}];
var _default = {
  table: table
};
exports.default = _default;