"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _HelpAndService = _interopRequireDefault(require("@/components/HelpAndService"));
var _Steps = _interopRequireDefault(require("@/components/Steps"));
var _stepsData = _interopRequireDefault(require("@/saas/steps-data"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "page-detils",
  components: {
    HelpAndService: _HelpAndService.default,
    Steps: _Steps.default
  },
  created: function created() {
    this.fetchRouterData();
  },
  watch: {
    $route: function $route(to, from) {
      this.fetchRouterData();
      this.showHelp = true;
    }
  },
  data: function data() {
    return {
      items: [],
      showHelp: true
    };
  },
  methods: {
    fetchRouterData: function fetchRouterData() {
      // 初始化菜单激活项
      var name = this.$route.query.name;
      this.items = _stepsData.default[name];
    },
    changeShowHelp: function changeShowHelp(flag) {
      this.showHelp = flag;
    }
  }
};
exports.default = _default;