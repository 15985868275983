"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("@/axios"));
var _product_def = _interopRequireDefault(require("@/saas/table-def/product_def.js"));
var tableDef = _product_def.default.table;
function get(id) {
  return _axios.default.gets("/api/SettingService/product/" + id);
}
function getAll(data) {
  return _axios.default.gets("/api/SettingService/product", data);
}
function deleteById(id) {
  return _axios.default.deletes("/api/SettingService/product/" + id);
}
function update(data) {
  return _axios.default.posts("/api/SettingService/product", data);
}
function create(data) {
  return _axios.default.posts("/api/SettingService/product", data);
}
//基础代码
function getAllCode(data) {
  return _axios.default.gets("/api/BDService/basecode/all", data);
}
function getFeaturesList() {
  return _axios.default.gets("/api/settingService/product/FeaturesList");
}
var _default = {
  get: get,
  getAll: getAll,
  deleteById: deleteById,
  update: update,
  create: create,
  getAllCode: getAllCode,
  getFeaturesList: getFeaturesList,
  tableDef: tableDef
};
exports.default = _default;