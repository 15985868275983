"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _api = _interopRequireDefault(require("../api"));
var _SassUpload = _interopRequireDefault(require("@/saas/components/SassUpload.vue"));
var _config = _interopRequireDefault(require("../../../../../static/config"));
var _Tinymce = _interopRequireDefault(require("@/components/Tinymce"));
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
//表单默认值
var defaultForm = {
  id: null,
  title: '',
  subTitle: '',
  price: 0,
  unit: '年',
  img: '',
  content: '',
  remark: '',
  states: true,
  features: '',
  productType: 0
};
var _default = {
  name: "products_Create",
  components: {
    Pagination: _Pagination.default,
    SaasUplaod: _SassUpload.default,
    config: _config.default,
    Tinymce: _Tinymce.default
  },
  directives: {
    permission: _index.default
  },
  props: [
  //是否显示窗口
  'isShow',
  //窗口关闭事件回调
  'closeCreate',
  //保存成功后回调
  'callback',
  //是否是修改
  'isEdit',
  //修改时传入的id
  'id',
  //标题
  'formTitle'],
  watch: {
    isShow: function isShow(newVal) {
      this.dialogFormVisible = newVal; //对父组件传过来的值进行监听，如果改变也对子组件内部的值进行改变
      this.initData(); //在显示的时候处理数据才能生效,因为显示的值在每次打开时肯定是变动的，
    },
    dialogFormVisible: function dialogFormVisible(newVal) {
      if (!newVal) {
        this.closeCreate(); //内部不关闭自己页面，会造成循环，让外部父组件来关闭
      }
    }
  },
  data: function data() {
    return {
      listQuery: {
        id: '',
        Filter: '',
        Sorting: '',
        SkipCount: 0,
        MaxResultCount: 10,
        payWay: -1,
        filterSupType: 3,
        isLast: this.$store.state.user.settings["BD.ItemClassSetting_EnableLast"]
      },
      //控制弹窗显示
      dialogFormVisible: false,
      //弹窗加载框
      formLoading: false,
      //表单提交校验规则
      rules: {
        title: [{
          required: true,
          message: "请填写标题",
          trigger: "blur"
        }],
        subTitle: [{
          required: true,
          message: "请填写副标题",
          trigger: "blur"
        }],
        price: [{
          required: true,
          message: "请填写零售价",
          trigger: "blur"
        }]
      },
      //表单定义 并赋默认值
      form: Object.assign({}, defaultForm),
      disabled: true,
      checked: true,
      storageApi: _config.default.storage.ip,
      activeName: 'first',
      key: 0,
      featuresList: [],
      featuresListDetail: [],
      featuresListData: null,
      checkList: ['Base'],
      title: "Base",
      productTypes: _enum.default.productType,
      productUnitType: _enum.default.productUnitType
    };
  },
  created: function created() {},
  close: function close() {
    console.info("关闭回调");
  },
  methods: {
    isRepeat: function isRepeat(v) {
      var obj = {};
      for (var i in v) {
        if (obj[v[i]]) {
          return true;
        }
        obj[v[i]] = true;
      }
      return false;
    },
    checkGroup: function checkGroup(e, index) {
      // 因为原生click事件会执行两次，第一次在label标签上，第二次在input标签上，故此处理
      if (e.target.tagName === 'INPUT') return;
      console.info('index', index);
      console.info('配置', this.featuresListData);
      this.featuresListDetail = this.featuresList[index];
      this.featuresListData[index].isChecked = this.featuresListData[index].isChecked == true ? false : true;
      this.title = this.featuresListData[index].key;
      //  var value = this.featuresListData.findIndex((item) => item.key ===  this.featuresListDetail.key);
      //  this.featuresListData[value].isChecked=  true
      // console.info(value)
      // if (value == -1)
      //     this.featuresListData.push( this.featuresListDetail)
      //  else
      //  this.featuresListData[value]= this.featuresListDetail
    },
    handleChecked: function handleChecked(value) {
      console.info("点击了", value);
    },
    search: function search(event, key, index) {
      //    console.info('event',event)
      //    console.info('key',key)
      //    console.info('index',index)
      //    var obj={key:key,value:event+''}
      //    this.featuresList[key]=obj
      //    console.info('跟新后的配置',this.featuresList)
    },
    handleTabClick: function handleTabClick(tab, event) {
      console.log(tab, event);
    },
    /** 显示时初始化数据 */initData: function initData() {
      var _this = this;
      this.featuresListData = null, this.checkList = ['Base'];
      this.title = "Base";
      this.checked = true;
      this.disabled = false;
      this.activeName = 'first';
      this.form = Object.assign({}, defaultForm);
      //如果是编辑状态，远程加载信息
      if (this.isEdit) {
        if (this.id != 0 && this.id != undefined) {
          this.checked = false;
          this.disabled = true;
          this.fetchData(this.id);
        }
      } else {
        _api.default.getFeaturesList().then(function (response) {
          _this.featuresList = response;
          _this.featuresListDetail = response[0];
          _this.featuresListData = response;
          console.info("基础配置", _this.featuresList);
        });
      }
    },
    /** 加载数据 */fetchData: function fetchData(id) {
      var _this2 = this;
      this.formLoading = true;
      _api.default.get(id).then(function (response) {
        _this2.form = response;
        if (_this2.form.features) {
          var array = [];
          _this2.featuresList = JSON.parse(_this2.form.features);
          _this2.featuresListDetail = _this2.featuresList[0];
          _this2.featuresListData = _this2.featuresList;
          _this2.featuresList.forEach(function (element) {
            if (element.isChecked == true) array.push(element.key);else return;
          });
          _this2.checkList = array;
        }
        _this2.key++;
        _this2.formLoading = false;
      });
    },
    /**保存 */save: function save() {
      var _this3 = this;
      // this.$refs.upload.submit();
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this3.form.price == 0) {
            _this3.$notify({
              title: "失败",
              message: "产品零售价不能为0",
              type: "info",
              duration: 2000
            });
            return;
          }
          // var featuresList=[]
          // this.checkList.forEach(element => {
          //    var item= this.featuresListData.filter(x=>x.key==element)
          //    featuresList.push(item[0])
          // });
          console.info("最终保存的值", _this3.featuresListData);
          _this3.form.features = _this3.featuresListData ? JSON.stringify(_this3.featuresListData) : null;
          if (_this3.isEdit) {
            _this3.formLoading = true;
            _api.default.update(_this3.form).then(function (response) {
              console.info(response);
              _this3.formLoading = false;
              _this3.$notify({
                title: "成功",
                message: "更新成功",
                type: "success",
                duration: 2000
              });
              _this3.dialogFormVisible = false;
              _this3.callback();
            }).catch(function () {
              _this3.formLoading = false;
            });
          } else {
            _this3.formLoading = true;
            _api.default.create(_this3.form).then(function (response) {
              _this3.formLoading = false;
              _this3.key++;
              _this3.$notify({
                title: "成功",
                message: "新增成功",
                type: "success",
                duration: 2000
              });
              if (_this3.checked == true) {
                _this3.$refs.form.clearValidate();
                _this3.form = Object.assign({}, defaultForm);
                _this3.callback();
              } else {
                _this3.dialogFormVisible = false;
                _this3.callback();
              }
            }).catch(function () {
              _this3.formLoading = false;
            });
          }
        }
      });
    },
    /**取消按钮 */cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
      this.form = Object.assign({}, defaultForm);
    },
    uploadIcon: function uploadIcon(val) {
      this.form.img = val;
    },
    beforeUpload: function beforeUpload(file) {
      var isLt2M = true;
      isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.loading = false;
        this.$message.error("上传文件大小不能超过 100MB!");
      }
      return isLt2M;
    },
    handleSuccess: function handleSuccess(response, file, fileList) {
      console.log("fff" + file.size / 1024 / 1024 + "ddd" + JSON.stringify(file.url));
      this.$notify({
        title: "成功",
        message: "更新成功",
        type: "success",
        duration: 2000
      });
      this.dialogFormVisible = false;
      this.getList();
    },
    // 监听上传失败
    handleError: function handleError(e, file, fileList) {
      this.$notify({
        title: e,
        type: "error",
        duration: 4000
      });
      this.loading = false;
    },
    onUploadChange: function onUploadChange(file) {
      this.form.url = URL.createObjectURL(file.raw);
      this.form.fileLength = (file.size / 1024 / 1024).toFixed(2);
    }
  }
};
exports.default = _default;