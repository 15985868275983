"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _components = require("./components");
//
//
//
//
//
//
var _default = {
  name: 'Layout',
  components: {
    AppMain: _components.AppMain
  },
  methods: {}
};
exports.default = _default;