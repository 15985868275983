var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "div",
            { staticClass: "head-container" },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    model: _vm.listQuery,
                    "label-width": "100px",
                    size: "mini",
                  },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { size: "mini" } },
                    [
                      _c(
                        "el-col",
                        { attrs: { size: "mini", span: 8 } },
                        [
                          _c("SaasButtonGroup", {
                            attrs: {
                              BtnType: "list",
                              Query: _vm.Search,
                              Create: _vm.Create,
                              Update: _vm.Update,
                              CreatePpermission: ["Saas.Agent.Create"],
                              UpdatePermission: [""],
                              DeletePermission: [""],
                              PrintPermission: [""],
                              ExportPermission: [""],
                              SettingPermission: [""],
                            },
                          }),
                          _c(
                            "el-button",
                            {
                              staticClass: "filter-item",
                              staticStyle: { "margin-left": "20px" },
                              attrs: { size: "mini", type: "info" },
                              on: {
                                click: function ($event) {
                                  _vm.dialogVisible = true
                                },
                              },
                            },
                            [_vm._v("扫码注册")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "search-group" },
                    [
                      _c(
                        "el-row",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "0" } },
                            [
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { size: "mini" },
                                  on: { change: _vm.getList },
                                  model: {
                                    value: _vm.listQuery.approve,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "approve", $$v)
                                    },
                                    expression: "listQuery.approve",
                                  },
                                },
                                [
                                  _c(
                                    "el-radio-button",
                                    { attrs: { value: "", label: "0" } },
                                    [_vm._v("未审核")]
                                  ),
                                  _c(
                                    "el-radio-button",
                                    { attrs: { value: "", label: "1" } },
                                    [_vm._v("已审核")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "代理商等级" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: {
                                    clearable: "",
                                    placeholder: "请选择",
                                  },
                                  model: {
                                    value: _vm.listQuery.agentType,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "agentType", $$v)
                                    },
                                    expression: "listQuery.agentType",
                                  },
                                },
                                _vm._l(_vm.agentType, function (item) {
                                  return _c("el-option", {
                                    key: item.value,
                                    attrs: {
                                      label: item.text,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "地区" } },
                            [
                              _c("Area", {
                                attrs: { cateProps: { checkStrictly: true } },
                                model: {
                                  value: _vm.areaData,
                                  callback: function ($$v) {
                                    _vm.areaData = $$v
                                  },
                                  expression: "areaData",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { "label-width": "10px" } },
                            [
                              _c(
                                "el-input",
                                {
                                  staticClass: "filter-item",
                                  attrs: {
                                    size: "mini",
                                    clearable: "",
                                    placeholder: "请输入名称/手机号",
                                  },
                                  nativeOn: {
                                    keyup: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.Search($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.listQuery.filter,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "filter", $$v)
                                    },
                                    expression: "listQuery.filter",
                                  },
                                },
                                [
                                  _c("el-button", {
                                    attrs: {
                                      slot: "append",
                                      icon: "el-icon-search",
                                    },
                                    on: { click: _vm.Search },
                                    slot: "append",
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-dialog",
                            {
                              attrs: {
                                visible: _vm.dialogVisible,
                                width: "20%",
                                center: "",
                              },
                              on: {
                                "update:visible": function ($event) {
                                  _vm.dialogVisible = $event
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: { height: "80px" },
                                  attrs: { slot: "title" },
                                  slot: "title",
                                },
                                [
                                  _c("el-avatar", {
                                    attrs: {
                                      src: require("@/assets/images/logo.png"),
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        color: "#f25b13",
                                        "margin-top": "10px",
                                        "font-size": "14px",
                                        "font-weight": "bold",
                                      },
                                    },
                                    [_vm._v(" 注册代理商 ")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticStyle: { "text-align": "center" } },
                                [
                                  _c("el-image", {
                                    staticStyle: {
                                      width: "150px",
                                      height: "150px",
                                    },
                                    attrs: { src: _vm.imgsrc, fit: "fit" },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "dialog-footer",
                                  attrs: { slot: "footer" },
                                  slot: "footer",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "font-size": "16px" } },
                                    [_vm._v("温馨提示")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        "font-size": "13px",
                                        "margin-top": "10px",
                                      },
                                    },
                                    [_vm._v(" 请将资料填写完整。 ")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-radio-group",
            {
              staticStyle: { "margin-top": "30px" },
              model: {
                value: _vm.tabPosition,
                callback: function ($$v) {
                  _vm.tabPosition = $$v
                },
                expression: "tabPosition",
              },
            },
            [
              _c("el-radio-button", { attrs: { label: "first" } }, [
                _c("i", { staticClass: "el-icon-menu" }),
              ]),
              _c("el-radio-button", { attrs: { label: "second" } }, [
                _c("i", { staticClass: "el-icon-s-unfold" }),
              ]),
            ],
            1
          ),
          _vm.tabPosition == "first"
            ? _c(
                "el-row",
                { staticClass: "chart" },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    _vm._l(_vm.list, function (o, index) {
                      return _c(
                        "el-col",
                        { key: index, attrs: { sm: 12, md: 8, lg: 6 } },
                        [
                          _c("el-row", { staticClass: "grid-content" }, [
                            _c(
                              "div",
                              { staticClass: "code" },
                              [
                                _c("label", [_vm._v(_vm._s(o.agentCode))]),
                                _c(
                                  "el-tooltip",
                                  {
                                    staticClass: "more",
                                    attrs: { placement: "bottom" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        attrs: { slot: "content" },
                                        slot: "content",
                                      },
                                      [
                                        _c(
                                          "el-row",
                                          [
                                            _c(
                                              "el-button",
                                              {
                                                staticClass: "tip-but",
                                                attrs: { type: "text" },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.Update(o)
                                                  },
                                                },
                                              },
                                              [_vm._v("详情")]
                                            ),
                                          ],
                                          1
                                        ),
                                        o.approve == 0
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "tip-but",
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.approve(o)
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("审核")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        o.approve == 0
                                          ? _c(
                                              "el-row",
                                              [
                                                _c(
                                                  "el-button",
                                                  {
                                                    staticClass: "tip-but",
                                                    attrs: { type: "text" },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.Delete(o)
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("删除")]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-button",
                                      { attrs: { type: "text" } },
                                      [_c("i", { staticClass: "el-icon-more" })]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("div", { staticClass: "company" }, [
                              _c("div", { staticClass: "company-logo" }, [
                                _c("img", {
                                  attrs: {
                                    src: o.companyLogo
                                      ? o.companyLogo
                                      : require("@/assets/images/logo.png"),
                                  },
                                }),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("findEnumKey")(
                                        o.agentType,
                                        _vm.agentType
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "company-info" }, [
                                _c("div", { staticClass: "company-title" }, [
                                  _vm._v(_vm._s(o.companyName)),
                                ]),
                                _c("div", { staticClass: "company-name" }, [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "lab_contacts",
                                      attrs: { title: o.contacts },
                                    },
                                    [_vm._v(_vm._s(o.contacts))]
                                  ),
                                  _c("span", [
                                    _vm._v("余额：" + _vm._s(o.balance)),
                                  ]),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "company-amount" },
                                  [
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c("label", [_vm._v("商户数量")]),
                                      _c("span", [
                                        _vm._v(_vm._s(o.businessQty)),
                                      ]),
                                    ]),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c("label", [_vm._v("日均笔数")]),
                                      _c("span", [
                                        _vm._v(_vm._s(o.daysSaleQty)),
                                      ]),
                                    ]),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c("label", [_vm._v("日均全额")]),
                                      _c("span", [
                                        _vm._v(_vm._s(o.daysSaleAmt)),
                                      ]),
                                    ]),
                                    _c("el-divider"),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c("label", [_vm._v("总站点数")]),
                                      _c("span", [_vm._v(_vm._s(o.pointQty))]),
                                    ]),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c("label", [_vm._v("已用点数")]),
                                      _c("span", [
                                        _vm._v(_vm._s(o.pointUseQty)),
                                      ]),
                                    ]),
                                    _c("el-col", { attrs: { span: 8 } }, [
                                      _c("label", [_vm._v("剩余点数")]),
                                      _c("span", [
                                        _vm._v(_vm._s(o.pointRemainQty)),
                                      ]),
                                    ]),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                            _c("div", { staticClass: "person" }, [
                              _c("div", [
                                _c("label", [_vm._v("业务员：")]),
                                _c("span", [_vm._v(_vm._s(o.salesManName))]),
                              ]),
                              _c("div", [
                                _c("label", [
                                  _vm._v("电" + _vm._s("\u3000") + "话："),
                                ]),
                                _c("span", [_vm._v(_vm._s(o.tel))]),
                              ]),
                              _c("div", { staticClass: "address" }, [
                                _c("label", [
                                  _vm._v("地" + _vm._s("\u3000") + "址："),
                                ]),
                                _c("span", [_vm._v(_vm._s(o.companyAddress))]),
                              ]),
                            ]),
                            _c(
                              "div",
                              { staticClass: "handle" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      disabled: !o.approve,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addBusiness(o)
                                      },
                                    },
                                  },
                                  [_vm._v("添加商户")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      disabled: !o.approve,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showBusiness(o)
                                      },
                                    },
                                  },
                                  [_vm._v("查看商户")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      disabled: !o.approve,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.give(o)
                                      },
                                    },
                                  },
                                  [_vm._v("赠送")]
                                ),
                                _c(
                                  "span",
                                  { staticClass: "right" },
                                  [
                                    _c("el-switch", {
                                      on: {
                                        change: function ($event) {
                                          return _vm.changeStatus(o)
                                        },
                                      },
                                      model: {
                                        value: o.status,
                                        callback: function ($$v) {
                                          _vm.$set(o, "status", $$v)
                                        },
                                        expression: "o.status",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.tabPosition == "second"
            ? _c(
                "el-row",
                [
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.listLoading,
                          expression: "listLoading",
                        },
                      ],
                      ref: "multipleTable",
                      attrs: {
                        stripe: "",
                        "highlight-current-row": "",
                        "row-key": "id",
                        data: _vm.list,
                        size: "small",
                      },
                      on: {
                        "sort-change": _vm.sortChange,
                        "selection-change": _vm.handleSelectionChange,
                        "row-click": _vm.handleRowClick,
                      },
                    },
                    [
                      _c("MyElColumn", {
                        attrs: { tableDef: _vm.tableDef, Update: _vm.Update },
                      }),
                      _c("el-table-column", {
                        attrs: {
                          fixed: "right",
                          align: "center",
                          label: "操作",
                          width: "320",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (scope) {
                                return [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        disabled: !scope.row.approve,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addBusiness(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("添加商户")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        disabled: !scope.row.approve,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showBusiness(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("查看商户")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: {
                                        type: "text",
                                        disabled: !scope.row.approve,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.give(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("赠送")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "text" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Update(scope.row)
                                        },
                                      },
                                    },
                                    [_vm._v("详情")]
                                  ),
                                  !scope.row.approve
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.approve(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("审核")]
                                      )
                                    : _vm._e(),
                                  !scope.row.approve
                                    ? _c(
                                        "el-button",
                                        {
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.Delete(scope.row)
                                            },
                                          },
                                        },
                                        [_vm._v("删除")]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1871511118
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.totalCount > 0,
                expression: "totalCount > 0",
              },
            ],
            attrs: {
              total: _vm.totalCount,
              page: _vm.page,
              limit: _vm.listQuery.MaxResultCount,
            },
            on: {
              "update:page": function ($event) {
                _vm.page = $event
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.listQuery, "MaxResultCount", $event)
              },
              pagination: _vm.getList,
            },
          }),
        ],
        1
      ),
      _c("MyCreate", {
        ref: "editData",
        attrs: {
          isEdit: _vm.isEdit,
          id: _vm.editId,
          isShow: _vm.dialogFormVisible,
          closeCreate: _vm.handleCloseCreate,
          callback: _vm.handleCreateCallback,
          formTitle: _vm.formTitle,
        },
      }),
      _c("MyMerchantCreate", {
        attrs: {
          isEdit: false,
          isShow: _vm.businessDialogVisible,
          closeCreate: _vm.businessDialogClose,
          callback: _vm.businessDialogCreateCallback,
          formTitle: "新增商户",
        },
      }),
      _c("MyGive", {
        attrs: {
          isEdit: false,
          isShow: _vm.giveDialogVisible,
          closeCreate: _vm.giveDialogClose,
          callback: _vm.giveDialogCreateCallback,
          formTitle: "代理商赠送",
          data: _vm.agentInfo,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }