"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _AgreeMent = _interopRequireDefault(require("./AgreeMent.vue"));
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var _api = _interopRequireDefault(require("../api"));
var _Area = _interopRequireDefault(require("@/components/Area"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//表单默认值
var defaultForm = {
  shopName: '',
  contacts: '',
  phone: '',
  email: '',
  agent: '',
  shopType: '',
  province: '',
  city: '',
  county: '',
  address: '',
  stopDate: '',
  loginNum: 0,
  branchNum: 0,
  areas: [],
  memo: ''
};
var _default = {
  name: 'Test1Create',
  components: {
    AgreeMent: _AgreeMent.default,
    Area: _Area.default
  },
  directives: {
    permission: _index.default
  },
  props: {
    //是否显示窗口
    isShow: {
      type: Boolean
    },
    //窗口关闭事件回调
    closeCreate: {
      type: Function
    },
    //保存成功后回调
    callback: {
      type: Function
    },
    //是否是修改
    isEdit: {
      type: Boolean
    },
    //修改时传入的id
    id: {
      type: String
    },
    formTitle: {
      type: String
    },
    isLoginShow: {
      type: Boolean,
      default: true
    }
  },
  //   props: [
  //     //是否显示窗口
  //     'isShow',
  //     //窗口关闭事件回调
  //     'closeCreate',
  //     //保存成功后回调
  //     'callback',
  //     //是否是修改
  //     'isEdit',
  //     //修改时传入的id
  //     'id',
  //     'formTitle'
  //   ],
  watch: {
    isShow: function isShow(newVal) {
      this.dialogFormVisible = newVal; //对父组件传过来的值进行监听，如果改变也对子组件内部的值进行改变
      this.initData();
    },
    dialogFormVisible: function dialogFormVisible(newVal) {
      if (!newVal) {
        this.closeCreate(); //内部不关闭自己页面，会造成循环，让外部父组件来关闭
      }
    },

    'form.areas': function formAreas(val) {
      if (val && Array.isArray(val)) {
        if (val[0] != undefined) {
          this.form.province = val[0];
        } else {
          this.form.province = null;
        }
        if (val[1] != undefined) {
          this.form.city = val[1];
        } else {
          this.form.city = null;
        }
        if (val[2] != undefined) {
          this.form.county = val[2];
        } else {
          this.form.county = null;
        }
      }
    }
  },
  data: function data() {
    return {
      //控制弹窗显示
      dialogFormVisible: false,
      //弹窗加载框
      formLoading: false,
      isTop: true,
      //表单提交校验规则
      rules: {
        agent: [{
          required: true,
          message: '请选择代理商',
          trigger: 'change'
        }],
        shopName: [{
          required: true,
          message: '请输入商户名称',
          trigger: 'blur'
        }],
        // subName: [
        //   { required: true, message: '请输入商户简称', trigger: 'blur' }
        // ],
        shopType: [{
          required: true,
          message: '请选择店铺类型',
          trigger: 'change'
        }],
        contacts: [{
          required: true,
          message: '请输入联系人',
          trigger: 'blur'
        }],
        areas: [{
          type: 'array',
          required: true,
          message: '请选择所在区域',
          trigger: 'change'
        }],
        phone: [{
          required: true,
          message: '请输入手机号码',
          trigger: 'blur'
        }, {
          pattern: /^1[3456789]\d{9}$/,
          message: '请输入正确的手机号码',
          trigger: 'blur'
        }],
        email: [{
          required: true,
          message: '请输入邮箱',
          trigger: 'blur'
        }, {
          pattern: /^[0-9a-zA-Z]*@{1}[0-9a-zA-Z]+\.(com|net|cn|com\.cn)$/,
          message: '请输入正确的邮箱',
          trigger: 'blur'
        }],
        loginNum: [{
          required: true,
          message: '请输入点数',
          trigger: 'blur'
        }],
        branchNum: [{
          required: true,
          message: '请输入门店数',
          trigger: 'blur'
        }]
      },
      //表单定义 并赋默认值
      form: Object.assign({}, defaultForm),
      options: _enum.default.shopType,
      agent: null
    };
  },
  created: function created() {
    var _this = this;
    _api.default.select().then(function (res) {
      _this.agent = res;
    });
  },
  methods: {
    /** 显示时初始化数据 */initData: function initData() {
      this.form = Object.assign({}, defaultForm);
      //如果是编辑状态，远程加载信息
      if (this.isEdit) {
        if (this.id != 0 && this.id != undefined) {
          this.fetchData(this.id);
        }
      }
    },
    /** 加载数据 */fetchData: function fetchData(id) {
      var _this2 = this;
      _api.default.get(id).then(function (response) {
        _this2.form = response;
      });
    },
    /**取消按钮 */cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
      this.form = Object.assign({}, defaultForm);
    },
    /**注册 */handleRegister: function handleRegister() {
      var _this3 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this3.formLoading = true;
          _this3.$axios.posts('/api/saas/register', _this3.form).then(function (response) {
            _this3.formLoading = false;
            _this3.$notify({
              title: '成功',
              message: '注册成功',
              type: 'success',
              duration: 2000
            });
            _this3.dialogFormVisible = false;
            _this3.callback();
          }).catch(function () {
            _this3.formLoading = false;
          });
        }
      });
    } // toPY(val) {
    //   this.form.subName = PY.chineseToPinYin(val).substring(0, 20)
    // }
  }
};
exports.default = _default;