"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/web.dom.iterable");
var _jquery = _interopRequireDefault(require("jquery"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: ['tableHeader', 'headerQuery', 'tableData', 'title'],
  data: function data() {
    return {
      user: this.$store.state.user,
      isShow: false,
      sumRow: []
    };
  },
  watch: {
    tableData: function tableData(val) {
      console.log(val, this.tableHeader, 9999);
      this.getSummaries();
    },
    tableHeader: function tableHeader(val) {
      this.getSummaries();
      //console.log('加载打印',this.tableData, this.headerQuery, val);
    },
    isShow: function isShow(newVal) {
      //console.log('加载打印',this.tableData, this.tableHeader, newVal);
    }
  },
  created: function created() {},
  methods: {
    //页面打印，父组件调用
    printView: function printView() {
      // console.log('加载打印',this.tableData, this.tableHeader);
      //console.log("打印", this.$refs.print)
      (0, _jquery.default)("#content").print({
        iframe: true,
        // 是否使用一个iframe来替代打印表单的弹出窗口，true为在本页面进行打印，false就是说新开一个页面打印，默认为true
        globalStyles: true,
        // 是否包含父文档的样式，默认为true
        debug: false,
        importCSS: true,
        printContainer: true,
        operaSupport: false
      });
    },
    getSummaries: function getSummaries() {
      var columns = this.tableHeader.filter(function (x) {
        return x.isPrint;
      });
      var data = this.tableData;
      var sums = [];
      var saleAmt = 0;
      var saleProfitAmt = 0;
      var isSum = false; //是否使用合计功能
      columns.forEach(function (column, index) {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        var values = data.map(function (item) {
          return Number(item[column.Name]);
        });
        if (column.IsSum) {
          isSum = isSum || column.IsSum;
          sums[index] = values.reduce(function (prev, curr) {
            var value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          switch (column.Name) {
            case "saleAmt":
              saleAmt += sums[index];
              break;
            case "saleProfitAmt":
              saleProfitAmt += sums[index];
              break;
            default:
              break;
          }
          sums[index] = sums[index].toFixed(2);
        } else {
          sums[index] = '';
        }
        if (column.Name == "saleProfitRate") {
          sums[index] = saleAmt > 0 ? (saleProfitAmt / saleAmt * 100).toFixed(2) + "%" : 0;
        }
      });
      if (isSum && sums) {
        this.sumRow = sums;
      }
    }
  }
};
exports.default = _default;