"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _data = _interopRequireDefault(require("./data.json"));
var _cascaderOption = require("../../utils/cascaderOption");
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "AdmDiv",
  props: {
    value: Array,
    cateProps: "",
    width: ""
  },
  data: function data() {
    return {
      options: (0, _cascaderOption.translateOptions)(_data.default),
      cascaderValue: this.value ? this.value : []
    };
  },
  watch: {
    value: function value(val) {
      if (val) {
        this.cascaderValue = val;
      } else {
        this.cascaderValue = "";
      }
    }
  },
  created: function created() {},
  methods: {
    valueChanged: function valueChanged(e) {
      this.$emit("input", this.cascaderValue);
    }
  }
};
exports.default = _default;