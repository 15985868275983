"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: "Log",
  components: {
    Pagination: _Pagination.default
  },
  data: function data() {
    return {
      list: null,
      totalCount: 0,
      listLoading: true,
      listQuery: {
        Url: null,
        UserName: null,
        ApplicationName: null,
        CorrelationId: null,
        HttpMethod: null,
        HttpStatusCode: null,
        MaxExecutionDuration: null,
        MinExecutionDuration: null,
        HasException: null,
        Sorting: null,
        SkipCount: 0,
        MaxResultCount: 10
      },
      page: 1
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      this.listQuery.SkipCount = (this.page - 1) * this.listQuery.MaxResultCount;
      this.$axios.gets("/api/SettingService/auditLogging/all", this.listQuery).then(function (response) {
        response.items.forEach(function (element) {
          if (!element.lastModificationTime) {
            element.lastModificationTime = element.creationTime;
          }
        });
        _this.list = response.items;
        _this.totalCount = response.totalCount;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.page = 1;
      this.getList();
    },
    sortChange: function sortChange(data) {
      var prop = data.prop,
        order = data.order;
      if (!prop || !order) {
        this.handleFilter();
        return;
      }
      this.listQuery.Sorting = prop + " " + order;
      this.handleFilter();
    }
  }
};
exports.default = _default;