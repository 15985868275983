"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var table = [{
  Display: true,
  DisplayType: "text",
  Label: "小程序名称",
  Name: "name",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "AppId",
  Name: "appId",
  Sort: false,
  Align: "center",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "AppSecret",
  Name: "appSecret",
  Sort: false,
  Align: "center",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "qrcodeUrl",
  Name: "qrcodeUrl",
  Sort: false,
  Align: "center",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "bool",
  Label: "状态",
  Name: "status",
  Sort: false,
  Align: "center",
  Width: "80",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "bool",
  Label: "默认",
  Name: "isDefault",
  Sort: false,
  Align: "center",
  Width: "80",
  IsLink: false,
  NavDisplayProp: ""
}];
var _default = {
  table: table
};
exports.default = _default;