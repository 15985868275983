"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("@/axios"));
var _pos_auth_def = _interopRequireDefault(require("@/saas/table-def/pos_auth_def"));
var tableDef = _pos_auth_def.default.table;
function get(id) {
  return _axios.default.gets("/api/BDService/bdOperAuth/" + id);
}
function getAll(data) {
  return _axios.default.gets("/api/BDService/bdOperAuth", data);
}
function deleteById(data) {
  return _axios.default.posts("/api/BDService/bdOperAuth/delete", data);
}
function update(data) {
  return _axios.default.posts("/api/BDService/bdOperAuth", data);
}
function create(data) {
  return _axios.default.posts("/api/BDService/bdOperAuth", data);
}
var _default = {
  get: get,
  getAll: getAll,
  deleteById: deleteById,
  update: update,
  create: create,
  tableDef: tableDef
};
exports.default = _default;