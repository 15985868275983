var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-steps",
    {
      staticStyle: { "margin-top": "40px" },
      attrs: { "align-center": "", space: 100 },
    },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        "el-step",
        {
          key: index,
          nativeOn: {
            click: function ($event) {
              return _vm.to(item.to)
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("el-image", {
                staticClass: "step-image",
                attrs: { src: item.imgUrl },
              }),
              _c("div", [_vm._v(_vm._s(item.title))]),
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: index == _vm.items.length - 1,
                    expression: "index == items.length - 1",
                  },
                ],
                staticClass: "jiantou",
              }),
            ],
            1
          ),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }