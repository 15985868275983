"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _SaasLineChart = _interopRequireDefault(require("@/saas/components/SaasLineChart"));
var _SaasFormItemSelect = _interopRequireDefault(require("@/saas/components/SaasFormItemSelect.vue"));
var _HelpAndService = _interopRequireDefault(require("@/components/HelpAndService"));
var _api = _interopRequireDefault(require("./api"));
//导入本功能的api接口
var _default = {
  name: "dashboard",
  components: {
    LineChart: _SaasLineChart.default,
    PageSelectFormItem: _SaasFormItemSelect.default,
    HelpAndService: _HelpAndService.default
  },
  data: function data() {
    return {
      contentWidth: "calc(100% - 256px)",
      branchId: '',
      lineChartData: {},
      lineChartType: "day",
      selectChartTab: "2",
      type: 2,
      saleData: {
        saleAmt: 0,
        totalInAmt: 0,
        totalOutAmt: 0,
        saleNum: 0,
        customerPrice: 0,
        rechargeAmt: 0
      },
      vipData: {
        memberNums: 0,
        residualNums: 0,
        residualAmt: 0,
        monthNums: 0,
        yearNums: 0,
        activeNums: 0
      },
      productDescData: [],
      productAscData: [],
      showHelp: true
    };
  },
  watch: {
    lineChartType: function lineChartType(newVal) {
      // this.setLinChartData(this.selectChartTab, newVal, this.branchId);
      // this.getSale(newVal, this.branchId);
    }
  },
  created: function created() {
    // this.setLinChartData(this.selectChartTab, this.lineChartType, this.branchId);
    // this.getSale(this.lineChartType, this.branchId);
    // this.getVip(this.branchId);
    // this.getProductDesc(this.branchId);
    // this.getProductAsc(this.branchId);
  },
  methods: {
    selectbranch: function selectbranch() {
      this.setLinChartData(this.selectChartTab, this.lineChartType, this.branchId);
      this.getSale(this.lineChartType, this.branchId);
      this.getVip(this.branchId);
      this.getProduct(this.branchId);
    },
    getSale: function getSale(type, branchId) {
      var _this = this;
      _api.default.getSaleData(type, branchId).then(function (response) {
        if (response != null) {
          _this.saleData = response;
        }
      });
    },
    getVip: function getVip(branchId) {
      var _this2 = this;
      _api.default.getVipData(branchId).then(function (response) {
        if (response != null) {
          _this2.vipData = response;
        }
      });
    },
    getProductDesc: function getProductDesc(branchId) {
      var _this3 = this;
      var param = {
        branchId: branchId
      };
      _api.default.getProductData(param).then(function (response) {
        if (response != null) {
          _this3.productDescData = response;
        }
      });
    },
    getProductAsc: function getProductAsc(branchId) {
      var _this4 = this;
      var param = {
        branchId: branchId,
        Sorting: "OrderQty asc"
      };
      _api.default.getProductData(param).then(function (response) {
        if (response != null) {
          _this4.productAscData = response;
        }
      });
    },
    setLinChartData: function setLinChartData(type, lineChartType, branchId) {
      var _this5 = this;
      if (type != undefined && type != null && type != "") {
        _api.default.getLinChartData(type, lineChartType, branchId).then(function (response) {
          console.log(response);
          if (response != null) {
            _this5.lineChartData = response;
          }
        }).catch(function (e) {
          _this5.lineChartData = _api.default.lineChartData;
        });
      }
    },
    changeShowHelp: function changeShowHelp(flag) {
      this.showHelp = flag;
      this.contentWidth = "100%";
    }
  }
};
exports.default = _default;