"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.exportFun = exportFun;
function exportFun(url, filter, title) {
  var _this = this;
  this.$axios.uploadFileRequest(url, filter, title).then(function (res) {
    var blob = new Blob([res], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    var fileName = title + ".xls";
    if ("download" in document.createElement("a")) {
      var elink = document.createElement("a");
      elink.download = fileName;
      elink.style.display = "none";
      elink.href = URL.createObjectURL(blob);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href);
      document.body.removeChild(elink);
    } else {
      navigator.msSaveBlob(blob.fileName);
    }
  }).catch(function () {
    _this.$message({
      type: "info",
      message: "导出异常,请检查设置"
    });
  });
}