"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
// 类别
var table = [{
  Display: true,
  DisplayType: "text",
  Label: "权限名称",
  Name: "authName",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "bool",
  Label: "系统默认",
  Name: "sysDef",
  Sort: false,
  Align: "center",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "排序（越大越靠前）",
  Name: "weight",
  Sort: false,
  Align: "center",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}];
var _default = {
  table: table
};
exports.default = _default;