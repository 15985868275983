"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _config = _interopRequireDefault(require("../../../static/config"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  props: {
    callback: Function,
    imgUrl: String
    // pdisplay:String
  },

  watch: {
    imgUrl: function imgUrl(newVal) {
      console.log(newVal);
      this.imageUrl = newVal;
    } // pdisplay(newVal){
    //    this.display=newVal;
    // }
  },
  data: function data() {
    return {
      storageApi: _config.default.storage.ip,
      imageUrl: ''
    };
  },
  created: function created() {
    //初始化空值
    if (this.imgUrl) {
      this.imageUrl = this.imgUrl;
    } else {
      this.imageUrl = '';
    }
  },
  methods: {
    handleAvatarSuccess: function handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      //this.callback(this.storageApi+"/Images/"+ res);
      this.callback(res);
    },
    beforeAvatarUpload: function beforeAvatarUpload(file) {
      var isJPG = 'image/jpeg;image/png'.indexOf(file.type) > -1;
      var isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 、png格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    }
  }
};
exports.default = _default;