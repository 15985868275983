"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
// 商品
var table = [{
  Display: true,
  DisplayType: 'text',
  Label: '商户名称',
  Name: 'merchantName',
  Sort: false,
  Align: '',
  Width: '',
  IsLink: false,
  NavDisplayProp: ''
}, {
  Display: true,
  DisplayType: 'text',
  Label: '所属代理商',
  Name: 'agentName',
  Sort: false,
  Align: '',
  Width: '',
  IsLink: false,
  NavDisplayProp: ''
}, {
  Display: true,
  DisplayType: 'datetime',
  Label: '现到期时间',
  Name: 'stopTime',
  Sort: false,
  Align: 'center',
  Width: '180',
  IsLink: false,
  NavDisplayProp: ''
}, {
  Display: true,
  DisplayType: 'text',
  Label: '申请延期天数',
  Name: 'delayDay',
  Sort: false,
  Align: 'center',
  Width: '100',
  IsLink: false,
  IsSum: true,
  NavDisplayProp: ''
}, {
  Display: true,
  DisplayType: 'datetime',
  Label: '申请到期时间',
  Name: 'delayStopTime',
  Sort: false,
  Align: 'center',
  Width: '180',
  IsLink: false,
  NavDisplayProp: ''
}, {
  Display: true,
  DisplayType: 'tagenum',
  DisplayTypeEnum: _enum.default.ApproveFlagMap,
  Label: '审核状态',
  Name: 'approve',
  Sort: false,
  Align: 'center',
  Width: '80',
  IsLink: false,
  NavDisplayProp: ''
}, {
  Display: true,
  DisplayType: 'datetime',
  Label: '创建日期',
  Name: 'creationTime',
  Sort: false,
  Align: 'center',
  Width: '180',
  IsLink: false,
  NavDisplayProp: ''
}, {
  Display: true,
  DisplayType: 'text',
  Label: '申请原因',
  Name: 'delayReason',
  Sort: false,
  Align: '',
  Width: '',
  IsLink: false,
  IsSum: true,
  NavDisplayProp: ''
}];
var _default = {
  table: table
};
exports.default = _default;