"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var _api = _interopRequireDefault(require("../api"));
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
//表单默认值
var defaultForm = {
  id: null,
  name: "",
  tel: "",
  companyName: "",
  status: true
};
var _default = {
  name: "applicationCreate",
  components: {
    Pagination: _Pagination.default
  },
  props: [
  //是否显示窗口
  'isShow',
  //窗口关闭事件回调
  'closeCreate',
  //保存成功后回调
  'callback',
  //是否是修改
  'isEdit',
  //修改时传入的id
  'id',
  //标题
  'formTitle'],
  watch: {
    isShow: function isShow(newVal) {
      this.dialogFormVisible = newVal; //对父组件传过来的值进行监听，如果改变也对子组件内部的值进行改变
      this.initData(); //在显示的时候处理数据才能生效,因为显示的值在每次打开时肯定是变动的，
    },
    dialogFormVisible: function dialogFormVisible(newVal) {
      if (!newVal) {
        this.closeCreate(); //内部不关闭自己页面，会造成循环，让外部父组件来关闭
      }
    }
  },
  data: function data() {
    return {
      //控制弹窗显示
      dialogFormVisible: false,
      //弹窗加载框
      formLoading: false,
      isTop: true,
      //表单提交校验规则
      rules: {
        name: [{
          required: true,
          message: "请输入姓名",
          trigger: "blur"
        }],
        tel: [{
          required: true,
          pattern: /^1[3456789]\d{9}$/,
          message: "请输入正确的手机号",
          trigger: "blur"
        }],
        companyName: [{
          required: true,
          message: "请输入公司名称",
          trigger: "blur"
        }]
      },
      //表单定义 并赋默认值
      form: Object.assign({}, defaultForm)
    };
  },
  methods: {
    /** 显示时初始化数据 */initData: function initData() {
      this.checked = true;
      this.disabled = false;
      this.form = Object.assign({}, defaultForm);
      //如果是编辑状态，远程加载信息
      if (this.isEdit) {
        if (this.id != 0 && this.id != undefined) {
          this.checked = false;
          this.disabled = true;
          this.fetchData(this.id);
        }
      }
    },
    /** 加载数据 */fetchData: function fetchData(id) {
      var _this = this;
      // this.getOrgNodes(id);
      this.formLoading = true;
      _api.default.get(id).then(function (response) {
        _this.form = response;
        _this.formLoading = false;
      });
    },
    /**保存 */save: function save() {
      var _this2 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this2.isEdit) {
            _this2.formLoading = true;
            _api.default.update(_this2.form).then(function (response) {
              console.info(response);
              _this2.formLoading = false;
              _this2.$notify({
                title: "成功",
                message: "更新成功",
                type: "success",
                duration: 2000
              });
              _this2.dialogFormVisible = false;
              _this2.callback();
            }).catch(function () {
              _this2.formLoading = false;
            });
          } else {
            _this2.formLoading = true;
            _api.default.create(_this2.form).then(function (response) {
              _this2.formLoading = false;
              _this2.$notify({
                title: "成功",
                message: "新增成功",
                type: "success",
                duration: 2000
              });
              _this2.dialogFormVisible = false;
              _this2.callback();
            }).catch(function () {
              _this2.formLoading = false;
            });
          }
        }
      });
    },
    /**取消按钮 */cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
      this.form = Object.assign({}, defaultForm);
    }
  }
};
exports.default = _default;