var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.dialogFormVisible,
        title: _vm.formTitle,
        width: "400px",
        "append-to-body": true,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: function ($event) {
          return _vm.cancel()
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "form",
          staticClass: "form",
          attrs: { inline: true, size: "small", "label-width": "80px" },
        },
        [
          _c(
            "el-descriptions",
            { staticClass: "margin-top", attrs: { column: 1, border: "" } },
            [
              _c(
                "el-descriptions-item",
                { attrs: { label: "应用", labelStyle: "width: 80px" } },
                [_vm._v(_vm._s(_vm.form.appName))]
              ),
              _c("el-descriptions-item", { attrs: { label: "简介" } }, [
                _vm._v(_vm._s(_vm.form.appInfo)),
              ]),
              _c("el-descriptions-item", { attrs: { label: "价格" } }, [
                _vm._v(
                  _vm._s(_vm.form.agentPrice) +
                    " 元/" +
                    _vm._s(
                      _vm._f("findEnumKey")(_vm.form.unit, _vm.productUnitType)
                    )
                ),
              ]),
              _c(
                "el-descriptions-item",
                { attrs: { label: "规格" } },
                _vm._l(_vm.form.specsList, function (e, i) {
                  return _c(
                    "span",
                    { key: i, staticStyle: { "margin-right": "10px" } },
                    [
                      _c(
                        "el-tag",
                        {
                          staticStyle: { cursor: "pointer" },
                          attrs: { type: _vm.buyNum == e ? "danger" : "info" },
                          on: {
                            click: function ($event) {
                              return _vm.selectNum(e)
                            },
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(e) +
                              _vm._s(
                                _vm._f("findEnumKey")(
                                  _vm.form.unit,
                                  _vm.productUnitType
                                )
                              )
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { staticClass: "pay-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "small", round: "" },
                  on: { click: _vm.pay },
                },
                [
                  [
                    _vm._v(" 立即支付 ￥ "),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-size": "16px",
                          "font-weight": "bold",
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("numFiter")(_vm.form.price * _vm.buyNum)
                          )
                        ),
                      ]
                    ),
                  ],
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.isShowCode,
                  expression: "isShowCode",
                },
              ],
              staticClass: "payCode",
            },
            [
              _c("vue-qr", {
                attrs: { logoSrc: _vm.imageUrl, text: _vm.codeurl, size: 180 },
              }),
              _c("div", { staticClass: "tips" }, [
                _vm.seconds > 60
                  ? _c(
                      "label",
                      [
                        _vm._v(" 界面等待超时，请手动查询结果或刷新页面。 "),
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary", round: "", size: "mini" },
                            on: { click: _vm.getOrder },
                          },
                          [_vm._v("手动查询支付结果")]
                        ),
                      ],
                      1
                    )
                  : _c("label", [
                      _vm._v("等待支付中（" + _vm._s(_vm.seconds) + "秒）"),
                    ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }