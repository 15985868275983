"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _api = _interopRequireDefault(require("../api"));
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var _vueQr = _interopRequireDefault(require("vue-qr"));
//表单默认值
var defaultForm = {
  id: null,
  appName: "",
  appInfo: "",
  price: 0
};
var _default = {
  name: "app_car",
  directives: {
    permission: _index.default
  },
  components: {
    vueQr: _vueQr.default
  },
  props: [
  //是否显示窗口
  "isShow",
  //窗口关闭事件回调
  "closeCreate",
  //保存成功后回调
  "callback",
  //修改时传入的id
  "data",
  //标题
  "formTitle",
  //商户ID
  "TenantId"],
  watch: {
    isShow: function isShow(newVal) {
      console.info('关闭续费弹窗', newVal);
      this.dialogFormVisible = newVal; //对父组件传过来的值进行监听，如果改变也对子组件内部的值进行改变
      this.initData(); //在显示的时候处理数据才能生效,因为显示的值在每次打开时肯定是变动的，
    },
    dialogFormVisible: function dialogFormVisible(newVal) {
      if (!newVal) {
        this.closeCreate(); //内部不关闭自己页面，会造成循环，让外部父组件来关闭
      }
    }
  },
  data: function data() {
    return {
      form: Object.assign({}, defaultForm),
      //控制弹窗显示
      dialogFormVisible: false,
      //弹窗加载框
      formLoading: false,
      imageUrl: "",
      codeurl: "",
      isShowCode: false,
      timer: "",
      //接收定时器
      seconds: 0,
      orderId: null,
      productUnitType: _enum.default.productUnitType,
      buyNum: 1 //购买数量
    };
  },

  methods: {
    /** 显示时初始化数据 */initData: function initData() {
      this.buyNum = 1;
      if (this.data.specs) {
        this.data.specsList = this.data.specs.split('|');
        this.buyNum = this.data.specsList[0];
      }
      this.form = this.data;
    },
    /** 加载数据 */fetchData: function fetchData(id) {
      // this.formLoading=true;
      // api.get(id).then(response => {
      //     this.form = response;
      //     this.formLoading=false
      // });
    },
    /**取消按钮 */cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
      this.form = Object.assign({}, defaultForm);
      this.isShowCode = false;
      this.orderId = null;
      this.seconds = 0;
      clearInterval(this.timer);
    },
    /** 支付生成二维码 */pay: function pay() {
      var _this = this;
      this.TenantId;
      console.log("支付");
      if (this.data.id) {
        clearInterval(this.timer);
        this.seconds = 0;
        this.formLoading = true;
        this.$axios.posts("/api/SettingService/merchantOrders", {
          productId: this.data.id,
          productNum: this.buyNum,
          productType: 2,
          TenantId: this.TenantId
        }).then(function (response) {
          _this.formLoading = false;
          if (response) {
            _this.formTitle = "扫码支付";
            _this.codeurl = response.url;
            _this.isShowCode = true;
            _this.orderId = response.id;
            _this.timer = setInterval(function () {
              _this.seconds++;
              if (_this.seconds % 3 == 0 && _this.seconds <= 60) {
                _this.getOrder();
              }
              if (_this.seconds >= 300) {
                clearInterval(_this.timer);
              }
            }, 1000);
          }
        }).catch(function () {
          _this.formLoading = false;
        });
      } else {
        this.$notify({
          title: "失败",
          message: "应用信息错误",
          type: "error",
          duration: 2000
        });
      }
    },
    //获取订单状态
    getOrder: function getOrder() {
      var _this2 = this;
      this.$axios.gets("/api/SettingService/merchantOrders/" + this.orderId).then(function (response) {
        if (response.orderStatus == 1) {
          _this2.$notify({
            title: "成功",
            message: "支付成功！",
            type: "success",
            duration: 2000
          });
          _this2.isShowCode = false;
          clearInterval(_this2.timer);
          _this2.dialogFormVisible = false;
          _this2.callback();
        }
      });
    },
    selectNum: function selectNum(e) {
      this.buyNum = Number(e);
    }
  }
};
exports.default = _default;