"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _echarts = _interopRequireDefault(require("echarts"));
var _resize = _interopRequireDefault(require("./mixins/resize"));
var _variables = _interopRequireDefault(require("@/styles/variables.scss"));
//
//
//
//

require("echarts/theme/macarons"); // echarts theme

var week = ["周一", "周二", "周三", "周四", "周五", "周六", "周日"];
var weekCompare = ["本周", "上周"];
var day = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24"];
var dayCompare = ["今日", "昨日"];
var month = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];
var monthCompare = ["本月", "上个月"];
var year = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
var yearCompare = ["今年", "去年"];
var _default = {
  mixins: [_resize.default],
  props: {
    className: {
      type: String,
      default: "chart"
    },
    width: {
      type: String,
      default: "100%"
    },
    height: {
      type: String,
      default: "350px"
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    },
    dateType: {
      type: String,
      default: "day"
    }
  },
  data: function data() {
    return {
      chart: null,
      primary: _variables.default.primary,
      success: _variables.default.success,
      xycolor: _variables.default.lightBlack,
      type: 'day'
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler: function handler(val) {
        this.setOptions(val, this.dateType);
      }
    },
    dateType: function dateType(val) {
      this.type = val;
      this.initChart();
    }
  },
  mounted: function mounted() {
    var _this = this;
    this.$nextTick(function () {
      _this.initChart();
    });
  },
  beforeDestroy: function beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart: function initChart() {
      this.chart = _echarts.default.init(this.$el, "macarons");
      this.setOptions(this.chartData);
    },
    setOptions: function setOptions() {
      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        expectedData = _ref.expectedData,
        actualData = _ref.actualData;
      var legend = [];
      var options = [];
      var type = this.type;
      switch (type) {
        case 'day':
          options = day;
          legend = dayCompare;
          break;
        case 'week':
          options = week;
          legend = weekCompare;
          break;
        case 'month':
          options = month;
          legend = monthCompare;
          break;
        case 'year':
          options = year;
          legend = yearCompare;
          break;
        default:
          options = day;
          legend = dayCompare;
          break;
      }
      this.chart.setOption({
        title: {
          text: this.className,
          textStyle: {
            color: '#000',
            fontSize: 14,
            fontWeight: 'bold',
            fontFamily: 'Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif'
          }
        },
        xAxis: {
          // 设置坐标轴字的颜色
          axisLabel: {
            color: "#666669"
          },
          data: options,
          boundaryGap: false,
          axisTick: {
            show: false
          },
          /*改变x轴颜色*/
          axisLine: {
            lineStyle: {
              color: this.xycolor,
              width: 2 //这里是为了突出显示加上的
            }
          }
        },

        grid: {
          left: 10,
          right: 10,
          bottom: 20,
          top: 30,
          containLabel: true
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross"
          },
          padding: [5, 10]
        },
        yAxis: {
          axisTick: {
            show: false
          },
          /*改变y轴颜色*/
          axisLine: {
            lineStyle: {
              color: this.xycolor,
              width: 2 //这里是为了突出显示加上的
            }
          }
        },

        legend: {
          data: legend
        },
        series: [{
          name: legend[0],
          itemStyle: {
            normal: {
              color: this.primary,
              lineStyle: {
                color: this.primary,
                width: 2
              }
            }
          },
          smooth: true,
          type: "line",
          data: expectedData,
          animationDuration: 2800,
          animationEasing: "cubicInOut"
        }, {
          name: legend[1],
          smooth: true,
          type: "line",
          itemStyle: {
            normal: {
              color: this.success,
              lineStyle: {
                color: this.success,
                width: 2
              },
              areaStyle: {
                color: "#f3f8ff"
              }
            }
          },
          data: actualData,
          animationDuration: 2800,
          animationEasing: "quadraticOut"
        }]
      });
    }
  }
};
exports.default = _default;