var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.dialogFormVisible,
        title: _vm.formTitle,
        width: "650px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: function ($event) {
          return _vm.cancel()
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            size: "small",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-descriptions",
            { attrs: { column: 2, colon: true } },
            [
              _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: "商户名称",
                    labelStyle: "width:90px;text-align:right;",
                  },
                },
                [_vm._v(_vm._s(_vm.form.merchantName))]
              ),
              _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: "所属代理商",
                    labelStyle: "width:90px;text-align:right;",
                  },
                },
                [_vm._v(_vm._s(_vm.form.agentName))]
              ),
              _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: "到期时间",
                    labelStyle: "width:90px;text-align:right;",
                  },
                },
                [_vm._v(_vm._s(_vm._f("formatDateTime")(_vm.form.stopTime)))]
              ),
            ],
            1
          ),
          _c(
            "el-descriptions",
            { attrs: { column: 2, colon: true } },
            [
              _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: "申请天数",
                    labelStyle: "width:90px;text-align:right;",
                  },
                },
                [_vm._v(_vm._s(_vm.form.delayDay) + " 天")]
              ),
              _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: "申请到期时间",
                    labelStyle: "width:90px;text-align:right;",
                  },
                },
                [
                  _vm._v(
                    _vm._s(_vm._f("formatDateTime")(_vm.form.delayStopTime))
                  ),
                ]
              ),
              _c(
                "el-descriptions-item",
                {
                  attrs: {
                    label: "申请原因",
                    labelStyle: "width:90px;text-align:right;",
                  },
                },
                [_vm._v(_vm._s(_vm.form.delayReason))]
              ),
            ],
            1
          ),
          _vm.isApprove && !_vm.isAgent
            ? _c(
                "el-row",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否审核", prop: "approve" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          model: {
                            value: _vm.form.approve,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "approve", $$v)
                            },
                            expression: "form.approve",
                          },
                        },
                        [
                          _c("el-radio", { attrs: { label: 1 } }, [
                            _vm._v("审核"),
                          ]),
                          _c("el-radio", { attrs: { label: 2 } }, [
                            _vm._v("驳回"),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "审核意见", prop: "approveIdea" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "100%" },
                        attrs: {
                          type: "textarea",
                          rows: 3,
                          placeholder: "请输入审核意见",
                          maxlength: "100",
                        },
                        model: {
                          value: _vm.form.approveIdea,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "approveIdea", $$v)
                          },
                          expression: "form.approveIdea",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "el-row",
                [
                  _c(
                    "el-descriptions",
                    { attrs: { column: 1, colon: true } },
                    [
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: "审核状态",
                            labelStyle: "width:90px;text-align:right;",
                          },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.approveFlagMap.find(function (x) {
                                return x.value == _vm.form.approve
                              }).text
                            )
                          ),
                        ]
                      ),
                      _c(
                        "el-descriptions-item",
                        {
                          attrs: {
                            label: "审核意见",
                            labelStyle: "width:90px;text-align:right;",
                          },
                        },
                        [_vm._v(_vm._s(_vm.form.approveIdea))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            { attrs: { size: "small" }, on: { click: _vm.cancel } },
            [_vm._v("关闭")]
          ),
          _vm.isApprove
            ? _c(
                "el-button",
                {
                  directives: [
                    {
                      name: "permission",
                      rawName: "v-permission",
                      value: ["Saas.MerchantDelay.Approve"],
                      expression: "['Saas.MerchantDelay.Approve']",
                    },
                  ],
                  attrs: {
                    size: "small",
                    loading: _vm.formLoading,
                    type: "primary",
                  },
                  on: { click: _vm.approve },
                },
                [_vm._v("确定")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }