"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.number.is-integer");
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _api = _interopRequireDefault(require("../api"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//表单默认值
var defaultForm = {
  giveType: 0,
  giveAmount: 0
};
var _default = {
  name: "agentGive",
  directives: {
    permission: _index.default
  },
  props: [
  //是否显示窗口
  "isShow",
  //窗口关闭事件回调
  "closeCreate",
  //保存成功后回调
  "callback", "formTitle", 'data'],
  watch: {
    isShow: function isShow(newVal) {
      console.log(newVal, 232);
      this.dialogFormVisible = newVal; //对父组件传过来的值进行监听，如果改变也对子组件内部的值进行改变
    },
    dialogFormVisible: function dialogFormVisible(newVal) {
      if (!newVal) {
        this.closeCreate(); //内部不关闭自己页面，会造成循环，让外部父组件来关闭
      }
    },
    data: function data(newVal) {
      this.agent = newVal;
    }
  },
  data: function data() {
    var checkMoney = function checkMoney(rule, value, callback) {
      if (!value) {
        return callback(new Error('请输入赠送数值'));
      }
      setTimeout(function () {
        if (!Number.isInteger(value)) {
          callback(new Error('请输入赠送数值'));
        } else {
          if (value > 0) {
            callback();
          } else {
            callback(new Error('赠送数值必须大于0'));
          }
        }
      }, 100);
    };
    return {
      //控制弹窗显示
      dialogFormVisible: false,
      //弹窗加载框
      formLoading: false,
      //表单提交校验规则
      rules: {
        giveAmount: [{
          validator: checkMoney,
          trigger: 'blur'
        }]
      },
      //表单定义 并赋默认值
      form: Object.assign({}, defaultForm),
      agent: []
    };
  },
  methods: {
    /**取消按钮 */cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
      this.form = Object.assign({}, defaultForm);
    },
    save: function save() {
      var _this = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this.formLoading = true;
          _this.form.id = _this.agent.id;
          _api.default.give(_this.form).then(function (response) {
            _this.formLoading = false;
            _this.$notify({
              title: "成功",
              type: "success",
              duration: 2000
            });
            _this.dialogFormVisible = false;
            _this.callback();
          }).catch(function () {
            _this.formLoading = false;
          });
        }
      });
    }
  }
};
exports.default = _default;