"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _SaasFormItemSelect = _interopRequireDefault(require("@/saas/components/SaasFormItemSelect.vue"));
var _SaasInputDialogs = _interopRequireDefault(require("@/saas/components/SaasInputDialogs.vue"));
var _validate = require("@/utils/validate");
var _vueTreeselect = _interopRequireDefault(require("@riophae/vue-treeselect"));
require("@riophae/vue-treeselect/dist/vue-treeselect.css");
var defaultForm = {
  id: '',
  organizationIds: [],
  userName: '',
  phoneNumber: '',
  name: '',
  email: '',
  password: '',
  lockoutEnabled: false,
  roleNames: [],
  jobIds: [],
  orgIdToName: null,
  userEx: {
    branch: "",
    vip: ""
  }
};
var _default = {
  name: "UserManager",
  components: {
    Pagination: _Pagination.default,
    Treeselect: _vueTreeselect.default,
    PageSelectFormItem: _SaasFormItemSelect.default,
    SaasInputDialog: _SaasInputDialogs.default
  },
  directives: {
    permission: _index.default
  },
  data: function data() {
    // 自定义验证
    var validPhone = function validPhone(rule, value, callback) {
      if (!value) {
        callback(new Error("请输入电话号码"));
      } else if (!(0, _validate.isvalidPhone)(value)) {
        callback(new Error("请输入正确的11位手机号码"));
      } else {
        callback();
      }
    };
    var validRoles = function validRoles(rule, value, callback) {
      if (!value) {
        callback(new Error("权限不能为空"));
      } else if (value.length == 0) {
        callback(new Error("权限不能为空"));
      } else {
        callback();
      }
    };
    return {
      rules: {
        userName: [{
          required: true,
          message: "请输入用户名",
          trigger: "blur"
        }, {
          min: 4,
          max: 8,
          message: "长度在 4 到 8 个字符",
          trigger: "blur"
        }
        // { pattern: /^[A-Za-z]{3,20}$/, message: '只能输入字母和数字', trigger: "blur" }
        ],

        name: [{
          required: true,
          message: "请输入用户姓名",
          trigger: "blur"
        }, {
          min: 2,
          max: 20,
          message: "长度在 2 到 20 个字符",
          trigger: "blur"
        }],
        // email: [
        //   { type: "email", message: "请输入正确的邮箱地址", trigger: "blur" }
        // ],
        // password: [
        //   { required: true, message: "请设置密码", trigger: "blur" },
        // ],
        // phoneNumber: [
        //   { required: false, trigger: "blur", validator: validPhone }
        // ],
        roleNames: [{
          required: true,
          trigger: "blur",
          validator: validRoles
        }]
        // branch: [
        //   { required: true, message: "请选择门店", trigger: "blur" },
        // ]
      },

      defaultProps: {
        children: "children",
        label: "name",
        isLeaf: "leaf"
      },
      form: Object.assign({}, defaultForm),
      list: null,
      roleName: "",
      roleList: [],
      totalCount: 0,
      listLoading: true,
      formLoading: false,
      listQuery: {
        OrganizationId: null,
        Filter: "",
        Sorting: "",
        SkipCount: 0,
        MaxResultCount: 10
      },
      page: 1,
      dialogFormVisible: false,
      multipleSelection: [],
      formTitle: "",
      isEdit: false
    };
  },
  watch: {},
  created: function created() {
    this.getList();
  },
  methods: {
    getRoles: function getRoles() {
      var _this = this;
      var params = {
        filter: this.roleName
      };
      setTimeout(function () {
        _this.$axios.gets("/api/identity/roles", params).then(function (response) {
          _this.roleList = response.items;
        });
      }, 100);
    },
    getList: function getList() {
      var _this2 = this;
      console.info("1");
      this.listLoading = true;
      this.listQuery.SkipCount = (this.page - 1) * this.listQuery.MaxResultCount;
      this.$axios.gets("/api/SettingService/user", this.listQuery).then(function (response) {
        _this2.list = response.items;
        _this2.totalCount = response.totalCount;
        _this2.listLoading = false;
        console.info('this.list', _this2.list);
      });
    },
    fetchData: function fetchData(id) {
      var _this3 = this;
      this.getAllRoles();
      this.$axios.gets("/api/SettingService/user/" + id).then(function (response) {
        _this3.form = response;
      });
      this.$axios.gets("/api/identity/users/" + id + "/roles").then(function (data) {
        data.items.forEach(function (item) {
          _this3.from.roleNames.push(item.name);
        });
      });
    },
    getAllRoles: function getAllRoles() {
      var _this4 = this;
      this.$axios.gets("/api/SettingService/role").then(function (response) {
        _this4.roleList = response.items;
      });
    },
    handleFilter: function handleFilter() {
      this.page = 1;
      this.getList();
    },
    handleNodeClick: function handleNodeClick(data) {
      this.listQuery.roleId = data.id;
      this.getList();
    },
    save: function save() {
      var _this5 = this;
      this.form.lockoutEnabled = false;
      // if (!this.form.userEx.branch) {
      //   this.$message({
      //     type: "warning",
      //     message: "请选择门店"
      //   });
      //   return;
      // }
      if (!this.isEdit) {
        if (this.form.password == '') {
          this.$message({
            message: "请输入密码",
            type: "warning"
          });
          return;
        }
      }
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this5.formLoading = true;
          if (_this5.form.userName.toLowerCase() === "admin" && _this5.form.roleNames.length == 0) {
            _this5.form.roleNames.push("admin");
          }
          if (_this5.isEdit) {
            _this5.$axios.posts("/api/SettingService/user/" + _this5.form.id, _this5.form).then(function (response) {
              _this5.formLoading = false;
              _this5.$notify({
                title: "成功",
                message: "更新成功",
                type: "success",
                duration: 2000
              });
              _this5.dialogFormVisible = false;
              _this5.getList();
            }).catch(function () {
              _this5.formLoading = false;
            });
          } else {
            if (_this5.form.email === "") {
              _this5.form.email = _this5.form.userName + "@qq.com";
            }
            _this5.$axios.posts("/api/SettingService/user", _this5.form).then(function (response) {
              console.log(response, 11111);
              _this5.formLoading = false;
              _this5.$notify({
                title: "成功",
                message: "新增成功",
                type: "success",
                duration: 2000
              });
              _this5.dialogFormVisible = false;
              _this5.getList();
            }).catch(function (err) {
              console.log(err, 11111);
              _this5.formLoading = false;
            });
          }
        }
      });
    },
    handleCreate: function handleCreate() {
      this.formTitle = "新增用户";
      this.isEdit = false;
      this.dialogFormVisible = true;
      this.getAllRoles();
      // this.getJobs()
      // this.getOrgNodes();
    },
    handleDelete: function handleDelete(row) {
      var _this6 = this;
      if (row) {
        this.$confirm("是否删除" + row.name + "?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        }).then(function () {
          _this6.$axios.deletes("/api/identity/users/" + row.id).then(function (response) {
            var index = _this6.list.indexOf(row);
            _this6.list.splice(index, 1);
            _this6.$notify({
              title: "成功",
              message: "删除成功",
              type: "success",
              duration: 2000
            });
            if (_this6.totalCount - 1 <= _this6.listQuery.SkipCount) {
              _this6.handleFilter();
            } else {
              _this6.getList();
            }
          });
        }).catch(function () {
          _this6.$message({
            type: "info",
            message: "已取消删除"
          });
        });
      } else {
        this.$alert("暂时不支持用户批量删除", "提示", {
          confirmButtonText: "确定",
          callback: function callback(action) {
            //
          }
        });
      }
    },
    handleUpdate: function handleUpdate(row) {
      this.formTitle = "修改用户";
      this.isEdit = true;
      if (row) {
        this.fetchData(row.id);
        this.dialogFormVisible = true;
      } else {
        if (this.multipleSelection.length != 1) {
          this.$message({
            message: "编辑必须选择单行",
            type: "warning"
          });
          return;
        } else {
          this.fetchData(this.multipleSelection[0].id);
          this.dialogFormVisible = true;
        }
      }
    },
    sortChange: function sortChange(data) {
      var prop = data.prop,
        order = data.order;
      if (!prop || !order) {
        this.handleFilter();
        return;
      }
      this.listQuery.Sorting = prop + " " + order;
      this.handleFilter();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleRowClick: function handleRowClick(row, column, event) {
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    cancel: function cancel() {
      this.form = Object.assign({}, defaultForm), this.orgs = [];
      this.jobData = [];
      this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
    },
    selectbranch: function selectbranch(e) {
      console.log("选择门店", e);
      if (e) {
        this.form.userEx.branchName = e.branchName;
        this.form.userEx.branchNo = e.branchNo;
        this.form.userEx.tradeType = e.tradeType;
      }
    },
    selectVip: function selectVip(e) {
      console.log("selectVip", e);
      if (e && e.length > 0) {
        this.form.userEx.vipName = e[0].vipName;
        this.form.userEx.openId = e[0].openid;
        this.form.userEx.photo = e[0].photo;
      }
    }
  }
};
exports.default = _default;