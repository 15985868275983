"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var table = [{
  Display: true,
  DisplayType: "text",
  Label: "模板ID",
  Name: "templateId",
  Sort: false,
  Align: "center",
  Width: "180",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "模板名称",
  Name: "title",
  Sort: false,
  Align: "center",
  Width: "120",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "微信内容",
  Name: "content",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}];
var _default = {
  table: table
};
exports.default = _default;