"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _moment = _interopRequireDefault(require("moment"));
var oneDay = 3600 * 1000 * 24;
var oneWeek = 3600 * 1000 * 24 * 7;
var oneMonth = 3600 * 1000 * 24 * 30;
var oneYear = 3600 * 1000 * 24 * 365;

//单据搜索日期范围快捷选项
var pickerOptionsPm = {
  shortcuts: [{
    text: '最近一周',
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - oneWeek);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '最近一个月',
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - oneMonth);
      picker.$emit('pick', [start, end]);
    }
  }, {
    text: '最近三个月',
    onClick: function onClick(picker) {
      var end = new Date();
      var start = new Date();
      start.setTime(start.getTime() - oneMonth * 3);
      picker.$emit('pick', [start, end]);
    }
  }]
};
var defaultDate = {
  /** 去年 */
  lastYear: (0, _moment.default)(new Date(new Date().getTime() - oneYear)).format('YYYY-MM-DD 00:00:00'),
  lastYearEnd: (0, _moment.default)(new Date(new Date().getTime() - oneYear)).format('YYYY-MM-DD 23:59:59'),
  /**上个月 */
  lastMonth: (0, _moment.default)(new Date(new Date().getTime() - oneMonth)).format('YYYY-MM-DD 00:00:00'),
  lastMonthEnd: (0, _moment.default)(new Date(new Date().getTime() - oneMonth)).format('YYYY-MM-DD 23:59:59'),
  /**上周 */
  lastWeek: (0, _moment.default)(new Date().getTime() - oneWeek).format('YYYY-MM-DD 00:00:00'),
  lastWeekEnd: (0, _moment.default)(new Date().getTime() - oneWeek).format('YYYY-MM-DD 23:59:59'),
  /**昨天 */
  yesterday: (0, _moment.default)(new Date().getTime() - oneDay).format('YYYY-MM-DD 00:00:00'),
  yesterdayEnd: (0, _moment.default)(new Date().getTime() - oneDay).format('YYYY-MM-DD 23:59:59'),
  /**今天 */
  today: (0, _moment.default)(new Date().getTime()).format('YYYY-MM-DDT00:00:00'),
  todayEnd: (0, _moment.default)(new Date().getTime()).format('YYYY-MM-DDT23:59:59'),
  /** 明天 */
  tomorrow: (0, _moment.default)(new Date().getTime() + oneDay).format('YYYY-MM-DD 00:00:00'),
  tomorrowEnd: (0, _moment.default)(new Date().getTime() + oneDay).format('YYYY-MM-DD 23:59:59'),
  /**下周 */
  nextWeek: (0, _moment.default)(new Date().getTime() + oneWeek).format('YYYY-MM-DD 00:00:00'),
  nextWeekEnd: (0, _moment.default)(new Date().getTime() + oneWeek).format('YYYY-MM-DD 23:59:59'),
  /**下个月 */
  nextMonth: (0, _moment.default)(new Date(new Date().getTime() + oneMonth)).format('YYYY-MM-DDT00:00:00'),
  nextMonthEnd: (0, _moment.default)(new Date(new Date().getTime() + oneMonth)).format('YYYY-MM-DDT23:59:59'),
  /** 明年 */
  nextYear: (0, _moment.default)(new Date(new Date().getTime() + oneYear)).format('YYYY-MM-DDT00:00:00'),
  nextYearEnd: (0, _moment.default)(new Date(new Date().getTime() + oneYear)).format('YYYY-MM-DDT23:59:59'),
  /** 2年后 */
  nextYear2: (0, _moment.default)(new Date(new Date().getTime() + oneYear * 2)).format('YYYY-MM-DD 00:00:00'),
  nextYear2End: (0, _moment.default)(new Date(new Date().getTime() + oneYear * 2)).format('YYYY-MM-DD 23:59:59')
};
var _default = {
  pickerOptionsPm: pickerOptionsPm,
  defaultDate: defaultDate
};
exports.default = _default;