"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("@/axios"));
var _moment = _interopRequireDefault(require("moment"));
var _variables = _interopRequireDefault(require("@/styles/variables.scss"));
var lineChartData = {
  sale: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130]
  }
};
function getLinChartData(lineChartType, type, branchId) {
  return _axios.default.gets("/api/ReportService/statistics/LinChartData?type=" + type + "&statisticsType=" + lineChartType + "&branchId=" + branchId);
}
;
function getSaleData(type, branchId) {
  return _axios.default.gets("/api/ReportService/statistics/saleStatistics?branchId=" + branchId + "&type=" + type);
}
;
function getVipData(branchId) {
  return _axios.default.gets("/api/ReportService/statistics/vipStatistics?branchId=" + branchId);
}
;
function getProductData(data) {
  return _axios.default.gets("api/StockService/imFlow/productSort", data);
}
;
var _default = {
  getLinChartData: getLinChartData,
  getSaleData: getSaleData,
  getVipData: getVipData,
  getProductData: getProductData
};
exports.default = _default;