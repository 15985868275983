"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var bdService = [{
  'data': [{
    title: '商品类别',
    imgUrl: require("@/assets/images/icon-1.png"),
    to: {
      path: '/bdService/iteminfo/item_cls'
    }
  }, {
    title: '商品单位',
    imgUrl: require("@/assets/images/icon-2.png"),
    to: {
      path: '/bdService/iteminfo/base_code'
    }
  }, {
    title: '供应商档案',
    imgUrl: require("@/assets/images/icon-3.png"),
    to: {
      path: '/pmService/baseInfo/supcust_info'
    }
  }, {
    title: '商品档案',
    imgUrl: require("@/assets/images/icon-4.png"),
    to: {
      path: '/bdService/iteminfo/item_info'
    }
  }]
}];
var pmService = [{
  'data': [{
    title: '供应商档案',
    imgUrl: require("@/assets/images/icon-1.png"),
    to: {
      path: '/pmService/baseInfo/supcust_info'
    }
  }, {
    title: '采购收货单',
    imgUrl: require("@/assets/images/icon-2.png"),
    to: {
      path: '/pmService/pmBusiness/poSheetIn',
      query: {
        transNo: 1
      }
    }
  }, {
    title: '采购退货单',
    imgUrl: require("@/assets/images/icon-3.png"),
    to: {
      path: '/pmService/pmBusiness/poSheetOut',
      query: {
        transNo: 2
      }
    }
  }, {
    title: '扣补单',
    imgUrl: require("@/assets/images/icon-4.png"),
    to: {
      path: '/pmService/pmBusiness/kbSheet'
    }
  }]
}];
var requireService = [{
  'data': [{
    title: '要货单',
    imgUrl: require("@/assets/images/icon-1.png"),
    to: {
      path: '/requireService/peisonginfo/toSheet'
    }
  }, {
    title: '直调入库单',
    imgUrl: require("@/assets/images/icon-2.png"),
    to: {
      path: '/requireService/peisonginfo/tfSheet',
      query: {
        transNo: 4
      }
    }
  }, {
    title: '直调出库单',
    imgUrl: require("@/assets/images/icon-2.png"),
    to: {
      path: '/requireService/peisonginfo/tfSheet',
      query: {
        transNo: 5
      }
    }
  }]
}, {
  'data': [{
    title: '要货单',
    imgUrl: require("@/assets/images/icon-1.png"),
    to: {
      path: '/requireService/peisonginfo/toSheet'
    }
  }, {
    title: '要货汇总',
    imgUrl: require("@/assets/images/icon-2.png"),
    to: {
      path: '/requireService/requireQuery/purchasingSummary'
    }
  }, {
    title: '配送批次号',
    imgUrl: require("@/assets/images/icon-3.png"),
    to: {
      path: '/requireService/peisonginfo/batch_number'
    }
  }, {
    title: '采购任务',
    imgUrl: require("@/assets/images/icon-4.png"),
    to: {
      path: '/requireService/peisonginfo/purchasing'
    }
  }, {
    title: '配送任务',
    imgUrl: require("@/assets/images/icon-1.png"),
    to: {
      path: '/requireService/peisonginfo/distribution'
    }
  }, {
    title: '配送收货',
    imgUrl: require("@/assets/images/icon-2.png"),
    to: {
      path: '/requireService/peisonginfo/receipt'
    }
  }]
}];
var sale = [{
  'data': [{
    title: 'POS机登记',
    imgUrl: require("@/assets/images/icon-1.png"),
    to: {
      path: '/sale/baseInfo/pos_info'
    }
  }, {
    title: '员工管理',
    imgUrl: require("@/assets/images/icon-2.png"),
    to: {
      path: '/sale/baseInfo/oper'
    }
  }, {
    title: '销售流水',
    imgUrl: require("@/assets/images/icon-3.png"),
    to: {
      path: '/sale/saleQuery/saleFlow'
    }
  }, {
    title: '收银日报',
    imgUrl: require("@/assets/images/icon-4.png"),
    to: {
      path: '/sale/saleQuery/casherDaysumRpt'
    }
  }]
}];
var settleService = [{
  'data': [{
    title: '财务代码',
    imgUrl: require("@/assets/images/icon-1.png"),
    to: {
      path: '/settleService/baseInfo/financial_code'
    }
  }, {
    title: '供应商结算',
    imgUrl: require("@/assets/images/icon-2.png"),
    to: {
      path: '/settleService/settleBusiness/cpSheet'
    }
  }]
}];
var shoppingMallService = [{
  'data': [{
    title: '商品分类',
    imgUrl: require("@/assets/images/icon-1.png"),
    to: {
      path: '/shoppingMallService/baseInfo/shoppingClass'
    }
  }, {
    title: '轮播图',
    imgUrl: require("@/assets/images/icon-2.png"),
    to: {
      path: '/shoppingMallService/baseInfo/banner'
    }
  }, {
    title: '推荐商品',
    imgUrl: require("@/assets/images/icon-3.png"),
    to: {
      path: '/shoppingMallService/shoppingMallBusiness/recommendItem'
    }
  }, {
    title: '今日特价',
    imgUrl: require("@/assets/images/icon-4.png"),
    to: {
      path: '/shoppingMallService/shoppingMallBusiness/dailySpecial'
    }
  }, {
    title: '首页管理',
    imgUrl: require("@/assets/images/icon-1.png"),
    to: {
      path: '/shoppingMallService/baseInfo/shoppingHome'
    }
  }]
}];
var stockService = [{
  'data': [{
    title: '盘点号申请',
    imgUrl: require("@/assets/images/icon-1.png"),
    to: {
      path: '/stockService/stockBusiness/pdSheetapply'
    }
  }, {
    title: '存货盘点单',
    imgUrl: require("@/assets/images/icon-2.png"),
    to: {
      path: '/stockService/stockBusiness/pdSheet'
    }
  }, {
    title: '差异处理单 ',
    imgUrl: require("@/assets/images/icon-3.png"),
    to: {
      path: '/stockService/stockBusiness/pdSumSheet'
    }
  }]
}];
var vip = [{
  'data': [{
    title: '会员等级',
    imgUrl: require("@/assets/images/icon-1.png"),
    to: {
      path: '/vip/vipInfo/vip_type'
    }
  }, {
    title: '会员档案',
    imgUrl: require("@/assets/images/icon-2.png"),
    to: {
      path: '/vip/vipInfo/vip_info'
    }
  }]
}];
var _default = {
  bdService: bdService,
  vip: vip,
  sale: sale,
  settleService: settleService,
  stockService: stockService,
  shoppingMallService: shoppingMallService,
  requireService: requireService,
  pmService: pmService
};
exports.default = _default;