var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c("div", {
      staticClass: "el-icon-close close",
      on: { click: _vm.changeShowHelp },
    }),
    _c("div", { staticClass: "box-1 padd" }, [_vm._v("帮助和服务")]),
    _c("div", { staticClass: "box-2" }, [
      _c("span", { staticClass: "product padd" }, [_vm._v("产品服务咨询")]),
      _c(
        "div",
        { staticClass: "padd" },
        [
          _c("svg-icon", { attrs: { "icon-class": "phone" } }),
          _c("span", { staticStyle: { "padding-left": "5px" } }, [
            _vm._v(_vm._s(_vm.phoneNumer)),
          ]),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "box-3 padd" }, [
      _c("div", { staticClass: "box-3-1" }, [_vm._v("扫码体验")]),
      _c("div", { staticClass: "box-3-2" }, [
        _c("div", { staticClass: "image" }, [
          _c("img", {
            staticStyle: { width: "150px", height: "150px" },
            attrs: { src: _vm.qrcode },
          }),
        ]),
      ]),
      _c("div", { staticClass: "box-3-3" }, [_vm._v("哇哇来客")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }