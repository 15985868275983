"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("@/axios"));
var _merchant_delay_def = _interopRequireDefault(require("@/saas/table-def/merchant_delay_def.js"));
var tableDef = _merchant_delay_def.default.table;
function get(id) {
  return _axios.default.gets("/api/SettingService/merchantDelay/" + id);
}
function getAll(data) {
  return _axios.default.gets("/api/SettingService/merchantDelay", data);
}
function deleteById(id) {
  return _axios.default.deletes("/api/SettingService/merchantDelay/" + id);
}
function update(data) {
  return _axios.default.posts("/api/SettingService/merchantDelay", data);
}
function create(data) {
  return _axios.default.posts("/api/SettingService/merchantDelay", data);
}
function approveById(data) {
  return _axios.default.gets("/api/SettingService/merchantDelay/approve", data);
}
var listQuery = {
  SkipCount: 0,
  MaxResultCount: 20,
  filter: '',
  agentId: null
};
var _default = {
  get: get,
  getAll: getAll,
  deleteById: deleteById,
  update: update,
  create: create,
  approveById: approveById,
  tableDef: tableDef,
  listQuery: listQuery
};
exports.default = _default;