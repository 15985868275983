// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"menuText": "#ffffffb3",
	"menuActiveText": "#FFFFFF",
	"subMenuActiveText": "#FFFFFF",
	"menuBg": "#000",
	"menuHover": "#F25B13",
	"subMenuBg": "#ffffff",
	"subMenuHover": "#b1afaf",
	"sideBarWidth": "210px",
	"subMenuActive": "#8A8989",
	"textColror": "#FFF",
	"success": "#46B34F",
	"successHover": "#61e06c",
	"successPlain": "#E3F4E5",
	"warning": "#D8A54F",
	"warningHover": "#f8bf5d",
	"warningPlain": "#FAF2E5",
	"danger": "#E76969",
	"dangerHover": "#fd7575",
	"dangerPlain": "#FBE9E9",
	"primary": "#F04F0B",
	"primaryHover": "#fde245",
	"primaryPlain": "#FDF9DE",
	"info": "#8A8989",
	"infoHover": "#b9b8b8",
	"infoPlain": "#EEEDED",
	"blue": "#709AC6",
	"lightBlue": "#3A71A8",
	"red": "#C03639",
	"pink": "#E65D6E",
	"green": "#2BBBBD",
	"tiffany": "#4AB7BD",
	"yellow": "#F3D422",
	"panGreen": "#30B08F",
	"orange": "#FF8400",
	"purple": "#B19DD8",
	"lightOrange": "#FFB980",
	"lightRed": "#D87A80",
	"black": "#4A4949",
	"lightBlack": "#878787",
	"groupColor": "#B19DD8, #FF8400, #F3D422, #2BBBBD, #FFB980, #D87A80, #4A4949"
};
module.exports = exports;
