"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.search");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _api = _interopRequireDefault(require("./api"));
//导入本功能的api接口
var _default = {
  name: "事件监控",
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    permission: _index.default
  },
  data: function data() {
    return {
      list: [],
      totalCount: 0,
      activeName: 'BDService',
      defaultActive: 'published',
      listLoading: true,
      listQuery: _api.default.listQuery,
      page: 1,
      //显示创建窗口
      dialogFormVisible: false,
      formTitle: "",
      isEdit: false,
      editId: '',
      form: []
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    /** 获取分页列表 */getList: function getList() {
      var _this = this;
      this.listLoading = true;
      this.listQuery.SkipCount = (this.page - 1) * this.listQuery.MaxResultCount;
      this.list = [];
      _api.default.getAll(this.listQuery, this.activeName, this.defaultActive).then(function (response) {
        _this.list = response.items;
        _this.totalCount = response.totalCount;
        _this.listLoading = false;
      });
    },
    /** 搜索事件 */search: function search() {
      this.page = 1;
      this.getList();
    },
    /** 新增窗口关闭事件 */handleCloseCreate: function handleCloseCreate() {
      this.dialogFormVisible = false;
    },
    /** 新增成功刷新列表和树 */handleCreateCallback: function handleCreateCallback(val) {
      //this.getList()
    },
    /** 更新 */show: function show(row) {
      this.formTitle = "查看";
      if (row) {
        this.form = row;
        this.dialogFormVisible = true;
      }
    },
    published: function published() {
      this.defaultActive = 'published';
      this.search();
    },
    received: function received() {
      this.defaultActive = 'received';
      this.search();
    },
    cancel: function cancel() {
      this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
    }
  }
};
exports.default = _default;