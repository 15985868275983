"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var defaultForm = {
  id: null,
  adminEmailAddress: '',
  adminPassword: '',
  name: '',
  exProp: {
    phone: "",
    email: "",
    shopType: 1
  }
};
var _default = {
  name: "Tenant",
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    permission: _index.default
  },
  data: function data() {
    return {
      rules: {
        name: [{
          required: true,
          message: "请输入租户名",
          trigger: "blur"
        }]
      },
      form: Object.assign({}, defaultForm),
      list: null,
      totalCount: 0,
      listLoading: true,
      formLoading: false,
      listQuery: {
        Filter: "",
        Sorting: "",
        SkipCount: 0,
        MaxResultCount: 10
      },
      page: 1,
      dialogFormVisible: false,
      multipleSelection: [],
      formTitle: "",
      isEdit: false,
      options: _enum.default.shopType
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      this.listQuery.SkipCount = (this.page - 1) * this.listQuery.MaxResultCount;
      this.$axios.gets("/api/saas/tenant", this.listQuery).then(function (response) {
        _this.list = response.items;
        _this.totalCount = response.totalCount;
        _this.listLoading = false;
      });
    },
    fetchData: function fetchData(id) {
      var _this2 = this;
      this.$axios.gets("/api/saas/tenant/" + id).then(function (response) {
        _this2.form = response;
      });
    },
    handleFilter: function handleFilter() {
      this.page = 1;
      this.getList();
    },
    save: function save() {
      var _this3 = this;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          _this3.formLoading = true;
          _this3.form.roleNames = _this3.checkedRole;
          _this3.form.exProp.email = _this3.form.adminEmailAddress;
          if (_this3.isEdit) {
            _this3.$axios.puts("/api/saas/tenant/" + _this3.form.id, _this3.form).then(function (response) {
              _this3.formLoading = false;
              _this3.$notify({
                title: "成功",
                message: "更新成功",
                type: "success",
                duration: 2000
              });
              _this3.dialogFormVisible = false;
              _this3.getList();
            }).catch(function () {
              _this3.formLoading = false;
            });
          } else {
            _this3.$axios.posts("/api/saas/tenant", _this3.form).then(function (response) {
              _this3.formLoading = false;
              _this3.$notify({
                title: "成功",
                message: "新增成功",
                type: "success",
                duration: 2000
              });
              _this3.dialogFormVisible = false;
              _this3.getList();
            }).catch(function () {
              _this3.formLoading = false;
            });
          }
        }
      });
    },
    handleCreate: function handleCreate() {
      this.formTitle = "新增租户";
      this.isEdit = false;
      this.dialogFormVisible = true;
    },
    handleDelete: function handleDelete(row) {
      var _this4 = this;
      this.$confirm("是否删除" + row.name + "?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(function () {
        _this4.$axios.deletes("/api/saas/tenant/" + row.id).then(function (response) {
          _this4.$notify({
            title: "成功",
            message: "删除成功",
            type: "success",
            duration: 2000
          });
          _this4.getList();
        });
      }).catch(function () {
        _this4.$message({
          type: "info",
          message: "已取消删除"
        });
      });
    },
    handleUpdate: function handleUpdate(row) {
      this.formTitle = "修改租户";
      this.isEdit = true;
      if (row) {
        this.fetchData(row.id);
        this.dialogFormVisible = true;
      } else {
        if (this.multipleSelection.length != 1) {
          this.$message({
            message: "编辑必须选择单行",
            type: "warning"
          });
          return;
        } else {
          this.fetchData(this.multipleSelection[0].id);
          this.dialogFormVisible = true;
        }
      }
    },
    sortChange: function sortChange(data) {
      var prop = data.prop,
        order = data.order;
      if (!prop || !order) {
        this.handleFilter();
        return;
      }
      this.listQuery.Sorting = prop + " " + order;
      this.handleFilter();
    },
    cancel: function cancel() {
      this.form = Object.assign({}, defaultForm);
      this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
    }
  }
};
exports.default = _default;