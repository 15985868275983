"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _axios = _interopRequireDefault(require("@/axios"));
var _developer_def = _interopRequireDefault(require("@/saas/table-def/developer_def"));
var tableDef = _developer_def.default.table;
function get(id) {
  return _axios.default.gets("/api/SettingService/developer/" + id);
}
function getAll(data) {
  return _axios.default.gets("/api/SettingService/developer", data);
}
function getByIds(ids) {
  return _axios.default.gets("/api/SettingService/developer/byIds?ids=" + ids);
}
function deleteById(id) {
  return _axios.default.deletes("/api/SettingService/developer/" + id);
}
function update(data) {
  return _axios.default.posts("/api/SettingService/developer", data);
}
function create(data) {
  return _axios.default.posts("/api/SettingService/developer", data);
}
function getApplet(data) {
  return _axios.default.gets("/api/BDService/appLet", data);
}
function getAgent(data) {
  return _axios.default.gets("/api/SettingService/agent", data);
}
function getMerchant(data) {
  return _axios.default.gets("/api/SettingService/merchant", data);
}

// 供下拉组件传参
var listQuery = {
  Filter: "",
  //编码、名称
  SkipCount: 0,
  MaxResultCount: 10
};
var _default = {
  get: get,
  getAll: getAll,
  getByIds: getByIds,
  deleteById: deleteById,
  update: update,
  create: create,
  getApplet: getApplet,
  tableDef: tableDef,
  listQuery: listQuery,
  getAgent: getAgent,
  getMerchant: getMerchant
};
exports.default = _default;