"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _enum = _interopRequireDefault(require("@/saas/enum.js"));
var table = [{
  Display: true,
  DisplayType: "text",
  Label: "姓名",
  Name: "name",
  Sort: false,
  Align: "",
  Width: "",
  IsLink: true,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "手机号",
  Name: "tel",
  Sort: false,
  Align: "center",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "text",
  Label: "公司名称",
  Name: "companyName",
  Sort: false,
  Align: "center",
  Width: "",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "bool",
  Label: "状态",
  Name: "status",
  Sort: false,
  Align: "center",
  Width: "80",
  IsLink: false,
  NavDisplayProp: ""
}, {
  Display: true,
  DisplayType: "datetime",
  Label: "创建时间",
  Name: "creationTime",
  Sort: false,
  Align: "center",
  Width: "135",
  IsLink: false,
  NavDisplayProp: ""
}];
var _default = {
  table: table
};
exports.default = _default;