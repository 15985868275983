"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/web.dom.iterable");
var _typeof2 = _interopRequireDefault(require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime-corejs2/helpers/typeof.js"));
require("core-js/modules/es6.array.find");
var _axios = _interopRequireDefault(require("@/axios"));
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _components$props$wat;
var _default = (_components$props$wat = {
  components: {
    Pagination: _Pagination.default
  },
  props: ["url", "cols", "placeholder", "size", "value", "disabled", "multiple"],
  watch: {
    value: function value(val) {
      this.selectId = val;
      // this.filterMethod(val);
      //外部绑定的值如果第一次是有值的，从这里传入
    },
    selectId: function selectId(newVal) {
      this.$emit("input", newVal); //v-model实现的是v-bind:value 和v-on:input，这句更新父级组件的value
      this.getChangeData(newVal);
    },
    placeholder: function placeholder(newVal) {
      if (newVal == null) {
        this.placeholder = "请选择";
      }
    },
    url: function url(val) {
      if (val != null && val != "") {
        this.getList(); // 初始化
      }
    }
  },
  mounted: function mounted() {},
  data: function data() {
    return {
      selectId: this.value,
      totalCount: 0,
      // 获取总数据量
      pageCount: 0,
      // 获取总页数
      selectPage: 1,
      // 当前页数
      list: [],
      //当前页数数据
      listQuery: {
        Filter: "",
        Sorting: "",
        SkipCount: 0,
        MaxResultCount: 20,
        shopStatusType: -1
      }
    };
  }
}, (0, _defineProperty2.default)(_components$props$wat, "mounted", function mounted() {
  // console.log(" this.selectId1", this.selectId);
  //      console.log(" this.url", this.url);
}), (0, _defineProperty2.default)(_components$props$wat, "methods", {
  getAll: function getAll(data) {
    return _axios.default.gets(this.url, data);
  },
  getList: function getList() {
    var _this = this;
    this.listQuery.SkipCount = (this.selectPage - 1) * this.listQuery.MaxResultCount;
    this.getAll(this.listQuery).then(function (response) {
      _this.list = response.items;
      _this.totalCount = response.totalCount;
      if (_this.totalCount > 0) {
        _this.pageCount = Math.ceil(_this.totalCount / _this.listQuery.MaxResultCount);
      }
    });
  },
  // 筛选方法,过滤输入的值
  filterMethod: function filterMethod(query) {
    this.listQuery.Filter = query;
    this.getList();
  },
  // 下拉框出现时，调用过滤方法
  visibleChange: function visibleChange(flag) {
    if (flag) {
      this.filterMethod();
    }
  },
  /**
   * 改变数据回调
   */
  getChangeData: function getChangeData(val) {
    var _this2 = this;
    if (typeof val == "string") {
      var find = this.list.find(function (x) {
        return x.id == val;
      });
      console.log("val", val);
      console.log("selectDataChange", find);
      this.$emit("selectDataChange", find);
    }
    if ((0, _typeof2.default)(val) == "object") {
      if (val != null) {
        var data = [];
        if (val.length > 0) {
          val.forEach(function (element) {
            var find = _this2.list.find(function (x) {
              return x.id == element;
            });
            data.push(find);
          });
        }
      }
      // console.log("selectDataChange", data);
      this.$emit("selectDataChange", data);
    }
  }
}), _components$props$wat);
exports.default = _default;