var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { background: "#f0f2f5" } },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 3 } },
            [
              _c(
                "el-card",
                { staticStyle: { margin: "20px 0 10px 0", height: "100vh" } },
                [
                  _c("el-tree", {
                    ref: "tree",
                    attrs: {
                      data: _vm.merchantOrderTypeMap,
                      "highlight-current": "",
                      props: _vm.defaultProps,
                      "node-key": "value",
                      "default-expanded-keys": _vm.defaultKey,
                    },
                    on: { "node-click": _vm.handleNodeClick },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-col",
            { staticClass: "merchantorder", attrs: { span: 21 } },
            [
              _c("el-card", { staticStyle: { margin: "20px 0 10px 0" } }, [
                _c(
                  "div",
                  { staticClass: "head-container" },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "form",
                        attrs: {
                          model: _vm.listQuery,
                          "label-width": "80px",
                          size: "mini",
                        },
                      },
                      [
                        _c(
                          "el-row",
                          { attrs: { size: "mini" } },
                          [
                            _c(
                              "el-col",
                              { attrs: { size: "mini" } },
                              [
                                _c("SaasButtonGroup", {
                                  attrs: {
                                    BtnType: "report",
                                    Query: _vm.Search,
                                    Export: _vm.Search,
                                  },
                                }),
                                _c("el-date-picker", {
                                  staticStyle: {
                                    "margin-left": "10px",
                                    width: "250px",
                                  },
                                  attrs: {
                                    size: "mini",
                                    type: "daterange",
                                    align: "right",
                                    "unlink-panels": "",
                                    format: "yyyy-MM-dd",
                                    "range-separator": "至",
                                    "start-placeholder": "开始日期",
                                    "end-placeholder": "结束日期",
                                    "picker-options": _vm.pickerOptions,
                                    clearable: false,
                                  },
                                  model: {
                                    value: _vm.listQuery.DateRegion,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.listQuery, "DateRegion", $$v)
                                    },
                                    expression: "listQuery.DateRegion",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "search-group" },
                          [
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "订单状态" } },
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        model: {
                                          value: _vm.listQuery.orderStatus,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.listQuery,
                                              "orderStatus",
                                              $$v
                                            )
                                          },
                                          expression: "listQuery.orderStatus",
                                        },
                                      },
                                      [
                                        _c(
                                          "el-radio-button",
                                          { attrs: { label: null } },
                                          [_vm._v("全部")]
                                        ),
                                        _vm._l(
                                          _vm.merchantOrderStatusMap,
                                          function (item) {
                                            return _c(
                                              "el-radio-button",
                                              {
                                                key: item.value,
                                                attrs: { label: item.value },
                                              },
                                              [_vm._v(_vm._s(item.text))]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-row",
                              [
                                _c(
                                  "el-form-item",
                                  { attrs: { label: "关键字" } },
                                  [
                                    _c("el-input", {
                                      staticClass: "filter-item",
                                      staticStyle: { width: "350px" },
                                      attrs: {
                                        size: "mini",
                                        placeholder: "订单号,商户,代理商,备注",
                                        "prefix-icon": "el-icon-search",
                                      },
                                      model: {
                                        value: _vm.listQuery.Filter,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.listQuery, "Filter", $$v)
                                        },
                                        expression: "listQuery.Filter",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
              _c("el-card", { staticStyle: { margin: "0 0 20px 0" } }, [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.listLoading,
                        expression: "listLoading",
                      },
                    ],
                  },
                  [
                    _vm.totalCount == 0
                      ? _c(
                          "el-row",
                          { staticStyle: {} },
                          [
                            _c(
                              "el-col",
                              { attrs: { span: 12, offset: 10 } },
                              [
                                _c(
                                  "el-link",
                                  { attrs: { type: "info", underline: false } },
                                  [_c("label", [_vm._v("暂无数据")])]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._l(_vm.list, function (o, index) {
                      return _c(
                        "el-card",
                        {
                          key: index,
                          staticClass: "box-card",
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: { shadow: "never" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "font-weight": "bold",
                                "font-size": "14px",
                                padding: "0",
                              },
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-document",
                                      }),
                                      _c(
                                        "label",
                                        {
                                          staticStyle: {
                                            color: "#a8a8a8",
                                            "font-size": "13px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "订单编号:" + _vm._s(o.orderNo)
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            "padding-left": "5px",
                                          },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              return _vm.copyOrderNo(o.orderNo)
                                            },
                                          },
                                        },
                                        [_vm._v("复制")]
                                      ),
                                      _c(
                                        "label",
                                        {
                                          staticStyle: {
                                            "padding-left": "20px",
                                            color: "#a8a8a8",
                                            "font-size": "13px",
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "el-icon-time",
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("formatDateTime")(
                                                o.creationTime
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { float: "right" },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.remark(o)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v("备注 "),
                                          _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { float: "right" },
                                          attrs: { type: "text" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.update(o)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(" 详情 "),
                                          _c("el-divider", {
                                            attrs: { direction: "vertical" },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-table",
                            {
                              ref: "table",
                              refInFor: true,
                              attrs: {
                                size: "mini",
                                stripe: "",
                                data: [o.details],
                                "cell-style": _vm.cellStyle,
                              },
                            },
                            [
                              _c("el-table-column", {
                                attrs: {
                                  prop: "itemName",
                                  label: "",
                                  width: "280px",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "span",
                                            [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    [
                                                      _c(
                                                        "el-row",
                                                        {
                                                          staticStyle: {
                                                            "font-weight":
                                                              "bold",
                                                            "font-size": "14px",
                                                            padding: "0",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "el-col",
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  " 订单类型:"
                                                                ),
                                                              ]),
                                                              _c(
                                                                "el-tag",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "20px",
                                                                  },
                                                                  attrs: {
                                                                    type: _vm._f(
                                                                      "findEnumType"
                                                                    )(
                                                                      o.orderType,
                                                                      _vm.merchantOrderTypeMap
                                                                    ),
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "findEnumKey"
                                                                      )(
                                                                        o.orderType,
                                                                        _vm.merchantOrderTypeMap
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "5px",
                                                              },
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  " 订单状态:"
                                                                ),
                                                              ]),
                                                              _c(
                                                                "el-tag",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "20px",
                                                                  },
                                                                  attrs: {
                                                                    type: _vm._f(
                                                                      "findEnumType"
                                                                    )(
                                                                      o.orderStatus,
                                                                      _vm.merchantOrderStatusMap
                                                                    ),
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm._f(
                                                                        "findEnumKey"
                                                                      )(
                                                                        o.orderStatus,
                                                                        _vm.merchantOrderStatusMap
                                                                      )
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "itemName", label: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "span",
                                            [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    [
                                                      _c(
                                                        "el-row",
                                                        [
                                                          _c("el-col", [
                                                            _c("label", [
                                                              _vm._v("商户:"),
                                                            ]),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "20px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    o.merchantName
                                                                  ) + " "
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "5px",
                                                              },
                                                            },
                                                            [
                                                              _c("label", [
                                                                _vm._v(
                                                                  "代理商:"
                                                                ),
                                                              ]),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticStyle: {
                                                                    "margin-left":
                                                                      "20px",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      o.agentName ==
                                                                        null
                                                                        ? "-"
                                                                        : o.agentName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: {
                                  prop: "itemName",
                                  label: "",
                                  width: "350px",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "span",
                                            [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    [
                                                      _c(
                                                        "el-row",
                                                        [
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 6,
                                                              },
                                                            },
                                                            [
                                                              _c("el-image", {
                                                                staticStyle: {
                                                                  width: "80px",
                                                                  height:
                                                                    "80px",
                                                                },
                                                                attrs: {
                                                                  src: scope.row
                                                                    .img,
                                                                  fit: "cover",
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "el-col",
                                                            {
                                                              attrs: {
                                                                span: 12,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticStyle: {
                                                                    height:
                                                                      "80px",
                                                                    "line-height":
                                                                      "40px",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "el-row",
                                                                    [
                                                                      _c(
                                                                        "el-col",
                                                                        {
                                                                          staticClass:
                                                                            "yc",
                                                                          attrs:
                                                                            {
                                                                              span: 24,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            [
                                                                              _vm._v(
                                                                                "名称:"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-left":
                                                                                    "20px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  scope
                                                                                    .row
                                                                                    .productName
                                                                                )
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "el-col",
                                                                        {
                                                                          staticClass:
                                                                            "yc",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "label",
                                                                            [
                                                                              _vm._v(
                                                                                " 零售价:"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              staticStyle:
                                                                                {
                                                                                  "margin-left":
                                                                                    "10px",
                                                                                },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "￥" +
                                                                                  _vm._s(
                                                                                    scope
                                                                                      .row
                                                                                      .price
                                                                                  ) +
                                                                                  "/年"
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                              _c("el-table-column", {
                                attrs: { prop: "itemName", label: "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function (scope) {
                                        return [
                                          _c(
                                            "span",
                                            [
                                              _c(
                                                "el-row",
                                                [
                                                  _c(
                                                    "el-col",
                                                    [
                                                      _c(
                                                        "el-row",
                                                        [
                                                          _c("el-col", [
                                                            _c("label", [
                                                              _vm._v("金 额:"),
                                                            ]),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "lblyellow",
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "20px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    o.amount
                                                                  ) + "元"
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c("el-col", [
                                                            _c("label", [
                                                              _vm._v("优 惠:"),
                                                            ]),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "lblyellow",
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "20px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    o.discount
                                                                  ) + "元"
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                          _c("el-col", [
                                                            _c("label", [
                                                              _vm._v("付 款:"),
                                                            ]),
                                                            _c(
                                                              "label",
                                                              {
                                                                staticClass:
                                                                  "lblyellow",
                                                                staticStyle: {
                                                                  "margin-left":
                                                                    "20px",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    o.payAmount
                                                                  ) + "元"
                                                                ),
                                                              ]
                                                            ),
                                                          ]),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    _c("el-divider", { staticStyle: { "margin-top": "20px" } }),
                    _c("pagination", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.totalCount > 0,
                          expression: "totalCount > 0",
                        },
                      ],
                      attrs: {
                        total: _vm.totalCount,
                        page: _vm.page,
                        limit: _vm.listQuery.MaxResultCount,
                      },
                      on: {
                        "update:page": function ($event) {
                          _vm.page = $event
                        },
                        "update:limit": function ($event) {
                          return _vm.$set(
                            _vm.listQuery,
                            "MaxResultCount",
                            $event
                          )
                        },
                        pagination: _vm.getList,
                      },
                    }),
                  ],
                  2
                ),
              ]),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "备注",
                    visible: _vm.dialogVisible,
                    width: "30%",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.dialogVisible = $event
                    },
                  },
                },
                [
                  _c("el-input", {
                    attrs: { type: "textarea", placeholder: "请输入" },
                    model: {
                      value: _vm.form.remark,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark",
                    },
                  }),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.dialogVisible = false
                            },
                          },
                        },
                        [_vm._v("取 消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.ok()
                            },
                          },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }