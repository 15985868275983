"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
var _index = _interopRequireDefault(require("@/directive/permission/index.js"));
var _config = _interopRequireDefault(require("../../../../../static/config"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

var defaultForm = {
  id: null,
  name: null
};
var _default = {
  name: "Storage",
  components: {
    Pagination: _Pagination.default
  },
  directives: {
    permission: _index.default
  },
  filters: {
    displayType: function displayType(typeId) {
      var typeMap = {
        0: "图片",
        1: "文件"
      };
      return typeMap[typeId];
    }
  },
  data: function data() {
    return {
      storageApi: _config.default.storage.ip,
      rules: {
        name: [{
          required: true,
          message: "请输入文件名",
          trigger: "blur"
        }]
      },
      form: Object.assign({}, defaultForm),
      list: null,
      totalCount: 0,
      listLoading: true,
      formLoading: false,
      listQuery: {
        Filter: "",
        Sorting: "",
        SkipCount: 0,
        MaxResultCount: 10
      },
      page: 1,
      dialogFormVisible: false,
      multipleSelection: [],
      formTitle: "",
      isEdit: false
    };
  },
  created: function created() {
    this.getList();
  },
  methods: {
    // 上传文件
    upload: function upload() {
      this.$refs.upload.submit();
    },
    beforeUpload: function beforeUpload(file) {
      var isLt2M = true;
      isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.loading = false;
        this.$message.error("上传文件大小不能超过 100MB!");
      }
      this.form.name = file.name;
      return isLt2M;
    },
    handleSuccess: function handleSuccess(response, file, fileList) {
      this.$notify({
        title: "成功",
        message: "更新成功",
        type: "success",
        duration: 2000
      });
      this.dialogFormVisible = false;
      this.getList();
    },
    // 监听上传失败
    handleError: function handleError(e, file, fileList) {
      this.$notify({
        title: e,
        type: "error",
        duration: 4000
      });
      this.loading = false;
    },
    getList: function getList() {
      var _this = this;
      this.listLoading = true;
      this.listQuery.SkipCount = (this.page - 1) * this.listQuery.MaxResultCount;
      this.$axios.gets(_config.default.storage.ip + "/api/FileService/file", this.listQuery).then(function (response) {
        _this.list = response.items;
        _this.totalCount = response.totalCount;
        _this.listLoading = false;
      });
    },
    handleFilter: function handleFilter() {
      this.page = 1;
      this.getList();
    },
    handleUpload: function handleUpload() {
      this.formTitle = "文件上传";
      this.isEdit = false;
      this.dialogFormVisible = true;
    },
    handleDelete: function handleDelete(row) {
      this.$message({
        message: "敬请期待！",
        type: "warning"
      });
      return;
    },
    sortChange: function sortChange(data) {
      var prop = data.prop,
        order = data.order;
      if (!prop || !order) {
        this.handleFilter();
        return;
      }
      this.listQuery.Sorting = prop + " " + order;
      this.handleFilter();
    },
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleRowClick: function handleRowClick(row, column, event) {
      this.$refs.multipleTable.clearSelection();
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    cancel: function cancel() {
      this.form = Object.assign({}, defaultForm);
      this.dialogFormVisible = false;
      this.$refs.form.clearValidate();
    }
  }
};
exports.default = _default;