"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _interopRequireWildcard2 = _interopRequireDefault(require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime-corejs2/helpers/interopRequireWildcard.js"));
var _layout = _interopRequireDefault(require("@/layout"));
var SystemService = {
  path: '/system',
  component: _layout.default,
  redirect: 'noRedirect',
  alwaysShow: true,
  name: 'settings',
  type: 'home',
  meta: {
    title: 'settings',
    icon: 'control',
    haveDetails: false
  },
  children: [{
    path: 'role',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/role/index'));
      });
    },
    name: 'Role',
    meta: {
      title: 'role',
      roles: ['Saas.System.Role'],
      icon: 'role',
      keepAlive: true
    }
  }, {
    path: 'settings',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/settings/index.vue'));
      });
    },
    name: 'Settings',
    meta: {
      title: 'settings',
      roles: ['Saas.System.Setting'],
      icon: 'system',
      keepAlive: true
    }
  }, {
    path: 'storage',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/storage/index'));
      });
    },
    name: 'Storage',
    meta: {
      title: 'storage',
      roles: ['Saas.System.Storage'],
      icon: 'storage',
      keepAlive: true
    }
  }, {
    path: 'log',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/auditLogging/index'));
      });
    },
    name: 'Log',
    meta: {
      title: 'log',
      roles: ['Saas.System.Log'],
      icon: 'log',
      keepAlive: true
    }
  }, {
    path: 'smsTemplate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/smsTemplate/index.vue'));
      });
    },
    name: 'smsTemplate',
    meta: {
      title: 'smsTemplate',
      roles: ['Saas.System.Role'],
      icon: 'message',
      keepAlive: true
    }
  }, {
    path: 'eventBus',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/eventBus/index.vue'));
      });
    },
    name: 'eventBus',
    meta: {
      title: 'eventBus',
      roles: ['Saas.System.Role'],
      icon: 'message',
      keepAlive: true
    }
  }, {
    path: 'wxSmsTemplate',
    component: function component() {
      return Promise.resolve().then(function () {
        return (0, _interopRequireWildcard2.default)(require('@/views/admin/wxSmsTemplate/index.vue'));
      });
    },
    name: 'wxSmsTemplate',
    meta: {
      title: 'wxSmsTemplate',
      roles: ['Saas.System.WxSms'],
      icon: 'message',
      keepAlive: true
    }
  }]
};
var _default = SystemService;
exports.default = _default;