var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.dialogFormVisible,
        title: _vm.formTitle,
        width: "600px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: function ($event) {
          return _vm.cancel()
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "form",
          attrs: {
            inline: true,
            model: _vm.form,
            rules: _vm.rules,
            size: "small",
            "label-width": "80px",
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "图片", prop: "img" } },
            [
              _c("SaasUplaod", {
                attrs: { callback: _vm.uploadIcon, imgUrl: _vm.form.img },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "标题", prop: "title" } },
            [
              _c("el-input", {
                staticStyle: { width: "380px" },
                model: {
                  value: _vm.form.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "title", $$v)
                  },
                  expression: "form.title",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "版本", prop: "versions" } },
            [
              _c("el-input", {
                staticStyle: { width: "380px" },
                model: {
                  value: _vm.form.versions,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "versions", $$v)
                  },
                  expression: "form.versions",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "环境", prop: "environment" } },
            [
              _c("el-input", {
                staticStyle: { width: "380px" },
                model: {
                  value: _vm.form.environment,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "environment", $$v)
                  },
                  expression: "form.environment",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "下载地址", prop: "url" } },
            [
              _c("el-input", {
                staticStyle: { width: "380px" },
                model: {
                  value: _vm.form.url,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "url", $$v)
                  },
                  expression: "form.url",
                },
              }),
              _c(
                "el-upload",
                {
                  ref: "upload",
                  attrs: {
                    multiple: false,
                    limit: 1,
                    "before-upload": _vm.beforeUpload,
                    "auto-upload": true,
                    "on-success": _vm.handleSuccess,
                    "on-error": _vm.handleError,
                    "on-change": _vm.onUploadChange,
                    action: "void",
                    "http-request": _vm.customUpload,
                  },
                },
                [
                  _c("div", { staticClass: "upload" }, [
                    _c("i", { staticClass: "el-icon-upload" }),
                    _vm._v(" 添加文件 "),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "el-upload__tip",
                      attrs: { slot: "tip" },
                      slot: "tip",
                    },
                    [_vm._v("上传文件不超过100M")]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "文件长度" } },
            [
              _c("el-input", {
                staticStyle: { width: "380px" },
                model: {
                  value: _vm.form.fileLength,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "fileLength", $$v)
                  },
                  expression: "form.fileLength",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "最小版本" } },
            [
              _c("el-input", {
                staticStyle: { width: "380px" },
                model: {
                  value: _vm.form.minVersion,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "minVersion", $$v)
                  },
                  expression: "form.minVersion",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "posType", label: "包类型" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "255px" },
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.form.posType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "posType", $$v)
                    },
                    expression: "form.posType",
                  },
                },
                _vm._l(_vm.optionsPosType, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.text, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "packageType", label: "安装包类型" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "255px" },
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.form.packageType,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "packageType", $$v)
                    },
                    expression: "form.packageType",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.text, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "warnLevel", label: "升级类型" } },
            [
              _c(
                "el-select",
                {
                  staticStyle: { width: "255px" },
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.form.warnLevel,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "warnLevel", $$v)
                    },
                    expression: "form.warnLevel",
                  },
                },
                _vm._l(_vm.warnLevel, function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: { label: item.text, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "生效时间" } },
            [
              _c("el-date-picker", {
                staticStyle: { width: "380px" },
                attrs: { type: "date", placeholder: "选择日期" },
                model: {
                  value: _vm.form.updateDate,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "updateDate", $$v)
                  },
                  expression: "form.updateDate",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "代理商" } },
            [
              _c("SaasInputDialog", {
                staticStyle: { width: "255px" },
                attrs: {
                  api: _vm.agentApi,
                  title: "选择代理商",
                  hasTree: false,
                  displayText: "companyName",
                  displayId: "agentCode",
                  callback: _vm.selectAgentCallback,
                  listQuery: _vm.agentListQuery,
                  hasRows: false,
                },
                model: {
                  value: _vm.form.agent,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "agent", $$v)
                  },
                  expression: "form.agent",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "修改说明" } },
            [
              _c("el-input", {
                staticStyle: { width: "380px" },
                attrs: { type: "textarea" },
                model: {
                  value: _vm.form.updateContent,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "updateContent", $$v)
                  },
                  expression: "form.updateContent",
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否启用", prop: "status" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                [
                  _c("el-radio", { attrs: { label: false } }, [_vm._v("禁用")]),
                  _c("el-radio", { attrs: { label: true } }, [_vm._v("启用")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { disabled: _vm.disabled },
                      model: {
                        value: _vm.checked,
                        callback: function ($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked",
                      },
                    },
                    [_vm._v("保存后自动新增")]
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.cancel },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    loading: _vm.formLoading,
                    type: "primary",
                  },
                  on: { click: _vm.save },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }