"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.translateOptions = translateOptions;
function translateOptions(val) {
  return val.map(function (opt) {
    return translate(opt);
  });
}
function translate(opt) {
  var res = {
    value: opt[1],
    label: opt[1]
  };
  if (Array.isArray(opt[2])) {
    res.children = translateOptions(opt[2]);
  }
  return res;
}