"use strict";

var _interopRequireDefault = require("/usr/Jenkins/workspace/huaweidev-agentvue/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _index = _interopRequireDefault(require("./blob/index"));
var _index2 = _interopRequireDefault(require("./local/index"));
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'Storage',
  components: {
    Blob: _index.default,
    Local: _index2.default
  },
  data: function data() {
    return {
      activeName: 'local'
    };
  },
  methods: {
    tabClick: function tabClick(name) {
      if (this.activeName === 'local') {
        this.$refs.local.getList();
      } else {
        //this.$refs.qiNiu.crud.toQuery()
      }
    }
  }
};
exports.default = _default;