var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        "close-on-click-modal": false,
        visible: _vm.dialogFormVisible,
        title: _vm.formTitle,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: function ($event) {
          return _vm.cancel()
        },
      },
    },
    [
      _c(
        "el-form",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.formLoading,
              expression: "formLoading",
            },
          ],
          ref: "form",
          attrs: {
            inline: true,
            model: _vm.form,
            rules: _vm.rules,
            size: "small",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-tabs",
            {
              on: { "tab-click": _vm.handleTabClick },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-tab-pane",
                { attrs: { label: "基础信息", name: "first" } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "图片", prop: "img" } },
                    [
                      _c("SaasUplaod", {
                        attrs: {
                          callback: _vm.uploadIcon,
                          imgUrl: _vm.form.img,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标题", prop: "title" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "380px" },
                        attrs: { maxlength: 20 },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "副标题", prop: "versions" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "380px" },
                        attrs: { maxlength: 50 },
                        model: {
                          value: _vm.form.subTitle,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "subTitle", $$v)
                          },
                          expression: "form.subTitle",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "productType", label: "商品类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          model: {
                            value: _vm.form.productType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "productType", $$v)
                            },
                            expression: "form.productType",
                          },
                        },
                        _vm._l(_vm.productTypes, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.text, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "零售价", prop: "price" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              precision: 2,
                              step: 0.01,
                              max: 99999,
                              controls: false,
                              "controls-position": "right",
                            },
                            on: {
                              focus: function ($event) {
                                return $event.currentTarget.select()
                              },
                            },
                            model: {
                              value: _vm.form.price,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "price", $$v)
                              },
                              expression: "form.price",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "单位", prop: "unit" } },
                        [
                          _c(
                            "el-select",
                            {
                              attrs: { placeholder: "请选择单位" },
                              model: {
                                value: _vm.form.unit,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "unit", $$v)
                                },
                                expression: "form.unit",
                              },
                            },
                            _vm._l(_vm.productUnitType, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.text, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "备注", prop: "versions" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "380px" },
                        attrs: { maxlength: 100 },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "是否启用", prop: "status" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.form.states,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "states", $$v)
                                },
                                expression: "form.states",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: true } }, [
                                _vm._v("启用"),
                              ]),
                              _c("el-radio", { attrs: { label: false } }, [
                                _vm._v("禁用"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-tab-pane",
                { attrs: { label: "产品介绍", name: "second" } },
                [
                  _c(
                    "el-card",
                    {
                      staticClass: "box-card",
                      staticStyle: { "margin-top": "20px" },
                    },
                    [
                      _c(
                        "el-divider",
                        { attrs: { "content-position": "left" } },
                        [_vm._v("描述 ")]
                      ),
                      _vm.dialogFormVisible
                        ? _c("Tinymce", {
                            key: _vm.key,
                            ref: "tinymce",
                            attrs: { height: 200 },
                            model: {
                              value: _vm.form.content,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "content", $$v)
                              },
                              expression: "form.content",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.form.productType == 0
                ? _c(
                    "el-tab-pane",
                    { attrs: { label: "配置", name: "three" } },
                    [
                      _c(
                        "el-row",
                        { attrs: { gutter: 4 } },
                        [
                          _c(
                            "el-col",
                            { attrs: { span: 6 } },
                            [
                              _c(
                                "el-card",
                                { staticStyle: { height: "300px" } },
                                [
                                  _c(
                                    "el-checkbox-group",
                                    {
                                      on: { change: _vm.handleChecked },
                                      model: {
                                        value: _vm.checkList,
                                        callback: function ($$v) {
                                          _vm.checkList = $$v
                                        },
                                        expression: "checkList",
                                      },
                                    },
                                    _vm._l(
                                      _vm.featuresList,
                                      function (item, index) {
                                        return _c(
                                          "el-col",
                                          {
                                            key: item.key,
                                            staticStyle: {
                                              "margin-top": "5px",
                                            },
                                            attrs: { span: 20 },
                                          },
                                          [
                                            _c(
                                              "el-checkbox",
                                              {
                                                attrs: {
                                                  label: item.key,
                                                  disabled:
                                                    item.key == "Base"
                                                      ? true
                                                      : false,
                                                },
                                                nativeOn: {
                                                  click: function ($event) {
                                                    return _vm.checkGroup(
                                                      $event,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.$t(item.key)) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      }
                                    ),
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "el-col",
                            { attrs: { span: 18 } },
                            [
                              _c(
                                "el-card",
                                { staticStyle: { height: "300px" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "clearfix",
                                      attrs: { slot: "header" },
                                      slot: "header",
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t(this.title))),
                                      ]),
                                    ]
                                  ),
                                  _vm._l(
                                    _vm.featuresListDetail.details,
                                    function (item, index) {
                                      return _c(
                                        "el-form-item",
                                        {
                                          key: index,
                                          attrs: { label: _vm.$t(item.key) },
                                        },
                                        [
                                          _c("el-input", {
                                            staticStyle: { width: "380px" },
                                            on: {
                                              change: function ($event) {
                                                return _vm.search(
                                                  $event,
                                                  item.key,
                                                  index
                                                )
                                              },
                                            },
                                            model: {
                                              value: item.value,
                                              callback: function ($$v) {
                                                _vm.$set(item, "value", $$v)
                                              },
                                              expression: "item.value",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-checkbox",
                    {
                      attrs: { disabled: _vm.disabled },
                      model: {
                        value: _vm.checked,
                        callback: function ($$v) {
                          _vm.checked = $$v
                        },
                        expression: "checked",
                      },
                    },
                    [_vm._v("保存后自动新增")]
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "text" },
                  on: { click: _vm.cancel },
                },
                [_vm._v("取消")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    loading: _vm.formLoading,
                    type: "primary",
                  },
                  on: { click: _vm.save },
                },
                [_vm._v("确认")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }